import React, { useState } from "react";

import Button from "~/components/elements/Button";

import logo from "../../../assets/images/logo_color.svg";
import { useTranslation } from "react-i18next";
import GoogleIcon from "~/components/shared/icons/GoogleIcon";
import Input from "~/components/elements/Input";
import InputAuthenticityToken from "~/components/utils/InputAuthenticityToken";

export default function LoginForm(props) {
  const { t } = useTranslation();

  const { email: initialEmail } = props;

  const [email, setEmail] = useState(initialEmail || "");
  const [password, setPassword] = useState("");

  const isValid = !!email && !!password;

  return (
    <div className="max-w-[512px]">
      <a href="https://www.inro.social" className="block" target="_blank">
        <img src={logo} className="w-[112px] h-auto" />
      </a>

      <div className="space-y-4 mb-8">
        <h1 className="text-4xl sm:text-5xl text-headings font-black whitespace-pre-line">
          {t("login.tagline")}
        </h1>
        <p className="text-muted">{t("login.description")}</p>
      </div>

      <div className="space-y-6">
        {/* Oauth links */}
        <div className="grid grid-cols-1 gap-2">
          {/* <Button
            href="/users/auth/facebook"
            label={t("login.facebook")}
            icon={FacebookIcon}
          /> */}
          <Button
            href="/users/auth/google_oauth2"
            label={t("login.google")}
            icon={GoogleIcon}
          />
        </div>
        {/* Divider */}
        <div className="flex justify-center relative">
          <div className="absolute w-full h-0 border-b top-1/2" />
          <div className="px-2 relative bg-white text-muted">
            {t("login.email_title")}
          </div>
        </div>
        {/* Email form */}
        <form className="space-y-4" method="POST" action="/users/sign_in">
          <InputAuthenticityToken />
          <Input
            label={t("login.email_label")}
            placeholder={t("login.email_placeholder")}
            value={email}
            onChange={setEmail}
            type="email"
            autoComplete="email"
            name="user[email]"
            id="user_email"
          />
          <div className="space-y-1">
            <Input
              label={t("login.password_label")}
              value={password}
              onChange={setPassword}
              type="password"
              autoComplete="current-password"
              name="user[password]"
              id="user_password"
            />
            <a
              href="/users/password/new"
              className="text-muted text-xs underline"
            >
              {t("login.forgot_password")}
            </a>
          </div>
          <Button
            className="w-full"
            style="primary"
            label={t("login.log_in")}
            disabled={!isValid}
            loadingOnClick
          />
        </form>
        <div className="p-4 text-center">
          {t("login.no_account")}{" "}
          <a href="/users/sign_up" className="text-primary underline">
            {t("login.sign_up_today")}
          </a>
        </div>
      </div>
    </div>
  );
}
