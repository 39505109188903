import {
  BoltIcon,
  CreditCardIcon,
  SparklesIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import { CurrencyEuroIcon } from "@heroicons/react/24/solid";

export default function EnterprisePlan() {
  const { t } = useTranslation();

  const handleContactSales = useCallback(() => {
    window.Intercom(
      "showNewMessage",
      "Hi, I'm interested in the Enterprise Plan. Can you help me with that?",
    );
  }, [window.Intercom]);

  return (
    <div className="p-6 pb-12 space-y-6 border rounded-2xl text-headings">
      <div className="space-y-2">
        <UserIcon className="w-8 h-8" />
        <div className="text-lg leading-6 font-medium">
          {t("onboarding.free_trial.enterprise_plan.title")}
        </div>
        <div className="leading-5">
          {t("onboarding.free_trial.enterprise_plan.description")}
        </div>
      </div>
      <div className="font-black text-4xl leading-none">
        {t("onboarding.free_trial.enterprise_plan.price")}
      </div>
      <Button
        label={t("onboarding.free_trial.enterprise_plan.cta")}
        className="w-full"
        onClick={handleContactSales}
      />
      <div className="space-y-4">
        <div className="flex items-start space-x-2">
          <CreditCardIcon className="w-5 flex-shrink-0" />
          <div className="font-semibold text-sm leading-5">
            {t("onboarding.free_trial.enterprise_plan.pay_monthly")}
            <div className="text-xs leading-4 text-muted font-normal">
              {t("onboarding.free_trial.enterprise_plan.save_yearly")}
            </div>
          </div>
        </div>
        <div className="flex space-x-2">
          <UsersIcon className="w-5 flex-shrink-0" />
          <div className="font-semibold text-sm leading-5">
            {t("onboarding.free_trial.enterprise_plan.contacts_limit")}
          </div>
        </div>
        <div className="flex space-x-2">
          <BoltIcon className="w-5 flex-shrink-0" />
          <div className="font-semibold text-sm leading-5">
            {t("onboarding.free_trial.enterprise_plan.automation_limit")}
          </div>
        </div>
        <div className="flex space-x-2">
          <CurrencyEuroIcon className="w-5 flex-shrink-0" />
          <div className="font-semibold text-sm leading-5">
            {t("onboarding.free_trial.enterprise_plan.payments")}
          </div>
        </div>
        <div className="flex space-x-2">
          <SparklesIcon className="w-5 flex-shrink-0" />
          <div className="font-semibold text-sm leading-5">
            {t("onboarding.free_trial.enterprise_plan.ai_agent")}
          </div>
        </div>
      </div>
    </div>
  );
}
