import React from "react";
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from "@xyflow/react";

export default function OptionEdge(props) {
  const { markerEnd, data } = props;

  const { label } = data;

  const [edgePath, labelX, labelY] = getSmoothStepPath(props);

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        className="bg-medium-gray hover:bg-dark-gray"
      />
      <EdgeLabelRenderer>
        <div
          className="border rounded-2xl bg-white pl-2.5 pr-2 py-1 text-sm"
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
        >
          {label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
