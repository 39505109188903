import React from "react";

export default function InvisibleInput(props) {
  const { value, onChange, name, ...passthroughProps } = props;

  return (
    <input
      value={value || ""}
      name={name}
      onChange={(evt) => onChange(evt.target.value)}
      {...passthroughProps}
    />
  );
}
