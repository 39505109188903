import React from "react";
import classNames from "~/utils/classNames";
import { SparklesIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

const sizes = {
  sm: "px-2 py-0.5 text-xs",
  md: "px-2.5 py-1 text-xs",
};

export default function AiBadge(props) {
  const { className = "", size = "md", ...passthroughProps } = props;

  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        "inline-flex items-center font-medium whitespace-nowrap rounded-md",
        sizes[size],
        `text-slate-100 bg-gradient-to-br from-slate-800 to-slate-500`,
        className,
      )}
      {...passthroughProps}
    >
      <SparklesIcon className="w-3 mr-1 flex-shrink-0" />
      {t("shared.ai")}
    </div>
  );
}
