import React from "react";
import RelativeDate from "~/components/utils/RelativeDate";
import { useTranslation } from "react-i18next";
import Button from "../../elements/Button";
import TextMessage from "../../messages/TextMessage";
import { REACTION_EMOJIS } from "../../../constants";

export default function StoryReplyItem(props) {
  const { t } = useTranslation();

  const { reply } = props;

  const { content, received_at, contact, conversation_id } = reply;

  const isReaction = REACTION_EMOJIS.includes(content);

  return (
    <div
      className={
        "w-full p-3 flex-shrink-0 overflow-hidden flex space-x-2 flex-grow items-start justify-between group"
      }
    >
      <a href={contact.instagram_url} target="_blank" className="flex-shrink-0">
        <img
          className={"w-5 h-5 rounded-full"}
          src={contact?.profile_picture}
        />
      </a>
      {/* Infos */}
      <div className="flex-grow flex flex-col items-start overflow-hidden">
        {contact.username?.length > 0 && (
          <a
            href={contact.instagram_url}
            target="_blank"
            className={`block truncate text-xs font-medium leading-none mb-2`}
            dangerouslySetInnerHTML={{ __html: contact.username }}
          />
        )}
        {isReaction ? (
          <div className="text-4xl">{content}</div>
        ) : (
          <TextMessage text={content} received />
        )}
      </div>
      {/* Date & status */}
      <div className="flex flex-col flex-shrink-0 space-y-2 justify-between items-end">
        <div className="text-xs font-light text-neutral-300 whitespace-nowrap ml-2 mb-1">
          <RelativeDate date={received_at} />
        </div>
        {conversation_id && (
          <Button
            to={`/inbox/conversations/${conversation_id}?messageId=${reply.id}`}
            label={t("content.stories.replies.view_conversation")}
            size="extra-small"
            className="opacity-0 group-hover:opacity-100 transition-opacity"
          />
        )}
      </div>
    </div>
  );
}
