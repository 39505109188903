import React from "react";

export default function EmptyInbox(props) {
  const { title, emoji, caption, button, className = null } = props;

  return (
    <div
      className={`flex-grow flex flex-col items-center justify-center py-16 p-6 ${className}`}
    >
      <div className="text-4xl mb-2">{emoji}</div>
      <h2 className="text-lg text-center mb-4">{title}</h2>
      <p className="text-center text-sm">{caption}</p>
      {button}
    </div>
  );
}
