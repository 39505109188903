import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import {
  appInset,
  desktopSidebarWidth,
  tabBarHeight,
} from "~/utils/environment";
import { isMobile } from "react-device-detect";

export default function InboxModalError(props) {
  const { children, onBackgroundClick } = props;

  return createPortal(
    <div
      className="fixed sm:absolute inset-0 px-4 py-6 flex flex-col justify-center items-center"
      style={{
        zIndex: 1000,
        bottom: isMobile ? tabBarHeight + appInset.bottom : 0,
        left: isMobile ? 0 : desktopSidebarWidth,
      }}
    >
      <div
        className={`absolute inset-0 bg-white bg-opacity-75`}
        onClick={onBackgroundClick}
      />
      <div className="relative bg-white border border-neutral-100 shadow-lg rounded-xl [max-width:360px] p-6">
        {children}
      </div>
    </div>,
    document.body,
  );
}
