import React from "react";
import { useTranslation } from "react-i18next";

const insightKeys = ["impressions", "reach"];

export default function StoryInsights(props) {
  const { t } = useTranslation();

  const { insights } = props;

  return (
    <div className="p-4 space-y-2">
      {insightKeys.map((key) => (
        <div key={key} className="flex justify-between">
          <div className="text-sm text-muted">
            {t("content.stories.insights." + key)}
          </div>
          <div className="text-sm font-medium">{insights[key]}</div>
        </div>
      ))}
    </div>
  );
}
