import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "~/utils/classNames";

const InputOption = (props) => {
  const { option } = props;

  if (typeof option === "string") {
    return <option value={option}>{option}</option>;
  } else if (typeof option === "object") {
    return (
      <option value={option.value} disabled={option.disabled}>
        {option.label}
      </option>
    );
  }

  return null;
};

export default function InputSelect(props) {
  const { t } = useTranslation();

  const {
    label,
    optional = false,
    placeholder,
    value,
    onChange = () => {},
    options = [],
    className = "",
    ...passthroughProps
  } = props;

  return (
    <div className={classNames("space-y-1", className)}>
      {label && (
        <div className="flex justify-between">
          <label className="input-label">{label}</label>
          {optional ? (
            <span
              className="text-xs mt-1 leading-tight text-muted"
              id="email-optional"
            >
              {t("shared.optional")}
            </span>
          ) : null}
        </div>
      )}
      <select
        className={classNames(
          "w-full px-3 py-2.5 pr-8 outline-none border-none ring-1 ring-border rounded-lg shadow-sm text-sm leading-5 placeholder:text-muted focus:ring-2",
          value ? "text-body" : "text-placeholder",
        )}
        value={value}
        onChange={(evt) => onChange(evt.target.value)}
        {...passthroughProps}
      >
        {placeholder && (
          <option value="" selected disabled>
            {placeholder}
          </option>
        )}
        {options.map((option, index) => (
          <InputOption key={index} option={option} />
        ))}
      </select>
    </div>
  );
}
