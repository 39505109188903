import axios from "axios";

const contactStatuses = [
  "pending",
  "active",
  "commented",
  "live_commented",
  "requested",
  "optout",
  "deleted",
  "inactive",
  // "spam",
];

const getContacts = async (params) => {
  const response = await axios.get(`/api/contacts/contacts`, { params });
  return response.data;
};

const getContact = async (id) => {
  const response = await axios.get(`/api/contacts/contacts/${id}`);
  return response.data;
};

export { contactStatuses, getContacts, getContact };
