import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import ActionNode from "./ActionNode";
import FolderPickerField from "~/components/fields/FolderPickerField";

export default function ContentFilterNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, options = {} } = data;

  const { folder_id } = options;

  const { editAction } = useContext(FlowContext);

  // This function generates a function
  const handleChangeAction = (option) => (value) => {
    editAction(id, { options: { ...options, [option]: value } });
  };

  return (
    <ActionNode {...props}>
      <div
        className={`py-3 px-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="text-md font-medium mb-2">
          {t("automation.scenarios.actions.content_filter.title")}
        </div>
        <div className="text-2sm text-darker-gray mb-2">
          {t("automation.scenarios.actions.content_filter.description")}
        </div>
        <div className="mt-2 flex items-center justify-start space-x-2">
          <div className="text-2sm">
            {t("automation.scenarios.actions.content_filter.add_to_folder")}
          </div>
          <FolderPickerField
            value={folder_id}
            onChange={handleChangeAction("folder_id")}
          />
        </div>
      </div>
    </ActionNode>
  );
}
