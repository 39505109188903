import React, { useContext } from "react";
import { UIContext } from "~/contexts/ui-context";
import { useTranslation } from "react-i18next";

export default function ProfileSelect(props) {
  const {
    options,
    value,
    onChange,
    label,
    description,
    className = "",
  } = props;

  const { t } = useTranslation();

  const { showMenu } = useContext(UIContext);

  const selectedOption = options.find((option) => option.value === value);

  const handleSelect = () => {
    showMenu({
      title: label,
      actions: options.map((option) => ({
        label: option.label,
        bold: option.value === value,
        selected: option.value === value,
        className: option.className,
        action: () => onChange(option.value),
      })),
    });
  };

  return (
    <div className={`px-4 py-6 ${className}`}>
      <div className="flex items-center justify-between mb-4">
        <div className=" text-md">{label}</div>
        <div
          className={`${
            selectedOption?.disabled || !selectedOption
              ? "bg-light-gray"
              : "bg-light-primary"
          } rounded-lg px-2 py-1 text-2xs font-medium`}
          onClick={handleSelect}
        >
          {selectedOption?.label || t("shared.select_option")}
        </div>
      </div>
      {description ? (
        <div className="text-2xs text-dark-gray">{description}</div>
      ) : null}
    </div>
  );
}
