import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import formatAmount from "~/utils/formatAmount";
import paymentClassName from "~/utils/paymentClassName";
import paymentStatusIcon from "~/utils/paymentStatusIcon";
import AppointmentStatusIcon from "../appointments/AppointmentStatusIcon";
import UnrepliedIcon from "./UnrepliedIcon";
import RepliedIcon from "./RepliedIcon";
import { PaymentsContext } from "~/contexts/payments-context";
import { CalendarContext } from "~/contexts/calendar-context";
import { DateTime } from "luxon";
import { UserContext } from "~/contexts/user-context";
import {
  ExclamationCircleIcon,
  BoltIcon,
  ExclamationTriangleIcon,
  ClockIcon,
} from "@heroicons/react/20/solid";
import { reverse, sortBy } from "lodash";
import Spinner from "~/components/utils/Spinner";

export default function ConversationStatusIcon(props) {
  const { t } = useTranslation();

  const { conversation } = props;

  const { user, organization } = useContext(UserContext);
  const { payments } = useContext(PaymentsContext);
  const { appointments } = useContext(CalendarContext);

  const payment_awaiting_or_paid = reverse(sortBy(payments, "created_at")).find(
    (payment) =>
      payment.conversation_id === conversation.id &&
      !payment.archived &&
      (payment.status === "awaiting" || payment.status === "paid"),
  );

  // Fake data
  const clientsFolder = organization?.folders.find((f) => f.name === "Clients");

  if (
    organization.dev &&
    clientsFolder &&
    conversation.folder_ids?.includes(clientsFolder?.id)
  ) {
    return (
      <div className="bg-yellow-500 text-dark-yellow-500 font-medium rounded-md px-2 py-1 text-2xs">
        Order #2357
      </div>
    );
  }

  // Error messages

  if (conversation.error_messages) {
    return <ExclamationCircleIcon className="w-4 text-red-400" />;
  }

  if (conversation.limited_executions) {
    return <ClockIcon className="w-4 text-yellow-500" />;
  }

  if (conversation.failed_executions) {
    return <ExclamationCircleIcon className="w-4 text-red" />;
  }

  if (conversation.pending_messages) {
    return <Spinner className="text-neutral-300 w-4" />;
  }

  if (conversation.pending_executions) {
    return <Spinner className="text-automation w-4" />;
  }

  // Payment and appointment statuses

  if (payment_awaiting_or_paid) {
    return (
      <div
        className={`rounded px-1.5 py-1 text-xs flex items-center space-x-1 ${paymentClassName(
          payment_awaiting_or_paid.status,
        )}`}
      >
        {paymentStatusIcon(payment_awaiting_or_paid.status)}
        <div>{formatAmount(payment_awaiting_or_paid.total, user.currency)}</div>
      </div>
    );
  }

  const appointment_upcoming = appointments.find(
    (appointment) =>
      appointment.conversation_id === conversation.id &&
      appointment.status === "confirmed" &&
      DateTime.fromISO(appointment.date) > DateTime.local(),
  );

  if (appointment_upcoming) {
    return (
      <AppointmentStatusIcon
        status="confirmed"
        date={appointment_upcoming.date}
      />
    );
  }

  const appointment_awaiting = appointments.find(
    (appointment) =>
      appointment.conversation_id === conversation.id &&
      appointment.status === "awaiting" &&
      DateTime.fromISO(appointment.date) > DateTime.local(),
  );

  if (appointment_awaiting) {
    return <AppointmentStatusIcon status="awaiting" />;
  } else if (conversation.unreplied) {
    return <UnrepliedIcon className="w-3 h-3 text-neutral-300 mt-1" />;
  } else {
    return <RepliedIcon className="w-3 h-3 text-neutral-300 mt-1" />;
  }
}
