import React, { useState } from "react";

import { delay } from "lodash";

export default function Folder(props) {
  const {
    label,
    count,
    hideCount = false,
    color = "primary",
    active,
    onClick,
    onLongPress,
    className,
  } = props;

  const [touchTime, setTouchTime] = useState(null);

  const handleClick = () => {
    const time = new Date();
    if (time?.getTime() >= touchTime?.getTime() + 300) {
      onLongPress();
    } else {
      onClick();
    }
  };

  return (
    <>
      <button
        onClick={handleClick}
        onTouchStart={() => setTouchTime(new Date())}
        onTouchEnd={() => delay(() => setTouchTime(null), 100)}
        className={`flex-grow w-full h-full flex justify-center items-center px-4 py-2 border-b-2 whitespace-nowrap select-none transition-colors  ${
          active ? `border-${color}` : ""
        } ${className}`}
      >
        {/* {label !== "All" && color !== "orange" ? (
          <div className={`w-2 h-2 mr-1 mt-0.5 rounded-full bg-${color}`} />
        ) : null} */}
        <div className={active ? "text-black" : "text-dark-gray"}>{label}</div>
        {hideCount || !count ? null : (
          <div
            className={`ml-1.5 mt-0.5 px-1 py-0.5 text-dark-gray bg-light-gray rounded text-4xs`}
          >
            {count}
          </div>
        )}
      </button>
    </>
  );
}
