import React from "react";
import classNames from "~/utils/classNames";

const labelSizes = {
  sm: "ml-1.5 mt-0.5 text-xs",
  md: "ml-2 text-md leading-5 mt-0.25 sm:mt-0 sm:text-sm",
  lg: "ml-3 text-lg sm:text-base",
};

export default function InputCheckbox(props) {
  const {
    value,
    onClick = () => {},
    id,
    name,
    label,
    icon,
    className,
    disabled,
    size = "md",
    children,
    ...passthroughProps
  } = props;

  const Icon = icon || null;

  return (
    <div
      className={classNames(
        "flex items-start cursor-pointer",
        disabled && "opacity-50 pointer-events-none",
        className,
      )}
      onClick={() => onClick(!value)}
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        readOnly
        checked={value}
        className={classNames(
          "mt-0.5 h-5 w-5 sm:h-4 sm:w-4 rounded border-gray-300 text-primary pointer-events-none",
          "focus:ring-primary focus:ring-2 focus:ring-offset-2",
        )}
        {...passthroughProps}
      />
      {icon && <Icon className="w-5 h-5 ml-2" />}
      {label && (
        <label
          htmlFor={name}
          className={classNames(
            "font-medium text-gray-900 pointer-events-none",
            labelSizes[size],
          )}
        >
          {label}
        </label>
      )}
      {children}
    </div>
  );
}
