import React, { useContext, useEffect, useState } from "react";
import shopifyIcon from "../../../../assets/images/shopify_icon.png";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import Button from "~/components/elements/Button";
import axios from "axios";
import { isEmpty } from "lodash";
import InputField from "~/components/shared/InputField";
import ExternalAccount from "./ExternalAccount";
import Input from "../../elements/Input";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";

export default function ShopifyAccount() {
  const { organization, updateOrganization } = useContext(UserContext);
  const { shopify_account } = organization;

  const { t } = useTranslation();

  const [shopifyDomain, setShopifyDomain] = useState(
    shopify_account?.domain || "",
  );

  const [shopifyToken, setShopifyToken] = useState(
    shopify_account?.token || "",
  );

  const handleSaveDetails = async () => {
    updateOrganization({
      shopify_domain: shopifyDomain,
      shopify_token: shopifyToken,
    });
  };

  const handleConnectShopify = () => {
    if (isEmpty(shopifyDomain)) return;

    window.location.replace(
      `/users/auth/shopify?shopify_domain=${shopifyDomain}`,
    );
  };

  const [shopifyInfo, setShopifyInfo] = useState(null);

  const getShopifyInfo = () => {
    axios.get("/api/shopify/shop").then((res) => setShopifyInfo(res.data));
  };

  useEffect(() => {
    if (shopify_account.connected) getShopifyInfo();
  }, [shopify_account.connected]);

  return (
    <ExternalAccount
      icon={shopifyIcon}
      provider="shopify"
      connected={shopify_account.connected}
      name={shopifyInfo?.name}
      subname={shopifyInfo?.primaryDomain?.host}
      externalUrl={shopifyInfo?.url}
      connect={
        <div className="space-y-3">
          <Input
            value={shopifyDomain}
            onChange={setShopifyDomain}
            trailingAddon=".myshopify.com"
          />
          <Input
            value={shopifyToken}
            onChange={setShopifyToken}
            placeholder={t("integrations.shopify.token")}
            trailingAddon={
              <a
                href="https://help.inro.social/en/articles/10244744-how-to-connect-a-shopify-store"
                target="_blank"
              >
                <QuestionMarkCircleIcon className="w-5 text-neutral-400" />
              </a>
            }
          />
          <Button
            label={t("integrations.shopify.save")}
            className="w-full"
            disabled={isEmpty(shopifyDomain) || isEmpty(shopifyToken)}
            style="primary"
            onClick={handleSaveDetails}
          />
          {/* <Button
            label={t("integrations.shopify.connect")}
            className="w-full"
            disabled={isEmpty(shopifyDomain)}
            onClick={handleConnectShopify}
          /> */}
        </div>
      }
    />
  );
}
