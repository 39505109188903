import React, { useContext } from "react";
import PaymentLinkCard from "./PaymentLinkCard";

import backgroundDefault from "../../../assets/images/payment_background_square_default.png";
import backgroundPurple from "../../../assets/images/payment_background_square_purple.png";
import backgroundYellow from "../../../assets/images/payment_background_square_yellow.png";
import backgroundDark from "../../../assets/images/payment_background_square_dark.png";
import { UserContext } from "~/contexts/user-context";

const backgrounds = {
  default: backgroundDefault,
  purple: backgroundPurple,
  yellow: backgroundYellow,
  dark: backgroundDark,
};

export default function PaymentLinkPreview(props) {
  const { organization, total, currency, background = "default" } = props;

  const backgroundImage =
    background == "custom"
      ? organization.custom_payment_background_url
      : backgrounds[background];

  return (
    <div className="w-72 h-72 bg-light-primary relative">
      <img
        src={backgroundImage}
        className="w-full h-full object-cover object-center"
      />
      <div className="flex items-center justify-center p-4 absolute inset-0">
        <PaymentLinkCard
          organization={organization}
          total={total}
          currency={currency}
        />
      </div>
    </div>
  );
}
