import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ContentPickerField from "./ContentPickerField";
import MediaPickerDialog from "./MediaPickerDialog";

export default function MediaPickerField(props) {
  const { t } = useTranslation();

  const { value = "", onChange } = props;

  const [mediaPickerVisible, showMediaPicker] = useState(false);

  return (
    <>
      <ContentPickerField
        value={value}
        onChange={onChange}
        inputPlaceholder={t("content.medias.media_id")}
        buttonPlaceholder={t("content.medias.pick_media")}
        onClick={() => showMediaPicker(true)}
      />
      {mediaPickerVisible ? (
        <MediaPickerDialog
          onClose={() => showMediaPicker(false)}
          onChange={onChange}
        />
      ) : null}
    </>
  );
}
