import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import LoadingImage from "../../utils/LoadingImage";
import { SubscriptionContext } from "../../../contexts/subscription-context";
import ProTag from "../../shared/ProTag";

export default function AddActionMenuItem(props) {
  const { t } = useTranslation();

  const { isPro } = useContext(SubscriptionContext);

  const { action, disabled, onClick } = props;

  const { action_type, icon: Icon = null, pro } = action;

  return (
    <button
      key={action_type}
      className="w-64 rounded-lg flex-shrink-0 border shadow-sm hover:shadow-md transition-all overflow-hidden bg-white"
      onClick={onClick}
      disabled={disabled}
    >
      <LoadingImage
        src={`/images/action_previews/${action_type}.png`}
        alt={t(`automation.scenarios.actions.${action_type}.title`)}
        className="block w-full h-[137px] object-cover"
        width={256}
        height={137}
        hideOnError
      />
      <div className="p-3 space-y-3">
        {Icon && <Icon className="w-8" />}
        <div className="flex justify-between items-start">
          <div className="text-left">
            <div className="font-medium text-sm">
              {t(`automation.scenarios.actions.${action_type}.title`)}
            </div>
            <div className="text-xs leading-4 text-muted">
              {t(`automation.scenarios.actions.${action_type}.description`)}
            </div>
          </div>
          {pro && !isPro && <ProTag />}
        </div>
      </div>
    </button>
  );
}
