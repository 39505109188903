import React from "react";
import { useTranslation } from "react-i18next";

export default function ProTag() {
  const { t } = useTranslation();
  return (
    <div className="bg-gradient-to-tr from-black to-neutral-600 text-white font-medium text-xs px-2 py-0.5 rounded">
      {t("shared.pro")}
    </div>
  );
}
