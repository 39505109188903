import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import LongPress from "~/components/utils/LongPress";

export default function FolderNotifications(props) {
  const { folder } = props;

  const { t } = useTranslation();

  const { setEditingFolderId, showFolderNotificationsMenu } =
    useContext(UserContext);

  const settingLabel = (setting) => {
    switch (setting) {
      case "real_time":
        return t("inbox.folders.notifications_real_time");
      case "daily":
        return t("inbox.folders.notifications_daily");
      case "weekly":
        return t("inbox.folders.notifications_weekly");
      default:
        return t("shared.disabled");
    }
  };

  return (
    <div className="p-4 flex items-center justify-between border-b">
      <LongPress
        className="flex items-center space-x-3"
        onPress={() => setEditingFolderId(folder.id)}
      >
        <div className={`w-4 h-4 rounded-full bg-${folder.color}-500`} />
        <div className="text-md">{folder.name}</div>
      </LongPress>
      <div
        className={`px-2 py-1 rounded-xl text-xs ${
          folder.notifications_setting == "none"
            ? "bg-light-gray"
            : "bg-light-primary"
        }`}
        onClick={() => showFolderNotificationsMenu(folder)}
      >
        {settingLabel(folder.notifications_setting)}
      </div>
    </div>
  );
}
