import React, { useEffect, useRef, useState } from "react";
import { delay, isNumber, throttle } from "lodash";
import Loader from "~/components/utils/Loader";

const ConversationScroller = React.forwardRef((props, ref) => {
  const { context } = props;

  const { onRefresh, scrollingRef, selecting } = context;

  const [loading, setLoading] = useState(false);
  const loaderRef = useRef(null);

  const throttledRefresh = throttle(onRefresh, 5000);

  const scrollTimeout = useRef(null);

  const throttledScroll = throttle((evt) => {
    scrollingRef.current = true;

    scrollTimeout.current && clearTimeout(scrollTimeout.current);
    scrollTimeout.current = setTimeout(handleScrollStop, 10);

    const scrollPosition = ref.current.scrollTop;
    if (scrollPosition <= 0 && !loading && !selecting) {
      const loaderOffset = Math.min(-scrollPosition, 48);
      loaderRef.current.style.transform = `translateY(${loaderOffset}px)`; // Pull down
    }
  }, 16.67);

  const handleScrollStop = () => {
    if (touchingRef.current) {
      scrollTimeout.current = setTimeout(handleScrollStop, 10);
    } else {
      scrollingRef.current = false;
    }
  };

  const touchingRef = useRef(false);

  const handleTouchStart = (evt) => {
    touchingRef.current = true;
  };

  const handleTouchEnd = (evt) => {
    touchingRef.current = false;
    const scrollPosition = ref.current.scrollTop;
    if (
      isNumber(scrollPosition) &&
      scrollPosition < -50 &&
      !loading &&
      !selecting
    ) {
      setLoading(true);
      loaderRef.current.style.transform = `translateY(0px)`; // Pull down
      throttledRefresh(() => setTimeout(() => setLoading(false), 1000));
    }
  };

  return (
    <div
      className={`flex-grow h-full overflow-hidden relative ${
        loading ? "pt-12" : null
      }`}
    >
      <div
        className={`absolute ${
          loading ? "top-0" : "-top-12"
        } inset-x-0 h-12 flex items-center justify-center`}
        ref={loaderRef}
      >
        <Loader width={20} strokeWidth={8} loading={loading} />
      </div>
      <div
        className={`h-full overflow-y-scroll scrollbar-hide relative`}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onScroll={throttledScroll}
        ref={ref}
        {...props}
      />
    </div>
  );
});

export default ConversationScroller;
