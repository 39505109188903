import React, { memo } from "react";
import RelativeDate from "~/components/utils/RelativeDate";
import ContactPicture from "~/components/shared/ContactPicture";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import FolderTag from "./folders/FolderTag";
import Loader from "~/components/utils/Loader";

export default memo(function ConversationItemInfo(props) {
  const {
    loading,
    name,
    contact,
    folders,
    activeFolder,
    excerpt,
    last_message_at,
    statusIcon = null,
    smaller = false,
    desktopEllipsis = null,
    unread = false,
  } = props;

  const firstFolder =
    folders.find((f) => f.id === activeFolder) || folders[0] || null;

  return (
    <div className="flex flex-grow items-center justify-between px-3 w-full h-full group">
      {/* Picture */}
      {loading ? (
        <div
          className={`w-11 h-11 p-2 flex-shrink-0 rounded-full bg-light-gray ${smaller ? "mr-2" : "mr-3"}`}
        >
          <Loader stroke="#ffffff" width={28} strokeWidth={8} />
        </div>
      ) : (
        <ContactPicture
          contact={contact}
          large={!smaller}
          className={smaller ? "mr-2" : "mr-3"}
        />
      )}
      {/* Infos */}
      <div className="flex-grow overflow-hidden">
        <div className="leading-tight flex items-center space-x-2 overflow-hidden">
          <div
            className={`truncate ${smaller ? "text-md" : "text-base"} ${
              unread ? "font-semibold" : "font-normal"
            }`}
            dangerouslySetInnerHTML={{ __html: name }}
          />

          {firstFolder ? <FolderTag folder={firstFolder} /> : null}
          {folders.length > 1 ? (
            <div
              className={`text-2xs font-normal text-dark-gray bg-light-gray rounded-full px-2 py-1 whitespace-nowrap flex-shrink-0`}
            >
              +{folders.length - 1}
            </div>
          ) : null}
        </div>
        {loading ? (
          <div className="w-24 h-4 my-0.5 rounded bg-light-gray" />
        ) : (
          <div
            className={`text-sm ${
              unread ? "text-neutral-800 font-semibold" : "text-neutral-400"
            } truncate`}
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        )}
      </div>
      {/* Date & status */}
      <div className={`flex flex-col flex-shrink-0 justify-between items-end`}>
        <div className="text-sm font-light text-neutral-300 whitespace-nowrap ml-2 mb-1">
          <RelativeDate date={last_message_at} />
        </div>
        {statusIcon}
      </div>
      {/* Desktop hover ellipsis */}
      {desktopEllipsis ? (
        <div
          className="w-5 h-5 hidden sm:group-hover:flex items-center justify-center ml-2 text-neutral-300 hover:text-neutral-400 relative"
          onClick={desktopEllipsis}
        >
          <EllipsisHorizontalIcon className="w-4 h-4 transition-colors" />
        </div>
      ) : null}
    </div>
  );
});
