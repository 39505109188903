import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { compact, sortBy, times, unionBy } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "~/components/elements/Input";
import SkeletonLoader from "~/components/utils/SkeletonLoader";
import { InboxContext } from "~/contexts/inbox-context";
import CommentItem from "~/components/comments/CommentItem";
import searchComments from "~/components/comments/searchComments";
import { loadComments } from "../../../api/inbox/comments";

const sortOptions = ["newest", "oldest", "most_replies"];

export default function MediaComments(props) {
  const { t } = useTranslation();

  const { media } = props;

  const [comments, setComments] = useState([]);

  const [loadingComments, setLoadingComments] = useState(true);

  const handleLoadComments = async () => {
    const res = await loadComments({ media_id: media.id });
    setComments(res);
    setLoadingComments(false);
  };

  useEffect(handleLoadComments, [media.id, media.comments_count]);

  const [sortOption, setSortOption] = useState("newest");

  const sortFunction = useMemo(
    () => ({
      newest: (c) => sortBy(c, "received_at").reverse(),
      oldest: (c) => sortBy(c, "received_at"),
      most_replies: (c) => sortBy(c, "replies_count").reverse(),
      most_likes: (c) => sortBy(c, "likes_count").reverse(),
    }),
    [],
  );

  const [searchQuery, setSearchQuery] = useState("");
  const searchedComments = useMemo(
    () => searchComments(compact(comments), searchQuery),
    [searchQuery, comments],
  );

  const topComments = sortFunction[sortOption](searchedComments);

  return (
    <div className="flex flex-col overflow-hidden h-full">
      <div className="p-3 flex justify-between border-b flex-shrink-0">
        <Input
          placeholder={t("shared.search")}
          icon={MagnifyingGlassIcon}
          size="small"
          className="max-w-40 w-full"
          value={searchQuery}
          onChange={setSearchQuery}
        />
        <select
          className="input-select"
          value={sortOption}
          onChange={(evt) => setSortOption(evt.target.value)}
        >
          {sortOptions.map((option) => (
            <option key={option} value={option}>
              {t("comments.sort_options." + option)}
            </option>
          ))}
        </select>
      </div>
      <div className="overflow-auto">
        {loadingComments && (
          <div className="py-2">
            {times(3).map((index) => (
              <div className="flex space-x-2 px-3 mt-2 mb-4" key={index}>
                <SkeletonLoader height={24} width={24} round />
                <SkeletonLoader height={60} width={260} />
              </div>
            ))}
          </div>
        )}
        {comments.length == 0 && !loadingComments && (
          <div className="text-center text-sm py-2 text-gray-500">
            {t("content.medias.no_comments")}
          </div>
        )}
        {topComments.map((comment) => (
          <CommentItem
            key={comment.id}
            comment={comment}
            setComment={(comment) =>
              setComments((comments) => unionBy([comment], comments, "id"))
            }
            children={sortBy(compact(comment.replies), "received_at").map(
              (reply) => (
                <CommentItem
                  key={reply.id}
                  comment={reply}
                  setComment={(reply) => {
                    const newParent = {
                      ...comment,
                      replies: unionBy([reply], comment.replies, "id"),
                    };
                    setComments((comments) =>
                      unionBy([newParent], comments, "id"),
                    );
                  }}
                  isReply
                />
              ),
            )}
          />
        ))}
      </div>
    </div>
  );
}
