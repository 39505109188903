import React, { useState, useEffect, useRef } from "react";

const HOURS = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

const MINUTES = ["00", "15", "30", "45"];

const OPTION_HEIGHT = 32;

export default function TimePicker(props) {
  const { value, onChange, options = null, className = "" } = props;

  const [hours, setHours] = useState(value.split(":")[0]);
  const [minutes, setMinutes] = useState(value.split(":")[1]);

  useEffect(() => {
    if (options) return;
    onChange(`${hours}:${minutes}`);
  }, [hours, minutes]);

  return (
    <div className={`flex px-4 relative ${className}`}>
      <div
        className="absolute inset-y-0 inset-x-0 z-0 m-auto bg-light-primary rounded-md pointer-events-none"
        style={{ height: OPTION_HEIGHT }}
      />
      {options && (
        <TimePickerColumn options={options} value={value} onChange={onChange} />
      )}
      {!options && (
        <>
          <TimePickerColumn options={HOURS} value={hours} onChange={setHours} />
          <TimePickerColumn
            options={MINUTES}
            value={minutes}
            onChange={setMinutes}
          />
        </>
      )}
    </div>
  );
}

function TimePickerColumn(props) {
  const { value, onChange, options } = props;

  const [localValue, setLocalValue] = useState(value);

  const scrollRef = useRef(null);

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    const index = Math.max(
      0,
      Math.min(options.length - 1, Math.round(scrollTop / OPTION_HEIGHT))
    );
    setLocalValue(options[index]);
    onChange(options[index]);
  };

  const handleOptionClick = (index) => {
    scrollRef.current.scrollTo({
      top: index * OPTION_HEIGHT,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const index = options.indexOf(value);
    const offset = index * OPTION_HEIGHT;
    scrollRef.current.scrollTo(0, offset);
  }, []);

  return (
    <div
      className="flex-shrink-0 flex-grow relative"
      style={{ height: OPTION_HEIGHT * 5 }}
    >
      <div
        className="absolute z-10 inset-0 scrollbar-hide overflow-y-auto overscroll-y-contain snap-y snap-mandatory"
        ref={scrollRef}
        onScroll={handleScroll}
      >
        <div style={{ height: OPTION_HEIGHT * 2 }} />
        {options.map((option, index) => (
          <div
            key={option}
            className={`text-center snap-center snap-normal transition-colors ${
              option == localValue ? "text-black" : "text-gray-400"
            }`}
            onClick={() => handleOptionClick(index)}
            style={{
              height: OPTION_HEIGHT,
              lineHeight: `${OPTION_HEIGHT}px`,
            }}
          >
            {option}
          </div>
        ))}
        <div style={{ height: OPTION_HEIGHT * 2 }} />
      </div>
      <div
        className="absolute inset-x-0 top-0 z-20 bg-gradient-to-b from-white to-transparent pointer-events-none"
        style={{ height: OPTION_HEIGHT }}
      />
      <div
        className="absolute inset-x-0 bottom-0 z-20 bg-gradient-to-t from-white to-transparent pointer-events-none"
        style={{ height: OPTION_HEIGHT }}
      />
    </div>
  );
}
