import React from "react";
import Media from "./Media";

export default function Share(props) {
  const { url, received, contact } = props;

  const caption = received
    ? `${contact?.username || "They"} shared a post`
    : `You shared a post`;

  const inboxUrl = `https://ig.me/m/${contact?.username}`;

  const bubbleClass = received ? "bg-white border" : "bg-neutral-100";

  return (
    <div className={`flex flex-col ${received ? "items-start" : "items-end"}`}>
      <div className={`rounded-3xl max-w-2.5xs overflow-hidden ${bubbleClass}`}>
        <div className="text-sm font-medium text-black py-3 px-4 select-none">
          {caption}
        </div>
        <Media src={url} href={inboxUrl} format="square" />
      </div>
    </div>
  );
}
