import React, { useContext, useEffect } from "react";
import { now, range } from "lodash";

import CalendarDay from "./CalendarDay";

import { HOURS, CELL_HEIGHT } from "./constants";
import CalendarDayHeader from "./CalendarDayHeader";
import { CalendarContext } from "~/contexts/calendar-context";
import { UserContext } from "~/contexts/user-context";
import { DateTime } from "luxon";

const CalendarWeek = (props) => {
  const {
    firstDay,
    weekId,
    nowCursorRef,
    events = [],
    connectedEvents = [],
    slots = [],
    addSlot,
    removeSlot,
    eventsActive,
    disablePast,
  } = props;

  const { loadConnectedEvents } = useContext(CalendarContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    loadConnectedEvents(weekId);
  }, []);

  // render days

  const days = range(7).map((index) => firstDay.plus({ days: index }));

  const renderDaysHeader = () =>
    days.map((day, index) => {
      const allDayConnectedEvents = connectedEvents.filter(
        (event) =>
          DateTime.fromISO(event.start_time).hasSame(day, "day") &&
          event.all_day,
      );
      return (
        <CalendarDayHeader
          day={day}
          key={index}
          connectedEvents={allDayConnectedEvents}
        />
      );
    });

  const renderDay = (day, index) => {
    const daySlots = slots.filter((slot) =>
      DateTime.fromISO(slot.start_time).hasSame(day, "day"),
    );
    const dayEvents = events.filter((event) =>
      DateTime.fromISO(event.start_time).hasSame(day, "day"),
    );
    const dayConnectedEvents = connectedEvents.filter(
      (event) =>
        DateTime.fromISO(event.start_time).hasSame(day, "day") &&
        !event.all_day,
    );
    const isPast = day < DateTime.now().startOf("day");
    const isToday = nowCursorRef ? day.hasSame(DateTime.now(), "day") : false;
    return (
      <CalendarDay
        key={index}
        day={day}
        eventsActive={eventsActive}
        addSlot={addSlot}
        removeSlot={removeSlot}
        disabled={disablePast && isPast}
        nowCursorRef={isToday ? nowCursorRef : null}
        slots={daySlots}
        events={dayEvents}
        connectedEvents={dayConnectedEvents}
      />
    );
  };

  return (
    <div className="flex flex-col h-full w-full flex-shrink-0">
      <div className="flex-shrink-0 flex">
        <div className="w-11 flex-shrink-0 flex flex-col justify-between">
          <div className="text-right text-2sm text-dark-gray pt-2 pr-1">
            {firstDay.toFormat("W")}
          </div>
          <div className="text-right text-2xs text-dark-gray pb-4 pr-1">
            {DateTime.now().setZone(user.time_zone).offsetNameShort}
          </div>
        </div>
        <div className="flex-grow grid grid-cols-7">{renderDaysHeader()}</div>
      </div>
      <div className="overflow-y-auto scrollbar-hide flex-grow flex select-none">
        <div className="w-11">
          {HOURS.map((hour) => (
            <div
              className="text-2xs pr-1 text-right"
              style={{ height: `${CELL_HEIGHT}px` }}
              key={hour}
            >
              {hour}:00
            </div>
          ))}
          <div className="h-8" />
        </div>
        <div className="flex-grow grid grid-cols-7">{days.map(renderDay)}</div>
      </div>
    </div>
  );
};

export default CalendarWeek;
