import React from "react";
import { useTranslation } from "react-i18next";
import MediaItem from "~/components/content/medias/MediaItem";
import Loader from "~/components/utils/Loader";
import classNames from "~/utils/classNames";
import ResultsNode from "./ResultsNode";

export default function ShareMediaResultsNode(props) {
  const { t } = useTranslation();
  const { data } = props;
  const { media } = data;

  return (
    <>
      <ResultsNode {...props}>
        {media ? (
          <a
            className={"rounded-xl max-w-2.5xs p-3 border bg-white"}
            href={media.permalink}
            target="_blank"
          >
            <MediaItem media={media} />
          </a>
        ) : (
          <div className="py-3 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl cursor-pointer nodrag opacity-75 hover:opacity-100 transition-opacity flex space-x-2 items-center">
            <Loader width={24} strokeWidth={8} />
            <div className="text-md font-medium text-center">
              {t("automation.scenarios.actions.share_media.loading")}
            </div>
          </div>
        )}
      </ResultsNode>
    </>
  );
}
