import React, { useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  EnvelopeIcon,
  PaperAirplaneIcon,
  BoltIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/outline";
import {
  EnvelopeIcon as EnvelopeIconSolid,
  PaperAirplaneIcon as PaperAirplaneIconSolid,
  BoltIcon as BoltIconSolid,
  PresentationChartLineIcon as PresentationChartLineIconSolid,
} from "@heroicons/react/24/solid";
import { identity } from "lodash";

import { appInset, isApp } from "~/utils/environment";
import { Bars3Icon } from "@heroicons/react/20/solid";

export default function MobileTabBar(props) {
  const { showSidebar, setShowSidebar } = props;

  const location = useLocation();

  const { t } = useTranslation();

  const tabs = [
    {
      label: t("navigation.dashboard"),
      path: "/dashboard",
      icon: <PresentationChartLineIcon className="w-6" />,
      iconActive: <PresentationChartLineIconSolid className="w-6" />,
    },
    {
      label: t("navigation.inbox"),
      path: "/inbox",
      icon: <EnvelopeIcon className="w-6" />,
      iconActive: <EnvelopeIconSolid className="w-6" />,
      onClick: () => window.dispatchEvent(new CustomEvent("inboxTabClick")),
    },
    {
      label: t("navigation.campaigns"),
      path: "/campaigns",
      icon: <PaperAirplaneIcon className="w-6" />,
      iconActive: <PaperAirplaneIconSolid className="w-6" />,
      onClick: () => window.dispatchEvent(new CustomEvent("calendarTabClick")),
    },
    {
      label: t("navigation.automation"),
      path: "/automation",
      icon: <BoltIcon className="w-6" />,
      iconActive: <BoltIconSolid className="w-6" />,
    },
  ].filter(identity);
  // Filter null values

  const activeTab = tabs.findIndex((tab) =>
    location?.pathname?.startsWith(tab.path),
  );

  return (
    <div
      className={`fixed sm:hidden z-20 inset-x-0 bottom-0 bg-white`}
      style={{
        paddingBottom: appInset.bottom,
      }}
    >
      <div className="flex relative">
        {tabs.map((tab) => (
          <NavLink
            key={tab.label}
            to={tab.path}
            className="h-16"
            onClick={tab.onClick}
            style={{
              width: `${Math.round(100 / (tabs.length + 1), 2)}%`,
            }}
          >
            {({ isActive }) => (
              <div
                className={`h-16 w-full flex flex-col items-center justify-center ${
                  isActive ? "text-primary" : "text-neutral-500"
                }`}
              >
                {isActive ? tab.iconActive : tab.icon}
                <div className="[font-size:10px]">{tab.label}</div>
              </div>
            )}
          </NavLink>
        ))}
        <div
          className={`h-16 w-full flex flex-col items-center justify-center text-neutral-500`}
          style={{ width: `${Math.round(100 / (tabs.length + 1), 2)}%` }}
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <Bars3Icon className="w-6" />
          <div className="[font-size:10px]">{t("navigation.menu")}</div>
        </div>
        <div
          className="absolute top-0 left-0 h-0.5 bg-primary transition-transform rounded-sm"
          style={{
            width: `${Math.round(100 / (tabs.length + 1), 2)}%`,
            transform: `translateX(${
              (activeTab * window.innerWidth) / (tabs.length + 1)
            }px)`,
          }}
        />
      </div>
    </div>
  );
}
