import React, { useContext } from "react";
import FilterButton from "./FilterButton";
import { UIContext } from "~/contexts/ui-context";
import { PaymentsContext } from "~/contexts/payments-context";
import { CalendarContext } from "~/contexts/calendar-context";
import { UserContext } from "~/contexts/user-context";
import { compact } from "lodash";
import { useTranslation } from "react-i18next";

export default function InboxFilters(props) {
  const { t } = useTranslation();

  const { showMenu } = useContext(UIContext);

  const { organization } = useContext(UserContext);

  const { activeFilter, setActiveFilter } = props;

  const filters = compact([
    organization.features.calendar || organization.features.payments
      ? {
          id: "awaiting_response",
          emoji: <div>⏳</div>,
          label: t("inbox.filters.pending"),
        }
      : null,
    organization.features.calendar
      ? {
          id: "upcoming_appointment",
          emoji: <div>🗓️</div>,
          label: t("inbox.filters.upcoming_appointment"),
        }
      : null,
    {
      id: "unreplied_message",
      emoji: <div>👋</div>,
      label: t("inbox.filters.messages"),
    },
    {
      id: "unread_message",
      emoji: <div>👀</div>,
      label: t("inbox.filters.unread"),
    },
    {
      id: "no_folder",
      emoji: <div>📁</div>,
      label: t("inbox.filters.no_folder"),
    },
    {
      id: "scenario_running",
      emoji: <div>⚡️</div>,
      label: t("inbox.filters.scenario_running"),
    },
    {
      id: "campaign_running",
      emoji: <div>💌</div>,
      label: t("inbox.filters.campaign_running"),
    },
  ]);

  const filterMenuActions = filters
    .filter((f) => f.id != activeFilter?.id)
    .map((filter) => ({
      label: (
        <div className="flex justify-center items-center space-x-2">
          <div>{filter.emoji}</div>
          <div>{filter.label}</div>
        </div>
      ),
      action: () => setActiveFilter(filter),
    }));

  if (activeFilter)
    filterMenuActions.push({
      label: t("inbox.filters.show_all"),
      action: () => setActiveFilter(null),
    });

  const openFilterMenu = () => {
    showMenu({
      title: t("inbox.filters.filter_by"),
      actions: filterMenuActions,
    });
  };

  return (
    <FilterButton
      activeFilter={activeFilter}
      activeFilterIcon={activeFilter?.emoji}
      onClick={openFilterMenu}
    />
  );
}
