import React from "react";
import {
  BoltIcon,
  BookmarkIcon,
  BuildingOffice2Icon,
  ChartBarIcon,
  CodeBracketIcon,
  DocumentIcon,
  DocumentPlusIcon,
  FireIcon,
  FolderIcon,
  FunnelIcon,
  HeartIcon,
  LightBulbIcon,
  LinkIcon,
  MagnifyingGlassIcon,
  MegaphoneIcon,
  PaperAirplaneIcon,
  ShoppingBagIcon,
  SparklesIcon,
  StarIcon,
  TagIcon,
} from "@heroicons/react/20/solid";

const icons = {
  bolt: BoltIcon,
  funnel: FunnelIcon,
  heart: HeartIcon,
  bookmark: BookmarkIcon,
  "chart-bar": ChartBarIcon,
  "light-bulb": LightBulbIcon,
  megaphone: MegaphoneIcon,
  "shopping-bag": ShoppingBagIcon,
  sparkles: SparklesIcon,
  tag: TagIcon,
  star: StarIcon,
  document: DocumentIcon,
  document_plus: DocumentPlusIcon,
  link: LinkIcon,
  building: BuildingOffice2Icon,
  "magnifying-glass": MagnifyingGlassIcon,
  fire: FireIcon,
  code: CodeBracketIcon,
  folder: FolderIcon,
  "paper-plane": PaperAirplaneIcon,
};

export default function CardIcon(props) {
  const { name = "bolt" } = props;
  const Icon = icons[name];
  return Icon ? <Icon className="h-6 w-6 text-black flex-shrink-0" /> : null;
}
