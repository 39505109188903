import React from "react";

export default function CommentBubble(props) {
  const { text } = props;

  return (
    <div
      className="bg-white border border-neutral-200 px-2.5 py-2 rounded-lg text-2sm leading-normal break-words max-w-full"
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  );
}
