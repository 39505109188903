import React, { useEffect, useState } from "react";

import { Outlet, useLocation } from "react-router-dom";

import MobileTabBar from "./MobileTabBar";
import DesktopSidebar from "./DesktopSidebar";
import OnboardingChecklist from "../onboarding/OnboardingChecklist";

export default function Layout() {
  const [showSidebar, setShowSidebar] = useState(false);

  const location = useLocation();

  // close sidebar on route change
  useEffect(() => {
    setShowSidebar(false);
  }, [location]);

  return (
    <div className="sm:flex sm:flex-row sm:w-screen sm:items-stretch max-h-screen overflow-hidden">
      <DesktopSidebar {...{ showSidebar, setShowSidebar }} />
      <OnboardingChecklist />
      <Outlet />
      <MobileTabBar {...{ showSidebar, setShowSidebar }} />
    </div>
  );
}
