import React from "react";
import { useTranslation } from "react-i18next";

export default function SelectHeader(props) {
  const {
    selecting,
    setSelecting,
    selectAll,
    selectedCount,
    maxCount,
    onCancel,
  } = props;

  const { t } = useTranslation();

  return selecting ? (
    <div className="flex h-9 flex-shrink-0 items-center justify-center relative">
      <div
        className="px-3 py-2 text-md absolute left-0 cursor-pointer"
        onClick={selectAll}
      >
        {selectedCount > 0 ? t("inbox.clear") : t("inbox.select_all")}
      </div>
      <div className="px-3 text-2xs">
        {selectedCount}
        {maxCount ? "/" + maxCount : null} {t("inbox.selected")}
      </div>
      <div
        className="px-3 py-2 text-md absolute right-0 text-red cursor-pointer"
        onClick={onCancel}
      >
        {t("shared.cancel")}
      </div>
    </div>
  ) : (
    <div
      className={`flex h-8 flex-shrink-0 items-center justify-end -mb-8 relative z-40`}
    >
      <div
        className="px-3 py-2 text-md cursor-pointer"
        onClick={() => setSelecting(true)}
      >
        {t("inbox.select")}
      </div>
    </div>
  );
}
