import React, { useContext, useState } from "react";
import instagramIcon from "../../../../assets/images/instagram_icon.png";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import ContactHeader from "../../contacts/ContactHeader";
import Button from "~/components/elements/Button";
import InboxModalError from "../../errors/InboxModalError";
import NoConnection from "../../errors/NoConnection";
import { UIContext } from "~/contexts/ui-context";
import {
  ArrowPathIcon,
  ExclamationTriangleIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import Badge from "~/components/elements/Badge";
import InstagramMissingPermissions from "./InstagramMissingPermissions";
import classNames from "../../../utils/classNames";

export default function InstagramAccount() {
  const { organization } = useContext(UserContext);

  const { t } = useTranslation();

  const { showAlert } = useContext(UIContext);

  const [connectInstagram, setConnectInstagram] = useState(false);

  const handleDisconnect = () => {
    showAlert({
      title: t("profile.instagram_account.confirm_disconnect"),
      confirm: t("shared.yes"),
      cancel: t("shared.no"),
      confirmClassName: "text-red-500",
      onSubmit: () => {
        window.location.replace(`/users/auth/instagram/disconnect`);
      },
    });
  };

  return (
    <div className="border rounded-2xl rounded-tl-none p-4 space-y-4">
      <div className="flex items-center justify-between">
        <div className="text-md flex">
          <img src={instagramIcon} className="w-5 mr-2" />
          {t("profile.instagram_account.title")}
        </div>
        {organization.instagram_connected ? (
          <Badge color="green" label={t("shared.connected")} />
        ) : (
          <Badge color="yellow" label={t("shared.not_connected")} />
        )}
      </div>
      <div className="text-2sm">{t("profile.instagram_account.subtitle")}</div>
      <div
        className={classNames(
          "p-3  rounded-xl group flex flex-col items-start space-y-3",
          organization.instagram_connected ? "bg-lighter-gray" : "bg-warning",
        )}
      >
        <div className="w-full flex items-center justify-between">
          <ContactHeader contact={organization} />
          {organization.instagram_connected ? (
            <div>
              <div className="flex space-x-4 group-hover:hidden">
                <div className="flex flex-col items-center">
                  <div className="text-md font-medium">
                    {organization.followers_count || "-"}
                  </div>
                  <div className="text-2xs">
                    {t("profile.instagram_account.followers")}
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <div className="text-md font-medium">
                    {organization.follows_count || "-"}
                  </div>
                  <div className="text-2xs">
                    {t("profile.instagram_account.following")}
                  </div>
                </div>
              </div>
              <div className="space-x-2 hidden group-hover:flex">
                <Button
                  href={`/users/auth/instagram`}
                  icon={ArrowPathIcon}
                  size="small"
                />
              </div>
            </div>
          ) : (
            <Button
              label={t("profile.instagram_account.reconnect_account")}
              href={`/users/auth/instagram`}
              size="extra-small"
              icon={ExclamationTriangleIcon}
            />
          )}
        </div>
        {organization.facebook_user_name &&
          organization.instagram_connected && (
            <div className="rounded-md bg-light-gray text-darker-gray px-2 py-1 text-2sm space-x-2">
              {t("profile.instagram_account.connected_with_account")}{" "}
              <strong>{organization.facebook_user_name}</strong>
            </div>
          )}
      </div>
      <InstagramMissingPermissions />
      {connectInstagram && (
        <InboxModalError onBackgroundClick={() => setConnectInstagram(false)}>
          <NoConnection setHidden={setConnectInstagram} />
        </InboxModalError>
      )}
    </div>
  );
}
