import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CardsSelect from "~/components/elements/CardsSelect";
import { UserContext } from "~/contexts/user-context";
import CardsRadio from "~/components/elements/CardsRadio";
import InputSelect from "~/components/elements/InputSelect";
import Input from "~/components/elements/Input";
import Button from "~/components/elements/Button";
import { head } from "lodash";

export default function AboutYou() {
  const { t } = useTranslation();

  const { organization, updateOrganization } = useContext(UserContext);

  const [info, setInfo] = useState(organization.onboarding_info || {});
  const handleUpdateInfo = (key) => (value) => {
    setInfo((prevInfo) => ({ ...prevInfo, [key]: value }));
  };

  const profileOptions = [
    {
      value: "app_website",
      label: t("onboarding.about_you.app_website"),
      icon: "link",
    },
    {
      value: "brand",
      label: t("onboarding.about_you.brand"),
      icon: "shopping-bag",
    },
    {
      value: "content_creator",
      label: t("onboarding.about_you.content_creator"),
      icon: "heart",
    },
    {
      value: "small_business",
      label: t("onboarding.about_you.small_business"),
      icon: "building",
    },
    {
      value: "other",
      label: t("onboarding.about_you.other"),
      icon: "magnifying-glass",
    },
  ];

  const companySizeOptions = ["1-10", "11-50", "51-200", "201+"].map(
    (value) => ({
      value,
      label: value,
    }),
  );

  const industryOptions = [
    "fashion",
    "beauty",
    "tech",
    "food",
    "music",
    "other",
  ].map((value) => ({
    value,
    label: t(`onboarding.about_you.${value}`),
  }));

  const hearAboutOptions = [
    "instagram",
    "search",
    "article",
    "word_of_mouth",
    "product_hunt",
    "other",
  ].map((value) => ({
    value,
    label: t(`onboarding.about_you.${value}`),
  }));

  const isValid = [
    info.profile_type,
    info.company_size,
    info.industry,
    info.hear_about,
  ].every((value) => !!value);

  const handleSave = () => {
    if (!isValid) return;

    updateOrganization({
      onboarding_info: { ...organization.onboarding_info, ...info },
    });
  };

  return (
    <div className="max-w-[912px] mx-auto space-y-8">
      <div className="space-y-4">
        <div className="font-black text-3xl sm:text-5xl text-headings whitespace-pre-line">
          {t("onboarding.about_you.title")}
        </div>
        <div className="leading-6 text-muted">
          {t("onboarding.about_you.description")}
        </div>
      </div>
      <div className="space-y-4">
        <CardsSelect
          label={t("onboarding.about_you.your_profile")}
          options={profileOptions}
          value={info.profile_type}
          onChange={handleUpdateInfo("profile_type")}
        />
        <CardsRadio
          label={t("onboarding.about_you.company_size")}
          options={companySizeOptions}
          value={info.company_size}
          onChange={handleUpdateInfo("company_size")}
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <InputSelect
            label={t("onboarding.about_you.your_industry")}
            value={info.industry}
            onChange={handleUpdateInfo("industry")}
            placeholder={t("onboarding.about_you.industry_placeholder")}
            options={industryOptions}
          />
          <Input
            label={t("onboarding.about_you.website")}
            value={info.website}
            onChange={handleUpdateInfo("website")}
            leadingAddon="https://"
            placeholder="e.g. sprawl.com"
            autoComplete="url"
            type="url"
          />
        </div>
        <InputSelect
          label={t("onboarding.about_you.hear_about")}
          value={info.hear_about}
          onChange={handleUpdateInfo("hear_about")}
          placeholder={t("onboarding.about_you.hear_about_placeholder")}
          options={hearAboutOptions}
        />
        {info.hear_about === "other" && (
          <Input
            value={info.hear_about_other}
            onChange={handleUpdateInfo("hear_about_other")}
            placeholder={t("onboarding.about_you.hear_about")}
          />
        )}
        <div className="flex justify-end">
          <Button
            label={t("shared.continue")}
            disabled={!isValid}
            style="primary"
            onClick={handleSave}
            loadingOnClick
          />
        </div>
      </div>
    </div>
  );
}
