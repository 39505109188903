import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ListBulletIcon } from "@heroicons/react/20/solid";
import Modal from "~/components/shared/Modal";
import SurveyForm from "~/components/surveys/SurveyForm";
import TemplateMessage from "~/components/messages/TemplateMessage";
import ResultsNode from "./ResultsNode";
import Loader from "~/components/utils/Loader";

export default function SurveyResultsNode(props) {
  const { t } = useTranslation();

  const { data } = props;
  const { survey } = data;

  const [surveyDialogVisible, setSurveyFormVisible] = useState(false);

  const buttons =
    survey?.survey_type == "single_choice"
      ? survey.survey_options
      : [
          {
            title: survey?.button_label,
          },
        ];

  return (
    <ResultsNode {...props} icon={ListBulletIcon}>
      <div>
        {survey ? (
          <TemplateMessage
            template={{
              title: survey.title,
              subtitle: survey.subtitle,
              buttons: buttons,
            }}
          />
        ) : (
          <div className="py-3 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl flex space-x-2 items-center">
            <Loader width={24} strokeWidth={8} />
            <div className="text-md font-medium text-center">
              {t("automation.scenarios.actions.survey.loading")}
            </div>
          </div>
        )}
      </div>
      {surveyDialogVisible && (
        <Modal onClose={() => setSurveyFormVisible(false)}>
          <SurveyForm survey={survey || {}} />
        </Modal>
      )}
    </ResultsNode>
  );
}
