import React, { useState } from "react";
import { DirtyContext } from "~/contexts/dirty-context";

// This context enables unsaved changes checks across the app
export default function DirtyProvider(props) {
  const [dirty, setDirty] = useState(false);

  return (
    <DirtyContext.Provider value={{ dirty, setDirty }}>
      {props.children}
    </DirtyContext.Provider>
  );
}
