import React from "react";
import Modal from "../shared/Modal";
import SurveyForm from "./SurveyForm";

export default function SurveyModal(props) {
  const { survey, onClose } = props;

  return (
    <Modal onClose={onClose}>
      <SurveyForm survey={survey} />
    </Modal>
  );
}
