import React from "react";

export default function UnrepliedIcon(props) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props?.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.83435 0.634376C8.68437 0.784398 8.60012 0.987844 8.60012 1.19998C8.60012 1.41211 8.68437 1.61555 8.83435 1.76558L10.6688 3.59998H6.19995C4.71474 3.59998 3.29036 4.18997 2.24015 5.24018C1.18995 6.29038 0.599952 7.71476 0.599952 9.19998V10.8C0.599952 11.0121 0.684237 11.2156 0.834266 11.3657C0.984295 11.5157 1.18778 11.6 1.39995 11.6C1.61213 11.6 1.81561 11.5157 1.96564 11.3657C2.11567 11.2156 2.19995 11.0121 2.19995 10.8V9.19998C2.19995 8.13911 2.62138 7.12169 3.37152 6.37155C4.12167 5.6214 5.13909 5.19998 6.19995 5.19998H10.6688L8.83435 7.03438C8.75794 7.10817 8.697 7.19645 8.65507 7.29405C8.61314 7.39166 8.59107 7.49663 8.59015 7.60286C8.58923 7.70908 8.60947 7.81442 8.64969 7.91274C8.68992 8.01106 8.74932 8.10038 8.82444 8.17549C8.89955 8.25061 8.98887 8.31001 9.08719 8.35023C9.18551 8.39046 9.29085 8.4107 9.39707 8.40978C9.5033 8.40885 9.60827 8.38678 9.70587 8.34486C9.80348 8.30293 9.89175 8.24198 9.96555 8.16558L13.1656 4.96558C13.3155 4.81555 13.3998 4.61211 13.3998 4.39998C13.3998 4.18784 13.3155 3.9844 13.1656 3.83438L9.96555 0.634376C9.81553 0.484399 9.61208 0.400146 9.39995 0.400146C9.18782 0.400146 8.98437 0.484399 8.83435 0.634376V0.634376Z"
        fill="#CDCBCB"
      />
    </svg>
  );
}
