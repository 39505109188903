import React from "react";
import { useTranslation } from "react-i18next";

import countryCodes from "country-codes-list";
import { map, sortBy } from "lodash";
import LabeledSelect from "./LabeledSelect";

export default function CountrySelect({
  name,
  value,
  onChange,
  label,
  required = false,
  disabled = false,
  ...props
}) {
  const { t } = useTranslation();

  const countries = sortBy(
    map(
      countryCodes.customList("countryCode", "{countryNameEn}"),
      (name, code) => ({
        label: name,
        value: code,
      })
    ),
    "label"
  );

  return (
    <LabeledSelect
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      placeholder={t("shared.country_placeholder")}
      disabled={disabled}
      options={countries}
      groupOptionsBy={(country) => country.label[0].toUpperCase()}
    />
  );
}
