import React, { useEffect, useState } from "react";
import classNames from "~/utils/classNames";
import Loader from "~/components/utils/Loader";
import { Link } from "react-router-dom";
import ButtonCount from "./ButtonCount";

export const buttonLoaderSizes = {
  "extra-small": 12,
  small: 14,
  base: 18,
};

export const loaderColors = {
  primary: "#fff",
  default: "#333",
  transparent: "#333",
  success: "#09514F",
  danger: "#6D0707",
  dark: "#fff",
};

/**
 * Button component.
 *
 * @module Button
 * @param {Object} props - Properties passed to component
 * @param {string} props.label - Button text
 * @param {number} props.count - Number to display in a badge
 * @param {string} props.type - Button type
 * @param {function} props.onClick - Click handler function, default is an empty function
 * @param {string} props.to - Path for react-router Link, default is null
 * @param {string} props.href - URL for anchor tag, default is null
 * @param {string} props.size - Size of the button, default is "base"
 * @param {string} props.style - Style of the button, default is "default"
 * @param {ReactNode} props.icon - Icon to display in the button, default is null
 * @param {boolean} props.rounded - If true, applies rounded button styles, default is false
 * @param {string} props.className - Additional CSS classes to apply to the button, default is an empty string
 * @param {boolean} props.loading - If true, displays a loading spinner, default is false
 * @param {boolean} props.loadingOnClick - If true, sets loading state on click, default is false
 * @param {boolean} props.readOnly - If true, renders the button as an unclickable div, default is false
 * @param {boolean} props.disabled - If true, disables the button, default is false
 * @param {ReactNode} props.children - Additional content to render inside the button, default is null
 * @returns {ReactNode} - Button component
 */
export default function Button(props) {
  const {
    label,
    type = null,
    count,
    onClick = () => {},
    to = null,
    href = null,
    size = "base",
    style = "default",
    icon: Icon = null,
    rounded = false,
    className = "",
    loading = false,
    loadingOnClick = false,
    readOnly = false,
    disabled = false,
    children,
    ...passthroughProps
  } = props;

  const [localLoading, setLocalLoading] = useState(loading);
  useEffect(() => setLocalLoading(loading), [loading]);

  const fullClassName = classNames(
    "button",
    disabled && "disabled",
    rounded ? "!rounded-full" : null,
    `button-${size}`,
    `button-${style}`,
    Icon || localLoading || loading || count
      ? "inline-flex items-center justify-center"
      : null,
    localLoading || loading ? "loading" : null,
    className,
  );

  const handleClick = (e) => {
    if (loadingOnClick) setLocalLoading(true);
    if (onClick) onClick(e);
  };

  const content = () => (
    <>
      {(localLoading || loading) && (
        <Loader
          stroke={loaderColors[style]}
          width={buttonLoaderSizes[size]}
          strokeWidth={8}
        />
      )}
      {!(localLoading || loading) && Icon && (
        <Icon className="button-icon" aria-hidden="true" />
      )}
      {label}
      {children}
      {count > 0 && <ButtonCount count={count} />}
    </>
  );

  if (readOnly) {
    return (
      <div
        className={classNames(fullClassName, "cursor-pointer")}
        {...passthroughProps}
      >
        {content()}
      </div>
    );
  }

  if (to) {
    return (
      <Link
        to={to}
        className={fullClassName}
        onClick={handleClick}
        {...passthroughProps}
      >
        {content()}
      </Link>
    );
  }

  if (href) {
    return (
      <a
        href={href}
        className={fullClassName}
        target={href[0] == "/" ? "_self" : "_blank"}
        onClick={() => loadingOnClick && setLocalLoading(true)}
        // onClick={handleClick}
        {...passthroughProps}
      >
        {content()}
      </a>
    );
  }

  return (
    <button
      className={fullClassName}
      onClick={handleClick}
      {...passthroughProps}
    >
      {content()}
    </button>
  );
}
