import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import classNames from "../../utils/classNames";

export default function SideDrawer(props) {
  const { onClose, zIndex = 800, children, className = "" } = props;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
    return () => setVisible(false);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300);
  };

  return createPortal(
    <div
      className={classNames(
        "fixed inset-0",
        visible ? "pointer-events-auto" : "pointer-events-none",
      )}
      style={{ zIndex }}
    >
      <div
        className={classNames(
          "absolute inset-0 bg-black transition-opacity z-10",
          visible ? "opacity-20" : "opacity-0",
        )}
        onClick={handleClose}
      />
      <div
        className={classNames(
          "fixed inset-y-0 right-0 w-auto bg-white shadow-lg z-50 transition-all duration-300",
          visible ? "translate-x-0 opacity-100" : "translate-x-1/2 opacity-0",
          className,
        )}
      >
        {children}
      </div>
    </div>,
    document.body,
  );
}
