import React, { useEffect, useRef } from "react";
import { Handle, Position } from "@xyflow/react";
import NodeButtons from "../NodeButtons";
import NodeWarnings from "../NodeWarnings";

export default function ActionNode(props) {
  const {
    nodeId,
    setNodeDimensions = () => {},
    data = {},
    triggers,
    first = false,
    last = false,
    children,
    selected,
    help = null,
    buttons = [],
    noEdit,
    noWarnings,
    icon: Icon,
  } = props;

  const { content, options } = data;

  const nodeRef = useRef(null);

  useEffect(() => {
    if (nodeRef.current) {
      setNodeDimensions((prevDimensions) => ({
        ...prevDimensions,
        [nodeId]: {
          width: nodeRef.current.offsetWidth,
          height: nodeRef.current.offsetHeight,
        },
      }));
    }
  }, [nodeRef.current, nodeId, setNodeDimensions, content, options, triggers]);

  return (
    <>
      {first ? null : (
        <Handle
          type="target"
          position={Position.Top}
          className="opacity-0"
          isConnectable={false}
        />
      )}
      <div
        className="group/action inline-block"
        // style={{ width: `${width}px` }}
        ref={nodeRef}
      >
        {/* <div className="text-sm text-medium-gray">
          {data.parent_id} / {data.id}
        </div> */}
        {Icon && (
          <Icon className="w-6 absolute -left-10 top-1/2 -translate-y-1/2" />
        )}
        {children}
        <div className="absolute top-1/2 -translate-y-1/2 left-full pl-3 flex flex-col items-end space-y-2">
          {!noWarnings && data?.warnings?.length > 0 && (
            <NodeWarnings warnings={data.warnings} />
          )}
          {!noEdit && (
            <NodeButtons selected={selected} buttons={buttons} help={help} />
          )}
        </div>
      </div>
      {last ? null : (
        <Handle
          type="source"
          position={Position.Bottom}
          className="opacity-0"
          isConnectable={false}
        />
      )}
    </>
  );
}
