import React from "react";
import { Switch } from "@headlessui/react";
import classNames from "~/utils/classNames";

export default function Toggle(props) {
  const { value, onChange, disabled = false } = props;

  return (
    <Switch
      checked={value || false}
      onChange={onChange}
      className={classNames(
        value ? "bg-primary" : "bg-neutral-200",
        disabled ? "pointer-events-none opacity-50" : "",
        "relative inline-flex flex-shrink-0 h-6 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-orange",
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          value ? "translate-x-4" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
        )}
      />
    </Switch>
  );
}
