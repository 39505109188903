import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CampaignExecution from "~/components/campaigns/CampaignExecution";
import Loader from "~/components/utils/Loader";
import { CampaignsContext } from "~/contexts/campaigns-context";

export default function ContactCampaignExecutions(props) {
  const { contactId } = props;

  const [loading, setLoading] = useState(true);

  const { loadCampaignExecutions } = useContext(CampaignsContext);

  const [campaignExecutions, setCampaignExecutions] = useState([]);

  useEffect(async () => {
    if (!contactId) return;

    const campaignExecutions = await loadCampaignExecutions({
      contact_id: contactId,
      limit: 20,
    });
    setCampaignExecutions(campaignExecutions);
    setLoading(false);
  }, [contactId]);

  return (
    <div className="p-3 flex-grow space-y-3 overflow-auto">
      {loading && (
        <div className="p-4 flex items-center justify-center">
          <Loader width={28} strokeWidth={8} />
        </div>
      )}
      {campaignExecutions.map((execution, index) => (
        <CampaignExecution key={index} execution={execution} />
      ))}
    </div>
  );
}
