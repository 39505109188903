import React from "react";

import filterIcon from "../../assets/filterIcon.svg";
import Button from "~/components/elements/Button";

export default function FilterButton(props) {
  const { activeFilter, activeFilterIcon, onClick } = props;

  return (
    <Button onClick={onClick} style={activeFilter ? "soft" : "default"}>
      {!activeFilter && <img src={filterIcon} />}
      {activeFilter && (
        <div className="text-md w-full h-full flex items-center justify-center">
          {activeFilterIcon}
        </div>
      )}
    </Button>
  );
}
