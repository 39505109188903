import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "~/components/elements/Dropdown";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import classNames from "~/utils/classNames";
import { UIContext } from "~/contexts/ui-context";

export default function SidebarNavLink(props) {
  const {
    link,
    level = 0,
    active,
    children,
    className = "",
    linkClassName = "",
    actions = [],
  } = props;

  const {
    label,
    path,
    icon,
    activeIcon,
    onClick,
    count,
    badge,
    right,
    visible = true,
  } = link;

  const { showMenu } = useContext(UIContext);

  if (!visible) return null;

  if (level == 0) {
    return (
      <NavLink
        key={label}
        to={path}
        onClick={onClick}
        className={linkClassName}
      >
        {({ isActive }) => (
          <>
            <div
              className={`flex items-center space-x-2 rounded-lg px-2 py-1.5 ${
                isActive || active
                  ? "text-black bg-white"
                  : "text-white hover:bg-white hover:bg-opacity-10"
              } ${className}`}
            >
              {isActive || active ? activeIcon || icon : icon}
              <div
                className={`flex-grow truncate ${
                  isActive || active ? "font-medium" : "font-normal"
                }`}
              >
                {label}
              </div>
              {count > 0 && (
                <div
                  className={`flex-shrink-0 block font-medium text-xs px-1.5 pt-0.5 pb-0.25 rounded ${
                    isActive || active
                      ? "bg-neutral-500 text-white"
                      : "bg-neutral-500"
                  }`}
                >
                  {count}
                </div>
              )}
              {right}
            </div>
            {isActive || active ? children : null}
          </>
        )}
      </NavLink>
    );
  }

  if (level == 1) {
    return (
      <NavLink key={label} to={path} onClick={onClick}>
        {({ isActive }) => (
          <div
            className={`group ml-4 flex items-center rounded-lg h-8 px-2 py-1.5 ${
              isActive || active
                ? "text-white bg-white bg-opacity-20"
                : "text-white hover:bg-white hover:bg-opacity-10"
            } ${className}`}
          >
            {icon && <div className="mr-2">{icon}</div>}
            <div
              className={`flex-grow text-sm truncate ${icon ? null : "pl-0.5"}`}
            >
              {label}
            </div>
            {count > 0 ? (
              <div
                className={classNames(
                  "flex-shrink-0 ml-2 text-xs px-1 pt-0.75 pb-0.25 rounded bg-white bg-opacity-40",
                  badge > 0 ? "opacity-100" : "opacity-40",
                  actions.length > 0 && "group-hover:hidden",
                )}
              >
                {count}
              </div>
            ) : null}
            {actions.length > 0 ? (
              <button
                onClick={(evt) => {
                  evt.stopPropagation();
                  evt.preventDefault();
                  showMenu({ actions });
                }}
                className="hidden group-hover:block ml-2 text-white text-opacity-50 rounded p-0.5 hover:bg-white hover:bg-opacity-10 hover:text-opacity-75 transition-all"
              >
                <EllipsisHorizontalIcon className="w-4" />
              </button>
            ) : null}
          </div>
        )}
      </NavLink>
    );
  }

  return null;
}
