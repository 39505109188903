import React, { useEffect, useMemo, useState } from "react";
import makeIcon from "../../../../assets/images/make_icon.png";
import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import {
  ArrowTopRightOnSquareIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { loadIntegrations } from "../../../api/integrations";
import { DateTime } from "luxon";

export default function MakeIntegration() {
  const { t } = useTranslation();

  const [accessGrants, setAccessGrants] = useState([]);

  useEffect(async () => {
    const res = await loadIntegrations();
    setAccessGrants(res.access_grants);
  }, []);

  const makeAccessGrant = useMemo(
    () =>
      accessGrants.find(
        (accessGrant) => accessGrant.application?.name === "Make",
      ),
    [accessGrants],
  );

  return (
    <div className="border rounded-2xl rounded-tl-none p-4 space-y-4">
      <div className="flex items-center justify-between">
        <div className="text-md flex">
          <img src={makeIcon} className="w-5 mr-2" />
          {t(`integrations.make.title`)}
        </div>
      </div>
      <div className="text-2sm">{t(`integrations.make.subtitle`)}</div>
      {makeAccessGrant ? (
        <div className="p-3 bg-lighter-gray rounded-xl flex justify-between items-center">
          <div className="flex flex-col justify-center">
            <div className="leading-tight text-sm font-medium mb-0.5">
              {t("integrations.make.connected_on", {
                date: DateTime.fromISO(
                  makeAccessGrant.created_at,
                ).toLocaleString(DateTime.DATE_MED),
              })}
            </div>
            <div className="text-xs text-neutral-400">
              {makeAccessGrant?.scopes?.join(", ")}
            </div>
          </div>
          <Button icon={TrashIcon} size="small" disabled />
        </div>
      ) : (
        <Button
          icon={ArrowTopRightOnSquareIcon}
          size="small"
          label={t(`integrations.make.connect`)}
          className="w-full"
          href="https://www.make.com/en/hq/app-invitation/db5f055d950e793929149d507e180265"
        />
      )}
    </div>
  );
}
