import React from "react";
import ButtonGroup from "./ButtonGroup";

export default function ButtonSelect(props) {
  const { options, value, onChange, className } = props;

  const buttons = options.map((option) => ({
    label: option.label,
    style: value == option.value ? "primary" : "default",
    onClick: () => onChange(option.value),
  }));

  return <ButtonGroup buttons={buttons} className={className} />;
}
