export const HOURS = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];

export const DURATIONS = {
  "15min": 15,
  "30min": 30,
  "45min": 45,
  "1h":   60,
  "1h15": 75,
  "1h30": 90,
  "2h":   120,
  "2h30": 150,
  "3h":   180,
  "3h30": 210,
  "4h":   240,
  "4h30": 270,
  "5h":   300,
  "5h30": 330,
  "6h":   360,
  "6h30": 390,
  "7h":   420,
  "7h30": 450,
  "8h":   480,
};

export const CELL_HEIGHT = 56;
