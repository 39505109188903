import React from "react";
import Button from "~/components/elements/Button";
import { useErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

export default function ErrorComponent() {
  const { t } = useTranslation();

  const { resetBoundary } = useErrorBoundary();

  return (
    <div className="w-full h-full p-4 text-center flex flex-col items-center justify-center">
      <h1 className="text-lg mb-4">{t("shared.errors.title")}</h1>
      <p>{t("shared.errors.description")}</p>
      <p className="mb-8">{t("shared.errors.contact_us")}</p>
      <Button label={t("shared.errors.refresh")} onClick={resetBoundary} />
    </div>
  );
}
