import axios from "axios";

const loadConversionLink = async (linkId) => {
  const response = await axios.get(
    `/api/automation/conversion_links/${linkId}`,
  );
  return response.data;
};

export { loadConversionLink };
