import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import { AutomationContext } from "~/contexts/automation-context";
import { UIContext } from "~/contexts/ui-context";
import { BeakerIcon, XMarkIcon } from "@heroicons/react/20/solid";
import SurveyType from "./SurveyType";
import SingleChoiceSurveyForm from "./SingleChoiceSurveyForm";
import Tabs from "../elements/Tabs";
import QuestionsSurveyMessage from "./questions/QuestionsSurveyMessage";
import QuestionsSurveyForm from "./questions/QuestionsSurveyForm";
import QuestionsSurveyResults from "./questions/QuestionsSurveyResults";
import ContactPickerDialog from "../contacts/ContactPickerDialog";

export default function SurveyForm(props) {
  const { t } = useTranslation();

  const { saveSurvey, testSurvey, destroySurvey } =
    useContext(AutomationContext);
  const { showAlert, showPrompt } = useContext(UIContext);

  const {
    handleClose,
    onSave = () => {},
    survey: initialSurvey = {
      title: "",
      subtitle: "",
      survey_type: "",
      survey_options: [],
      survey_questions: [],
    },
  } = props;

  const [survey, setSurvey] = useState(initialSurvey);
  const [options, setOptions] = useState(initialSurvey?.survey_options || []);
  const [questions, setQuestions] = useState(
    initialSurvey?.survey_questions || [],
  );
  const [errorMessage, setErrorMessage] = useState(null);

  const visibleOptions = options.filter((o) => !o._destroy);
  const visibleQuestions = questions.filter((o) => !o._destroy);

  // Validation & saving

  const isOptionValid = (option) => option.title.trim().length > 0;
  const isQuestionValid = (question) => question.title.trim().length > 0;

  const isOptionsValid =
    visibleOptions.length > 0 && visibleOptions.every(isOptionValid);
  const isQuestionsValid =
    visibleQuestions.length > 0 &&
    visibleQuestions.every(isQuestionValid) &&
    survey.button_label?.trim()?.length > 0;

  const isValid =
    survey.title?.trim()?.length > 0 &&
    ((survey.survey_type == "single_choice" && isOptionsValid) ||
      (survey.survey_type == "questions" && isQuestionsValid));

  const [loadingSave, setLoadingSave] = useState(false);

  const testPayload = useMemo(
    () => ({
      ...survey,
      id: survey.id ? parseInt(survey.id) : null,
      title: survey.title?.trim(),
      subtitle: survey.subtitle?.trim(),
      survey_options_attributes: options,
      survey_questions_attributes: questions,
    }),
    [survey, options, questions],
  );

  const handleSave = async () => {
    setLoadingSave(true);

    const savedSurvey = await saveSurvey(testPayload);

    setSurvey(savedSurvey);

    setLoadingSave(false);
    onSave(savedSurvey);

    // close modal if last page
    if (survey.survey_type == "single_choice") {
      handleClose();
    } else if (survey.survey_type == "questions" && activeTab === "form") {
      handleClose();
    }
  };

  // Run test
  const [testDialog, setTestDialog] = useState(false);

  const handleTest = async (contactId) => {
    setLoadingSave(true);
    const newSurvey = await testSurvey(testPayload, contactId);

    setLoadingSave(false);
    onSave(newSurvey);

    // delay to wait for the previous alert to close
    delay(() => showAlert(t("automation.scenarios.test_sent")), 500);
  };

  const handleDelete = () => {
    showPrompt(t("surveys.delete_confirm"), () => {
      destroySurvey(survey.id);
      handleClose();
    });
  };

  // Questions survey tabs
  const [activeTab, setActiveTab] = useState("message");
  const tabs = ["message", "form", "results"].map((tab) => ({
    title: t(`surveys.questions.tabs.${tab}`),
    active: activeTab === tab,
    onClick: () => setActiveTab(tab),
    count: tab == "results" ? survey.metrics?.replied : null,
  }));

  const TabComponent = {
    message: QuestionsSurveyMessage,
    form: QuestionsSurveyForm,
    results: QuestionsSurveyResults,
  }[activeTab];

  const handleNext = () => {
    handleSave();
    if (activeTab === "message") {
      setActiveTab("form");
    }
  };

  return (
    <div
      className={`flex-grow flex flex-col overflow-hidden w-[880px] max-w-full`}
    >
      <div className="p-4 flex justify-between items-center border-b">
        <div className="text-lg">
          {survey.id ? t("surveys.edit_survey") : t("surveys.new_survey")}
        </div>
        <XMarkIcon
          className="h-6 w-6 text-muted cursor-pointer"
          onClick={handleClose}
        />
      </div>

      {!survey.survey_type && <SurveyType {...{ survey, setSurvey }} />}

      {survey.survey_type == "single_choice" && (
        <SingleChoiceSurveyForm
          {...{
            survey,
            setSurvey,
            options,
            setOptions,
          }}
        />
      )}

      {survey.survey_type == "questions" && (
        <>
          <Tabs tabs={tabs} />
          <TabComponent
            {...{
              survey,
              setSurvey,
              questions,
              setQuestions,
            }}
          />
        </>
      )}

      <div className="border-t p-4 flex items-center justify-between">
        <div className="flex space-x-2">
          <Button label={t("shared.cancel")} onClick={handleClose} />
          {survey?.id ? (
            <Button
              label={t("shared.delete")}
              onClick={handleDelete}
              style="danger"
            />
          ) : null}
        </div>
        <div className="flex items-center space-x-2">
          {errorMessage && (
            <div className="text-red text-sm text-medium">{errorMessage}</div>
          )}
          <Button
            icon={BeakerIcon}
            label={t("surveys.run_test")}
            onClick={() => setTestDialog(true)}
          />
          {survey.survey_type == "questions" &&
          activeTab === "message" &&
          !questions.length ? (
            <Button
              label={t("shared.next")}
              style="primary"
              onClick={handleNext}
              disabled={survey?.title?.trim()?.length === 0}
              loading={loadingSave}
            />
          ) : (
            <Button
              label={t("shared.save")}
              style="primary"
              onClick={handleSave}
              disabled={!isValid}
              loading={loadingSave}
            />
          )}
        </div>
      </div>
      {testDialog && (
        <ContactPickerDialog
          confirmPrompt={t("surveys.run_test_confirm")}
          onClose={() => setTestDialog(false)}
          onChange={handleTest}
        />
      )}
    </div>
  );
}
