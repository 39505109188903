import moment from "moment";

export default function readableDate(dateString, format = "MMMM Do") {
  const date = moment(dateString);
  if (!date.isValid()) {
    return "";
  } else {
    return date.format(format);
  }
}
