import React, { useContext } from "react";
import ContactPicture from "~/components/shared/ContactPicture";
import {
  ArrowTopRightOnSquareIcon,
  ChatBubbleBottomCenterIcon,
} from "@heroicons/react/20/solid";
import RelativeDate from "~/components/utils/RelativeDate";
import Button from "~/components/elements/Button";
import { useTranslation } from "react-i18next";
import { InboxContext } from "~/contexts/inbox-context";
import { useNavigate } from "react-router-dom";
import RepliedIcon from "../inbox/RepliedIcon";
import Badge from "~/components/elements/Badge";
import CommentBubble from "../comments/CommentBubble";
import { UserContext } from "~/contexts/user-context";
import { DateTime } from "luxon";

export default function MentionItem(props) {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);

  const { mention } = props;

  const { id, received_at, text, media_url, media_type, reply_id, reply_text } =
    mention;

  const { mentionReplyDialog } = useContext(InboxContext);

  const highlightedUsernameText = (text) =>
    text.replace(
      organization.username,
      `<span class="text-blue-500">@${organization.username}</span>`,
    );

  return (
    <div className="w-full max-w-md border-r p-4 text-xs space-y-2">
      {/* Date */}
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1 font-bold">{t("shared.date")}</div>
        <div className="col-span-2 text-neutral-800">
          {DateTime.fromISO(received_at).toLocaleString(DateTime.DATETIME_MED)}
        </div>
      </div>
      {/* Media type */}
      {media_type && (
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-1 font-bold">{t("shared.media_type")}</div>
          <div className="col-span-2 text-neutral-800 font-medium">
            {media_type}
          </div>
        </div>
      )}
      {/* Media URL */}
      {media_url && (
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-1 font-bold">{t("shared.media_link")}</div>
          <div className="col-span-2 text-neutral-800">
            <a
              href={media_url}
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 hover:underline font-medium"
            >
              {t("shared.view_media")}
              <ArrowTopRightOnSquareIcon className="w-4 inline-block ml-1 -translate-y-0.5" />
            </a>
          </div>
        </div>
      )}
      {/* Caption */}
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1 font-bold">{t("shared.caption")}</div>
        <div
          className="col-span-2 text-neutral-800 break-words"
          dangerouslySetInnerHTML={{ __html: highlightedUsernameText(text) }}
        ></div>
      </div>

      {/* {reply_text ? (
          <div className="flex items-center space-x-2 mt-2">
            <RepliedIcon className="rotate-180" />
            <CommentBubble text={reply_text} />
            <div className="text-xs text-neutral-300">
              {t("mentions.reply.your_reply")}
            </div>
          </div>
        ) : null} */}
    </div>
  );
}
