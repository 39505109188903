import React from "react";

export default function PaymentConfirmationMessage(props) {
  const { message } = props;

  return (
    <div
      className={`rounded-3xl px-4 py-2.5 w-72 text-left break-words overflow-hidden select-none sm:select-auto bg-white border`}
    >
      <div className="font-semibold text-xs mb-1">{message.payment_confirmation_title}</div>
      <div className="text-2xs text-neutral-500">{message.payment_confirmation_subtitle}</div>
    </div>
  );
}
