import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "~/components/elements/Button";

import ContactHeader from "../contacts/ContactHeader";
import PublicBackground from "~/components/shared/PublicBackground";
import axios from "axios";
import headers from "~/utils/headers";
import Loader from "~/components/utils/Loader";
import ButtonSelect from "../elements/ButtonSelect";

export default function PublicCheckoutPage(props) {
  const { organization, contact, id } = props;

  const { t } = useTranslation();

  const [checkout, setCheckout] = useState();

  const loadCheckoutLink = () => {
    axios.get(`/public/checkout_links/${id}.json`, headers()).then((res) => {
      setCheckout(res.data);
    });
  };

  useEffect(loadCheckoutLink, [id]);

  const colors = [
    {
      label: "Forest Green",
      value: "green",
    },
    {
      label: "Navy Blue",
      value: "blue",
    },
    {
      label: "Autumn Red",
      value: "red",
    },
  ];
  const [color, setColor] = useState(colors[0].value);

  return (
    <PublicBackground>
      <div className="bg-white p-4 rounded-xl flex flex-col space-y-4 w-full max-w-md">
        <ContactHeader contact={organization} large />
      </div>
      <div className="bg-white p-4 rounded-xl flex flex-col w-full max-w-md">
        {!checkout && <Loader className="w-8 self-center" />}
        {checkout && (
          <>
            <img
              src={`/products/product_02_${color}.jpg`}
              className="rounded-md mb-3"
            />
            <div className="text-lg font-medium mb-1">{checkout.title}</div>
            <div className="text-dark-gray mb-3">{checkout.price}</div>
            <div className="text-sm mb-1 font-medium">Pick color</div>
            <ButtonSelect options={colors} value={color} onChange={setColor} />
          </>
        )}
      </div>
      <Button label="Checkout" className="w-full max-w-md" />
    </PublicBackground>
  );
}
