import { ExclamationTriangleIcon, TrashIcon } from "@heroicons/react/20/solid";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Input from "~/components/elements/Input";
import Button from "~/components/elements/Button";
import { FlowContext } from "~/contexts/flow-context";

export default function TriggerKeywordsOption(props) {
  const { t } = useTranslation();

  const { trigger, showNotice } = props;

  const { editTriggerOptions } = useContext(FlowContext);

  const addKeyword = () =>
    editTriggerOptions(trigger.id, {
      keywords: [...(trigger.options.keywords || []), ""],
    });

  const updateKeyword = (index, keyword) => {
    // check if actual change or just state change
    if (trigger.options.keywords[index] === keyword) return;
    editTriggerOptions(trigger.id, {
      keywords: [
        ...trigger.options.keywords.slice(0, index),
        keyword,
        ...trigger.options.keywords.slice(index + 1),
      ],
    });
  };

  const removeKeyword = (index) => {
    editTriggerOptions(trigger.id, {
      keywords: [
        ...trigger.options.keywords.slice(0, index),
        ...trigger.options.keywords.slice(index + 1),
      ],
    });
  };

  return (
    <>
      <div className="flex flex-col space-y-2">
        {trigger.options.keywords?.map((keyword, index) => (
          <div key={index} className="flex items-center space-x-2">
            {index !== 0 ? (
              <select
                className="input-select"
                value={trigger.options.keywords_logic || "and"}
                onChange={(evt) =>
                  editTriggerOptions(trigger.id, {
                    keywords_logic: evt.target.value,
                  })
                }
              >
                {["and", "or"].map((logic, index) => (
                  <option value={logic} key={index}>
                    {t(`contacts.filters.logic.${logic}`)}
                  </option>
                ))}
              </select>
            ) : (
              <div className="text-sm whitespace-nowrap">
                {t(`automation.scenarios.trigger_node.with_keyword`)}
              </div>
            )}
            <Input
              value={keyword || ""}
              onChange={(value) => updateKeyword(index, value)}
              placeholder={t(`automation.scenarios.trigger_node.keyword`)}
              className="flex-grow"
              autoFocus={!keyword?.length}
              emojiPicker
              changeOnBlur
            />
            {index > 0 && (
              <Button
                icon={TrashIcon}
                size="small"
                onClick={() => removeKeyword(index)}
              />
            )}
          </div>
        ))}
        <Button
          label={t(`automation.scenarios.trigger_node.add_keyword_filter`)}
          size="small"
          onClick={addKeyword}
          className="self-start"
        />
      </div>
      {showNotice && (
        <div className="bg-lighter-gray px-3 py-2 rounded-lg text-2sm">
          <ExclamationTriangleIcon className="w-4 inline-block mr-1" />
          {t("automation.scenarios.trigger_type.messages.keyword_notice")}
        </div>
      )}
    </>
  );
}
