import React, { useState } from "react";
import { sortBy } from "lodash";
import Calendar from "../calendar/Calendar";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import Button from "~/components/elements/Button";
import { DateTime } from "luxon";

export default function AppointmentCalendar(props) {
  const {
    slots = [],
    addSlot,
    removeSlot,
    duration,
    openDurationAlert,
    handleSubmit,
  } = props;

  const { t } = useTranslation();

  const [showSlots, setShowSlots] = useState(false);

  return (
    <>
      <Calendar
        slots={slots}
        addSlot={addSlot}
        removeSlot={removeSlot}
        disablePast
        eventsActive={false}
      />
      <div className="p-4 border-t">
        <div className="flex-shrink-0 flex items-center justify-between text-2sm mb-4">
          <div
            className="flex space-x-2 items-center cursor-pointer"
            onClick={openDurationAlert}
          >
            <div>{t("calendar.duration.label")}</div>
            <div className="px-2 py-1 rounded bg-light-primary font-medium">
              {duration}
            </div>
          </div>
          <div
            className="flex space-x-2 items-center"
            onClick={() => {
              if (slots.length > 0) setShowSlots(!showSlots);
            }}
          >
            <div className="px-2 py-1 rounded bg-light-primary font-medium">
              {slots.length}
            </div>
            <div>
              {t("calendar.slots.select.selected", { count: slots.length })}
            </div>
            {slots.length > 0 && (
              <ChevronDownIcon
                className={`w-5 transition-transform ${
                  showSlots ? "rotate-180" : ""
                }`}
              />
            )}
          </div>
        </div>
        <div
          className="flex-grow flex flex-col items-stretch overflow-auto scrollbar-hide"
          style={{
            transition: "max-height 0.3s ease-out",
            maxHeight: showSlots && slots.length > 0 ? "8rem" : 0,
            marginBottom: showSlots && slots.length > 0 ? ".5rem" : 0,
          }}
        >
          {sortBy(slots, "start_time").map((slot, index) => (
            <div
              key={index}
              className="relative px-4 py-2 mb-2 rounded-lg bg-light-gray text-center text-2sm font-medium"
            >
              {DateTime.fromISO(slot.start_time).toFormat(
                "EEEE, MMMM d - HH:mm",
              )}
              <div
                className="absolute inset-y-0 right-0 p-2"
                onClick={() => removeSlot(slot)}
              >
                <XMarkIcon className="w-4" />
              </div>
            </div>
          ))}
        </div>
        <Button
          label={t("calendar.slots.select.confirm")}
          style="primary"
          onClick={handleSubmit}
          disabled={slots.length === 0}
          className="w-full"
        />
      </div>
    </>
  );
}
