import React, { useContext } from "react";

import StoryMessage from "./StoryMessage";
import TextMessage from "./TextMessage";
import { UserContext } from "~/contexts/user-context";
import { REACTION_EMOJIS } from "../../constants";
import formatMessageText from "~/utils/formatMessageText";

export default function StoryReply(props) {
  const { contact, url, received, id, text } = props;

  const { organization } = useContext(UserContext);

  const caption = received
    ? `${contact?.username} replied to your story`
    : `You replied to ${contact?.username}'s story'`;

  const storyUrl = `https://www.instagram.com/stories/${
    received ? organization.username : contact?.username
  }/${id}/`;

  const isReaction = REACTION_EMOJIS.includes(text);

  return (
    <div
      className={`flex flex-col space-y-2 ${
        received ? "items-start" : "items-end"
      }`}
    >
      <StoryMessage
        storyUrl={storyUrl}
        caption={caption}
        mediaUrl={url}
        received={received}
        reaction={isReaction ? text : false}
      />
      {!isReaction && <TextMessage text={text} received={received} />}
    </div>
  );
}
