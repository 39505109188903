import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { times } from "lodash";
import React, { useMemo } from "react";

export default function PaginationPages(props) {
  const { onClick, currentPage, numberOfPages } = props;

  const visiblePages = useMemo(
    () =>
      times(numberOfPages + 1).slice(
        Math.max(currentPage - 2, 1),
        Math.max(currentPage - 2, 1) + 5,
      ),
    [currentPage, numberOfPages],
  );

  return (
    <div>
      <nav
        className="isolate inline-flex -space-x-px rounded-md shadow-sm select-none"
        aria-label="Pagination"
      >
        <button
          onClick={() => onClick(Math.max(currentPage - 1, 1))}
          className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        {visiblePages[0] > 1 && (
          <>
            <button
              onClick={() => onClick(1)}
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              1
            </button>
            <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
              ...
            </span>
          </>
        )}
        {visiblePages.map((page) =>
          page == currentPage ? (
            <button
              key={page}
              aria-current="page"
              className="relative z-10 inline-flex items-center bg-primary px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              {page}
            </button>
          ) : (
            <button
              key={page}
              onClick={() => onClick(page)}
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              {page}
            </button>
          ),
        )}
        {visiblePages[visiblePages.length - 1] < numberOfPages && (
          <>
            <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
              ...
            </span>
            <button
              onClick={() => onClick(numberOfPages)}
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              {numberOfPages}
            </button>
          </>
        )}
        <button
          onClick={() => onClick(Math.min(currentPage + 1, numberOfPages))}
          className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </nav>
    </div>
  );
}
