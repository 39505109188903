import React from "react";
import {
  ArrowUturnLeftIcon,
  CheckIcon,
  ClockIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { BanknotesIcon } from "@heroicons/react/24/outline";

export default function paymentStatusIcon(status) {
  switch (status) {
    case "paid":
      return <CheckIcon className="w-4" />;
    case "manual":
      return <BanknotesIcon className="w-4" />;
    case "cancelled":
      return <XMarkIcon className="w-4" />;
    case "refunded":
      return <ArrowUturnLeftIcon className="w-3 mr-1" />;
    default:
      return <ClockIcon className="w-4" />;
  }
}
