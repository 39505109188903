import React, { useContext, useState } from "react";

import { UserContext } from "~/contexts/user-context";

import NoPage from "../errors/NoPage";
import NoConnection from "../errors/NoConnection";
import InboxModalError from "../errors/InboxModalError";
import ManyPages from "../errors/ManyPages";
import NoInstagram from "../errors/NoInstagram";
import MessagePermission from "../errors/MessagePermission";
import FacebookError from "../errors/FacebookError";
import AuthorisationMissing from "../errors/AuthorisationMissing";
import InboxInlineError from "../errors/InboxInlineError";

export default function InboxStatusDialog() {
  const { organization } = useContext(UserContext);

  const [hidden, setHidden] = useState(false);

  if (hidden) return null;

  if (organization.facebook_error_code == "200") {
    return (
      <InboxModalError>
        <MessagePermission />
      </InboxModalError>
    );
  } else if (
    ["10", "190", "100", "230"].includes(organization.facebook_error_code)
  ) {
    return (
      <InboxInlineError>
        <AuthorisationMissing />
      </InboxInlineError>
    );
  } else if (organization.facebook_error) {
    return (
      <InboxInlineError>
        <FacebookError />
      </InboxInlineError>
    );
  } else if (!organization.instagram_connected) {
    return (
      <InboxInlineError>
        <AuthorisationMissing />
      </InboxInlineError>
    );
  } else {
    return null;
  }
}
