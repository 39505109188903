import React, { useContext } from "react";
import { FlowContext } from "~/contexts/flow-context";
import triggerTypeOptions from "./triggerTypeOptions";
import { useTranslation } from "react-i18next";
import TriggerKeywordsOption from "./TriggerKeywordsOption";

export default function MessagesOptions(props) {
  const { t } = useTranslation();

  const { trigger } = props;

  const { editTriggerOptions } = useContext(FlowContext);

  const options = triggerTypeOptions.messages.options;

  const handleOptionChange = (evt) => {
    editTriggerOptions(trigger.id, { messages_option: evt.target.value });
  };

  return (
    <div className="flex flex-col space-y-3">
      <select
        className="input-select"
        value={trigger.options.messages_option}
        onChange={handleOptionChange}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {t("automation.scenarios.trigger_type.messages.options." + option)}
          </option>
        ))}
      </select>
      {trigger.options.messages_option === "keywords" && (
        <TriggerKeywordsOption trigger={trigger} showNotice />
      )}
    </div>
  );
}
