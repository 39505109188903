import { createContext } from "react";

export const UserContext = createContext({
  user: null,
  setUser: () => {},
  loadUser: () => {},
  organization: null,
  setOrganization: () => {},
  loadOrganization: () => {},
});
