import React, { useRef, useEffect, forwardRef, useContext } from "react";
import { appInset, tabBarHeight } from "~/utils/environment";
import { UIContext } from "~/contexts/ui-context";

export default forwardRef(function FullScreen(
  {
    children,
    className = "",
    hideTabBar = false,
    statusBarColor = "bg-white",
    ...passthroughProps
  },
  ref,
) {
  // Screen size
  const screenRef = ref || useRef();

  const bottomPadding = hideTabBar ? 0 : tabBarHeight + appInset.bottom;

  const { isMediaSm } = useContext(UIContext);

  const setHeight = () => {
    if (isMediaSm) return;
    const height = window.innerHeight - bottomPadding;
    screenRef.current.style.height = `${height}px`;
  };

  useEffect(() => {
    setHeight();
    window.addEventListener("resize", setHeight);

    return () => {
      window.removeEventListener("resize", setHeight);
    };
  }, []);

  return (
    <div
      ref={screenRef}
      className={`h-screen sm:h-auto sm:min-h-screen bg-white ${className}`}
      {...passthroughProps}
    >
      {appInset.top > 0 && (
        <div
          className={`flex-shrink-0 sticky top-0 inset-x-0 ${statusBarColor}`}
          style={{
            height: appInset.top,
          }}
        />
      )}
      {children}
    </div>
  );
});
