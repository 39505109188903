import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import addPropsToChildren from "~/utils/addPropsToChildren";
import { useLocation, useNavigate } from "react-router-dom";
import { appInset, tabBarHeight } from "~/utils/environment";
import { delay } from "lodash";
import { createPortal } from "react-dom";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useMediaQuery } from "react-responsive";

function ScreenSlide({
  visible = false,
  className = "",
  hideTabBar = false,
  desktopInline = false,
  origin,
  stackDepth = 1,
  zIndex = 100,
  children,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const isMediaSm = useMediaQuery({ minWidth: 640 });

  const back = () => {
    navigate(location?.state?.from || origin || -1);
  };

  const bottomPadding =
    hideTabBar || isMediaSm ? 0 : tabBarHeight + appInset.bottom;

  // Swipe from left
  const screenRef = useRef(null);

  const setScreenTransform = (value) => {
    if (!screenRef.current) return;
    screenRef.current.style.transform = value;
  };

  const [swiping, setSwiping] = useState(false);
  const leftTouchMove = (evt) => {
    setSwiping(true);
    const x = evt.changedTouches[0].pageX;
    setScreenTransform(`translateX(${x}px)`);
  };
  const leftTouchEnd = (evt) => {
    setSwiping(false);
    const x = evt.changedTouches[0].pageX;
    if (x > 100) {
      setScreenTransform(`translateX(100%)`);
      delay(() => {
        setScreenTransform(null);
        back();
      }, 300);
    } else {
      delay(() => setScreenTransform(null), 100);
    }
  };

  const render = () => {
    return (
      <div
        className={`fixed inset-0 ${
          visible
            ? "pointer-events-auto sm:block"
            : "pointer-events-none sm:hidden"
        } ${
          desktopInline
            ? "sm:relative sm:w-auto sm:flex-grow sm:h-screen sm:overflow-hidden"
            : ""
        } ${className}`}
        style={{
          zIndex: !isMediaSm || !desktopInline ? zIndex + stackDepth : "auto",
          bottom: hideTabBar ? 0 : bottomPadding,
        }}
      >
        <div
          className={`fixed ${
            desktopInline ? "sm:hidden" : "sm:absolute"
          } inset-0 bg-black transition-opacity ${
            visible
              ? "opacity-20 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
          style={{
            bottom: hideTabBar ? 0 : bottomPadding,
          }}
          onClick={desktopInline ? null : back}
        />
        {!desktopInline && (
          <div
            className="hidden sm:block absolute top-10 left-1/2 translate-x-72 p-2 cursor-pointer bg-white rounded-full shadow-lg z-50"
            onClick={back}
          >
            <XMarkIcon className="h-6 w-6 text-dark-gray" />
          </div>
        )}
        <div
          ref={screenRef}
          className={`fixed inset-0 transform-gpu overflow-auto scrollbar-hide ${
            swiping ? "" : "transition-transform duration-300"
          }  ${visible ? "translate-x-0" : " translate-x-full"} bg-white ${
            desktopInline
              ? "sm:static sm:transform-none sm:w-full sm:h-full"
              : "sm:absolute sm:w-auto sm:h-auto sm:inset-x-0 sm:!bottom-10 sm:top-10 sm:mx-auto sm:shadow sm:rounded-xl sm:max-w-lg"
          }`}
          style={{
            bottom: hideTabBar ? 0 : bottomPadding,
          }}
        >
          {visible && addPropsToChildren(children, { back })}
          <div
            className="absolute inset-y-0 left-0 w-4 z-10 sm:hidden sm:pointer-events-none"
            onTouchMove={leftTouchMove}
            onTouchEnd={leftTouchEnd}
          />
        </div>
      </div>
    );
  };

  if (desktopInline && isMediaSm) {
    return render();
  } else {
    return createPortal(render(), document.body);
  }
}

ScreenSlide.propTypes = {
  visible: PropTypes.bool,
  className: PropTypes.string,
  hideTabBar: PropTypes.bool,
};

export default ScreenSlide;
