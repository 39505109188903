import React, { useContext } from "react";
import { ContentContext } from "~/contexts/content-context";
import { reverse, sortBy } from "lodash";
import StoryItem from "./stories/StoryItem";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "../shared/Modal";
import StoryDetails from "./stories/StoryDetails";

export default function ContentStories() {
  const navigate = useNavigate();
  const { resourceId } = useParams();

  const { stories } = useContext(ContentContext);

  const activeStory = stories.find(
    (story) => story.id === parseInt(resourceId),
  );

  return (
    <>
      <div className="space-y-4">
        <div className="flex gap-4 flex-wrap pb-10">
          {reverse(sortBy(stories, "posted_at")).map((story) => (
            <Link
              to={`/content/stories/${story.id}`}
              key={story.id}
              className="w-56 rounded-lg overflow-hidden"
            >
              <StoryItem story={story} />
            </Link>
          ))}
        </div>
      </div>
      {activeStory && (
        <Modal onClose={() => navigate("/content/stories")}>
          <StoryDetails story={activeStory} />
        </Modal>
      )}
    </>
  );
}
