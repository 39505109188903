import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

export default function Alert(props) {
  const {
    title = null,
    message = null,
    confirm = null,
    confirmClassName = "",
    hideButtons = false,
    hideCancel = false,
    cancel = null,
    cancelClassName = "",
    onSubmit = () => {},
    onClose = () => {},
    onCancel = () => {},
    keyboardHeight,
    children,
  } = props;

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleCancel = (evt) => {
    evt.preventDefault();
    handleClose();
    onCancel();
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleClose();
    onSubmit();
  };

  useEffect(() => setTimeout(() => setIsOpen(true), 50), []);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        onClose={handleClose}
        className={`fixed inset-0 flex flex-col items-center justify-center p-4 select-none`}
        style={{
          zIndex: 1200,
          paddingBottom: keyboardHeight / 2 + 16,
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`absolute inset-0 bg-black/30`}
            onClick={handleClose}
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition-all transform-gpu"
          enterFrom="scale-95 opacity-0"
          enterTo="scale-100 opacity-100"
          leave="transition-all"
          leaveFrom="scale-100 opacity-100"
          leaveTo="scale-95 opacity-0"
        >
          <Dialog.Panel
            className={`w-full max-w-sm bg-white rounded-xl shadow relative`}
          >
            {title ? (
              <Dialog.Title className="p-4 sm:py-5 text-center font-medium whitespace-pre-line">
                {title}
              </Dialog.Title>
            ) : null}
            {message ? (
              <Dialog.Description
                className="px-5 pb-4 text-center text-2sm leading-normal whitespace-pre-line"
                dangerouslySetInnerHTML={{ __html: message }}
              />
            ) : null}
            {children ? <div className="px-4 pb-4">{children}</div> : null}
            {!hideButtons ? (
              <div className="flex flex-row-reverse border-t">
                <button
                  type="submit"
                  className={`${
                    !hideCancel ? "w-1/2" : "w-full"
                  } text-center font-medium p-3 disabled:text-neutral-400 ${confirmClassName}`}
                  onClick={handleSubmit}
                >
                  {confirm || t("shared.confirm")}
                </button>
                {!hideCancel && (
                  <button
                    className={`w-1/2 text-center p-3 ${
                      cancel ? "" : "text-red-500"
                    } border-r ${cancelClassName}`}
                    onClick={handleCancel}
                  >
                    {cancel || t("shared.cancel")}
                  </button>
                )}
              </div>
            ) : null}
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
