import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import scenarioStatusIcon from "~/utils/scenarioStatusIcon";
import { AutomationContext } from "~/contexts/automation-context";
import SkeletonLoader from "~/components/utils/SkeletonLoader";
import ScenarioMetrics from "./ScenarioMetrics";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "~/components/utils/ErrorComponent";
import ScenarioHistory from "./ScenarioHistory";
import {
  Bars3Icon,
  Bars4Icon,
  FunnelIcon,
  ListBulletIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import ScenarioLimitReached from "./ScenarioLimitReached";
import Tabs from "../../elements/Tabs";
import { useTranslation } from "react-i18next";
import ScenarioInsights from "./ScenarioInsights";

const tabIcons = {
  insights: FunnelIcon,
  history: ListBulletIcon,
};

export default function ScenarioResults(props) {
  const { t } = useTranslation();

  const { scenarioId, handleClose = () => {} } = props;

  const { scenarios, loadScenario } = useContext(AutomationContext);

  const scenario = useMemo(
    () =>
      scenarios.find((s) => s.id.toString() === scenarioId.toString()) || {},
    [scenarioId, scenarios],
  );

  const { active, title, loaded } = scenario;

  useEffect(() => !loaded && loadScenario(scenarioId), [scenarioId, loaded]);

  const [activeTab, setActiveTab] = useState("insights");
  const tabs = ["insights", "history"].map((tab) => ({
    id: tab,
    title: t("shared." + tab),
    active: tab === activeTab,
    onClick: () => setActiveTab(tab),
    icon: tabIcons[tab],
  }));

  const tabsContent = {
    insights: ScenarioInsights,
    history: ScenarioHistory,
  };

  const ActiveTab = tabsContent[activeTab];

  if (!scenario) {
    return (
      <div className="w-full h-full flex items-center justify-center"></div>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <div className="w-full h-full flex flex-col overflow-hidden">
        <div className="flex items-center justify-between p-4 border-b">
          {loaded ? (
            <div className="flex items-center space-x-2">
              {scenarioStatusIcon(active)}
              <div className="text-medium">{title}</div>
            </div>
          ) : (
            <SkeletonLoader width={200} height={20} />
          )}
          <button className="p-2" onClick={handleClose}>
            <XMarkIcon className="w-6" />
          </button>
        </div>
        <ScenarioMetrics scenario={scenario} />
        <ScenarioLimitReached scenario={scenario} />
        <Tabs tabs={tabs} />
        {ActiveTab && <ActiveTab scenarioId={scenarioId} />}
      </div>
    </ErrorBoundary>
  );
}
