import React, { useState } from "react";
import FullscreenAttachments from "../inbox/FullscreenAttachments";

const scaledWidth = 224;
const defaultHeight = 224;

export default function ImageMessage(props) {
  const { url, dimensions, className = "", clickable = false } = props;

  const [loaded, setLoaded] = useState(false);

  const [fullscreen, setFullscreen] = useState(false);

  const scaledHeight = dimensions
    ? (dimensions.height * scaledWidth) / dimensions.width
    : defaultHeight;

  return (
    <div
      className={`rounded-3xl max-w-2.5xs overflow-hidden ${className}`}
      onClick={() => clickable && setFullscreen(true)}
    >
      {!loaded && (
        <div
          className="bg-neutral-100 animate-pulse"
          style={{
            width: scaledWidth + "px",
            height: scaledHeight + "px",
          }}
        />
      )}
      <img
        src={url}
        className={`w-full h-auto ${loaded ? "" : "hidden"}`}
        width={scaledWidth}
        height={scaledHeight}
        onLoad={() => setLoaded(true)}
      />
      {fullscreen && (
        <FullscreenAttachments
          media={[
            {
              url: url,
              type: "image",
            },
          ]}
          onClose={() => setFullscreen(false)}
        />
      )}
    </div>
  );
}
