import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

import { InboxContext } from "~/contexts/inbox-context";
import { LoadingContext } from "~/contexts/loading-context";
import { UIContext } from "~/contexts/ui-context";

import headers from "~/utils/headers";

import BottomMenu from "~/components/shared/BottomMenu";
import BatchMessage from "./BatchMessage";
import BatchActions from "~/components/shared/BatchActions";
import { UserContext } from "~/contexts/user-context";
import { AutomationContext } from "~/contexts/automation-context";
import FoldersDrawer from "./folders/FoldersDrawer";

export default function InboxBatchActions(props) {
  const { conversations, clearSelection, cancel } = props;

  const { setLoading } = useContext(LoadingContext);
  const { showAlert } = useContext(UIContext);
  const { showRunScenarioMenu } = useContext(AutomationContext);

  const conversationIds = conversations.map((c) => c.id);
  const contacts = conversations.map((c) => c.contact);

  const { t } = useTranslation();

  const { updateLocalConversations, updateConversations } =
    useContext(InboxContext);

  const [folderDrawer, setFolderDrawer] = useState(false);

  const handleMove = () => {
    setFolderDrawer(true);
  };

  // useEffect(() => {
  //   window.addEventListener("moved-conversations", clearSelection);
  //   return () => {
  //     window.removeEventListener("moved-conversations", clearSelection);
  //   };
  // }, []);

  // Reply
  const [replyPanel, setReplyPanel] = useState(false);

  // Like
  const handleLike = () =>
    showAlert({
      title: t("inbox.actions.like_last_message"),
      message: t("inbox.actions.like_last_message_confirm", {
        count: conversations.length,
      }),
      onSubmit: handleLikeConfirm,
    });
  const handleLikeConfirm = () => {
    setLoading(t("inbox.actions.liking", { count: conversations.length }));
    axios
      .post(
        `/api/inbox/conversations/${conversationIds.join(",")}/like`,
        null,
        headers(),
      )
      .then(() => {
        setLoading(false);
      });
  };

  // Reply & like
  const [replyMenu, setReplyMenu] = useState(false);
  const replyMenuActions = [
    {
      label: t("inbox.actions.send_message_emoji"),
      action: () => setReplyPanel(true),
    },
    {
      label: t("inbox.actions.like_last_message"),
      action: handleLike,
    },
    {
      label: "⚡️ " + t("automation.scenarios.run"),
      action: () => showRunScenarioMenu({ conversation_ids: conversationIds }),
    },
  ];

  // Spam
  const handleSpam = () =>
    showAlert({
      title: t("inbox.actions.move_to_spam"),
      message: t("inbox.actions.move_to_spam_confirm", {
        count: conversations.length,
      }),
      onSubmit: moveToSpam,
    });
  const moveToSpam = () => {
    updateConversations(conversationIds, { spam: true });
    clearSelection();
  };

  // Replied
  const handleReplied = () =>
    showAlert({
      title: t("inbox.actions.mark_as_replied"),
      message: t("inbox.actions.mark_as_replied_confirm", {
        count: conversations.length,
      }),
      onSubmit: markAsReplied,
    });
  const markAsReplied = () => {
    updateConversations(conversationIds, { marked_replied: true });
    clearSelection();
  };

  // Read
  const handleRead = () =>
    showAlert({
      title: t("inbox.actions.mark_as_read"),
      message: t("inbox.actions.mark_as_read_confirm", {
        count: conversations.length,
      }),
      onSubmit: markAsRead,
    });
  const markAsRead = () => {
    updateConversations(conversationIds, { unread: false });
    clearSelection();
  };

  // Mark as spam, read & replied
  const [markMenu, setMarkMenu] = useState(false);
  const markMenuActions = [
    {
      label: t("inbox.actions.mark_as_read"),
      action: handleRead,
    },
    {
      label: t("inbox.actions.mark_as_replied"),
      action: handleReplied,
    },
    {
      label: t("inbox.actions.move_to_spam"),
      action: handleSpam,
    },
  ];

  const actions = [
    {
      label: t("inbox.actions.actions"),
      action: () => setReplyMenu(true),
    },
    {
      label: t("inbox.actions.mark_as"),
      action: () => setMarkMenu(true),
    },
    {
      label: t("inbox.actions.move_to"),
      action: handleMove,
    },
  ];

  const render = () => {
    return (
      <>
        <BatchActions actions={actions} />
        {replyMenu ? (
          <BottomMenu
            title={t("inbox.actions.conversations_selected", {
              count: conversations.length,
            })}
            actions={replyMenuActions}
            onClose={() => setReplyMenu(false)}
          />
        ) : null}
        {markMenu ? (
          <BottomMenu
            title={t("inbox.actions.conversations_selected", {
              count: conversations.length,
            })}
            actions={markMenuActions}
            onClose={() => setMarkMenu(false)}
          />
        ) : null}
        {replyPanel ? (
          <BatchMessage
            conversationIds={conversationIds}
            onClose={() => setReplyPanel(false)}
            onSuccess={cancel}
          />
        ) : null}
        {folderDrawer && (
          <FoldersDrawer
            contacts={contacts}
            onSubmit={(contacts) => {
              updateLocalConversations(conversationIds, {
                contact: {
                  folder_ids: contacts[0].folder_ids,
                },
              });
            }}
            onClose={() => setFolderDrawer(false)}
          />
        )}
      </>
    );
  };

  // Only attach to body on mobile
  return isMobile ? createPortal(render(), document.body) : render();
}
