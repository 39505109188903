import React from "react";

export default function FacebookIcon(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 10C20.5 4.477 16.023 0 10.5 0C4.977 0 0.5 4.477 0.5 10C0.5 14.991 4.157 19.128 8.938 19.878V12.891H6.398V10H8.938V7.797C8.938 5.291 10.43 3.907 12.715 3.907C13.809 3.907 14.953 4.102 14.953 4.102V6.562H13.693C12.45 6.562 12.063 7.333 12.063 8.124V10H14.836L14.393 12.89H12.063V19.878C16.843 19.128 20.5 14.991 20.5 10Z"
        fill="currentColor"
      />
    </svg>
  );
}
