import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LineOption from "./LineOption";
import Input from "./Input";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

export default function LinesSelect(props) {
  const {
    label,
    value = [],
    onChange = () => {},
    options = [],
    foldable = false,
    closed = false,
    multiple = false,
    icon = null,
    description = null,
    name = "",
    searchable = false,
  } = props;

  const { t } = useTranslation();

  const handleSelectOption = (option) => {
    if (!multiple) {
      return onChange([option.value]);
    }

    if (value.includes(option.value)) {
      onChange(value.filter((value) => value !== option.value));
    } else if (!option.value) {
      onChange([]);
    } else {
      onChange([...value, option.value]);
    }
  };

  const isSelectedOption = (option) => {
    return (
      value.includes(option.value) || (value.length === 0 && !option.value)
    );
  };

  const [searchQuery, setSearchQuery] = useState("");

  const filteredOptions = searchQuery
    ? options.filter((option) =>
        option.title?.toLowerCase()?.includes(searchQuery?.toLowerCase()),
      )
    : options;

  return foldable && closed ? null : (
    <div className={"relative rounded-lg bg-surface space-y-2"}>
      {searchable && (
        <Input
          placeholder={t("shared.search")}
          value={searchQuery}
          onChange={setSearchQuery}
          icon={MagnifyingGlassIcon}
        />
      )}
      {filteredOptions.map((option, index) => (
        <LineOption
          key={option.value}
          option={option}
          isSelected={isSelectedOption}
          icon={option.icon || icon}
          onClick={handleSelectOption}
          index={index}
          name={name}
        />
      ))}
    </div>
  );
}
