import React, { useMemo } from "react";
import Input from "~/components/elements/Input";
import TemplateMessage from "~/components/messages/TemplateMessage";
import { useTranslation } from "react-i18next";

export default function QuestionsSurveyMessage(props) {
  const { t } = useTranslation();

  const { survey, setSurvey } = props;

  const handleChange = (field) => (value) => {
    setSurvey((survey) => ({ ...survey, [field]: value }));
  };

  // Preview

  const templatePayload = useMemo(() => {
    const buttons = [
      {
        title: survey.button_label || t("surveys.default_button_label"),
      },
    ];
    return survey.title
      ? {
          title: survey.title,
          subtitle: survey.subtitle,
          buttons,
        }
      : null;
  }, [survey]);

  return (
    <div className="flex flex-col sm:flex-row items-stretch overflow-hidden min-h-[500px]">
      <div className="w-full space-y-4 p-4 overflow-y-auto flex flex-col items-stretch justify-center">
        <div className="text-sm text-muted">
          {t("surveys.questions.message.form_title")}
        </div>
        <Input
          placeholder={t("surveys.questions.message.title")}
          value={survey.title}
          onChange={handleChange("title")}
          autoFocus={!survey.title}
          maxLength={80}
          emojiPicker
        />
        <Input
          type="textarea"
          placeholder={t("surveys.questions.message.subtitle")}
          value={survey.subtitle}
          onChange={handleChange("subtitle")}
          maxLength={80}
          emojiPicker
        />
        <Input
          placeholder={t("surveys.questions.message.button")}
          value={survey.button_label}
          onChange={handleChange("button_label")}
          maxLength={20}
          emojiPicker
        />
      </div>
      <div className="w-full p-8 bg-lighter-gray hidden sm:flex flex-col space-y-8 items-center justify-center">
        <div className="text-3xs uppercase font-bold text-neutral-400">
          {t("surveys.questions.message.preview")}
        </div>
        {templatePayload && <TemplateMessage template={templatePayload} />}
      </div>
    </div>
  );
}
