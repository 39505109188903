import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import appIcon from "../../../../assets/images/app_small_icon.png";
import PageHeader from "~/components/shared/PageHeader";
import FullScreen from "~/components/utils/FullScreen";
import ProfileToggle from "../ProfileToggle";
import { UserContext } from "~/contexts/user-context";
import PermissionCheck from "./PermissionCheck";

export default function BusinessNotificationsPage(props) {
  const { back } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { user, updateNotificationSettings } = useContext(UserContext);

  const [settings, setSettings] = useState(user.notification_settings);

  const handleChange = (key, value) => {
    setSettings((sett) => ({ ...sett, [key]: value }));
    updateNotificationSettings(key, value);
  };

  return (
    <FullScreen>
      <PageHeader
        title={t("notifications.business_title")}
        back={back}
        className="mb-4"
      />
      <PermissionCheck />
      <ProfileToggle
        label={t("notifications.weekly.name")}
        value={settings.weekly}
        onChange={(value) => handleChange("weekly", value)}
      />
      <div className="px-4 mb-2">
        <div className="flex items-center space-x-4 bg-light-gray bg-opacity-60 rounded-2xl p-2 pr-3.5">
          <img src={appIcon} className="w-9 h-9 flex-shrink-0" />
          <div>
            <div className="flex justify-between mb-0.25">
              <div className="text-2sm font-semibold flex-grow">
                {t("notifications.weekly.preview_title")}
              </div>
              <div className="text-xs text-dark-gray">
                {t("notifications.preview_time")}
              </div>
            </div>
            <div className="text-2sm">
              {t("notifications.weekly.preview_text")}
            </div>
          </div>
        </div>
      </div>
    </FullScreen>
  );
}
