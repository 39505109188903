import {
  ArrowTopRightOnSquareIcon,
  ChatBubbleOvalLeftIcon,
  HeartIcon,
} from "@heroicons/react/20/solid";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import Button from "~/components/elements/Button";
import ReelIcon from "~/components/shared/icons/ReelIcon";
import { truncate } from "lodash";
import { DateTime } from "luxon";
import MediaComments from "./MediaComments";
import { ContentContext } from "~/contexts/content-context";

export default function MediaDetails(props) {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);
  const { loadMedia } = useContext(ContentContext);

  const { media } = props;

  useEffect(() => loadMedia(media.id), [media.id]);

  return (
    <div className="sm:flex flex-row w-full max-w-4xl sm:max-h-[600px] overflow-y-auto sm:overflow-hidden">
      <div className="sm:max-w-sm sm:border-r">
        <div className="relative">
          <img
            className="block w-full aspect-square object-cover object-center"
            src={media.url}
          />
          {media.media_type === "VIDEO" && (
            <div className="absolute top-2 right-2 text-white">
              <ReelIcon />
            </div>
          )}
          <Button
            icon={ArrowTopRightOnSquareIcon}
            size="extra-small"
            label={t("content.medias.view_on_instagram")}
            className="absolute bottom-2 right-2"
            href={media.permalink}
          />
        </div>
        <div className="p-3 space-y-2">
          {media.caption && (
            <div className="text-xs">
              <span className="font-medium">{organization?.username}</span>{" "}
              {truncate(media.caption, { length: 68 })}
            </div>
          )}
          <div className="flex items-center space-x-3">
            <div className="flex items-center space-x-1">
              <HeartIcon className="w-4 h-4 text-gray-500" />
              <div className="text-xs text-gray-500">
                {t("content.medias.likes", {
                  count: media.like_count || 0,
                })}
              </div>
            </div>
            <div className="flex items-center space-x-1">
              <ChatBubbleOvalLeftIcon className="w-4 h-4 text-gray-500" />
              <div className="text-xs text-gray-500">
                {t("content.medias.comments", {
                  count: media.comments_count || 0,
                })}
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-2xs text-gray-400">
              {t("content.medias.published", {
                date: DateTime.fromISO(media.posted_at).toLocaleString(
                  DateTime.DATE_MED,
                ),
              })}
            </div>
            <div className="text-2xs text-gray-400">
              {t("content.medias.last_synced", {
                date: DateTime.fromISO(media.updated_at).toLocaleString(
                  DateTime.DATE_MED,
                ),
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="sm:w-[480px] overflow-hidden">
        <MediaComments media={media} />
      </div>
    </div>
  );
}
