import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ReactFlowProvider } from "@xyflow/react";
import ErrorComponent from "~/components/utils/ErrorComponent";

export default function FlowWrapper(props) {
  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <ReactFlowProvider>{props.children}</ReactFlowProvider>
    </ErrorBoundary>
  );
}
