import React from "react";
import { useTranslation } from "react-i18next";
import ImageMessage from "~/components/messages/ImageMessage";
import VideoMessage from "~/components/messages/VideoMessage";
import Loader from "~/components/utils/Loader";
import ResultsNode from "./ResultsNode";

export default function MediaMessageResultsNode(props) {
  const { t } = useTranslation();

  const { data } = props;
  const { media_file } = data;

  return (
    <ResultsNode {...props}>
      <div className={`bg-white rounded-3xl`}>
        {media_file ? (
          media_file.media_type === "image" ? (
            <ImageMessage
              url={media_file.media_url}
              dimensions={media_file.dimensions}
            />
          ) : (
            <VideoMessage
              url={media_file.media_url}
              preview_url={media_file.media_preview_url}
              className="w-40 h-40 max-h-55"
            />
          )
        ) : (
          <div className="py-3 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl cursor-pointer nodrag opacity-75 hover:opacity-100 transition-opacity flex space-x-2 items-center">
            <Loader width={24} strokeWidth={8} />
            <div className="text-md font-medium text-center">
              {t("automation.scenarios.actions.media_message.loading")}
            </div>
          </div>
        )}
      </div>
    </ResultsNode>
  );
}
