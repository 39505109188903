import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isApp } from "~/utils/environment";

import {
  ArrowPathRoundedSquareIcon,
  ShareIcon,
} from "@heroicons/react/20/solid";

import {
  ChatBubbleLeftEllipsisIcon,
  StarIcon,
} from "@heroicons/react/24/outline";

import { UserContext } from "~/contexts/user-context";

import ContactHeader from "../contacts/ContactHeader";
import FullScreen from "~/components/utils/FullScreen";
import MenuTitle from "./MenuTitle";
import MenuItem from "./MenuItem";

import NotificationsPage from "./NotificationsPage";
import PersonalAccountPage from "./PersonalAccountPage";
import OrganizationAccountPage from "./OrganizationAccountPage";

import PaymentSettings from "../payments/settings/PaymentSettings";
import BalancePage from "../payments/BalancePage";

import reactNativeMessage from "~/utils/reactNativeMessage";
import ScreenSlide from "~/components/utils/ScreenSlide";
import BankDetails from "../payments/BankDetails";
import CalendarSettings from "../calendar/CalendarSettings";
import OrganizationUsersPage from "./OrganizationUsersPage";
import IntegrationsPage from "./IntegrationsPage";
import { SubscriptionContext } from "../../contexts/subscription-context";

const SHARE_URL = "https://www.inro.social?utm_source=share&utm_medium=app";

export default function ProfilePage() {
  const { t } = useTranslation();

  const { user, organization, switchOrganization } = useContext(UserContext);
  const { limits } = useContext(SubscriptionContext);

  const { path } = useParams();

  const pages = {
    personal_account: PersonalAccountPage,
    organization_account: OrganizationAccountPage,
    organization_users: OrganizationUsersPage,
    integrations: IntegrationsPage,
    notifications: NotificationsPage,
    payment_settings: PaymentSettings,
    calendar_settings: CalendarSettings,
    bank_details: BankDetails,
    balance: BalancePage,
  };

  const Page = pages[path];

  const handleShare = () => {
    if (isApp) {
      reactNativeMessage({ share: SHARE_URL + "&utm_medium=app" });
    } else if (navigator.share) {
      navigator.share({ url: SHARE_URL + "&utm_medium=website" });
    }
  };

  return (
    <div className="sm:h-screen sm:flex sm:flex-grow">
      <FullScreen className="sm:w-96 sm:border-r flex flex-col">
        <div className="p-4 flex-shrink-0">
          {/* <PageTitle text={t("profile.title")} className="mb-4" /> */}
          <ContactHeader contact={user} large />
        </div>
        <div className="flex-grow overflow-auto scrollbar-hide pb-20">
          {/* Account */}
          <MenuTitle title={t("profile.account")} className="mb-6" />
          <MenuItem
            label={t("profile.personal_account")}
            to="/profile/personal_account"
            className="border-t"
          />
          <MenuItem
            label={t("profile.organization_account")}
            to="/profile/organization_account"
          />
          <MenuItem
            label={t("profile.organization_users.title")}
            to="/profile/organization_users"
          />
          {!limits.features?.includes("integrations") && (
            <MenuItem
              label={t("profile.integrations")}
              to="/profile/integrations"
            />
          )}

          <MenuItem
            label={t("profile.switch_organization")}
            onClick={switchOrganization}
            icon={ArrowPathRoundedSquareIcon}
            className="my-12 border-t"
          />

          {/* Payments & Calendar */}
          {organization.features.payments && (
            <>
              <MenuItem
                label={t("payments.settings.title")}
                to="/profile/payment_settings"
                className="border-t"
              />
              <MenuItem label={t("profile.balance")} to="/profile/balance" />
            </>
          )}
          {organization.features.calendar && (
            <MenuItem
              label={t("calendar.settings.title")}
              to="/profile/calendar_settings"
            />
          )}

          {/* Contact us */}
          <MenuItem
            label={t("profile.contact_us")}
            href="https://ig.me/m/inroapp"
            icon={ChatBubbleLeftEllipsisIcon}
            className="mt-12 border-t"
          />
          {/* Share */}
          {isApp && (
            <MenuItem
              label={t("profile.share")}
              onClick={handleShare}
              icon={ShareIcon}
              arrow={false}
            />
          )}
          {isApp && (
            <MenuItem
              label={t("shared.request_review_action")}
              icon={StarIcon}
              onClick={() => reactNativeMessage({ requestStoreReview: true })}
              arrow={false}
            />
          )}
          <MenuItem
            label={t("profile.privacy_policy")}
            href="https://www.iubenda.com/privacy-policy/66567077"
            arrow={false}
            external
          />
          <a href="/sign_out" className="mt-12 block p-4 text-2sm underline">
            {t("profile.log_out")}
          </a>
        </div>
      </FullScreen>
      {/* Pages */}
      <ScreenSlide
        visible={!!Page}
        origin="/profile"
        desktopInline
        stackDepth={1}
        className="sm:border-r sm:max-w-md"
      >
        {Page && <Page />}
      </ScreenSlide>
    </div>
  );
}
