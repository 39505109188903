import React, { useContext, useMemo } from "react";
import { AutomationContext } from "../../contexts/automation-context";
import { useTranslation } from "react-i18next";
import { BoltIcon, PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import Modal from "../shared/Modal";
import Button from "../elements/Button";
import scenarioStatusIcon from "../../utils/scenarioStatusIcon";
import { DateTime } from "luxon";
import ScenarioTriggerBadges from "../automation/scenarios/ScenarioTriggerBadges";
import classNames from "../../utils/classNames";

const scenarioColumns = ["name", "triggers", "last_updated", "last_executed"];

export default function ScenarioPickerDialog(props) {
  const { t } = useTranslation();

  const { value, onChange, onClose, excludedIds = [] } = props;

  const { scenarios } = useContext(AutomationContext);

  const visibleScenarios = useMemo(
    () => scenarios.filter((s) => !excludedIds.includes(s.id)),
    [scenarios, excludedIds],
  );

  const handlePick = (scenarioId) => {
    onChange(scenarioId);
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      title={t("automation.scenarios.select_scenario")}
      titleIcon={BoltIcon}
      className="w-full max-w-2xl"

      // TODO create new scenario here

      // footerRight={
      //   <Button
      //     label={t("automation.scenarios.new_scenario")}
      //     icon={PlusIcon}
      //   />
      // }
      // footerLeft={<Button label={t("shared.cancel")} onClick={onClose} />}
    >
      <div className="min-h-[400px] max-h-[500px] flex-grow overflow-x-auto relative">
        <table className="w-full">
          <thead className="w-full sticky top-0">
            {/* Trick to have a bottom border on sticky thead */}
            <div className="absolute w-full h-0 bottom-0 border-t" />
            <tr scope="col">
              {scenarioColumns.map((column) => (
                <th
                  key={column}
                  className="bg-white p-3 font-semibold text-sm text-left"
                >
                  {t(`automation.scenarios.table.columns.${column}`)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y">
            {visibleScenarios.map((scenario) => (
              <tr
                key={scenario.id}
                className={classNames(
                  "hover:bg-neutral-50 transition-colors cursor-pointer",
                  scenario.id == value && "bg-neutral-100 hover:bg-neutral-100",
                )}
                onClick={() => handlePick(scenario.id)}
              >
                <td className="flex items-center space-x-2 py-4 px-3">
                  {scenarioStatusIcon(scenario.active)}
                  <div className="whitespace-nowrap text-sm font-medium">
                    {scenario.title}
                  </div>
                </td>
                <td className="py-4 px-3">
                  <ScenarioTriggerBadges triggers={scenario.triggers} />
                </td>
                <td className="whitespace-nowrap py-4 px-3 text-sm">
                  {DateTime.fromISO(scenario.updated_at).toRelative()}
                </td>
                <td className="whitespace-nowrap py-4 px-3 text-sm">
                  {DateTime.fromISO(scenario.last_executed_at).toRelative()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
