import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";

import { InboxContext } from "~/contexts/inbox-context";
import { UserContext } from "~/contexts/user-context";

import subscribeToChannel from "~/utils/subscribeToChannel";
import headers from "~/utils/headers";
import FolderDialog from "../components/inbox/folders/FolderDialog";
import { unionBy } from "lodash";
import { UIContext } from "~/contexts/ui-context";
import FoldersDrawer from "../components/inbox/folders/FoldersDrawer";
import BatchMessage from "../components/inbox/BatchMessage";
import { useTranslation } from "react-i18next";

const MENTIONS_PER_PAGE = 200;

export default function InboxProvider(props) {
  const { organization, loadOrganization, createFolder } =
    useContext(UserContext);
  const { showPrompt, showDialog } = useContext(UIContext);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  // Conversations
  const [conversations, setConversations] = useState([]);

  const unreadConversationsCount = conversations.filter((c) => c.unread).length;

  // Update or insert conversations
  const upsertConversations = (newConversations) => {
    setConversations((conversations) => {
      return unionBy(newConversations, conversations, "id");
    });
  };

  // Load one conversation (full info)
  const loadConversation = async (conversationId) => {
    if (!conversationId) return;
    try {
      const res = await axios.get(`/api/inbox/conversations/${conversationId}`);
      upsertConversations([res.data]);
      return res.data;
    } catch (err) {
      return false;
    }
  };

  // Load many conversations (basic info)
  const loadConversations = (params = {}) => {
    setLoading(true);
    axios
      .get("/api/inbox/conversations", { params })
      .then((res) => {
        setConversations(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const loadMoreConversations = (params = {}, offset) => {
    setLoadingMore(true);
    axios
      .get("/api/inbox/conversations", { params: { ...params, offset } })
      .then((res) => {
        upsertConversations(res.data);
        setLoadingMore(false);
      })
      .catch((err) => console.log(err));
  };

  // load conversations & subscribe on mount
  useEffect(() => {
    // subscribe to action cable channels (websocket)
    subscribeToChannel("ConversationsChannel", (conversation) => {
      upsertConversations([conversation]);
    });
  }, [organization.id]);

  const updateLocalConversations = (conversationIds, newConversation) => {
    setConversations((conversations) => {
      return conversations.map((conversation) => {
        if (conversationIds.indexOf(conversation.id) > -1) {
          return {
            ...conversation,
            ...newConversation,
          };
        } else {
          return conversation;
        }
      });
    });
  };

  // used to move one or more conversations to a folder
  const updateConversations = (conversationIds, newConversation) => {
    updateLocalConversations(conversationIds, newConversation);
    axios.patch(
      `/api/inbox/conversations/${conversationIds.join(",")}`,
      newConversation,
      headers(),
    );
  };

  // used to update conversations individually
  const updateEachLocalConversation = (newConversations) => {
    setConversations((conversations) => {
      return conversations.map((conversation) => {
        const newConversation = newConversations.find(
          (c) => c.id == conversation.id,
        );
        if (newConversation) {
          return {
            ...conversation,
            ...newConversation,
          };
        } else {
          return conversation;
        }
      });
    });
  };
  const updateEachConversation = (conversations) => {
    updateEachLocalConversation(conversations);
    axios.patch(`/api/inbox/conversations`, { conversations }, headers());
  };

  const markAllRead = () => {
    axios.post("/api/inbox/conversations/mark_all_read", {}, headers());
    setConversations((conversations) =>
      conversations.map((c) => ({ ...c, unread: false })),
    );
  };

  // NB: these functions are used to update contact info inside the conversation objects, not the contact objects from the ContactsContext

  const updateContact = (contactId, contact, callback = null) => {
    axios.patch(`/api/contacts/contacts/${contactId}`, contact, headers());
    updateLocalContact(contactId, contact);
    callback && callback();
  };

  const refreshContactInfo = (contactId) => {
    axios
      .get(`/api/contacts/contacts/${contactId}?update_info=true`)
      .then((res) => updateLocalContact(contactId, res.data));
  };

  const updateLocalContact = (contactId, contact) => {
    setConversations((conversations) =>
      conversations.map((conversation) =>
        conversation.contact.id != contactId
          ? conversation
          : {
              ...conversation,
              contact: { ...conversation.contact, ...contact },
            },
      ),
    );
  };

  // Edit conversation folders

  const [foldersDrawer, showFoldersDrawer] = useState(null);

  const moveConversationsToFolders = (conversations, callback) => {
    updateEachConversation(conversations);
    window.dispatchEvent(new CustomEvent("moved-conversations"));
    callback && callback(conversations);
    loadOrganization();
  };

  // Create folder & move conversations to it
  const [createFolderDialog, showCreateFolderDialog] = useState(null);

  const editConversationsFolder = (conversations, callback = null) => {
    showFoldersDrawer({
      conversations,
      onCreateFolder: showCreateFolderDialog,
      onSubmit: (newConversations) =>
        moveConversationsToFolders(newConversations, callback),
    });
  };

  // Batch message
  const [batchMessage, showBatchMessage] = useState(null);

  // Comments

  const deleteComment = async (commentId, callback = () => {}) => {
    showPrompt(t("comments.delete_confirm"), async () => {
      try {
        await axios.delete(`/api/inbox/comments/${commentId}`, headers());
        callback();
      } catch (err) {
        console.log(err);
      }
    });
  };

  const commentReplyDialog = (commentId, callback = () => {}) => {
    showDialog({
      label: t("comments.comment_reply.title"),
      placeholder: t("comments.comment_reply.placeholder"),
      confirm: t("shared.send"),
      onSubmit: (content) => {
        axios
          .post(
            `/api/inbox/comments/${commentId}/comment_reply`,
            { content },
            headers(),
          )
          .then((res) => callback(res.data));
      },
    });
  };

  const privateReplyDialog = (commentId, callback = () => {}) => {
    showDialog({
      label: t("comments.private_reply.title"),
      placeholder: t("comments.private_reply.placeholder"),
      confirm: t("shared.send"),
      onSubmit: (content) => {
        axios
          .post(
            `/api/inbox/comments/${commentId}/private_reply`,
            { content },
            headers(),
          )
          .then((res) => callback(res.data));
      },
    });
  };

  // Mentions
  const [mentions, setMentions] = useState([]);

  const upsertMentions = (newMentions) => {
    setMentions((mentions) => {
      const union = unionBy(newMentions, mentions, "id");
      return union;
    });
  };

  const loadMentions = () => {
    axios
      .get("/api/inbox/mentions")
      .then((res) => {
        setMentions(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const loadMoreMentions = () => {
    const page = Math.floor(mentions.length / MENTIONS_PER_PAGE) + 1;
    axios
      .get(`/api/inbox/mentions?page=${page}`)
      .then((res) => {
        upsertMentions(res.data);
      })
      .catch((err) => console.log(err));
  };

  // load mentions on mount
  useEffect(() => {
    loadMentions();
  }, [organization.id]);

  const mentionReplyDialog = (mentionId) => {
    showDialog({
      label: t("mentions.reply.title"),
      placeholder: t("mentions.reply.placeholder"),
      confirm: t("shared.send"),
      onSubmit: (content) => {
        axios
          .post(
            `/api/inbox/mentions/${mentionId}/reply`,
            { content },
            headers(),
          )
          .then((res) => upsertMentions([res.data]));
      },
    });
  };

  const inboxValues = {
    loading,
    loadingMore,
    conversations,
    loadConversation,
    loadConversations,
    loadMoreConversations,
    updateLocalConversations,
    updateConversations,
    unreadConversationsCount,
    markAllRead,
    updateContact,
    refreshContactInfo,
    editConversationsFolder,
    showBatchMessage,
    deleteComment,
    commentReplyDialog,
    privateReplyDialog,
    mentions,
    loadMoreMentions,
    mentionReplyDialog,
  };

  return (
    <InboxContext.Provider value={inboxValues}>
      {props.children}
      {foldersDrawer ? (
        <FoldersDrawer
          {...foldersDrawer}
          onClose={() => showFoldersDrawer(null)}
        />
      ) : null}
      {createFolderDialog ? (
        <FolderDialog
          onSubmit={createFolder}
          onClose={() => showCreateFolderDialog(false)}
        />
      ) : null}
      {batchMessage ? (
        <BatchMessage
          conversationIds={batchMessage.conversationIds}
          onClose={() => showBatchMessage(false)}
          onSuccess={batchMessage.onSuccess}
        />
      ) : null}
    </InboxContext.Provider>
  );
}
