import React from "react";

export default function StatusBadge(props) {
  const { status, small, label } = props;

  let statusClass;
  switch (status) {
    case "success":
      statusClass = "bg-light-green text-dark-green-500";
      break;
    case "failure":
      statusClass = "bg-light-red text-dark-red-500";
      break;
    default:
      statusClass = "bg-yellow-500 text-dark-yellow-500";
      break;
  }

  return (
    <div
      className={`inline-block ${small ? "py-1.5 px-2 text-xs" : "py-2 px-3 text-sm"} rounded-lg ${statusClass}`}
    >
      {label}
    </div>
  );
}
