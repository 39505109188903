import React from "react";
import Loader from "~/components/utils/Loader";

export default function SyncNotification(props) {
  const { title, text } = props;

  return (
    <div className="max-w-sm sm:mx-auto bg-warning shadow-lg rounded-lg text-black flex items-center px-4 py-3">
      <Loader stroke="#000" strokeWidth={6} className="w-8" />
      <div className="ml-4">
        {title && <h3 className="text-sm font-medium">{title}</h3>}
        {text && <p className="text-xs text-black opacity-75">{text}</p>}
      </div>
    </div>
  );
}
