import React from "react";
import classNames from "~/utils/classNames";
import Loader from "~/components/utils/Loader";

const loadingColors = {
  green: "#09514F",
  blue: "#1F2D52",
  gray: "#8D8D8D",
  cream: "#A77C05",
  yellow: "#A77C05",
  red: "#6D0707",
};

const sizes = {
  sm: "px-2 py-0.5 text-xs",
  md: "px-2.5 py-1 text-xs",
};

export default function Badge(props) {
  const {
    label,
    color = "gray",
    size = "md",
    rounded = false,
    icon: Icon = null,
    loading = false,
    className = "",
    children,
    ...passthroughProps
  } = props;

  return (
    <div
      className={classNames(
        "inline-flex items-center font-medium ring-1 ring-inset whitespace-nowrap",
        sizes[size],
        `text-${color}-700 bg-${color}-100 ring-${color}-200`,
        rounded ? "rounded-full" : "rounded-md",
        className,
      )}
      {...passthroughProps}
    >
      {loading && (
        <div className="mr-1">
          <Loader width={12} strokeWidth={8} stroke={loadingColors[color]} />
        </div>
      )}
      {!loading && Icon && (
        <Icon
          className={classNames("w-3 mr-1 flex-shrink-0", `text-${color}-500`)}
        />
      )}
      {label}
      {children}
    </div>
  );
}
