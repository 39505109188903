import {
  DocumentArrowDownIcon,
  NoSymbolIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Badge from "~/components/elements/Badge";
import ButtonWithDropdown from "~/components/elements/ButtonWithDropdown";

import { CampaignsContext } from "~/contexts/campaigns-context";
import { ContactsContext } from "~/contexts/contacts-context";
import { UserContext } from "~/contexts/user-context";

import { useNavigate } from "react-router-dom";
import { isDev } from "~/utils/environment";
import Loader from "~/components/utils/Loader";
import SkeletonLoader from "~/components/utils/SkeletonLoader";

const statusColors = {
  draft: "yellow",
  running: "blue",
  completed: "green",
  scheduled: "blue",
  limited: "yellow",
};

export default function CampaignItem(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    loadCampaign,
    checkCampaignStatus,
    saveCampaign,
    cancelCampaign,
    duplicateCampaign,
    deleteCampaign,
  } = useContext(CampaignsContext);

  const { campaign } = props;

  const {
    id,
    title,
    status,
    is_template,
    sent_at,
    created_at,
    scheduled_at,
    metrics,
    loaded,
  } = campaign;

  useEffect(() => !loaded && loadCampaign(id), [id, loaded]);

  useEffect(() => {
    if (status == "running") {
      const interval = setInterval(() => {
        checkCampaignStatus(id);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [status, id, checkCampaignStatus]);

  const handleCancelSchedule = useCallback(async () => {
    await saveCampaign({
      ...campaign,
      status: "draft",
      scheduled_at: null,
    });
  }, [campaign, saveCampaign]);

  const dropdownActions = useMemo(
    () => [
      {
        label: t("shared.duplicate"),
        icon: DocumentArrowDownIcon,
        onClick: () => duplicateCampaign(id),
      },
      status == "draft" && {
        label: t("shared.delete"),
        icon: TrashIcon,
        onClick: () => deleteCampaign(id),
        className: "!text-red-500",
      },
      status == "running" && {
        label: t("campaigns.cancel_campaign"),
        icon: NoSymbolIcon,
        onClick: () => cancelCampaign(id),
        className: "!text-red-500",
      },
    ],
    [campaign],
  );

  const metricList =
    status == "running"
      ? ["running", "sent", "failed", "opened"]
      : ["sent", "failed", "opened", "converted"];

  return (
    <div className="flex items-center justify-between gap-x-6 py-5">
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            {isDev && `#${id} - `}
            {title}
          </p>
          <Badge
            color={statusColors[status]}
            label={t("campaigns.status." + status)}
            loading={status == "running"}
          />
          {is_template && <Badge color="blue" label={t("shared.template")} />}
        </div>
        <div className="mt-1 flex items-center gap-x-1">
          <p className="whitespace-nowrap text-xs text-gray-500">
            {status == "draft"
              ? t("campaigns.created_on", {
                  date: DateTime.fromISO(created_at).toLocaleString(
                    DateTime.DATETIME_MED,
                  ),
                })
              : status == "scheduled"
                ? t("campaigns.scheduled_on", {
                    date: DateTime.fromISO(scheduled_at).toLocaleString(
                      DateTime.DATETIME_MED,
                    ),
                  })
                : t("campaigns.sent_on", {
                    date: DateTime.fromISO(sent_at).toLocaleString(
                      DateTime.DATETIME_MED,
                    ),
                  })}
          </p>
        </div>
      </div>
      <div className="flex items-center space-x-4">
        {status != "draft" ? (
          <dl className="hidden md:w-[220px] lg:w-[400px] md:grid gap-y-2 grid-cols-2 lg:grid-cols-4">
            {metricList.map((metric) => (
              <div
                key={metric}
                className="flex flex-wrap items-baseline justify-between gap-y-0 lg:gap-y-2 bg-white px-1 md:px-2 xl:px-4"
              >
                <dt className="text-2sm font-medium text-gray-500">
                  {t("campaigns.metrics." + metric)}
                </dt>
                <dd className="w-full flex-none text-lg font-medium tracking-tight text-gray-900">
                  {loaded ? (
                    metrics[metric].count
                  ) : (
                    <SkeletonLoader width={30} height={32} />
                  )}
                </dd>
              </div>
            ))}
          </dl>
        ) : (
          <div className="flex flex-wrap items-baseline justify-between gap-y-0 lg:gap-y-2 bg-white px-1 md:px-2 xl:px-4">
            <dt className="text-2sm font-medium text-gray-500">
              {t("campaigns.metrics.contacts")}
            </dt>
            <dd className="w-full flex-none text-lg font-medium tracking-tight text-gray-900">
              {loaded ? (
                metrics?.contacts
              ) : (
                <SkeletonLoader width={30} height={32} />
              )}
            </dd>
          </div>
        )}
        {status == "draft" ? (
          <ButtonWithDropdown
            actions={dropdownActions}
            label={t("campaigns.edit_campaign")}
            onClick={() => navigate(`/campaigns/${id}`)}
            size="small"
          />
        ) : status == "scheduled" ? (
          <ButtonWithDropdown
            actions={dropdownActions}
            label={t("campaigns.cancel_schedule")}
            onClick={handleCancelSchedule}
            size="small"
          />
        ) : (
          <ButtonWithDropdown
            actions={dropdownActions}
            label={t("campaigns.view_results")}
            onClick={() => navigate(`/campaigns/${id}`)}
            size="small"
          />
        )}
      </div>
    </div>
  );
}
