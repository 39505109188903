import React, { useContext } from "react";
import Button from "~/components/elements/Button";
import { useTranslation } from "react-i18next";
import { UIContext } from "~/contexts/ui-context";
import { ArrowPathIcon, TrashIcon } from "@heroicons/react/20/solid";
import Badge from "~/components/elements/Badge";

export default function ExternalAccount(props) {
  const {
    icon,
    provider,
    connected,
    name,
    subname,
    externalUrl,
    refresh,
    connect,
    children = null,
  } = props;

  const { t } = useTranslation();

  const { showAlert } = useContext(UIContext);

  const handleDisconnect = () => {
    if (!provider) return;
    showAlert({
      title: t("integrations.confirm_disconnect"),
      confirm: t("shared.yes"),
      cancel: t("shared.no"),
      confirmClassName: "text-red-500",
      onSubmit: () => {
        window.location.replace(`/users/auth/${provider}/disconnect`);
      },
    });
  };

  return (
    <div className="border rounded-2xl rounded-tl-none p-4 space-y-4">
      <div className="flex items-center justify-between">
        <div className="text-md flex">
          <img src={icon} className="w-5 mr-2" />
          {t(`integrations.${provider}.title`)}
        </div>
        {connected ? (
          <Badge label={t("shared.connected")} color="green" />
        ) : (
          <Badge label={t("shared.not_connected")} color="gray" />
        )}
      </div>
      <div className="text-2sm">{t(`integrations.${provider}.subtitle`)}</div>
      {connected ? (
        <div className="p-3 bg-lighter-gray rounded-xl flex justify-between items-center">
          <div className="flex flex-col justify-center">
            <div className="leading-tight text-md font-medium mb-0.5">
              {name || "-"}
            </div>
            <div className="text-xs text-neutral-400">{subname || "-"}</div>
          </div>
          <div className="flex space-x-2">
            {externalUrl ? (
              <Button
                href={externalUrl}
                label={t(`shared.view`)}
                size="small"
              />
            ) : null}
            {refresh ? (
              <Button
                href={`/users/auth/${provider}/refresh`}
                icon={ArrowPathIcon}
                size="small"
              />
            ) : null}
            <Button onClick={handleDisconnect} icon={TrashIcon} size="small" />
          </div>
        </div>
      ) : (
        connect
      )}
      {children}
    </div>
  );
}
