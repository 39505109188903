import React from "react";

export default function ContentPickerField(props) {
  const {
    value = "",
    inputPlaceholder = "Content ID",
    buttonPlaceholder,
    onChange,
    onClick,
  } = props;

  return (
    <div className="flex rounded-md shadow-sm">
      <div className="relative flex flex-grow items-stretch focus-within:z-10">
        <input
          type="text"
          name="media_id"
          id="media_id"
          className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          placeholder={inputPlaceholder}
          value={value || ""}
          onChange={(evt) => onChange(evt.target.value)}
        />
      </div>
      <button
        type="button"
        className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
        onClick={onClick}
      >
        {buttonPlaceholder}
      </button>
    </div>
  );
}
