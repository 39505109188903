import React, { useEffect } from "react";
import InputCheckbox from "./InputCheckbox";
import { uniq } from "lodash";

export default function InputCheckboxes(props) {
  const { label, options, value = [], onChange } = props;

  const handleClick = (optionValue, checked) => {
    const newValue = checked
      ? uniq([...value, optionValue])
      : value.filter((v) => v !== optionValue);
    onChange(newValue);
  };

  // transform value to array if it's not
  useEffect(() => {
    if (!Array.isArray(value)) {
      onChange([value]);
    }
  }, [value]);

  return (
    <div className="space-y-1">
      {label ? <div className="input-label">{label}</div> : null}
      <div className="space-y-1.5">
        {options.map((option, index) => (
          <InputCheckbox
            key={index}
            label={option.label}
            value={value.includes(option.value)}
            onClick={(checked) => handleClick(option.value, checked)}
          />
        ))}
      </div>
    </div>
  );
}
