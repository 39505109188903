import React, { useContext } from "react";

import { HOURS, CELL_HEIGHT } from "./constants";
import CalendarEvent from "./CalendarEvent";
import ConnectedCalendarEvent from "./ConnectedCalendarEvent";
import calendarPosition from "~/utils/calendarPosition";
import { UserContext } from "~/contexts/user-context";
import { DateTime } from "luxon";

export default function CalendarDay(props) {
  const {
    day,
    events,
    slots,
    connectedEvents,
    addSlot,
    removeSlot,
    eventsActive,
    disabled,
    nowCursorRef,
  } = props;

  const { user } = useContext(UserContext);

  const handleSelectSlot = (evt, hour) => {
    evt.stopPropagation();
    if (disabled) return;
    const hourQuarter =
      Math.round((evt.nativeEvent.offsetY / CELL_HEIGHT) * 4) - 1;
    const startTime = day
      .setZone(user.time_zone)
      .plus({ hours: hour, minutes: hourQuarter * 15 })
      .toISO();
    if (addSlot) addSlot(startTime);
  };

  const handleClickSlot = (slot) => {
    if (disabled) return;
    if (removeSlot) removeSlot(slot);
  };

  const renderConnectedEvents = (connectedEvent) => {
    if (
      events.find(
        (event) => event.connected_calendar_event_id == connectedEvent.id,
      )
    )
      return;
    return (
      <ConnectedCalendarEvent
        key={connectedEvent.id}
        event={connectedEvent}
        active={eventsActive}
      />
    );
  };

  return (
    <div
      className={`border-t border-l relative ${disabled && "bg-neutral-100"}`}
    >
      {HOURS.map((hour, hourIndex) => (
        <div
          key={hour}
          className="border-b"
          style={{ height: `${CELL_HEIGHT}px` }}
          onClick={(evt) => handleSelectSlot(evt, hour)}
        >
          <div className="w-full h-1/2 border-b border-dashed border-neutral-100 pointer-events-none" />
        </div>
      ))}
      {nowCursorRef && (
        <div
          className="bg-primary w-full h-0.5 absolute inset-x-0 z-40 pointer-events-none"
          ref={nowCursorRef}
          style={{
            top: `${calendarPosition(DateTime.now().setZone(user.time_zone))}px`,
          }}
        >
          <div className="h-1.5 w-1.5 rounded-full bg-primary absolute -left-0.5 -top-0.5" />
        </div>
      )}
      {events.map((event) => (
        <CalendarEvent key={event.id} active={eventsActive} event={event} />
      ))}
      {slots.map((slot, index) => (
        <CalendarEvent
          key={index}
          event={slot}
          onClick={() => handleClickSlot(slot)}
          slot
        />
      ))}
      <div
        className={`h-full absolute z-20 inset-y-0 right-0 w-[90%] ${
          !eventsActive ? "pointer-events-none" : ""
        }`}
      >
        {connectedEvents.map(renderConnectedEvents)}
      </div>
    </div>
  );
}
