import { createConsumer } from "@rails/actioncable";

const cableUrl = process.env.CABLE_URL;

const subscribeToChannel = (channel, received, params = {}) => {
  const consumer = createConsumer(cableUrl);
  consumer.subscriptions.create({ channel, ...params }, { received });
};

export default subscribeToChannel;
