import React, { useRef } from "react";

export default function AutoInput(props) {
  const spanRef = useRef(null);

  return (
    <div className="flex flex-col items-start">
      <input
        {...props}
        style={{
          width: `${spanRef.current?.offsetWidth}px` || "auto",
        }}
      />
      <span
        className={props.className}
        ref={spanRef}
        style={{
          height: 0,
          visibility: "hidden",
          whiteSpace: "pre",
          padding: "0 2px",
        }}
      >
        {props.value || props.placeholder || ""}
      </span>
    </div>
  );
}
