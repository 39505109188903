import React, { useContext, useMemo, useState } from "react";
import { AutomationContext } from "../../contexts/automation-context";
import { BoltIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import Modal from "../shared/Modal";
import { useTranslation } from "react-i18next";
import ScenarioPickerDialog from "./ScenarioPickerDialog";

export default function ScenarioPickerField(props) {
  const { t } = useTranslation();

  const { value, onChange, excludedIds = [] } = props;

  const { scenarios } = useContext(AutomationContext);

  const [scenarioDialog, setScenarioDialog] = useState(false);

  const scenario = useMemo(
    () => scenarios.find((o) => o.id === value),
    [value],
  );

  return (
    <>
      <div
        className="border rounded-lg p-2.5 pl-2.5 flex items-center space-x-1 cursor-pointer hover:bg-neutral-50 transition-colors bg-white shadow-sm"
        onClick={() => setScenarioDialog(true)}
      >
        {scenario ? (
          <div className="flex items-center space-x-1 flex-grow">
            <BoltIcon className="w-4" />
            <div className="text-sm font-medium">{scenario.title}</div>
          </div>
        ) : (
          <div className="text-sm text-muted italic flex-grow">
            {t("automation.scenarios.select_scenario")}
          </div>
        )}
        <ChevronDownIcon className="w-5 text-muted" />
      </div>
      {scenarioDialog && (
        <ScenarioPickerDialog
          value={value}
          onChange={onChange}
          onClose={() => setScenarioDialog(false)}
          excludedIds={excludedIds}
        />
      )}
    </>
  );
}
