import React from "react";
import {
  ClockIcon,
  BellSnoozeIcon,
  EllipsisHorizontalCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";

const colors = {
  started: "text-medium-gray",
  scheduled: "text-medium-gray",
  waiting: "text-automation",
  pending: "text-automation animate-pulse",
  running: "text-automation animate-pulse",
  completed: "text-green-500",
  failed: "text-red-500",
  interrupted: "text-medium-gray",
  expired: "text-medium-gray",
  limited: "text-yellow-500",
};

const icons = {
  started: ClockIcon,
  scheduled: ClockIcon,
  waiting: BellSnoozeIcon,
  pending: EllipsisHorizontalCircleIcon,
  running: EllipsisHorizontalCircleIcon,
  completed: CheckCircleIcon,
  failed: ExclamationCircleIcon,
  interrupted: XCircleIcon,
  expired: XCircleIcon,
  limited: ClockIcon,
};

const sizes = {
  sm: "w-4",
  md: "w-6",
  lg: "w-8",
};

export default function ExecutionStatusIcon(props) {
  const { status, size = "sm", className = "" } = props;

  const Icon = icons[status];

  if (!Icon) return null;

  return <Icon className={`${sizes[size]} ${colors[status]} ${className}`} />;
}
