import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

import Loader from "~/components/utils/Loader";
import Button from "~/components/elements/Button";
import PublicFormInput from "../forms/PublicFormInput";

import headers from "~/utils/headers";
import { Trans, useTranslation } from "react-i18next";
import Alert from "~/components/shared/Alert";

export default function PaymentForm(props) {
  const { organization, payment, onReady, onSubmit, showPhone = false } = props;

  const stripe = useStripe();
  const elements = useElements();

  const { t } = useTranslation();

  const [isReady, setIsReady] = useState(false);
  const [message, setMessage] = useState(props?.message);
  const [isLoading, setIsLoading] = useState(false);

  const [acceptTerms, setAcceptTerms] = useState(!organization?.terms_active);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret",
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          window.location.reload();
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleReady = () => {
    setIsReady(true);
    onReady?.();
  };

  const [name, setName] = useState(payment?.name || "");
  const [email, setEmail] = useState(payment?.email || "");
  const [phone, setPhone] = useState(payment?.phone || "");

  const [viewTerms, setViewTerms] = useState(false);

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    // inherited submit
    onSubmit();

    // Update name & email
    axios.patch(
      `/public/payments/${payment?.id}`,
      {
        contact_name: name,
        contact_email: email,
        contact_phone: phone,
      },
      headers(),
    );

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.origin + "/public/payments/callback",
      },
    });

    // If there is no error, the redirection happens before this
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="w-full max-w-sm">
        <div className="w-full max-w-sm flex flex-col items-stretch space-y-2 justify-center p-4 bg-white rounded-lg mb-4">
          <PublicFormInput
            name="name"
            label={t("inbox.details.name")}
            placeholder="Jane Doe"
            autoComplete="name"
            value={name}
            onChange={setName}
            required
          />
          <PublicFormInput
            name="email"
            type="email"
            inputMode="email"
            autoComplete="email"
            label={t("inbox.details.email_address")}
            placeholder="jane.doe@gmail.com"
            value={email}
            onChange={setEmail}
            required
          />
          {showPhone && (
            <PublicFormInput
              name="phone"
              type="tel"
              inputMode="tel"
              autoComplete="phone"
              label={t("inbox.details.phone_number")}
              placeholder="+33 X XX XX XX XX"
              value={phone}
              onChange={setPhone}
              required
            />
          )}
        </div>
        <div className="w-full max-w-sm flex flex-col items-stretch justify-center p-4 bg-white rounded-lg mb-4">
          {["attempted", "failed"].includes(payment.status) && (
            <div className="py-2 px-1 mb-4 rounded bg-light-red text-center text-dark-red text-sm leading-normal">
              {t("payments.page.payment_failed")}
            </div>
          )}
          {!isReady && (
            <div className="flex items-center justify-center">
              <Loader className="w-8" />
            </div>
          )}
          <PaymentElement id="payment-element" onReady={handleReady} />
          {organization?.terms_active && organization?.terms_url?.length && (
            <div className="pt-4 px-1 flex items-center">
              <input
                type="checkbox"
                id="terms"
                name="terms"
                className="w-4 h-4 mr-2"
                onChange={(evt) => setAcceptTerms(evt.target.checked)}
                required
              />
              <label for="terms" className="text-md text-dark-gray">
                <Trans i18nKey="payments.page.accept_terms">
                  <span>I accept the </span>
                  <a
                    className="underline"
                    href={organization?.terms_url}
                    target="_blank"
                  >
                    terms of sale
                  </a>
                </Trans>
              </label>
            </div>
          )}
          {message && (
            <div className="bg-light-red p-2.5 rounded text-dark-red mt-4 text-sm">
              {message}
            </div>
          )}
        </div>
        <Button
          label={
            isLoading
              ? t("payments.page.in_progress")
              : t("payments.page.confirm")
          }
          className="text-base font-medium w-full max-w-md"
          disabled={
            !acceptTerms || !isReady || isLoading || !stripe || !elements
          }
        />
        <div className="text-2xs p-2 text-center">
          {t("payments.page.stripe_notice")}
        </div>
      </form>
    </>
  );
}
