import React, { useState, useRef, useEffect, useContext } from "react";
import { delay, flatMap, identity, times } from "lodash";
import { useTranslation } from "react-i18next";

import CalendarWeek from "./CalendarWeek";
import { CalendarContext } from "~/contexts/calendar-context";
import { useNavigate, useParams } from "react-router-dom";
import AppointmentDetails from "../appointments/AppointmentDetails";
import { UserContext } from "~/contexts/user-context";
import { DateTime } from "luxon";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { isApp } from "~/utils/environment";

const TOTAL_WEEKS = 50;
const VISIBLE_WEEKS = 4;
const EXTRA_WEEKS = 2;

export default function Calendar(props) {
  const {
    slots = [],
    addSlot,
    removeSlot,
    eventsActive = false,
    disablePast = false,
    hideEvent = null,
    defaultDay = null,
    className = "",
  } = props;

  const { t } = useTranslation();

  const {
    appointments,
    events,
    connectedEvents,
    loadAppointments,
    loadEvents,
  } = useContext(CalendarContext);

  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const { appointmentId } = useParams();

  const todayRef = useRef(
    defaultDay
      ? DateTime.fromISO(defaultDay).setZone(user.time_zone).startOf("week")
      : DateTime.now().setZone(user.time_zone).startOf("week"),
  );

  const scrollerRef = useRef(null);
  const calendarWidth = useRef(null);
  const scrollTimeout = useRef(null);

  const setCalendarWidth = () =>
    (calendarWidth.current = scrollerRef.current?.offsetWidth);

  const [firstIndex, setFirstIndex] = useState(0);

  const nowCursorRef = useRef(null);

  useEffect(() => {
    setCalendarWidth();
    window.addEventListener("resize", setCalendarWidth);
    delay(() => scrollToIndex(TOTAL_WEEKS / 2), 200);

    return () => window.removeEventListener("resize", setCalendarWidth);
  }, [scrollerRef.current]);

  const [currentMonth, setCurrentMonth] = useState(
    DateTime.now().toFormat("MMMM yyyy"),
  );

  const handleHorizontalScroll = (evt) => {
    if (!scrollerRef.current) return;

    scrollTimeout.current && clearTimeout(scrollTimeout.current);
    scrollTimeout.current = setTimeout(handleHorizontalScrollStop, 10);

    // update current month
    const newMonth = todayRef.current
      .plus({ weeks: currentIndex() - TOTAL_WEEKS / 2 })
      .toFormat("MMMM yyyy");
    if (newMonth !== currentMonth) setCurrentMonth(newMonth);
  };

  const handleHorizontalScrollStop = () => {
    const scrollOffset = scrollerRef.current.scrollLeft;

    const index = Math.round(scrollOffset / calendarWidth.current);

    setFirstIndex(Math.min(index - EXTRA_WEEKS, TOTAL_WEEKS - VISIBLE_WEEKS));
  };

  const currentIndex = () => {
    const scrollOffset = scrollerRef.current.scrollLeft;

    const index = Math.round(scrollOffset / calendarWidth.current);
    return index;
  };

  const scrollToIndex = (index, smooth = false) => {
    if (!scrollerRef.current) return;

    scrollerRef.current.scrollTo({
      left: index * calendarWidth.current,
      behavior: isApp && smooth ? "smooth" : "instant",
    });
  };

  const scrollToNextWeek = () => {
    scrollToIndex(currentIndex() + 1, true);
  };

  const scrollToPrevWeek = () => {
    scrollToIndex(currentIndex() - 1, true);
  };

  const scrollToNow = () => {
    scrollToIndex(TOTAL_WEEKS / 2, true);
    nowCursorRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  // Initialize
  useEffect(() => {
    loadAppointments();
    loadEvents();
    window.addEventListener("calendarTabClick", scrollToNow);
    return () => window.removeEventListener("calendarTabClick", scrollToNow);
  }, []);

  const renderWeek = (i) => {
    const firstDay = todayRef.current.plus({ weeks: i });

    const weekSlots = slots.filter((slot) =>
      DateTime.fromISO(slot.start_time).hasSame(firstDay, "week"),
    );

    const weekEvents = events.filter(
      (e) =>
        e.visible &&
        e.id != hideEvent?.id &&
        DateTime.fromISO(e.start_time).hasSame(firstDay, "week"),
    );

    const weekId = firstDay.toFormat("yyyy-WW");
    const weekConnectedEvents = connectedEvents[weekId]?.filter(
      (e) => e.id != hideEvent?.connected_calendar_event_id,
    );
    const isCurrent = i == 0;
    return (
      <CalendarWeek
        key={i}
        firstDay={firstDay}
        weekId={weekId}
        slots={weekSlots}
        eventsActive={eventsActive}
        disablePast={disablePast}
        nowCursorRef={isCurrent ? nowCursorRef : null}
        addSlot={addSlot}
        removeSlot={removeSlot}
        connectedEvents={weekConnectedEvents}
        events={weekEvents}
      />
    );
  };

  // Appointment details
  const appointment = appointments.find((app) => app.id == appointmentId);

  return (
    <div className={`flex-grow flex flex-col overflow-hidden ${className}`}>
      <div className="flex items-center justify-between px-4 pt-2 pb-4 flex-shrink-0">
        <div className="text-md font-medium capitalize">{currentMonth}</div>
        <div className="flex items-center space-x-2">
          <div className="hidden sm:flex border rounded-lg">
            <button
              className="text-2sm border-r px-2.5 py-1.5 hover:bg-lighter-gray"
              onClick={scrollToPrevWeek}
            >
              <ChevronLeftIcon className="w-4" />
            </button>
            <button
              className="text-2sm px-2.5 py-1.5 hover:bg-lighter-gray"
              onClick={scrollToNextWeek}
            >
              <ChevronRightIcon className="w-4" />
            </button>
          </div>
          <button
            className="text-2sm sm:border sm:rounded-lg sm:px-2.5 sm:py-1.5 hover:bg-lighter-gray"
            onClick={scrollToNow}
          >
            {t("time.this_week")}
          </button>
        </div>
      </div>
      <div
        className="flex-grow overflow-x-auto overflow-y-hidden relative snap-x snap-mandatory scrollbar-hide"
        onScroll={handleHorizontalScroll}
        ref={scrollerRef}
      >
        <div
          className="flex items-start h-full"
          style={{
            width: TOTAL_WEEKS * calendarWidth.current,
          }}
        >
          {times(TOTAL_WEEKS).map((i) => (
            <div
              className="h-full w-screen flex-shrink-0 snap-center snap-always"
              data-index={i}
              data-visible={i >= firstIndex && i <= firstIndex + VISIBLE_WEEKS}
              style={{
                width: calendarWidth.current,
              }}
              key={i}
            >
              {i >= firstIndex && i <= firstIndex + VISIBLE_WEEKS
                ? renderWeek(i - TOTAL_WEEKS / 2)
                : null}
            </div>
          ))}
        </div>
      </div>
      {appointment && (
        <AppointmentDetails
          appointment={appointment}
          onClose={() => navigate("/calendar")}
        />
      )}
    </div>
  );
}
