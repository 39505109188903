import { useTranslation } from "react-i18next";

export default function paymentLabel(status) {
  const { t } = useTranslation();
  switch (status) {
    case "paid":
      return t("payments.labels.paid");
    case "manual":
      return t("payments.labels.manual");
    case "cancelled":
      return t("payments.labels.cancelled");
    case "attempted":
      return t("payments.labels.attempted");
    case "failed":
      return t("payments.labels.failed");
    case "refunded":
      return t("payments.labels.refunded");
    default:
      return t("payments.labels.pending");
  }
}
