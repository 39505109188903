import React from "react";
import classNames from "~/utils/classNames";

export default function GrowthRate(props) {
  const { value, className = "" } = props;

  return (
    <div
      className={classNames(
        value < 0
          ? "text-red-500"
          : value > 0
            ? "text-green-500"
            : "text-neutral-700",
        className,
      )}
    >
      {value ? `${value > 0 ? "+" : ""}${(value * 100).toFixed(0)}%` : null}
    </div>
  );
}
