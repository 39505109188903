import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "~/components/elements/Input";
import InputCheckbox from "~/components/elements/InputCheckbox";
import ButtonGroup from "../elements/ButtonGroup";
import TemplateMessage from "../messages/TemplateMessage";
import SurveyOptionsForm from "./options/SurveyOptionsForm";
import SurveyOptionsResults from "./options/SurveyOptionsResults";

export default function SingleChoiceSurveyForm(props) {
  const { t } = useTranslation();

  const { survey, setSurvey, options, setOptions } = props;

  const visibleOptions = options.filter((o) => !o._destroy);

  const handleChange = (field) => (value) => {
    setSurvey((survey) => ({ ...survey, [field]: value }));
  };

  // Preview & results

  const previewPages = ["template", "results"];
  const [previewPage, setPreviewPage] = useState("template");

  const templatePayload = useMemo(() => {
    return survey.title
      ? {
          title: survey.title,
          subtitle: survey.subtitle,
          buttons: visibleOptions,
        }
      : null;
  }, [survey, visibleOptions]);

  const optionResults = useMemo(
    () =>
      visibleOptions?.map((option) => ({
        title: option.title,
        replies: option.reply_count,
      })) || [],
    [survey, visibleOptions],
  );

  return (
    <div className="flex flex-col sm:flex-row items-stretch overflow-hidden">
      <div className="w-full sm:min-w-96 space-y-4 p-4 overflow-y-auto">
        <Input
          placeholder={t("surveys.title_placeholder")}
          value={survey.title}
          onChange={handleChange("title")}
          autoFocus={!survey.title}
          maxLength={80}
          emojiPicker
        />
        <Input
          type="textarea"
          placeholder={t("surveys.subtitle_placeholder")}
          value={survey.subtitle}
          onChange={handleChange("subtitle")}
          maxLength={80}
          emojiPicker
        />
        <InputCheckbox
          label={t("surveys.limit_replies")}
          value={survey.limit_replies}
          onClick={handleChange("limit_replies")}
          className="px-1 py-1"
        />
        <SurveyOptionsForm options={options} setOptions={setOptions} />
      </div>
      <div className="w-full min-w-96 py-4 sm:py-8 bg-lighter-gray hidden sm:flex flex-col space-y-8 items-center justify-center">
        <ButtonGroup
          buttons={previewPages.map((page) => ({
            label: t(`surveys.preview.${page}`),
            style: previewPage === page ? "primary" : "default",
            onClick: () => setPreviewPage(page),
          }))}
        />
        {previewPage == "template" && templatePayload && (
          <TemplateMessage template={templatePayload} />
        )}
        {previewPage == "results" && (
          <div className="bg-white rounded-lg border p-3">
            <div className="text-xs font-medium text-muted mb-2">
              {t("shared.results")}
            </div>
            <SurveyOptionsResults options={optionResults} />
          </div>
        )}
      </div>
    </div>
  );
}
