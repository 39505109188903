import React, { useContext, useState } from "react";

import { LoadingContext } from "~/contexts/loading-context";

import loginPage from "../../../assets/images/login_page.png";
import facebook_logo from "../../../assets/images/facebook_logo_white.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { UserContext } from "~/contexts/user-context";
import Input from "~/components/elements/Input";
import Button from "~/components/elements/Button";
import headers from "~/utils/headers";
import {
  ArrowTopRightOnSquareIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import FacebookIcon from "~/components/shared/icons/FacebookIcon";

export default function NoPage(props) {
  const { showTutorial } = props;

  const { setLoading } = useContext(LoadingContext);
  const { setOrganization } = useContext(UserContext);

  const { t } = useTranslation();

  const [pageId, setPageId] = useState("");

  const handlePickPage = () => {
    setLoading(t("inbox.permissions.connecting"));
    axios
      .patch(
        "/api/organization/choose_facebook_page",
        { page_id: pageId },
        headers(),
      )
      .then((res) => {
        setOrganization(res.data);
        setLoading(false);
      });
  };

  return (
    <div className="space-y-6">
      <h2 className="text-lg font-semibold">
        {t("inbox.permissions.no_page.title")}
      </h2>
      <div className="max-w-sm space-y-6">
        <p className="text-sm">{t("inbox.permissions.no_page.text")}</p>
        <div className="px-4">
          <img src={loginPage} className="shadow-md rounded" />
        </div>

        <Button
          href="/users/auth/instagram"
          label={t("inbox.permissions.retry")}
          style="inverse"
          icon={FacebookIcon}
          className="w-full"
        />

        {showTutorial && (
          <Button
            className="w-full"
            icon={QuestionMarkCircleIcon}
            label={t("onboarding.connect_instagram.show_me_how")}
            onClick={showTutorial}
          />
        )}

        <hr />

        <div className="flex flex-col space-y-3">
          <div className="text-sm text-darker-gray">
            {t("inbox.permissions.no_page.custom_page_id.text")}
          </div>

          <Input
            placeholder={t(
              "inbox.permissions.no_page.custom_page_id.placeholder",
            )}
            value={pageId}
            onChange={setPageId}
          />

          <div className="grid gap-3 grid-cols-2">
            <Button
              label={t("inbox.permissions.no_page.custom_page_id.connect")}
              onClick={handlePickPage}
              disabled={!pageId}
            />

            <Button
              icon={ArrowTopRightOnSquareIcon}
              label={t("inbox.permissions.no_page.custom_page_id.find")}
              href="https://business.facebook.com/settings/pages/"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
