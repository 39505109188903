import React from "react";
import netsuke_1 from "../../../assets/images/netsuke_1.png";
import netsuke_2 from "../../../assets/images/netsuke_2.png";
import netsuke_3 from "../../../assets/images/netsuke_3.png";
import netsuke_4 from "../../../assets/images/netsuke_4.png";
import netsuke_5 from "../../../assets/images/netsuke_5.png";
import netsuke_6 from "../../../assets/images/netsuke_6.png";

const images = {
  calm: netsuke_1,
  cool: netsuke_2,
  curious: netsuke_3,
  fabulous: netsuke_4,
  fire: netsuke_5,
  foxy: netsuke_6,
};

export default function Netsuke(props) {
  const { style = "curious", className = "" } = props;

  return (
    <img src={images[style]} alt={style} className={"w-40 " + className} />
  );
}
