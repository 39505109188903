import React, { useState, useEffect, useContext } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { UIContext } from "~/contexts/ui-context";

const colors = ["orange", "red", "green", "blue", "purple", "pink", "yellow"];

export default function FolderDialog(props) {
  const { folder, onSubmit, onClose } = props;

  const { t } = useTranslation();

  const { keyboardHeight } = useContext(UIContext);

  const [visible, setVisible] = useState(false);

  // animate appear
  useEffect(() => {
    setTimeout(() => setVisible(true), 50);
  }, []);

  const handleClose = (evt) => {
    evt.preventDefault();
    setVisible(false);
    setTimeout(onClose, 50);
  };

  const [name, setName] = useState(folder?.name || "");
  const [color, setColor] = useState(folder?.color || "orange");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (name === "") return;
    onSubmit({
      ...folder,
      name,
      color,
    });
    onClose();
  };

  return createPortal(
    <div
      className={`fixed inset-0 flex flex-col items-center justify-center p-4 select-none ${
        visible ? "pointer-events-auto" : "pointer-events-none"
      }`}
      style={{
        zIndex: 1000,
        paddingBottom: keyboardHeight / 2,
      }}
    >
      <div
        className={`absolute inset-0 bg-black/30 transition-opacity ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleClose}
      />
      <form
        className={`w-full max-w-sm bg-white rounded-xl shadow-lg relative transform-gpu transition-all ${
          visible ? "scale-100 opacity-100" : "scale-95 opacity-0"
        }`}
        onSubmit={handleSubmit}
      >
        <div className="p-4">
          <label htmlFor="tag" className="block mb-4 text-dark-gray">
            {folder ? t("inbox.folders.edit") : t("inbox.folders.create_new")}
          </label>
          <input
            autoFocus
            placeholder={t("inbox.folders.placeholder")}
            name="name"
            id="name"
            type="text"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
            className="w-full border rounded p-2 mb-4"
            autoComplete="off"
          />
          <div className="flex justify-between flex-wrap">
            {colors.map((c) => (
              <button
                key={c}
                className="w-8 h-8 p-2 flex-shrink-0"
                onClick={(evt) => {
                  evt.preventDefault();
                  setColor(c);
                }}
              >
                <div
                  className={`w-4 h-4 rounded-full bg-${c}-500 ring-2 ring-offset-2 ${
                    color == c ? `ring-${c}` : "ring-transparent"
                  }`}
                />
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-row-reverse border-t">
          <input
            type="submit"
            className="w-1/2 text-center p-3 font-medium disabled:text-neutral-400"
            disabled={name.length == 0}
            value={folder ? t("shared.save") : t("shared.create")}
          />
          <button
            className="w-1/2 text-center p-3 text-red border-r"
            onClick={handleClose}
          >
            {t("shared.cancel")}
          </button>
        </div>
      </form>
    </div>,
    document.body,
  );
}
