import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import CheckCircle from "~/components/shared/CheckCircle";
import VideoDrawer from "~/components/shared/VideoDrawer";

import i18n from "../../../../locales/i18n";
import { isApp } from "~/utils/environment";
import Button from "~/components/elements/Button";
import Checkbox from "~/components/shared/Checkbox";

export default function OauthChecklist(props) {
  const {
    checkInstagram,
    setCheckInstagram,
    checkFacebookPage,
    setCheckFacebookPage,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center space-y-5">
      <p className="text-sm">
        <Trans i18nKey="onboarding.connect_instagram.connect_text" />
      </p>

      <div>
        <div className="flex space-x-3 items-center">
          <Checkbox
            checked={checkInstagram}
            className="!w-6 !h-6"
            onClick={() => setCheckInstagram(!checkInstagram)}
          />
          <div className="text-2sm">
            <Trans i18nKey="onboarding.connect_instagram.connect_insta_check" />
          </div>
        </div>
      </div>

      <div>
        <div className="flex space-x-3 items-center">
          <Checkbox
            checked={checkFacebookPage}
            className="!w-6 !h-6"
            onClick={() => setCheckFacebookPage(!checkFacebookPage)}
          />
          <div className="text-2sm">
            <Trans i18nKey="onboarding.connect_instagram.connect_facebook_check" />
          </div>
        </div>
      </div>
    </div>
  );
}
