import React from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

export default function StoryItem(props) {
  const { t } = useTranslation();

  const { story } = props;

  const postedAt = DateTime.fromISO(story.posted_at);

  return (
    <div className="flex-none w-full opacity-90 transition-opacity hover:opacity-100 relative aspect-story">
      <img
        src={story.url}
        className="w-full h-full object-cover object-center"
      />
      {postedAt < DateTime.now().minus({ hours: 1 }) && (
        <div className="absolute top-2 right-2 bg-white text-xs font-medium px-2 py-1.5 rounded-lg opacity-75">
          {t("shared.expired")}
        </div>
      )}
      <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black to-transparent p-4 flex justify-between text-white">
        <div className="text-xs font-medium">
          {postedAt.toLocaleString(DateTime.DATE_MED)}
        </div>
        <div className="text-xs font-medium">
          {t("content.stories.replies_count", { count: story.replies_count })}
        </div>
      </div>
    </div>
  );
}
