import React from "react";
import { useTranslation } from "react-i18next";
import RepliedIcon from "~/components/inbox/RepliedIcon";
import ResultsNode from "./ResultsNode";

export default function CommentResultsNode(props) {
  const { t } = useTranslation();

  const { data } = props;

  const { content } = data;

  return (
    <ResultsNode {...props}>
      <div className={`relative flex flex-col items-center space-y-3`}>
        <div className="flex items-center space-x-2">
          <RepliedIcon className="rotate-180" />
          <div
            className={`relative bg-white border border-neutral-200 rounded-lg text-2sm max-w-xs min-h-[34px] text-left break-words whitespace-pre-wrap`}
          >
            <div
              className={`px-2.5 py-2`}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      </div>
    </ResultsNode>
  );
}
