import React from "react";

import paymentBackground from "../../../assets/images/public_background.png";

export default function PublicBackground(props) {
  const { children, className, containerRef } = props;

  return (
    <div
      className={`bg-black p-4 pb-8 min-h-screen flex flex-col space-y-4 items-center bg-center bg-cover ${className}`}
      // style={{ backgroundImage: `url(${paymentBackground})` }}
      ref={containerRef}
    >
      {children}
    </div>
  );
}
