import React from "react";

export default function InlineInput({
  name,
  value,
  onChange,
  label,
  placeholder,
  required = false,
  className = "",
  ...props
}) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={`flex items-center space-x-4 px-4 border-b ${className}`}>
      <label
        htmlFor={name}
        className="w-24 flex-shrink-0 flex items-center space-x-1.5"
      >
        <div className="text-2sm whitespace-nowrap">{label}</div>
        {!required ? <div className="text-4xs">(optional)</div> : null}
      </label>
      <input
        name={name}
        id={name}
        className="flex-grow text-md h-13 outline-none"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        {...props}
      />
    </div>
  );
}
