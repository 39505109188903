import { PlayIcon } from "@heroicons/react/20/solid";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UIContext } from "../../../../contexts/ui-context";

export default function ConnectInstagramHelp() {
  const { t } = useTranslation();

  const { showVideo } = useContext(UIContext);

  return (
    <div className="bg-blue-100 p-8 space-y-4">
      <div className="leading-5">
        {t("onboarding.connect_instagram.help.title")}
      </div>
      <div className="grid grid-cols-2 gap-4">
        {["link", "switch"].map((item, index) => (
          <div
            className="block cursor-pointer opacity-80 hover:opacity-100 transition-opacity rounded-lg overflow-hidden border relative"
            onClick={() =>
              showVideo({
                title: t(`onboarding.connect_instagram.help.${item}`),
                url: `/videos/onboarding_${item}.mp4`,
              })
            }
          >
            <video
              // autoPlay
              loop
              muted
              playsInline
              className="object-contain"
            >
              <source src={`/videos/onboarding_${item}.mp4`} type="video/mp4" />
            </video>
            <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black to-transparent p-2 pt-6 flex items-center space-x-2">
              <div className="w-10 h-10 rounded-full bg-white p-2.5">
                <PlayIcon className="w-5 h-5 text-black" />
              </div>
              <div className="text-white text-sm font-medium leading-4">
                {t(`onboarding.connect_instagram.help.${item}`)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
