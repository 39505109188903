import React from "react";
import { isBrowser } from "react-device-detect";
import { useTranslation } from "react-i18next";

export default function Unsupported(props) {
  const { received, contact } = props;

  const { t } = useTranslation();

  const inboxUrl = isBrowser
    ? `https://www.instagram.com/direct/inbox`
    : `https://ig.me/m/${contact?.username}`;

  const bubbleClass = received ? "bg-white border" : "bg-neutral-100";

  return (
    <a href={inboxUrl} target="_blank">
      <div
        className={`rounded-3xl px-8 py-5 max-w-sm text-center text-sm text-neutral-400 overflow-ellipsis overflow-hidden select-none hover:text-neutral-500 transition-colors ${bubbleClass}`}
      >
        {t("inbox.conversation.unsupported_message")} <br />{" "}
        {t("inbox.conversation.unsupported_message_view")}
      </div>
    </a>
  );
}
