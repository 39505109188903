import axios from "axios";
import headers from "../../utils/headers";

const loadComments = async (params) => {
  const res = await axios.get("/api/inbox/comments", { params });
  return res.data;
};

const hideComment = async (commentId, hide = true) => {
  const res = await axios.patch(
    `/api/inbox/comments/${commentId}/hide`,
    { hide },
    headers(),
  );
  return res.data;
};

export { loadComments, hideComment };
