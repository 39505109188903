import React, { useEffect, useState } from "react";
import Loader from "~/components/utils/Loader";
import { getCampaignResults } from "../../api/campaigns/campaigns";
import FlowWrapper from "../automation/flow/FlowWrapper";
import ResultsFlow from "../automation/flow/results/ResultsFlow";

export default function CampaignInsights(props) {
  const { campaignId } = props;

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);

  useEffect(async () => {
    setLoading(true);
    const { actions } = await getCampaignResults(campaignId);
    setActions(actions);
    setLoading(false);
  }, [campaignId]);

  if (loading) {
    return (
      <div className="flex-grow flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex-grow">
      <FlowWrapper>
        <ResultsFlow actions={actions} />
      </FlowWrapper>
    </div>
  );
}
