import React, { useContext } from "react";
import { UserContext } from "~/contexts/user-context";
import Alert from "~/components/shared/Alert";
import { useTranslation } from "react-i18next";

export default function InstagramAlreadyConnected(props) {
  const { organization } = useContext(UserContext);

  const { t } = useTranslation();

  return (
    <Alert
      title={t("inbox.permissions.instagram_already_connected.title")}
      message={t("inbox.permissions.instagram_already_connected.description", {
        username: organization.username,
      })}
      confirm={t("shared.understood")}
      hideCancel
    />
  );
}
