import {
  ArrowPathIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  FireIcon,
} from "@heroicons/react/20/solid";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutomationContext } from "~/contexts/automation-context";
import ActionExecution from "./ActionExecution";
import { sortBy } from "lodash";
import ExecutionStatusIcon from "./ExecutionStatusIcon";
import Button from "~/components/elements/Button";
import { SubscriptionContext } from "~/contexts/subscription-context";
import Loader from "../../../utils/Loader";
import SkeletonLoader from "../../../utils/SkeletonLoader";

export default function ScenarioExecution(props) {
  const { execution, setExecution } = props;

  const { scenario_id, status, created_at, trigger } = execution;

  const { t } = useTranslation();

  const { limitReached } = useContext(SubscriptionContext);
  const { scenarios, loadActionExecutions, retryScenarioExecution } =
    useContext(AutomationContext);

  const scenario = scenarios.find((s) => s.id === scenario_id);

  const [retrying, setRetrying] = useState(false);

  const handleRetryScenarioExecution = async () => {
    setRetrying(true);
    const res = await retryScenarioExecution(execution.id);
    setExecution(res);
    setRetrying(false);
  };

  const [loading, setLoading] = useState(true);
  const [actionExecutions, setActionExecutions] = useState([]);

  const handleLoadActionExecutions = async () => {
    const res = await loadActionExecutions("scenario", execution.id);
    setActionExecutions(res);
    setLoading(false);
  };

  const [opened, setOpen] = useState(false);

  useEffect(() => {
    if (opened && actionExecutions.length === 0) handleLoadActionExecutions();
  }, [opened]);

  return (
    <div
      className={`max-w-80 w-full rounded-lg border shadow ${
        opened ? "" : "opacity-75 transition-opacity hover:opacity-100"
      }`}
    >
      <div
        className="p-2 flex justify-between items-center cursor-pointer"
        onClick={() => setOpen(!opened)}
      >
        <div className="flex items-center space-x-1.5">
          <ExecutionStatusIcon status={status} />
          <div className="font-medium text-2sm flex-grow">
            {scenario
              ? scenario.title
              : t("automation.scenarios.executions.not_found")}
          </div>
        </div>

        {opened ? (
          <ChevronDownIcon className="w-5" />
        ) : (
          <ChevronLeftIcon className="w-5" />
        )}
      </div>
      <div
        className="text-xs px-2 pb-2"
        title={DateTime.fromISO(created_at).toLocaleString(
          DateTime.DATETIME_FULL,
        )}
      >
        {t("automation.scenarios.executions.started", {
          date: DateTime.fromISO(created_at).toLocaleString(
            DateTime.DATETIME_SHORT,
          ),
        })}
      </div>
      {opened ? (
        <div className="px-2 space-y-2">
          {trigger?.content && (
            <div className="text-message small">{trigger.content}</div>
          )}
          {status == "limited" && (
            <div className="bg-warning p-2 rounded space-y-2">
              <div className="text-xs font-medium">
                {t("automation.scenarios.executions.limited")}
              </div>
              {!limitReached && (
                <Button
                  size="extra-small"
                  icon={ArrowPathIcon}
                  label={t("shared.retry")}
                  loading={retrying}
                  onClick={handleRetryScenarioExecution}
                />
              )}
            </div>
          )}
          <div className="relative divide-y">
            {loading && (
              <div className="flex justify-between py-3">
                <SkeletonLoader width={120} height={20} />
                <SkeletonLoader width={20} height={20} round />
              </div>
            )}
            {sortBy(actionExecutions, "created_at").map((actionExecution) => (
              <ActionExecution
                className="relative z-10"
                execution={actionExecution}
                key={actionExecution.id}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
