import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import FolderTag from "../inbox/folders/FolderTag";
import { list } from "postcss";
import { UserContext } from "../../contexts/user-context";
import { ContactsContext } from "../../contexts/contacts-context";
import Badge from "../elements/Badge";
import { FunnelIcon } from "@heroicons/react/20/solid";

const CampaignRule = ({ rule }) => {
  const { list_type, destination_id } = rule;

  const { organization } = useContext(UserContext);
  const { segments } = useContext(ContactsContext);

  if (list_type === "folder") {
    const folder = organization?.folders.find((f) => f.id === destination_id);
    return <FolderTag folder={folder} />;
  }

  if (list_type === "segment") {
    const segment = segments.find((s) => s.id === destination_id);
    return <Badge label={segment.title} icon={FunnelIcon} color="purple" />;
  }

  return null;
};

export default function CampaignDestination(props) {
  const { t } = useTranslation();

  const { campaign } = props;

  const { campaign_rules } = campaign;

  if (campaign_rules === undefined) return null;

  const inclusionRules = campaign_rules.filter(
    (rule) => rule.rule_type === "inclusion",
  );

  const exclusionRules = campaign_rules.filter(
    (rule) => rule.rule_type === "exclusion",
  );

  return (
    <div className="space-y-2">
      {inclusionRules.length ? (
        <div className="flex space-x-2">
          {inclusionRules.map((rule, index) => (
            <CampaignRule key={index} rule={rule} />
          ))}
        </div>
      ) : (
        <div className="text-sm font-medium">
          {t("campaigns.destinations.all_contacts.title")}
        </div>
      )}
      {exclusionRules.length ? (
        <div className="flex space-x-2">
          <div className="text-sm font-medium">{t("shared.excluding")}:</div>
          {exclusionRules.map((rule, index) => (
            <CampaignRule key={index} rule={rule} />
          ))}
        </div>
      ) : null}
    </div>
  );
}
