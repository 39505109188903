import React from "react";
import { useTranslation } from "react-i18next";
import FolderTag from "~/components/inbox/folders/FolderTag";
import ResultsNode from "./ResultsNode";

export default function FolderResultsNode(props) {
  const { t } = useTranslation();

  const { data } = props;

  const { folder, options } = data;

  const { folder_action } = options;

  return (
    <>
      <ResultsNode {...props}>
        <div
          className={`py-3 px-3 bg-white border-2 rounded-2xl flex flex-col space-y-3 items-center text-md whitespace-nowrap`}
        >
          <div>{t("automation.scenarios.actions.folder." + folder_action)}</div>

          <FolderTag folder={folder} />
        </div>
      </ResultsNode>
    </>
  );
}
