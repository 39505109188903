import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "~/contexts/user-context";
import { FireIcon, HeartIcon } from "@heroicons/react/20/solid";
import Button from "~/components/elements/Button";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import formatAmount from "~/utils/formatAmount";
import { SubscriptionContext } from "~/contexts/subscription-context";
import SubscriptionPaymentError from "./SubscriptionPaymentError";

export default function SubscriptionStatus(props) {
  const { t } = useTranslation();

  const { hidePro } = props;

  const { subscription, startFreeTrial } = useContext(SubscriptionContext);

  const {
    subscription_product,
    contacts_activated,
    current_tier_maximum,
    next_billing_amount,
    free_trial_used,
  } = subscription;

  const isTrial =
    subscription.subscription_tier == "trial" || !!subscription.trial_end_date;
  const isPro = subscription.subscription_tier != "free" && !isTrial;

  const endDate = isTrial
    ? subscription.trial_end_date
    : subscription.current_billing_period_end;

  const daysLeft =
    endDate &&
    Math.floor((new Date(endDate) - new Date()) / (1000 * 60 * 60 * 24));

  if (!subscription.subscription_tier) return null;

  if (
    ["incomplete", "incomplete_expired", "past_due", "unpaid"].includes(
      subscription.subscription_status,
    )
  ) {
    return <SubscriptionPaymentError />;
  }

  if (hidePro && isPro && !isTrial) return null;

  return (
    <div className="hidden xl:block bg-blue-500 text-white select-none rounded-2xl rounded-tl-none p-4 space-y-4">
      <div className="space-y-2">
        <div className="flex space-x-2">
          <HeartIcon className="w-5" />
          <div className="font-medium leading-5">
            {isTrial
              ? t("subscription.free_trial")
              : isPro
                ? subscription_product || t("subscription.pro_plan")
                : t("subscription.free_plan")}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm leading-4">
            {isTrial
              ? t("subscription.days_left", { count: daysLeft })
              : t("subscription.renews_on", {
                  date: DateTime.fromISO(endDate).toLocaleString(
                    DateTime.DATE_MED,
                  ),
                })}
          </div>
          {next_billing_amount && (
            <div className="px-1 py-0.5 rounded bg-interactive-active text-xs font-medium leading-4">
              {formatAmount(
                next_billing_amount.amount,
                next_billing_amount.currency,
              )}
            </div>
          )}
        </div>
      </div>
      <div className="p-2 space-y-2 bg-interactive-active rounded-lg">
        <div className="flex justify-between">
          <div className="text-sm leading-5">
            {t("subscription.activated_contacts")}
          </div>
          <div className="text-sm leading-5">
            {contacts_activated.toLocaleString()}/
            {current_tier_maximum.toLocaleString()}
          </div>
        </div>
        <div className="rounded-full overflow-hidden h-2 w-full bg-sidebar-highlighted relative">
          <div
            className="bg-white rounded-full absolute inset-y-0 left-0"
            style={{
              width: `${(contacts_activated / current_tier_maximum) * 100}%`,
            }}
          />
        </div>
      </div>
      {isPro && !isTrial ? (
        <Button
          label={t("subscription.manage_plan")}
          className="w-full"
          icon={FireIcon}
          href="/stripe/checkout_session"
        />
      ) : free_trial_used || isTrial ? (
        <Button
          label={t("subscription.upgrade_to_pro")}
          className="w-full"
          icon={FireIcon}
          href="/stripe/checkout_session"
        />
      ) : (
        <Button
          label={t("subscription.start_free_trial")}
          className="w-full"
          icon={FireIcon}
          onClick={startFreeTrial}
        />
      )}
    </div>
  );
}
