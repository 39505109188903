import axios from "axios";

const getEventTypes = async () => {
  const res = await axios.get("/api/calendly/event_types");
  return res.data;
};

const getEventType = async (id) => {
  const res = await axios.get(`/api/calendly/event_types/${id}`);
  return res.data;
};

export { getEventTypes, getEventType };
