import React, { useContext, useEffect, useState } from "react";

import { LoadingContext } from "~/contexts/loading-context";

import loginPage from "../../../assets/images/login_page.png";
import facebook_logo from "../../../assets/images/facebook_logo_white.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Loader from "~/components/utils/Loader";
import { UserContext } from "~/contexts/user-context";
import headers from "~/utils/headers";
import FacebookIcon from "~/components/shared/icons/FacebookIcon";
import Button from "../elements/Button";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";

export default function ManyPages(props) {
  const { setHidden, showTutorial } = props;

  const { setLoading } = useContext(LoadingContext);
  const { setOrganization } = useContext(UserContext);

  const { t } = useTranslation();

  const [pages, setPages] = useState("loading");

  const loadPages = () => {
    axios.get("/api/organization/facebook_pages").then((res) => {
      setPages(res.data);
    });
  };

  useEffect(loadPages, []);

  const handlePickPage = (page) => {
    setLoading(t("inbox.permissions.connecting"));
    axios
      .patch(
        "/api/organization/choose_facebook_page",
        { page_id: page.id },
        headers(),
      )
      .then((res) => {
        setOrganization(res.data);
        setLoading(false);
      });
  };

  return (
    <div className="space-y-6">
      <h2 className="text-lg leading-tight font-semibold mb-4">
        {t("inbox.permissions.many_pages.title")}
      </h2>
      <div className="max-w-sm">
        <p className="text-sm mb-6">{t("inbox.permissions.many_pages.text")}</p>

        {pages ? (
          pages == "loading" ? (
            <div className="flex justify-center">
              <Loader width={24} strokeWidth={8} />
            </div>
          ) : (
            pages.map((page) => (
              <div
                className="flex items-center space-x-3 p-3 rounded-lg bg-light-gray cursor-pointer hover:bg-light-primary mb-3"
                key={page.id}
                onClick={() => handlePickPage(page)}
              >
                <div className="w-6 h-6 rounded-full bg-light-primary uppercase font-bold text leading-normal text-center">
                  {page.name[0]}
                </div>
                <div className="font-medium">{page.name}</div>
              </div>
            ))
          )
        ) : (
          <div className="space-y-6">
            <div className="px-4">
              <img src={loginPage} className="shadow-md rounded" />
            </div>

            <Button
              href="/users/auth/instagram"
              label={t("inbox.permissions.retry")}
              style="inverse"
              icon={FacebookIcon}
              className="w-full"
            />

            {showTutorial && (
              <Button
                className="w-full"
                icon={QuestionMarkCircleIcon}
                label={t("onboarding.connect_instagram.show_me_how")}
                onClick={showTutorial}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
