import emojiRegex from "emoji-regex";
import React from "react";
import formatMessageText from "~/utils/formatMessageText";
import classNames from "~/utils/classNames";

export default function TextMessage(props) {
  const { received = false, text, highlight, className = "" } = props;

  const formattedText = formatMessageText(text);

  const htmlText = formattedText?.replace(
    highlight,
    "<mark>" + highlight + "</mark>",
  );

  const singleEmoji = htmlText.length <= 3 && htmlText.match(emojiRegex());

  return (
    <div
      className={classNames(
        "text-message overflow-hidden select-none sm:select-auto",
        singleEmoji ? "text-4xl" : received ? "received" : "",
        className,
      )}
      dangerouslySetInnerHTML={{ __html: htmlText }}
    />
  );
}
