import React, { useContext, useEffect } from "react";
import StoryItem from "./StoryItem";
import { useTranslation } from "react-i18next";
import { ContentContext } from "~/contexts/content-context";
import StoryInsights from "./StoryInsights";
import StoryReplies from "./StoryReplies";

export default function StoryDetails(props) {
  const { t } = useTranslation();

  const { story } = props;

  const { loadStory } = useContext(ContentContext);

  useEffect(() => loadStory(story.id), [story.id]);

  return (
    <div className="sm:flex flex-row w-full max-w-4xl sm:max-h-[680px] overflow-y-auto sm:overflow-hidden">
      <div className="sm:max-w-sm">
        <StoryItem story={story} />
      </div>

      <div className="sm:w-[420px] overflow-hidden flex flex-col">
        <div className="border-b flex-none">
          <StoryInsights insights={story.insights} />
        </div>

        <StoryReplies story={story} />
      </div>
    </div>
  );
}
