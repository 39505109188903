import React from "react";
import { useTranslation } from "react-i18next";
import formatAmount from "~/utils/formatAmount";

export default function PaymentFeesDetails(props) {
  const {
    className,
    amount,
    total,
    fee,
    stripeFee,
    currency,
    estimated = false,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={`rounded-lg bg-light-gray p-4 space-y-8 ${className}`}>
      {/* Amount received */}
      <div className="flex justify-between text-2sm">
        <div>
          {t("payments.details.amount_received")}
          {estimated && "*"}
        </div>
        <div>{formatAmount(amount, currency)}</div>
      </div>
      <div className="space-y-2">
        {/* Amount requested */}
        <div className="flex justify-between text-2xs">
          <div>{t("payments.details.amount_requested")}</div>
          <div>{formatAmount(total, currency)}</div>
        </div>
        {/* Service fees */}
        <div className="flex justify-between text-2xs">
          <div>{t("payments.details.service_fees")}</div>
          <div>{formatAmount(fee, currency)}</div>
        </div>
        {/* Payment fees */}
        {stripeFee ? (
          <div className="flex justify-between text-2xs">
            <div>
              {t("payments.details.payment_processing_fees")}
              {estimated && "*"}
            </div>
            <div>{formatAmount(stripeFee, currency)}</div>
          </div>
        ) : null}
      </div>
      {/* Notice */}
      {estimated && (
        <div className="text-4xs">
          *{t("payments.details.approximate_notice")}
        </div>
      )}
    </div>
  );
}
