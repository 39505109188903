import { ChevronRightIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link } from "react-router-dom";
import classNames from "../../utils/classNames";

const WRAPPER_CLASS = "block w-full ";

export default function MenuItem({
  label,
  description = null,
  external = false,
  to,
  href,
  onClick,
  count,
  badge = null,
  dot = false,
  className = "",
  icon = null,
  arrow = true,
  disabled = false,
}) {
  if (disabled) className += " opacity-50 pointer-events-none";

  const active = to && window.location.pathname === to;

  if (to) {
    return (
      <Link className={WRAPPER_CLASS + className} to={to}>
        <MenuItemContent
          label={label}
          description={description}
          dot={dot}
          badge={badge}
          count={count}
          icon={icon}
          arrow={arrow}
          active={active}
        />
      </Link>
    );
  }

  if (href) {
    return (
      <a
        className={WRAPPER_CLASS + className}
        href={href}
        target={external ? "_blank" : null}
      >
        <MenuItemContent
          label={label}
          description={description}
          dot={dot}
          badge={badge}
          count={count}
          icon={icon}
          arrow={arrow}
        />
      </a>
    );
  }

  if (onClick) {
    return (
      <button className={WRAPPER_CLASS + className} onClick={onClick}>
        <MenuItemContent
          label={label}
          description={description}
          dot={dot}
          badge={badge}
          count={count}
          icon={icon}
          arrow={arrow}
        />
      </button>
    );
  }

  return (
    <div className={WRAPPER_CLASS + className + " opacity-50"}>
      <MenuItemContent
        label={label}
        description={description}
        dot={dot}
        badge={badge}
        count={count}
        icon={icon}
        arrow={arrow}
      />
    </div>
  );
}

function MenuItemContent({
  label,
  description,
  dot,
  badge,
  count,
  icon: Icon,
  arrow,
  active = false,
}) {
  return (
    <div
      className={classNames(
        "w-full px-4 py-4 flex items-center justify-between space-x-2 border-b",
        active ? "bg-neutral-100" : "bg-white",
      )}
    >
      <div className="flex items-center">
        {Icon && <Icon className="w-4 h-4 mr-2" />}
        <div className="text-md">
          {label}
          {description && (
            <div className="mt-1 text-2sm text-dark-gray">{description}</div>
          )}
        </div>
        {count ? (
          <div
            className={`ml-2 mt-0.5 px-1 py-0.5 text-dark-gray bg-light-gray rounded text-4xs`}
          >
            {count}
          </div>
        ) : null}
      </div>
      <div className="flex items-center space-x-2">
        {badge}
        {dot && (
          <div className="w-2.5 h-2.5 rounded-full bg-primary flex-shrink-0" />
        )}
        {arrow && <ChevronRightIcon className="w-6 flex-shrink-0" />}
      </div>
    </div>
  );
}
