import React, { useContext, useMemo, useState } from "react";
import { FlowContext } from "~/contexts/flow-context";
import ActionNode from "./ActionNode";
import { useTranslation } from "react-i18next";
import { ContentContext } from "~/contexts/content-context";
import classNames from "~/utils/classNames";
import MediaItem from "~/components/content/medias/MediaItem";
import Button from "~/components/elements/Button";
import MediaPickerDialog from "~/components/fields/MediaPickerDialog";

export default function ShareMediaNode(props) {
  const { t } = useTranslation();
  const { selected, data } = props;
  const { id, options, editable = false } = data;

  const { medias } = useContext(ContentContext);

  const media_ig_id = options && options.media_ig_id;

  const { editAction } = useContext(FlowContext);

  const [mediaPickerVisible, showMediaPicker] = useState(editable);

  const handlePickMedia = (mediaId) => {
    editAction(id, {
      options: { ...options, media_ig_id: mediaId },
    });
  };

  const media = useMemo(
    () => medias?.find((m) => m.instagram_id === media_ig_id),
    [media_ig_id, medias],
  );

  return (
    <>
      <ActionNode {...props}>
        {!media ? (
          <div
            className={classNames(
              "py-3 px-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all flex flex-col space-y-2 text-md whitespace-nowrap",
              selected && "ring-opacity-100 z-50",
            )}
          >
            <div className="text-2sm text-darker-gray">
              {t("automation.scenarios.actions.share_media.title")}
            </div>
            <Button
              label={t("content.medias.pick_media")}
              onClick={() => showMediaPicker(true)}
              className="w-full"
            />
          </div>
        ) : (
          <div
            className={classNames(
              "rounded-xl max-w-2.5xs p-3 overflow-hidden nodrag bg-white border ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all cursor-pointer",
              selected && "ring-opacity-100 z-50",
            )}
            onClick={() => showMediaPicker(true)}
          >
            <MediaItem media={media} />
          </div>
        )}
      </ActionNode>

      {mediaPickerVisible ? (
        <MediaPickerDialog
          onClose={() => showMediaPicker(false)}
          onChange={handlePickMedia}
        />
      ) : null}
    </>
  );
}
