import { delay } from "lodash";

import React, { useCallback, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UIContext } from "~/contexts/ui-context";
import { UserContext } from "~/contexts/user-context";

import FolderTag from "../inbox/folders/FolderTag";
import FolderDialog from "../inbox/folders/FolderDialog";

import { PlusCircleIcon } from "@heroicons/react/20/solid";

export default function FolderPickerField(props) {
  const { t } = useTranslation();

  const {
    value = "",
    onChange = () => {},
    menuVisible = false,
    createNew = true,
  } = props;

  const { showMenu } = useContext(UIContext);
  const { organization, createFolder } = useContext(UserContext);
  const { folders } = organization;

  const [folderDialogVisible, showFolderDialog] = useState(false);
  const selectedFolder = folders.find((f) => f.id === parseInt(value));

  useEffect(() => {
    if (menuVisible) delay(handleShowMenu, 50);
  }, [menuVisible]);

  const handleCreateFolder = async (folderParams) => {
    const folder = await createFolder(folderParams);
    onChange(folder.id);
  };

  const newFolderAction = {
    label: (
      <div className="text-sm font-medium">{t("inbox.folders.create_new")}</div>
    ),
    action: () => showFolderDialog(true),
  };

  const handleShowMenu = useCallback(() => {
    showMenu({
      title: t("inbox.folders.pick"),
      actions: [
        ...folders.map((folder) => ({
          label: <FolderTag folder={folder} size="md" />,
          action: () => onChange(folder.id),
        })),
        createNew ? newFolderAction : null,
      ],
    });
  }, [folders, onChange, value]);

  return (
    <>
      {selectedFolder ? (
        <FolderTag folder={selectedFolder} onClick={handleShowMenu} size="md" />
      ) : (
        // Button "Pick a folder" when empty
        <button
          className="flex items-center text-2xs text-dark-gray bg-light-gray rounded-full px-2 py-1 whitespace-nowrap flex-shrink-0"
          onClick={handleShowMenu}
        >
          <PlusCircleIcon className="w-3.5 mr-1" />
          <div>{t("inbox.folders.pick")}</div>
        </button>
      )}
      {folderDialogVisible && (
        <FolderDialog
          onSubmit={handleCreateFolder}
          onClose={() => showFolderDialog(false)}
        />
      )}
    </>
  );
}
