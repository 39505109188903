import React from "react";
import { useTranslation } from "react-i18next";
import ActionNode from "./ActionNode";

export default function HideCommentNode(props) {
  const { t } = useTranslation();

  const { selected } = props;

  return (
    <ActionNode {...props}>
      <div
        className={`py-3 px-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="text-md font-medium mb-2">
          {t("automation.scenarios.actions.hide_comment.title")}
        </div>
        <div className="text-2sm text-darker-gray mb-2">
          {t("automation.scenarios.actions.hide_comment.description")}
        </div>
      </div>
    </ActionNode>
  );
}
