import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { every, some } from "lodash";
import { CampaignsContext } from "~/contexts/campaigns-context";
import Button from "~/components/elements/Button";
import Input from "~/components/elements/Input";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import CardsSelect from "~/components/elements/CardsSelect";
import {
  PaperAirplaneIcon,
  PlayCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import Modal from "../shared/Modal";

export default function NewCampaignDialog(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { onClose } = props;

  const { createCampaign, campaignTemplates, loadCampaignTemplates } =
    useContext(CampaignsContext);

  const [campaign, setCampaign] = useState({});
  const [loading, setLoading] = useState(false);

  const isValid = some([campaign.title, campaign.template_id]);

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 250);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);

    if (!isValid) return;

    createCampaign(campaign).then((newCampaign) => {
      if (newCampaign) {
        setLoading(false);
        navigate(`/campaigns/${newCampaign.id}`);
        handleClose();
      }
    });
  };

  useEffect(() => setTimeout(() => setIsOpen(true), 50), []);
  useEffect(loadCampaignTemplates, []);
  const cardOptions = useMemo(() => {
    return [
      ...campaignTemplates.map((template) => {
        return {
          value: template.id,
          label: template.title,
          description: template.template_description,
          icon: template.template_icon || "bolt",
        };
      }),
      {
        value: "new",
        label: t("campaigns.templates.empty_campaign"),
        description: t("campaigns.templates.empty_campaign_description"),
        icon: "document_plus",
      },
    ];
  }, [campaignTemplates]);

  return (
    <Modal
      className="w-full max-w-2xl"
      title={t("campaigns.templates.new")}
      titleIcon={PaperAirplaneIcon}
      footerLeft={<Button label={t("shared.cancel")} onClick={handleClose} />}
      footerRight={
        <Button
          label={t("campaigns.templates.create_campaign")}
          style="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={!isValid}
          loading={loading}
        />
      }
    >
      <div className="flex-grow py-4 px-4 space-y-4">
        <div className="space-y-1">
          <div className="text-base font-semibold leading-6 text-gray-900">
            {t("campaigns.templates.title_label")}
          </div>
          <Input
            placeholder={t("campaigns.templates.title_placeholder")}
            name="title"
            value={campaign?.title}
            onChange={(value) => setCampaign({ ...campaign, title: value })}
            className="w-1/2"
            autoFocus
          />
        </div>
        <CardsSelect
          label={t("campaigns.templates.choose_template")}
          options={cardOptions}
          cols={3}
          onChange={(value) => setCampaign({ ...campaign, template_id: value })}
        />
      </div>
    </Modal>
  );
}
