import React, { useContext, useState, useEffect } from "react";
import { useCopyToClipboard } from "react-use";
import { UIContext } from "~/contexts/ui-context";
import { Square2StackIcon } from "@heroicons/react/24/outline";
import {
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import Input from "~/components/elements/Input";
import Button from "~/components/elements/Button";

export default function WebhookOptions(props) {
  const { t } = useTranslation();
  const { trigger } = props;
  const [_state, copyToClipboard] = useCopyToClipboard();
  const url = trigger.webhook_uuid
    ? `${window.location.origin}/webhooks/triggers/${trigger.webhook_uuid}/start`
    : null;
  const { showTooltip } = useContext(UIContext);

  const handleCopyToClipboard = () => {
    showTooltip(t("shared.copied"));
    copyToClipboard(url);
  };

  return (
    <>
      <Input
        value={url}
        placeholder={t("automation.scenarios.trigger_type.webhook.placeholder")}
        readOnly
        className="w-72"
        trailingAddon={
          trigger.webhook_uuid && (
            <Square2StackIcon
              className="w-5 text-darker-gray opacity-75 hover:opacity-100 cursor-pointer flex-shrink-0"
              onClick={handleCopyToClipboard}
            />
          )
        }
      />
      <div className="bg-lighter-gray px-3 py-2 rounded-lg text-2sm">
        <ExclamationTriangleIcon className="w-4 inline-block mr-1" />
        {t("automation.scenarios.trigger_type.webhook.notice")}
        <Button
          icon={QuestionMarkCircleIcon}
          size="small"
          className="!flex mt-2"
          label={t("shared.view_documentation")}
          href="https://inro.notion.site/Trigger-scenarios-from-an-external-tool-682dba2053ec4a75bb4872c7798ca37a?pvs=4"
        />
      </div>
    </>
  );
}
