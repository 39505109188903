import React, { useContext, useMemo } from "react";
import { UserContext } from "~/contexts/user-context";
import { compact } from "lodash";
import FolderTag, { sizes } from "./FolderTag";
import InlineTooltip from "~/components/shared/InlineTooltip";
import classNames from "../../../utils/classNames";

export default function ContactFolders(props) {
  const { folderIds, size = "md", max = 2 } = props;

  const { organization } = useContext(UserContext);

  const folders = useMemo(
    () =>
      compact(
        folderIds?.map((folderId) => {
          return organization.folders.find((folder) => folder.id === folderId);
        }),
      ),
    [folderIds, organization.folders],
  );

  const extraFoldersText = folders
    .slice(max)
    .map((folder) => folder.name)
    .join(", ");

  if (!folders || folders?.length == 0) return null;

  return (
    <div className="flex flex-wrap gap-2">
      {folders.slice(0, max).map((folder) => (
        <FolderTag key={folder.id} folder={folder} size={size} />
      ))}
      {folders.length > max ? (
        <InlineTooltip text={extraFoldersText} align="right">
          <div
            className={classNames(
              "font-normal text-dark-gray bg-light-gray whitespace-nowrap flex-shrink-0 cursor-pointer",
              sizes[size],
            )}
          >
            +{folders.length - max}
          </div>
        </InlineTooltip>
      ) : null}
    </div>
  );
}
