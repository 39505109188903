import React from "react";
import {
  PlayCircleIcon,
  ArrowRightCircleIcon,
  PauseCircleIcon,
} from "@heroicons/react/20/solid";

export default function scenarioStatusIcon(active, small = false) {
  const size = small ? "w-5" : "w-7";

  if (active) {
    return (
      <PlayCircleIcon className={`${size} text-automation flex-shrink-0`} />
    );
  } else {
    return (
      <PauseCircleIcon className={`${size} text-medium-gray flex-shrink-0`} />
    );
  }
}
