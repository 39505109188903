import React from "react";
import classNames from "~/utils/classNames";

export default function TriggerBadge(props) {
  const { label, className } = props;

  return (
    <div
      className={classNames(
        "inline-block px-1.5 py-0.5 rounded text-purple-900 bg-purple-100 text-xs font-medium whitespace-nowrap cursor-default",
        className,
      )}
    >
      {label}
    </div>
  );
}
