import React, { useContext } from "react";
import { ContentContext } from "~/contexts/content-context";
import MediaItem from "./medias/MediaItem";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "~/components/shared/Modal";
import MediaDetails from "./medias/MediaDetails";
import { reverse, sortBy } from "lodash";

export default function ContentMedias() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { resourceId } = useParams();

  const { medias } = useContext(ContentContext);

  const activeMedia = medias.find((media) => media.id === parseInt(resourceId));

  return (
    <>
      <div className="space-y-4">
        <div className="flex gap-6 flex-wrap pb-10">
          {reverse(sortBy(medias, "posted_at")).map((media) => (
            <Link
              className="w-56 opacity-90 transition-opacity hover:opacity-100"
              to={`/content/medias/${media.id}`}
              key={media.id}
            >
              <MediaItem media={media} />
            </Link>
          ))}
        </div>
      </div>
      {activeMedia && (
        <Modal onClose={() => navigate("/content/medias")}>
          <MediaDetails media={activeMedia} />
        </Modal>
      )}
    </>
  );
}
