import React from "react";
import Button from "./Button";
import {
  CheckCircleIcon,
  DocumentDuplicateIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import Badge from "./Badge";
import { capitalize } from "lodash";
import ButtonWithDropdown from "./ButtonWithDropdown";
import ButtonGroup from "./ButtonGroup";
import Input from "./Input";
import InputSelect from "./InputSelect";
import InputCheckbox from "./InputCheckbox";
import InputCheckboxes from "./InputCheckboxes";

const dropdownActions = [
  {
    label: "Action 1",
  },
  {
    label: "Action 2",
  },
];

const selectOptions = [
  {
    value: "option_1",
    label: "Option 1",
  },
  {
    value: "option_2",
    label: "Option 2",
  },
];

export default function ElementsPage() {
  return (
    <div className="p-4 space-y-4">
      <h1 className="text-2xl font-semibold">Elements</h1>

      <h2 className="text-xl font-semibold">Buttons</h2>
      <div className="flex flex-wrap items-start gap-3">
        <Button label="Default Extra-small" size="extra-small" />
        <Button label="Default Small" size="small" />
        <Button label="Default Base" size="base" />
        <Button label="Default Primary" size="base" style="primary" />
        <Button label="Disabled" disabled />
        <Button label="Primary" style="primary" />
        <Button label="Transparent" style="transparent" />
        <Button label="With icon" icon={CheckCircleIcon} />
        <Button label="Loading" loading />
        <Button label="Loading primary" loading style="primary" />
        <Button label="Loading small" loading size="small" />
        <Button label="Loading on click" loadingOnClick style="primary" />
        <ButtonWithDropdown
          label="Button + dropdown"
          actions={dropdownActions}
        />
        <ButtonWithDropdown
          label="Small button + dropdown"
          size="small"
          actions={dropdownActions}
        />
        <ButtonWithDropdown
          label="Small button + dropdown"
          size="extra-small"
          actions={dropdownActions}
        />
        <ButtonWithDropdown
          label="Primary style"
          style="primary"
          actions={dropdownActions}
        />
        <ButtonWithDropdown
          label="With icons"
          icon={PencilIcon}
          actions={[
            {
              label: "Action 1",
              icon: DocumentDuplicateIcon,
            },
          ]}
        />
      </div>

      <ButtonGroup
        buttons={[
          {
            label: "Button 1",
            icon: PencilIcon,
          },
          {
            label: "Button 2",
          },
          {
            label: "Button 3",
            style: "primary",
          },
        ]}
      />

      <h2 className="text-xl font-semibold">Forms</h2>
      <h3 className="font-semibold">Labels</h3>
      <div className="flex items-start gap-3">
        <Input label="Label" placeholder="Input with label" />
        <InputSelect
          label="Label"
          placeholder="Select with label"
          options={selectOptions}
        />
      </div>
      <h3 className="font-semibold">Input</h3>
      <div className="flex items-start flex-wrap gap-3">
        <Input placeholder="Default" />
        <Input placeholder="Date" type="date" />
        <Input placeholder="Number" type="number" />
        <Input placeholder="With icon" icon={MagnifyingGlassIcon} />
        <Input placeholder="Leading addon" leadingAddon="@" />
        <Input placeholder="Trailing addon" trailingAddon="€" />
        <Input placeholder="Emoji picker" emojiPicker />
        <Input placeholder="Variables" insertVariables />
        <Input placeholder="Small" size="small" />
        <Input placeholder="Extra small" size="extra-small" />
      </div>
      <h3 className="font-semibold">Select</h3>
      <div className="flex items-start gap-3">
        <InputSelect placeholder="Select an option" options={selectOptions} />
        <InputSelect
          icon={UserIcon}
          placeholder="Select an option"
          options={selectOptions}
        />
      </div>
      <h3 className="font-semibold">Checkbox</h3>
      <div className="flex items-start gap-4">
        <InputCheckbox label="Single Checkbox" />
        <InputCheckboxes label="Multiple checkboxes" options={selectOptions} />
      </div>

      <h2 className="text-xl font-semibold">Badges</h2>
      <div className="flex items-start gap-3">
        {["green", "blue", "gray", "yellow", "red"].map((color) => (
          <Badge key={color} color={color} label={capitalize(color)} />
        ))}
      </div>
      <div className="flex items-start gap-3">
        {["green", "blue", "gray", "yellow", "red"].map((color) => (
          <Badge key={color} color={color} label={capitalize(color)} rounded />
        ))}
      </div>
    </div>
  );
}
