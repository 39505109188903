import React, { useState } from "react";
import axios from "axios";
import headers from "~/utils/formDataHeaders";
import { MediaFilesContext } from "~/contexts/media-files-context";

export default function MediaFilesProvider(props) {
  const [loading, setLoading] = useState(true);

  const createMedia = async (file, fileType) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("media_type", fileType);
    const res = await axios.post("/api/inbox/media_files", formData, headers());
    setLoading(false);
    return res.data;
  };

  const getMedia = async (mediaId) => {
    if (!mediaId) return;

    setLoading(true);
    const res = await axios.get(`/api/inbox/media_files/${mediaId}`);
    setLoading(false);
    return res.data;
  };

  const mediaFilesValues = {
    loading,
    createMedia,
    getMedia,
  };

  return (
    <MediaFilesContext.Provider value={mediaFilesValues}>
      {props.children}
    </MediaFilesContext.Provider>
  );
}
