import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import PageHeader from "~/components/shared/PageHeader";
import FullScreen from "~/components/utils/FullScreen";

import InstagramAccount from "./accounts/InstagramAccount";
import Button from "~/components/elements/Button";
import SubscriptionStatus from "../subscription/SubscriptionStatus";
import { SubscriptionContext } from "../../contexts/subscription-context";
import RedeemCodeModal from "../subscription/RedeemCodeModal";

export default function OrganizationAccountPage(props) {
  const { back } = props;

  const { user, organization, leaveOrganization, deleteOrganization } =
    useContext(UserContext);
  const { isFree, isTrial, limits } = useContext(SubscriptionContext);

  const { t } = useTranslation();

  const organizationUser = user.organization_users.find(
    (ou) => ou.organization.id === organization.id,
  );

  const [redeemCodeModal, setRedeemCodeModal] = useState(false);

  return (
    <FullScreen>
      <PageHeader
        title={t("profile.organization_account")}
        back={back}
        className="mb-4"
      />
      <div className="p-4 space-y-4">
        <SubscriptionStatus />
        {(isFree || isTrial) && (
          <div className="pb-2 flex justify-center">
            <button
              className="text-sm text-muted hover:underline"
              onClick={() => setRedeemCodeModal(true)}
            >
              {t("subscription.redeem_code.have_code")}
            </button>
          </div>
        )}
        <InstagramAccount />
        {user.organization_users.length > 1 ? (
          organizationUser?.role === "owner" ? (
            <Button
              style="danger"
              label={t("profile.delete_organization")}
              className="w-full"
              onClick={deleteOrganization}
            />
          ) : (
            <Button
              style="danger"
              label={t("profile.leave_organization")}
              className="w-full"
              onClick={leaveOrganization}
            />
          )
        ) : null}
      </div>
      {redeemCodeModal && (
        <RedeemCodeModal onClose={() => setRedeemCodeModal(false)} />
      )}
    </FullScreen>
  );
}
