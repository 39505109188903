import React from "react";

export default function ProfileSectionTitle({ label, subtitle, className }) {
  return (
    <div className={`px-4 py-4 text-md ${className}`}>
      {label}
      {subtitle && <div className="text-2xs text-dark-gray mt-2">{subtitle}</div>}
    </div>
  );
}
