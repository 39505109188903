import {
  CalendarDaysIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/20/solid";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import { UIContext } from "~/contexts/ui-context";
import ActionNode from "./ActionNode";

const amounts = [
  "never",
  "at least once",
  "at least twice",
  "at least 3 times",
];
const periods = ["day", "7 days", "month", "3 months", "year"];

export default function EngagementConditionNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id } = data;

  const { editAction } = useContext(FlowContext);
  const { showMenu } = useContext(UIContext);

  const amountMenu = useCallback(() => {
    showMenu({
      title: t("automation.scenarios.actions.engagement_condition.if"),
      actions: amounts.map((a) => ({
        label: a,
        bold: a == data.data.amount,
        action: () => handlePick(a, data.data.period),
      })),
    });
  }, [handlePick, data.data.amount, data.data.period]);

  const periodMenu = useCallback(() => {
    showMenu({
      title: t("automation.scenarios.actions.engagement_condition.if"),
      actions: periods.map((p) => ({
        label: p,
        bold: p == data.data.period,
        action: () => handlePick(data.data.amount, p),
      })),
    });
  }, [handlePick, data.data.amount, data.data.period]);

  const handlePick = (amount, period) => {
    editAction(id, { data: { amount, period } });
  };

  return (
    <ActionNode {...props}>
      <div
        className={`p-4 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all flex flex-col space-y-3 text-md whitespace-nowrap ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="flex items-center space-x-1.5">
          <div>{t(`automation.scenarios.actions.engagement_condition.if`)}</div>
          <button
            className="px-1.5 rounded bg-automation text-white nodrag flex items-center space-x-1"
            onClick={amountMenu}
          >
            <ChatBubbleLeftRightIcon className="w-4" />
            <div className="font-medium pb-0.5">{data.data.amount}</div>
          </button>
        </div>
        <div className="flex items-center space-x-1.5">
          <div>{t(`automation.scenarios.actions.engagement_condition.in`)}</div>
          <button
            className="px-1.5 rounded bg-automation text-white nodrag flex items-center space-x-1"
            onClick={periodMenu}
          >
            <CalendarDaysIcon className="w-4" />
            <div className="font-medium pb-0.5">{data.data.period}</div>
          </button>
        </div>
      </div>
    </ActionNode>
  );
}
