import React, { useContext, useState } from "react";
import { ContactsContext } from "../../contexts/contacts-context";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon, IdentificationIcon } from "@heroicons/react/20/solid";
import { UIContext } from "../../contexts/ui-context";
import NewContactProperty from "../contacts/properties/NewContactProperty";

export default function ContactPropertyPickerField(props) {
  const { t } = useTranslation();

  const { value, onChange } = props;

  const { contactProperties } = useContext(ContactsContext);

  const { showMenu } = useContext(UIContext);

  const [newPropertyDialog, setNewPropertyDialog] = useState(false);

  const handleSelectProperty = async () => {
    const columns = ["email", "phone"].map((o) => ({
      label: t("contacts.columns." + o),
      bold: true,
      action: () => onChange(o),
    }));
    const properties = contactProperties.map((o) => ({
      label: o.title,
      bold: true,
      action: () => onChange(`property_${o.id}`),
    }));

    showMenu({
      title: t("contacts.properties.select_property"),
      actions: [
        ...columns,
        ...properties,
        {
          label: t("contacts.properties.new_property"),
          action: () => setNewPropertyDialog(true),
        },
      ],
    });
  };

  const selectedPropertyTitle = value
    ? value?.includes("property_")
      ? contactProperties.find(
          (o) => parseInt(o.id) === parseInt(value.split("_")[1]),
        )?.title
      : t("contacts.columns." + value)
    : null;

  return (
    <>
      <div
        className="border rounded-lg p-2.5 pl-2.5 flex items-center space-x-1 cursor-pointer hover:bg-neutral-50 transition-colors bg-white shadow-sm"
        onClick={handleSelectProperty}
      >
        {selectedPropertyTitle ? (
          <div className="flex items-center space-x-1 flex-grow">
            <IdentificationIcon className="w-4" />
            <div className="text-sm font-medium">{selectedPropertyTitle}</div>
          </div>
        ) : (
          <div className="text-sm text-muted italic flex-grow whitespace-nowrap">
            {t("contacts.properties.no_property_selected")}
          </div>
        )}
        <ChevronDownIcon className="w-5 text-muted" />
      </div>
      {newPropertyDialog && (
        <NewContactProperty
          onCreate={(newProperty) => onChange(`property_${newProperty.id}`)}
          onClose={() => setNewPropertyDialog(false)}
        />
      )}
    </>
  );
}
