import React from "react";
import { useTranslation } from "react-i18next";
import ContactDetailsField from "./ContactDetailsField";

export default function ContactDetails(props) {
  const { contact, className } = props;

  const { t } = useTranslation();

  const contactDetails = [
    {
      label: t("inbox.details.real_name"),
      placeholder: t("inbox.details.real_name_placeholder"),
      value: contact?.real_name,
    },
    {
      label: t("inbox.details.email"),
      placeholder: t("inbox.details.email_placeholder"),
      value: contact?.email,
    },
    {
      label: t("inbox.details.phone"),
      placeholder: t("inbox.details.phone_placeholder"),
      value: contact?.phone,
    },
  ];

  return (
    <div className={`border-t ${className}`}>
      {contactDetails.map((detail, index) => (
        <ContactDetailsField key={index} canCopy {...detail} />
      ))}
    </div>
  );
}
