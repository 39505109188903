import React, { useContext, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import Table from "~/components/elements/table/Table";
import { CampaignsContext } from "~/contexts/campaigns-context";
import {
  FireIcon,
  EnvelopeOpenIcon,
  CheckCircleIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/20/solid";
import classNames from "~/utils/classNames";
import { sortBy } from "lodash";
import Loader from "~/components/utils/Loader";
import Button from "~/components/elements/Button";

export default function RecentCampaigns(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { loading, campaigns, loadCampaign } = useContext(CampaignsContext);

  const firstFiveCampaigns = useMemo(() => {
    const filteredCampaigns = campaigns.filter((campaign) => campaign.sent_at);
    return sortBy(filteredCampaigns, "sent_at").reverse().slice(0, 5);
  }, [campaigns]);

  const columns = useMemo(
    () => [
      {
        label: t("dashboard.campaigns.name"),
        accessor: (campaign) => {
          return (
            <div className="flex flex-col gap-y-1">
              <Link
                className="flex items-end text-headings font-semibold hover:underline cursor-pointer"
                to={`/campaigns/${campaign.id}`}
              >
                <span>{campaign.title}</span>
              </Link>
              <div>
                <span
                  className={classNames(
                    "text-muted text-xs",
                    campaign.sent_at ? "" : "invisible",
                  )}
                >
                  {t("dashboard.campaigns.sent_at", {
                    date: DateTime.fromISO(campaign.sent_at).toLocaleString(),
                  })}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        label: t("dashboard.campaigns.sent"),
        accessor: (campaign) => {
          return (
            <div className="flex items-center text-headings">
              <CheckCircleIcon className="h-5 w-5 mr-2" />
              <span>{campaign.metrics?.sent?.count}</span>
            </div>
          );
        },
      },
      {
        label: t("dashboard.campaigns.opened"),
        accessor: (campaign) => {
          return (
            <div className="flex items-center text-headings">
              <EnvelopeOpenIcon className="h-5 w-5 mr-2" />
              <span>{campaign.metrics?.opened?.count}</span>
            </div>
          );
        },
      },
      {
        label: t("dashboard.campaigns.conversions"),
        accessor: (campaign) => {
          return (
            <div className="flex items-center text-headings">
              <FireIcon className="h-5 w-5 mr-2" />
              <span>{campaign.metrics?.converted?.count}</span>
            </div>
          );
        },
      },
    ],
    [],
  );

  if (loading) {
    return (
      <div className="flex min-h-80 items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-4">
      <h2 className="font-bold text-headings text-xl">
        {t("dashboard.campaigns.title")}
      </h2>
      <Table
        items={firstFiveCampaigns}
        columns={columns}
        onRowRender={(campaign) => loadCampaign(campaign.id)}
        emptyState={
          <div className="flex flex-col space-y-4 min-h-80 items-center justify-center">
            <div className="text-center">{t("dashboard.campaigns.empty")}</div>
            <Button
              icon={PaperAirplaneIcon}
              label={t("dashboard.campaigns.empty_cta")}
              style="primary"
              to="/campaigns?new=true"
            />
          </div>
        }
      />
      {campaigns.length > 0 && (
        <Link
          to="/campaigns"
          className="text-muted w-full text-center font-medium py-2"
        >
          {t("dashboard.campaigns.view_all")}
        </Link>
      )}
    </div>
  );
}
