import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { FlowContext } from "~/contexts/flow-context";
import ActionNode from "./ActionNode";
import TriggerItem from "../triggers/TriggerItem";
import AddTrigger from "../triggers/AddTrigger";

export default function TriggerNode(props) {
  const { t } = useTranslation();

  const { triggers } = useContext(FlowContext);

  return (
    <ActionNode first noEdit noWarnings triggers={triggers} {...props}>
      <div className="pt-3 px-4 pb-4 bg-white border-2 border-automation rounded-2xl nodrag flex flex-col space-y-3">
        {triggers
          ?.filter((trigger) => !trigger._destroy)
          .map((trigger) => (
            <TriggerItem key={trigger.id} trigger={trigger} />
          ))}
        <AddTrigger />
      </div>
    </ActionNode>
  );
}
