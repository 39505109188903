import React from "react";
import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import InlineTooltip from "~/components/shared/InlineTooltip";
import SkeletonLoader from "~/components/utils/SkeletonLoader";

const EvolutionDisplay = ({ value }) => {
  if (value >= 0) {
    return (
      <div className="flex items-center gap-x-1 text-success">
        <span className="font-medium">+{value.toLocaleString()}%</span>
        <ArrowTrendingUpIcon className="w-4" />
      </div>
    );
  } else if (value < 0) {
    return (
      <div className="flex items-center gap-x-1 text-danger">
        <span className="font-medium">{value.toLocaleString()}%</span>
        <ArrowTrendingDownIcon className="w-4" />
      </div>
    );
  } else {
    return null;
  }
};

export default function DashboardMetricCard(props) {
  const { loading, icon, title, value, evolution, helpMessage, subtext } =
    props;

  return (
    <div>
      <div className="bg-highlight p-4 rounded-lg">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-x-2">
            {icon}
            <h3 className="font-medium text-headings">{title}</h3>
          </div>
          <InlineTooltip
            text={helpMessage}
            align="right"
            position="bottom"
            className="w-64"
          >
            <QuestionMarkCircleIcon className="w-5 text-muted" />
          </InlineTooltip>
        </div>
        <div className="flex items-end justify-between">
          {loading ? (
            <SkeletonLoader width={100} height={60} className="!bg-gray-300" />
          ) : (
            <h3 className="font-light text-headings text-6xl">
              {value.toLocaleString()}
            </h3>
          )}
          {!loading && <EvolutionDisplay value={evolution} />}
        </div>
      </div>
      <p className="text-sm text-muted pt-2">{subtext}</p>
    </div>
  );
}
