import React, { useContext } from "react";
import { FlowContext } from "~/contexts/flow-context";
import triggerTypeOptions from "./triggerTypeOptions";
import { useTranslation } from "react-i18next";
import Input from "~/components/elements/Input";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import MediaPickerField from "~/components/fields/MediaPickerField";
import TriggerKeywordsOption from "./TriggerKeywordsOption";

export default function CommentOptions(props) {
  const { t } = useTranslation();

  const { trigger } = props;

  const { editTriggerOptions } = useContext(FlowContext);

  const options = triggerTypeOptions.comment.options;

  const handleOptionChange = (evt) => {
    editTriggerOptions(trigger.id, { comment_option: evt.target.value });
  };

  return (
    <div className="flex flex-col space-y-3">
      <select
        className="input-select"
        value={trigger.options.comment_option}
        onChange={handleOptionChange}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {t("automation.scenarios.trigger_type.comment.options." + option)}
          </option>
        ))}
      </select>
      {trigger.options.comment_option === "specific" && (
        <MediaPickerField
          value={trigger.options.media_id}
          onChange={(media_id) => editTriggerOptions(trigger.id, { media_id })}
        />
      )}
      {trigger.options.comment_option === "keywords" && (
        <TriggerKeywordsOption trigger={trigger} showNotice />
      )}
      {trigger.options.comment_option === "caption_keyword" && (
        <>
          <Input
            placeholder={t("automation.scenarios.trigger_node.keyword")}
            value={trigger.options.caption_keyword}
            onChange={(caption_keyword) => {
              if (trigger.options.caption_keyword === caption_keyword) return;
              editTriggerOptions(trigger.id, { caption_keyword });
            }}
            changeOnBlur
          />
          <div className="bg-lighter-gray px-3 py-2 rounded-lg text-2sm">
            <ExclamationTriangleIcon className="w-4 inline-block mr-1" />
            {t("automation.scenarios.trigger_type.comment.keyword_notice")}
          </div>
        </>
      )}
    </div>
  );
}
