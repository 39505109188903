import React, { useContext, useEffect, useState } from "react";
import Textarea from "react-expanding-textarea";
import { useTranslation } from "react-i18next";
import InputCheckbox from "~/components/elements/InputCheckbox";
import { FlowContext } from "~/contexts/flow-context";
import { UserContext } from "~/contexts/user-context";
import RepliedIcon from "../../../inbox/RepliedIcon";
import ActionNode from "./ActionNode";
import Button from "../../../elements/Button";
import { PlusIcon, QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import AiBadge from "../../../elements/AiBadge";
import classNames from "../../../../utils/classNames";
import { SubscriptionContext } from "../../../../contexts/subscription-context";

/**
 * Node for replying to a comment with another comment
 * @component
 * @param {Object} props - Properties passed to component
 * @param {Object} props.data - Comment data
 * @param {boolean} props.selected - If true, node is selected
 * @returns
 */

const MAX_VARIANTS = 6;

export default function CommentNode(props) {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);
  const { requestFeature } = useContext(SubscriptionContext);
  const { editAction } = useContext(FlowContext);

  const { data, selected } = props;

  const { id, options = {} } = data;

  const { autowrite, comment_variants: variants = [] } = options;

  const handleAddVariant = () => {
    editAction(id, {
      options: { ...options, comment_variants: [...variants, ""] },
    });
  };

  const handleChangeVariant = (index) => (text) => {
    const newVariants = [...variants];
    newVariants[index] = text;
    editAction(id, { options: { ...options, comment_variants: newVariants } });
  };

  const handleDeleteVariant = (index) => {
    const newVariants = [...variants];
    newVariants.splice(index, 1);
    editAction(id, { options: { ...options, comment_variants: newVariants } });
  };

  useEffect(() => {
    if (variants.length == 0) {
      editAction(id, { options: { ...options, comment_variants: [""] } });
    }
  }, [variants]);

  return (
    <ActionNode {...props}>
      <div
        className={`w-full py-3 px-3 bg-white border rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all space-y-3 ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="text-md font-medium">
          {t("automation.scenarios.actions.comment.title")}
        </div>
        <div className="flex flex-col items-end space-y-2">
          {variants.map((variant, index) => (
            <div className="flex items-center space-x-2" key={index}>
              <RepliedIcon className="rotate-180" />
              <CommentInput
                value={variant}
                onChange={handleChangeVariant(index)}
                autoFocus={variant.length == 0}
              />
            </div>
          ))}
          {variants.length <= MAX_VARIANTS && (
            <Button
              size="extra-small"
              onClick={handleAddVariant}
              label={t("automation.scenarios.actions.comment.add_variant")}
              icon={PlusIcon}
            />
          )}
        </div>
        <div className="bg-lighter-gray px-3 py-2 rounded-lg text-2sm">
          <QuestionMarkCircleIcon className="w-4 inline-block mr-1" />
          {t("automation.scenarios.actions.comment.variant_notice")}
        </div>
        <InputCheckbox
          value={autowrite}
          size="md"
          label={t("automation.scenarios.actions.comment.autowrite")}
          onClick={() => {
            if (organization.features.ai) {
              editAction(id, {
                options: { ...options, autowrite: !autowrite },
              });
            } else {
              requestFeature("ai_rewrite");
            }
          }}
          className={classNames(
            "bg-white border rounded-lg p-2 flex items-start",
            !organization.features.ai && "opacity-75",
          )}
        >
          <AiBadge size="sm" />
        </InputCheckbox>
      </div>
    </ActionNode>
  );
}

const CommentInput = (props) => {
  const { t } = useTranslation();

  const { value, onChange, ...passthroughProps } = props;

  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (value != localValue) setLocalValue(value);
  }, [value]);

  return (
    <Textarea
      className={`px-2.5 py-2 outline-none bg-white border border-neutral-200 rounded-lg ring-none nodrag nopan resize-none text-2sm leading-4 block`}
      placeholder={t("automation.scenarios.actions.comment.placeholder")}
      value={localValue}
      onChange={(evt) => setLocalValue(evt.target.value)}
      onBlur={() => onChange(localValue)}
      {...passthroughProps}
    />
  );
};
