import React from "react";
import ConnectedCalendarEvent from "./ConnectedCalendarEvent";
import { DateTime } from "luxon";

export default function CalendarDayHeader(props) {
  const { day, connectedEvents } = props;

  return (
    <div
      className={`flex flex-col items-center ${
        connectedEvents.length > 0 ? "pb-0" : "pb-3"
      }`}
    >
      <div
        className={`text-2xs uppercase m-2 ${
          day.hasSame(DateTime.now(), "day") && "text-primary"
        }`}
      >
        {day.toFormat("EEEEE")}
      </div>
      <div
        className={`text-2sm font-medium text-center w-6 h-6 rounded-full p-1 ${
          day.hasSame(DateTime.now(), "day") && "bg-primary text-white"
        }`}
      >
        {day.toFormat("d")}
      </div>
      {connectedEvents.length > 0 && (
        <div className="p-0.5 w-full flex flex-col space-y-0.5">
          {connectedEvents.map((event) => (
            <ConnectedCalendarEvent event={event} key={event.id} />
          ))}
        </div>
      )}
    </div>
  );
}
