import React from "react";
import { Trans, useTranslation } from "react-i18next";
import SkeletonLoader from "~/components/utils/SkeletonLoader";
import percentage from "~/utils/percentage";

export default function CampaignResultsMetrics(props) {
  const { t } = useTranslation();

  const { campaign } = props;

  const { metrics, loaded } = campaign;

  return (
    <>
      <dl className="w-full grid grid-cols-2 divide-x md:grid-cols-4">
        {["sent", "failed", "opened", "converted"].map((metric, index) => (
          <div
            key={index}
            className="flex flex-col space-y-4 p-3 sm:p-4 md:px-6 border-b "
          >
            <dt className="text-sm font-medium leading-6 text-neutral-500">
              {t("campaigns.metrics." + metric)}
            </dt>
            {loaded ? (
              <div className="flex items-baseline justify-between">
                <dd className="flex-none text-2xl font-medium leading-10 text-neutral-900">
                  {metrics[metric]?.count}
                </dd>
                <dd className="text-sm font-medium leading-6 text-neutral-500">
                  {percentage(metrics[metric]?.rate)}%
                </dd>
              </div>
            ) : (
              <SkeletonLoader width={100} height={40} />
            )}
          </div>
        ))}
      </dl>
    </>
  );
}
