import React from "react";
import { useTranslation } from "react-i18next";
import CheckCircle from "~/components/shared/CheckCircle";

export default function ConnectedCalendar(props) {
  const { calendar, updateCalendar, toggleDefault } = props;

  const { t } = useTranslation();

  const toggleVisibility = () => {
    updateCalendar({ ...calendar, visible: !calendar.visible });
  };

  return (
    <div className="flex justify-between items-center space-x-4 py-2">
      <div className="flex items-center" onClick={toggleVisibility}>
        <CheckCircle
          checked={calendar.visible}
          className="!w-6 !h-6 mr-3 !border-2"
          color={calendar.background_color || "#d6eaff"}
        />
        <div className="flex-grow text-2sm font-medium">{calendar.title}</div>
      </div>
      {calendar.primary ? (
        <div className="text-2sm font-medium bg-primary rounded-md py-1 px-2 text-white">
          {t("shared.default")}
        </div>
      ) : (
        <div
          className="text-2sm text-neutral-400"
          onClick={() => toggleDefault(calendar.id)}
        >
          {t("shared.make_default")}
        </div>
      )}
    </div>
  );
}
