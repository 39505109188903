import React from "react";
import { useTranslation } from "react-i18next";

export default function PublicFormInput(props) {
  const {
    label,
    placeholder,
    name,
    id,
    type,
    autoComplete,
    required,
    value,
    onChange,
    ...passthroughProps
  } = props;

  const { t } = useTranslation();

  return (
    <div className={`flex flex-col ${props?.className}`}>
      <label
        htmlFor={name}
        className="text-md text-neutral-800 font-normal mb-1"
      >
        {label}{" "}
        {required && (
          <span className="text-xs text-dark-gray mb-0.5">
            ({t("shared.required").toLowerCase()})
          </span>
        )}
      </label>
      {type == "textarea" ? (
        <textarea
          name={name}
          id={id || name}
          placeholder={placeholder}
          autoComplete={autoComplete}
          required={required}
          value={value}
          rows={3}
          onChange={(evt) => onChange(evt.target.value)}
          className="text-normal text-md border border-input-gray shadow-sm rounded px-3 py-2 leading-normal focus:outline-none focus:border-primary focus:ring-4 focus:ring-light-orange resize-none"
          {...passthroughProps}
        />
      ) : (
        <input
          type={type || "text"}
          name={name}
          id={id || name}
          placeholder={placeholder}
          autoComplete={autoComplete}
          required={required}
          value={value}
          onChange={(evt) => onChange(evt.target.value)}
          className="text-normal text-md border border-input-gray shadow-sm rounded p-3 leading-none focus:outline-none focus:border-primary focus:ring-4 focus:ring-light-orange"
          {...passthroughProps}
        />
      )}
    </div>
  );
}
