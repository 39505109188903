import React, { useContext, useState } from "react";
import OauthChecklist from "../onboarding/steps/connect_instagram/OauthChecklist";
import { LoadingContext } from "~/contexts/loading-context";
import facebook_logo from "../../../assets/images/facebook_logo_white.svg";
import { useTranslation } from "react-i18next";

export default function NoConnection(props) {
  const { t } = useTranslation();

  const { setHidden } = props;

  const { setLoading } = useContext(LoadingContext);

  const [checkInstagram, setCheckInstagram] = useState(false);
  const [checkFacebookPage, setCheckFacebookPage] = useState(false);

  const authoriseButtonDisabled = !checkInstagram || !checkFacebookPage;

  return (
    <div className="flex flex-col items-center space-y-4 p-6">
      <div className="font-medium text-center">
        {t("onboarding.connect_instagram.title")}
      </div>
      <OauthChecklist
        checkInstagram={checkInstagram}
        setCheckInstagram={setCheckInstagram}
        checkFacebookPage={checkFacebookPage}
        setCheckFacebookPage={setCheckFacebookPage}
      />

      <a
        href="/users/auth/instagram"
        onClick={() => {
          setLoading(t("inbox.permissions.connecting"));
          setHidden(true);
        }}
        className={`w-full flex justify-center py-2.5 px-4 !mt-6 bg-black text-white rounded ${
          authoriseButtonDisabled && "opacity-50 pointer-events-none"
        }`}
      >
        <img src={facebook_logo} />
        <div className="ml-2.5 text-sm font-medium rounded cursor-pointer">
          {t("inbox.permissions.authorise")}
        </div>
      </a>
    </div>
  );
}
