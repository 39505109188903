import { LinkIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import ActionNode from "./ActionNode";
import { AutomationContext } from "~/contexts/automation-context";
import SmallConversionLinkItem from "../../conversion_links/SmallConversionLinkItem";
import ConversionLinkMessage from "../../../messages/ConversionLinkMessage";
import { UIContext } from "~/contexts/ui-context";
import ConversionLinkDialog from "../../conversion_links/ConversionLinkDialog";

export default function ConversionLinkNode(props) {
  const { t } = useTranslation();

  const { showMenu } = useContext(UIContext);
  const { conversionLinks } = useContext(AutomationContext);
  const { editAction } = useContext(FlowContext);

  const { data, selected } = props;

  const { id, editable = false, conversion_link } = data;

  const [conversionLinkDialogVisible, setConversionLinkDialogVisible] =
    useState(false);

  const handleShowMenu = () => {
    showMenu({
      title: t("automation.scenarios.actions.conversion_link.placeholder"),
      actions: [
        ...conversionLinks.map((conversionLink) => {
          return {
            label: <SmallConversionLinkItem conversionLink={conversionLink} />,
            action: () => handlePick(conversionLink),
          };
        }),
        {
          label: t("conversion_links.create"),
          action: () => setConversionLinkDialogVisible(true),
        },
      ],
    });
  };

  const handlePick = (link) => {
    editAction(id, {
      conversion_link: link,
      options: {
        conversion_link_id: link.id,
      },
    });
  };

  useEffect(() => {
    if (editable) handleShowMenu();
  }, [editable]);

  return (
    <ActionNode
      {...props}
      buttons={[
        {
          icon: PencilSquareIcon,
          onClick: handleShowMenu,
        },
      ]}
      icon={LinkIcon}
    >
      <div className={`relative ${selected || editable ? "z-50" : null}`}>
        {conversion_link ? (
          <ConversionLinkMessage
            message={{ conversion_link }}
            className={`ring-1 ring-offset-1 ring-indigo-400 ring-opacity-0 hover:ring-opacity-100 ${
              selected ? "ring-opacity-100" : null
            }`}
            preview
          />
        ) : (
          <div
            className="py-3 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl cursor-pointer nodrag opacity-75 hover:opacity-100 transition-opacity flex space-x-2 items-center"
            onClick={handleShowMenu}
          >
            <LinkIcon className="w-4" />
            <div className="text-md font-medium text-center">
              {t("automation.scenarios.actions.conversion_link.placeholder")}
            </div>
          </div>
        )}
      </div>
      {conversionLinkDialogVisible ? (
        <ConversionLinkDialog
          onSubmit={handlePick}
          onClose={() => setConversionLinkDialogVisible(false)}
        />
      ) : null}
    </ActionNode>
  );
}
