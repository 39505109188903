import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import axios from "axios";
import { SubscriptionContext } from "~/contexts/subscription-context";
import headers from "~/utils/headers";
import { UIContext } from "../contexts/ui-context";
import { useTranslation } from "react-i18next";
import { UserContext } from "../contexts/user-context";
import FeatureRequestAccess from "../components/agent/FeatureRequestAccess";

export default function SubscriptionProvider(props) {
  const { organizationId } = props;

  const { t } = useTranslation();

  const { showAlert } = useContext(UIContext);
  const { completeOnboarding } = useContext(UserContext);

  const [subscription, setSubscription] = useState({});

  const loadSubscription = async () => {
    const res = await axios.get("/api/subscription");
    setSubscription(res.data);
    return res.data;
  };

  const startFreeTrial = async () => {
    const res = await axios.post("/api/subscription", {}, headers());
    setSubscription(res.data);
    completeOnboarding(true);
    return res.data;
  };

  const [requestedFeature, requestFeature] = useState(false);

  const redeemCode = async (code) => {
    try {
      const res = await axios.post(
        "/api/subscription/redeem_code",
        { code },
        headers(),
      );
      setSubscription(res.data);
      completeOnboarding(true);
      showAlert({
        title: t("subscription.redeem_code.success_title"),
        message: t("subscription.redeem_code.success_description"),
      });
      return res.data;
    } catch (error) {
      showAlert({
        title: t("subscription.redeem_code.error_title"),
        message: t("subscription.redeem_code.error_description"),
      });
      return false;
    }
  };

  const limitReached = useMemo(() => {
    return subscription.contacts_activated >= subscription.current_tier_maximum;
  }, [subscription]);

  useEffect(loadSubscription, [organizationId]);

  // set booleans for subscription tiers
  const { isFree, isTrial, isPro } = useMemo(() => {
    const isTrial = subscription.subscription_tier == "trial";
    const isPro = subscription.subscription_tier != "free" && !isTrial;
    const isFree = subscription.subscription_tier == "free";
    return { isFree, isTrial, isPro };
  }, [subscription]);

  const limits = useMemo(() => {
    return subscription.subscription_limits || {};
  }, [subscription]);

  const limitedFeatureAlert = useCallback(() => {
    showAlert({
      title: t("subscription.feature_limited_title"),
      message: t("subscription.feature_limited_description"),
      confirm: t("subscription.upgrade_to_pro"),
      onSubmit: () => {
        window.location.href = "/stripe/checkout_session";
      },
    });
  }, [showAlert]);

  const values = {
    subscription,
    isFree,
    isTrial,
    isPro,
    limits,
    limitedFeatureAlert,
    loadSubscription,
    startFreeTrial,
    limitReached,
    redeemCode,
    requestFeature,
  };

  return (
    <SubscriptionContext.Provider value={values}>
      {props.children}
      {requestedFeature && (
        <FeatureRequestAccess
          feature={requestedFeature}
          onClose={() => requestFeature(null)}
        />
      )}
    </SubscriptionContext.Provider>
  );
}
