import React from "react";
import ActionNode from "./ActionNode";
import { useTranslation } from "react-i18next";

export default function BranchOptionNode(props) {
  const { t } = useTranslation();

  const { data } = props;
  const { label } = data;

  return (
    <ActionNode noEdit noWarnings {...props}>
      <div className="space-y-2 flex flex-col items-center">
        <div className="text-3xs uppercase font-bold text-neutral-400">
          {t(label)}
        </div>
      </div>
    </ActionNode>
  );
}
