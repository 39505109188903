export default function searchComments(comments, query = "") {
  if (!query) {
    return comments;
  }

  if (!comments) {
    return [];
  }

  return comments
    .map((comment) => {
      if (commentMatch(comment, query)) {
        return {
          ...comment,
          replies: comment.replies && searchComments(comment.replies, query),
        };
      } else {
        if (!comment.replies) {
          return null;
        }
        const replies = searchComments(comment.replies, query);
        if (replies.length) {
          return {
            ...comment,
            replies,
          };
        }
      }
    })
    .filter(Boolean);
}

function commentMatch(comment, query) {
  const cleanQuery = query.trim().toLowerCase();
  return (
    comment.text?.toLowerCase()?.includes(cleanQuery) ||
    comment.contact?.username?.toLowerCase()?.includes(cleanQuery)
  );
}
