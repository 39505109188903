import AddActionNode from "./nodes/AddActionNode";
import AudioMessageNode from "./nodes/AudioMessageNode";
import CollectEmailNode from "./nodes/CollectEmailNode";
import CommentNode from "./nodes/CommentNode";
import ContentFilterNode from "./nodes/ContentFilterNode";
import ConversionLinkNode from "./nodes/ConversionLinkNode";
import DelayNode from "./nodes/DelayNode";
import EngagementConditionNode from "./nodes/EngagementConditionNode";
import FolderNode from "./nodes/FolderNode";
import HideCommentNode from "./nodes/HideCommentNode";
import HttpRequestNode from "./nodes/HttpRequestNode";
import LikeNode from "./nodes/LikeNode";
import MediaMessageNode from "./nodes/MediaMessageNode";
import MessageNode from "./nodes/MessageNode";
import PaymentLinkNode from "./nodes/PaymentLinkNode";
import BranchExpirationNode from "./nodes/BranchExpirationNode";
import AskQuestionNode from "./nodes/AskQuestionNode";
import AskQuestionOptionNode from "./nodes/AskQuestionOptionNode";
import ScenarioConditionNode from "./nodes/ScenarioConditionNode";
import ShareMediaNode from "./nodes/ShareMediaNode";
import ShopifyNode from "./nodes/ShopifyNode";
import SurveyNode from "./nodes/SurveyNode";
import TriggerNode from "./nodes/TriggerNode";
import TriggerScenarioNode from "./nodes/TriggerScenarioNode";
import UpdatePropertyNode from "./nodes/UpdatePropertyNode";
import WaitForReplyNode from "./nodes/WaitForReplyNode";
import BranchOptionNode from "./nodes/BranchOptionNode";
import CalendlyLinkNode from "./nodes/CalendlyLinkNode";

export default {
  trigger: TriggerNode,
  message: MessageNode,
  audio_message: AudioMessageNode,
  media_message: MediaMessageNode,
  share_media: ShareMediaNode,
  comment: CommentNode,
  like: LikeNode,
  delay: DelayNode,
  folder: FolderNode,
  update_property: UpdatePropertyNode,
  survey: SurveyNode,
  ask_question: AskQuestionNode,
  ask_question_option: AskQuestionOptionNode,
  wait_for_reply: WaitForReplyNode,
  engagement_condition: EngagementConditionNode,
  scenario_condition: ScenarioConditionNode,
  content_filter: ContentFilterNode,
  hide_comment: HideCommentNode,
  conversion_link: ConversionLinkNode,
  checkout_link: ShopifyNode,
  collect_email: CollectEmailNode,
  payment_link: PaymentLinkNode,
  http_request: HttpRequestNode,
  trigger_scenario: TriggerScenarioNode,
  calendly_link: CalendlyLinkNode,
  branch_option: BranchOptionNode,
  branch_expiration: BranchExpirationNode,
  newAction: AddActionNode,
};
