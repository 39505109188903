import React from "react";

import LoginForm from "./LoginForm";
import BottomTooltip from "~/components/utils/BottomTooltip";
import QuoteCarousel from "./QuoteCarousel";

import star from "../../../assets/images/large_star.svg";

export default function LoginPage(props) {
  const { messages, email } = props;

  return (
    <div className="w-screen h-screen flex">
      <div className="w-1/2 h-full hidden lg:flex flex-col items-center justify-center bg-black relative overflow-hidden">
        <img src={star} className="w-[400px] absolute right-0 bottom-0" />
        <QuoteCarousel />
      </div>
      <div className="w-full lg:w-1/2 h-full flex flex-col items-center justify-center p-4 sm:p-10 lg:p-20 overflow-auto">
        <LoginForm email={email} />
      </div>
      {messages.map((message, index) => (
        <BottomTooltip key={index} text={message[1]} />
      ))}
    </div>
  );
}
