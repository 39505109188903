import React, { useContext, useEffect, useState } from "react";
import { PaymentsContext } from "~/contexts/payments-context";
import axios from "axios";
import { unionBy } from "lodash";
import headers from "~/utils/headers";
import { UIContext } from "~/contexts/ui-context";
import { useTranslation } from "react-i18next";
import subscribeToChannel from "~/utils/subscribeToChannel";
import reactNativeMessage from "~/utils/reactNativeMessage";
import { UserContext } from "~/contexts/user-context";

export default function PaymentsProvider(props) {
  const { t } = useTranslation();

  const { showMenu, showPrompt } = useContext(UIContext);
  const { organization } = useContext(UserContext);

  const [loadingPayments, setLoadingPayments] = useState(true);
  const [payments, setPayments] = useState([]);

  const upsertPayments = (newPayments) => {
    setPayments((payments) => unionBy(newPayments, payments, "id"));
  };

  const loadAllPayments = async () => {
    let loadedPayments = await loadPayments();
    let count = loadedPayments.length;
    while (loadedPayments.length > 0) {
      loadedPayments = await loadPayments(count);
      count += loadedPayments.length;
    }
    setLoadingPayments(false);
  };

  const loadPayments = async (offset = null) => {
    const res = await axios.get(`/api/payments?offset=${offset}`);
    upsertPayments(res.data);
    return res.data;
  };

  const initializePayments = () => {
    if (!organization.features.payments) return;
    loadAllPayments();
    subscribeToChannel("PaymentsChannel", (payment) =>
      upsertPayments([payment]),
    );
  };

  useEffect(initializePayments, [organization.id]);

  const createPayment = async (conversationId, amount) => {
    const { data: payment } = await axios.post(
      "/api/payments",
      {
        conversation_id: conversationId,
        amount,
      },
      headers(),
    );
    reactNativeMessage({ track: "key_app_usage" });
    upsertPayments([payment]);
    return payment;
  };

  const paymentsRequest = async (url, ids) => {
    const { data: payments } = await axios.patch(url, { ids }, headers());
    upsertPayments(payments);
    return payments;
  };

  const refreshPayment = async (paymentId) => {
    const { data: payment } = await axios.get(
      `/api/payments/${paymentId}/refresh`,
      headers(),
    );
    upsertPayments([payment]);
    return payment;
  };

  const refundPayments = async (paymentIds) =>
    paymentsRequest(`/api/payments/refund`, paymentIds);

  const revertPayments = async (paymentIds) =>
    paymentsRequest(`/api/payments/revert_to_awaiting`, paymentIds);

  const markAsPaid = async (paymentIds) =>
    paymentsRequest(`/api/payments/mark_as_paid`, paymentIds);

  const cancelPayments = async (paymentIds) =>
    paymentsRequest(`/api/payments/cancel`, paymentIds);

  const archivePayments = async (paymentIds, callback = () => {}) => {
    showPrompt(t("payments.actions.archive_confirm"), async () => {
      paymentsRequest(`/api/payments/archive`, paymentIds);
      callback();
    });
  };

  const showPaymentMenu = (payment, detailsAction = null) => {
    showMenu({
      title: t("payments.actions.title"),
      actions: [
        detailsAction,
        payment.status == "paid"
          ? {
              label: t("payments.actions.refund"),
              action: () => refundPayments([payment.id]),
            }
          : null,
        ["manual"].includes(payment.status)
          ? {
              label: t("payments.actions.revert_to_pending"),
              action: () => revertPayments([payment.id]),
            }
          : null,
        payment.status == "awaiting"
          ? {
              label: t("payments.actions.mark_as_paid"),
              action: () => markAsPaid([payment.id]),
            }
          : null,
        ["awaiting", "attempted", "failed"].includes(payment.status)
          ? {
              label: t("payments.actions.cancel", { count: 1 }),
              action: () => cancelPayments([payment.id]),
              className: "text-red-500",
            }
          : null,
        !payment.archived && {
          label: t("payments.actions.archive", { count: 1 }),
          action: () => archivePayments([payment.id]),
        },
      ],
    });
  };

  const paymentsValues = {
    loadingPayments,
    payments,
    loadAllPayments,
    loadPayments,
    createPayment,
    refreshPayment,
    revertPayments,
    cancelPayments,
    archivePayments,
    markAsPaid,
    showPaymentMenu,
  };

  return (
    <PaymentsContext.Provider value={paymentsValues}>
      {props.children}
    </PaymentsContext.Provider>
  );
}
