import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import ResultsNode from "./ResultsNode";
import { FlowContext } from "../../../../../contexts/flow-context";
import { BoltIcon } from "@heroicons/react/20/solid";

export default function TriggerResultsNode(props) {
  const { t } = useTranslation();

  const { triggers } = useContext(FlowContext);

  return (
    <ResultsNode {...props}>
      <div className="pt-3 px-4 pb-4 bg-white border-2 border-automation rounded-2xl nodrag flex flex-col space-y-3">
        {triggers.map((trigger) => (
          <div className="py-4 px-4 bg-white border-2 border-automation border-dashed rounded-2xl">
            <div className="flex items-center space-x-1.5">
              <div className="whitespace-nowrap">
                {t(`automation.scenarios.trigger_node.when`)}
              </div>
              <div className="px-1.5 rounded bg-purple-100 text-purple-900 text-sm nodrag flex items-center space-x-1">
                <BoltIcon className="w-4 text-purple-500" />
                <div className="font-medium pb-0.5 whitespace-nowrap">
                  {t(
                    `automation.scenarios.trigger_type.${trigger.trigger_type}.label`,
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </ResultsNode>
  );
}
