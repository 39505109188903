import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import classNames from "~/utils/classNames";

export default function CheckCircle({
  checked,
  className = "",
  onClick = () => {},
}) {
  return (
    <button
      className={classNames(
        "w-5 h-5 flex-shrink-0 border rounded-full flex items-center justify-center border-primary",
        checked ? "bg-primary" : "bg-transparent",
        className,
      )}
      onClick={onClick}
    >
      {checked && <CheckIcon className="w-2/3 h-2/3 text-white" />}
    </button>
  );
}
