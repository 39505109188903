import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Badge from "~/components/elements/Badge";
import classNames from "~/utils/classNames";
import InlineTooltip from "../shared/InlineTooltip";
import { UIContext } from "../../contexts/ui-context";
import { ContactsContext } from "../../contexts/contacts-context";

const colors = {
  pending: "yellow",
  active: "green",
  commented: "blue",
  live_commented: "blue",
  requested: "yellow",
  optout: "red",
  deleted: "gray",
  inactive: "gray",
};

export default function ContactStatus(props) {
  const { t } = useTranslation();

  const { showMenu, showPrompt } = useContext(UIContext);

  const { updateContact } = useContext(ContactsContext);

  const { contact } = props;
  const { status } = contact;

  const canEdit = [
    "active",
    "commented",
    "live_commented",
    "requested",
    "optout",
    "spam",
  ].includes(status);

  const handleOptout = () => {
    showPrompt(t("contacts.status.edit_optout"), () => {
      updateContact({ id: contact.id, status: "optout" });
    });
  };

  const handleSpam = () => {
    showPrompt(t("contacts.status.edit_spam"), () => {
      updateContact({ id: contact.id, status: "spam" });
    });
  };

  const handleActive = () => {
    showPrompt(t("contacts.status.edit_active"), () => {
      updateContact({ id: contact.id, status: "active" });
    });
  };

  const handleEdit = () => {
    let actions = [];
    if (
      ["active", "commented", "live_commented", "requested"].includes(status)
    ) {
      actions = [
        {
          label: t("contacts.status.edit_optout"),
          action: handleOptout,
        },
        {
          label: t("contacts.status.edit_spam"),
          action: handleSpam,
        },
      ];
    }
    if (["optout", "spam"].includes(status)) {
      actions = [
        {
          label: t("contacts.status.edit_active"),
          action: handleActive,
        },
      ];
    }
    showMenu({
      title: t("contacts.status.edit_status"),
      actions,
    });
  };

  if (!status) return null;

  return (
    <Badge
      color={colors[status]}
      label={t(`contacts.status.${status}.label`)}
      className={classNames("pr-1.5 select-none", canEdit && "cursor-pointer")}
      onClick={handleEdit}
    >
      <InlineTooltip
        align="right"
        position="bottom"
        text={t(`contacts.status.${status}.description`)}
      >
        <QuestionMarkCircleIcon
          className={classNames(
            "w-3 ml-1 flex-shrink-0 cursor-pointer",
            `text-${colors[status]}-500`,
          )}
        />
      </InlineTooltip>
    </Badge>
  );
}

// :pending, # default state when created
// :active, # can receive DMs
// :commented, # commented on a post but hasn't received a DM yet
// :live_commented, # commented on a live but hasn't received a DM yet
// :requested, # received a first DM request but hasn't accepted yet
// :optout, # has manually opted out from DMs
// :deleted, # account deleted
// :inactive # cannot receive DMs (e.g. blocked or still in requested after 3 attempts)
