import React from "react";
import { useTranslation } from "react-i18next";
import EmptyInbox from "../inbox/EmptyInbox";
import FullScreen from "~/components/utils/FullScreen";

export default function ComingSoon() {
  const { t } = useTranslation();
  return (
    <FullScreen className="flex flex-col items-center justify-center sm:h-screen sm:pl-20">
      <EmptyInbox
        emoji="🧱"
        title={t("shared.coming_soon")}
        caption={t("shared.coming_soon_text")}
      />
    </FullScreen>
  );
}
