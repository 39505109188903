import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import React from "react";

export default function TableHeader(props) {
  const { columns, sortColumn, sortDirection } = props;

  return (
    <thead className="bg-highlight sticky top-0 z-20 divide-default divide-y">
      <tr className="divide-x">
        {columns.map((column) => (
          <th
            key={column.label}
            scope="col"
            className={`group px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-gray-900 ${column.className} ${column.sort && "cursor-pointer"}`}
            onClick={() => column.sort && column.sort()}
          >
            <span className="flex">
              {column.label}
              {column.sort ? (
                <span
                  className={`ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible ${column.name !== sortColumn && "transition-opacity opacity-0 group-hover:opacity-100 group-focus:opacity-100 "} ${column.name === sortColumn ? "opacity-100" : ""}`}
                >
                  {sortDirection == "desc" ? (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              ) : null}
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
}
