import React, { useState } from "react";
import { PlayIcon } from "@heroicons/react/24/solid";
import FullscreenAttachments from "../inbox/FullscreenAttachments";

const scaledWidth = 224;
const defaultHeight = 224;

export default function VideoMessage(props) {
  const {
    url,
    preview_url,
    dimensions,
    className = "",
    clickable = false,
  } = props;

  const [loaded, setLoaded] = useState(false);

  const [fullscreen, setFullscreen] = useState(false);

  const scaledHeight = dimensions
    ? (dimensions.height * scaledWidth) / dimensions.width
    : defaultHeight;

  return (
    <div
      className={`rounded-3xl max-w-2.5xs overflow-hidden relative`}
      onClick={() => clickable && setFullscreen(true)}
    >
      {!loaded && (
        <div
          className="bg-neutral-100 animate-pulse"
          style={{
            width: scaledWidth + "px",
            height: scaledHeight + "px",
          }}
        />
      )}
      <img
        src={preview_url}
        className={`w-full h-auto ${loaded ? "" : "hidden"}`}
        width={scaledWidth}
        height={scaledHeight}
        onLoad={() => setLoaded(true)}
      />
      {loaded && (
        <PlayIcon
          className="w-12 h-12 text-white absolute inset-0 m-auto drop-shadow-md cursor-pointer"
          onClick={() => setFullscreen(true)}
        />
      )}
      {fullscreen && (
        <FullscreenAttachments
          media={[
            {
              url: url,
              type: "video",
            },
          ]}
          onClose={() => setFullscreen(false)}
        />
      )}
    </div>
  );
}
