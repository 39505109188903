import React, { useEffect, useState } from "react";
import { loadComments } from "../../api/inbox/comments";
import Loader from "../utils/Loader";
import CommentItem from "../comments/CommentItem";

export default function ContactComments(props) {
  const { contactId } = props;

  const [comments, setComments] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    if (!contactId) return;
    setLoading(true);
    const res = await loadComments({ contact_id: contactId });
    setComments(res);
    setLoading(false);
  }, [contactId]);

  return (
    <div className="p-3 flex-grow overflow-hidden">
      {loading && (
        <div className="p-4 flex items-center justify-center">
          <Loader width={28} strokeWidth={8} />
        </div>
      )}
      <div className="flex-grow flex flex-col space-y-3 pb-3 overflow-auto">
        {comments.map((comment) => (
          <CommentItem
            key={comment.id}
            comment={comment}
            setComment={(newComment) => {
              setComments((prev) =>
                prev.map((c) =>
                  c.id === comment.id ? { ...comment, ...newComment } : c,
                ),
              );
            }}
          />
        ))}
      </div>
    </div>
  );
}
