import React from "react";
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from "@xyflow/react";

export default function NormalEdge(props) {
  const { markerEnd } = props;

  const [edgePath] = getSmoothStepPath(props);

  return (
    <BaseEdge
      path={edgePath}
      markerEnd={markerEnd}
      className="bg-medium-gray hover:bg-dark-gray"
    />
  );
}
