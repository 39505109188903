import React from "react";
import { useTranslation } from "react-i18next";

export default function ActionTitle(props) {
  const { action } = props;

  const { t } = useTranslation();

  if (!action)
    return (
      <span className="italic text-muted">
        {t("automation.scenarios.actions.not_found.title")}
      </span>
    );

  if (action.action_type == "folder") {
    return action.options.folder_action === "remove"
      ? t("automation.scenarios.actions.folder.remove")
      : t("automation.scenarios.actions.folder.add");
  } else {
    return t(`automation.scenarios.actions.${action.action_type}.title`);
  }
}
