import React from "react";
import RelativeDate from "~/components/utils/RelativeDate";
import { DateTime } from "luxon";

export default function MessageDateSeparator(props) {
  const { previous, current } = props;

  const previousDate = previous ? DateTime.fromISO(previous.received_at) : null;
  const currentDate = DateTime.fromISO(current.received_at);

  if (previousDate?.hasSame(currentDate, "day")) {
    return null;
  } else {
    return (
      <div className="text-center p-4 text-2xs text-dark-gray">
        <RelativeDate date={currentDate} includeTime />
      </div>
    );
  }
}
