import { LinkIcon } from "@heroicons/react/20/solid";
import React from "react";

export default function SmallConversionLinkItem(props) {
  const { conversionLink } = props;

  const { title, icon_url } = conversionLink;

  return (
    <div className="flex items-center space-x-2">
      {icon_url ? (
        <img src={icon_url} className="w-5 object-center object-contain" />
      ) : (
        <div className="p-1">
          <LinkIcon className="w-3 text-darker-gray" />
        </div>
      )}
      <div className="text-md font-medium">{title}</div>
    </div>
  );
}
