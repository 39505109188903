import React, { useState } from "react";
import Button from "../../../elements/Button";
import { useTranslation } from "react-i18next";

export default function OauthTutorial(props) {
  const { onClose } = props;

  const { t } = useTranslation();

  const slidesLength = 5;

  const [activeSlide, setActiveSlide] = useState(0);

  const next = () => {
    if (activeSlide < slidesLength - 1) {
      setActiveSlide(activeSlide + 1);
    } else {
      onClose();
    }
  };

  const prev = () => {
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
    }
  };

  return (
    <div className="flex flex-col divide-y">
      <div className="p-3">
        <img
          src={`/images/oauth_tutorial_${activeSlide + 1}_en.png`}
          className="w-[600px] h-auto cursor-pointer"
          onClick={next}
        />
      </div>
      <div className="flex justify-between p-3 space-x-4">
        {activeSlide === 0 ? (
          <Button label={t("shared.close")} onClick={onClose} />
        ) : (
          <Button label={t("shared.previous")} onClick={prev} />
        )}
        {activeSlide < slidesLength - 1 ? (
          <Button label={t("shared.next")} onClick={next} style="primary" />
        ) : (
          <Button label={t("shared.close")} onClick={onClose} style="primary" />
        )}
      </div>
    </div>
  );
}
