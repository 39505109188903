import {
  CalendarDaysIcon,
  PaperAirplaneIcon,
  ArrowTopRightOnSquareIcon,
  UsersIcon,
  UserGroupIcon,
  FolderIcon,
  FunnelIcon,
  ExclamationCircleIcon,
  FireIcon,
  ChatBubbleLeftIcon,
  MagnifyingGlassIcon,
  EyeIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { delay, every } from "lodash";
import { DateTime } from "luxon";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Panel } from "@xyflow/react";
import { Link } from "react-router-dom";

import { CampaignsContext } from "~/contexts/campaigns-context";
import { ContactsContext } from "~/contexts/contacts-context";
import { DirtyContext } from "~/contexts/dirty-context";
import { UIContext } from "~/contexts/ui-context";
import { UserContext } from "~/contexts/user-context";
import { SubscriptionContext } from "~/contexts/subscription-context";

import Flow from "../automation/flow/Flow";
import FlowWrapper from "../automation/flow/FlowWrapper";
import validateAction from "../automation/flow/validateAction";
import Button from "../elements/Button";
import Input from "../elements/Input";
import Toggle from "../shared/Toggle";
import CalendarModal from "../settings/CalendarModal";
import Table from "../elements/table/Table";
import ContactPicture from "../shared/ContactPicture";
import InputCheckbox from "~/components/elements/InputCheckbox";
import InputSelect from "../elements/InputSelect";
import ButtonWithDropdown from "../elements/ButtonWithDropdown";
import ContactPickerDialog from "../contacts/ContactPickerDialog";
import GroupedLinesSelect from "../elements/GroupedLinesSelect";
import LinesSelect from "../elements/LinesSelect";
import { getContacts } from "../../api/contacts/contacts";
import ContactsDrawer from "../contacts/ContactsDrawer";
import SkeletonLoader from "../utils/SkeletonLoader";
import InvisibleInput from "../utils/InvisibleInput";

export default function EditCampaign(props) {
  const { t } = useTranslation();

  const { initialValues, onSend = () => {}, handleClose } = props;

  const isNew = !initialValues?.id;

  const { showAlert } = useContext(UIContext);
  const { user, organization } = useContext(UserContext);
  const { segments, loadContacts, loadContact } = useContext(ContactsContext);
  const {
    loadCampaign,
    saveCampaign,
    testCampaign,
    sendCampaign,
    scheduleCampaign,
    deleteCampaign,
  } = useContext(CampaignsContext);
  const { limits, limitReached, limitedFeatureAlert } =
    useContext(SubscriptionContext);

  const { dirty, setDirty } = useContext(DirtyContext);

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sending, setSending] = useState(false);
  const [campaign, setCampaign] = useState({ ...initialValues });

  const [rules, setRules] = useState([]);

  useEffect(() => {
    if (campaign.campaign_rules) {
      setRules(campaign.campaign_rules);
    } else if (initialValues.campaign_rules) {
      setRules(initialValues.campaign_rules);
    }
  }, [initialValues.campaign_rules, campaign.campaign_rules]);

  const handleCampaignChange = (field) => (value) => {
    setCampaign({ ...campaign, [field]: value });
  };

  const [contacts, setContacts] = useState(initialValues.contacts ?? []);

  const handleLoadContact = async (id) => {
    const res = await loadContact(id);
    setContacts((contacts) =>
      contacts.map((contact) =>
        contact.id === id ? { ...contact, ...res } : contact,
      ),
    );
  };

  useEffect(async () => {
    if (campaign.selected_contact_ids?.length > 0) {
      const res = await loadContacts({
        ids: campaign.selected_contact_ids,
      });
      setContacts(res.contacts);
    }
  }, [campaign.selected_contact_ids]);

  // Contacts count

  const [allContactsCount, setAllContactsCount] = useState(0);
  useEffect(async () => {
    const res = await getContacts({ count_only: true, sendable: true });
    setAllContactsCount(res.total_count);
  }, []);

  const [contactsCount, setContactsCount] = useState();
  const [loadingContactsCount, setLoadingContactsCount] = useState(true);

  const getContactsCount = async () => {
    if (!campaign.loaded && campaign.id) return;

    setLoadingContactsCount(true);
    const res = await getContacts({
      count_only: true,
      sendable: true,
      campaign: {
        options: campaign.options,
        campaign_rules_attributes: rules.map((r) => ({ ...r, id: null })),
      },
    });
    setContactsCount(res.total_count);
    setLoadingContactsCount(false);
  };

  useEffect(getContactsCount, [campaign.options, rules]);

  // Contacts drawer
  const [contactsDrawer, setContactsDrawer] = useState(null);
  const previewContacts = useCallback(() => {
    setContactsDrawer({
      params: {
        campaign: {
          options: campaign.options,
          campaign_rules_attributes: rules.map((r) => ({ ...r, id: null })),
        },
      },
    });
  }, [campaign, rules]);

  // Actions
  const [initialActions, setInitialActions] = useState(
    initialValues.actions ?? [],
  );
  const [actions, setActions] = useState(initialValues.actions ?? []);
  const resetActions = () => {
    setActions(initialActions);
    setDirty(false);
  };

  // Save

  const isSaveValid = useMemo(
    () => every([campaign.title?.length > 0, actions?.every(validateAction)]),
    [campaign, actions],
  );

  const handleSave = async () => {
    if (!isSaveValid) return;

    setSaving(true);

    const res = await saveCampaign(campaignPayload);

    setSaving(false);
    setCampaign(res);
    setInitialActions(res.actions);
    setActions(res.actions);
    setDirty(false);
  };
  // Test campaign

  const [testDialog, setTestDialog] = useState(false);

  const handleTest = async (contactId) => {
    if (!isSaveValid) return;

    setSaving(true);
    const res = await testCampaign(campaignPayload, contactId);
    setSaving(false);
    setCampaign(res);
    setInitialActions(res.actions);
    setActions(res.actions);
    setDirty(false);

    // delay to wait for the previous alert to close
    delay(() => showAlert(t("campaigns.test_sent")), 500);
  };

  // Send

  const isSendValid = useMemo(
    () =>
      every([
        campaign.title?.length > 0,
        campaign.contacts?.length > 0 || contactsCount > 0,
        actions?.length > 0,
        actions?.every(validateAction),
      ]),
    [campaign, actions, contactsCount],
  );

  const campaignPayload = useMemo(() => {
    const contactIds = campaign.contacts?.map((c) => c.id);
    return {
      ...campaign,
      actions,
      selected_contact_ids: contactIds,
      campaign_rules_attributes: rules,
    };
  }, [campaign, actions, rules]);

  const handleSend = () => {
    if (!isSendValid) return;

    showAlert({
      title: t("campaigns.send_campaign_confirm"),
      message: t("campaigns.send_campaign_contact", { count: contactsCount }),
      onSubmit: async () => {
        setSending(true);
        sendCampaign(campaignPayload);

        setSending(false);
        setDirty(false);

        onSend();

        handleClose();
      },
    });
  };

  // Schedule

  const [scheduleDialog, setScheduleDialog] = useState(false);

  const showScheduleDialog = useCallback(() => {
    if (limits.features?.includes("schedule_campaigns")) {
      limitedFeatureAlert();
    } else {
      setScheduleDialog(true);
    }
  }, [limits, setScheduleDialog]);

  const handleSchedule = (date) => {
    if (!isSaveValid) return;

    setLoading(true);
    scheduleCampaign(campaignPayload, date);

    setLoading(false);
    handleClose();
  };

  const secondaryActions = useMemo(
    () => [
      {
        label: t("campaigns.send_test"),
        icon: ChatBubbleLeftIcon,
        onClick: () => setTestDialog(true),
        disabled: !isSaveValid,
      },
      {
        label: t("campaigns.schedule_campaign"),
        icon: CalendarDaysIcon,
        onClick: showScheduleDialog,
        disabled: !isSendValid,
      },
    ],
    [setScheduleDialog, isSendValid],
  );

  const renderOptions = useCallback(
    () => (
      <>
        {(user.admin
          ? ["interrupt_on_message", "is_template"]
          : ["interrupt_on_message"]
        ).map((field) => (
          <div className="flex items-center space-x-2" key={field}>
            <Toggle
              value={campaign[field]}
              onChange={handleCampaignChange(field)}
            />
            <div className="text-md">{t("campaigns.settings." + field)}</div>
          </div>
        ))}
        {user.admin && campaign.is_template && (
          <>
            <InputSelect
              placeholder={"Template icon"}
              value={campaign.template_icon}
              onChange={handleCampaignChange("template_icon")}
              options={[
                "funnel",
                "heart",
                "bookmark",
                "chart-bar",
                "light-bulb",
                "megaphone",
                "shopping-bag",
                "sparkles",
                "tag",
                "star",
              ]}
            />
            <Input
              type="textarea"
              placeholder={"Description"}
              value={campaign.template_description}
              onChange={handleCampaignChange("template_description")}
            />
          </>
        )}
      </>
    ),
    [campaign, handleCampaignChange],
  );

  // Load campaign
  useEffect(async () => {
    if (campaign.id) {
      const res = await loadCampaign(campaign.id);
      setCampaign(res);
      setInitialActions(res.actions);
      setActions(res.actions);
      setDestinations(res.campaign_rules);
    }
  }, [campaign.id]);

  const [includedDestinations, setIncludedDestinations] = useState([]);
  const [excludedDestinations, setExcludedDestinations] = useState([]);

  const setDestinations = (campaign_rules) => {
    if (campaign_rules) {
      const inclusions = campaign_rules.filter(
        (r) => r.rule_type === "inclusion",
      );
      const exclusions = campaign_rules.filter(
        (r) => r.rule_type === "exclusion",
      );
      setIncludedDestinations(
        inclusions.map((r) => `${r.list_type}_${r.destination_id}`),
      );
      setExcludedDestinations(
        exclusions.map((r) => `${r.list_type}_${r.destination_id}`),
      );
    }
  };

  const updateDestinations = (destinations, ruleType, setDestinations) => {
    setDestinations(destinations);
    const newRules = destinations.map((d) => {
      const [type, id] = d.split("_");
      const destination = {
        list_type: type,
        destination_id: id,
        rule_type: ruleType,
      };
      // Avoid duplicates by passing the id in the nested params
      const existingRule = rules.find(
        (r) =>
          r.destination_id == id &&
          r.list_type == type &&
          r.rule_type == ruleType,
      );
      if (existingRule) {
        destination.id = existingRule.id;
      }
      return destination;
    });
    // Handle deletions
    rules.forEach((r) => {
      if (
        r.rule_type === ruleType &&
        !destinations.includes(`${r.list_type}_${r.destination_id}`)
      ) {
        newRules.push({
          id: r.id,
          destination_id: r.destination_id,
          list_type: r.list_type,
          rule_type: ruleType,
          _destroy: true,
        });
      }
    });
    const otherRules = rules.filter((r) => r.rule_type !== ruleType);
    setRules([...otherRules, ...newRules]);
  };

  const updateIncludedDestinations = (destinations) => {
    updateDestinations(destinations, "inclusion", setIncludedDestinations);
  };

  const updateExcludedDestinations = (destinations) => {
    updateDestinations(destinations, "exclusion", setExcludedDestinations);
  };

  const resetDestinations = () => {
    updateIncludedDestinations([]);
  };

  const destinations = useMemo(
    () => [
      {
        options: organization.folders.map((f) => ({
          title: f.name,
          value: `folder_${f.id}`,
          contactCount: f.count,
          description: t("campaigns.destinations.contacts_count", {
            count: f.count,
          }),
        })),
        icon: FolderIcon,
        label: t("campaigns.destinations.folders.title"),
        value: "folder",
        multiple: true,
        searchable: organization.folders.length > 3,
      },
      {
        options: segments.map((segment) => ({
          title: segment.title,
          value: `segment_${segment.id}`,
          contactCount: segment.count,
          description: t("campaigns.destinations.contacts_count", {
            count: segment.count,
          }),
        })),
        icon: FunnelIcon,
        label: t("campaigns.destinations.segments.title"),
        value: "segment",
        multiple: true,
        searchable: segments.length > 3,
      },
    ],
    [
      segments,
      organization.folders,
      includedDestinations,
      excludedDestinations,
    ],
  );

  // Contacts
  const contactColumns = useMemo(
    () => [
      {
        label: t("contacts.columns.username"),
        name: "username",
        accessor: (contact) => (
          <div className="flex items-center font-medium text-black">
            <ContactPicture
              contact={contact}
              className="w-6 h-6 mr-2"
              linkToProfile
            />
            {contact.username}
            <Link
              to={
                contact.conversation_id &&
                `/inbox/conversations/${contact.conversation_id}`
              }
            >
              <ArrowTopRightOnSquareIcon className="w-4 ml-1 text-dark-gray hover:text-darker-gray" />
            </Link>
          </div>
        ),
      },
      {
        label: t("contacts.columns.name"),
        name: "name",
        accessor: (contact) => contact.real_name || contact.name,
      },
      {
        label: t("contacts.columns.email"),
        name: "email",
        accessor: (contact) => contact.email,
      },
    ],
    [],
  );

  const [showIncludedDestinations, setShowIncludedDestinations] =
    useState(false);

  useEffect(() => {
    setShowIncludedDestinations(includedDestinations.length > 0);
  }, [includedDestinations]);

  const [showExcludedDestinations, setShowExcludedDestinations] =
    useState(false);

  useEffect(() => {
    setShowExcludedDestinations(excludedDestinations.length > 0);
  }, [excludedDestinations]);

  return (
    <>
      <div className={`w-full flex-grow flex flex-col sm:overflow-hidden`}>
        <div className="p-3 sm:p-4 bg-white border-b flex justify-between">
          <div className="flex items-center space-x-2">
            <PaperAirplaneIcon className="w-6" />
            <InvisibleInput
              name="title"
              placeholder={t("automation.scenarios.title_placeholder")}
              value={campaign?.title}
              onChange={(value) => handleCampaignChange("title")(value)}
              className="text-medium p-1 placeholder:italic border-none outline-none bg-transparent min-w-72"
            />
          </div>
          <Button icon={XMarkIcon} onClick={handleClose} />
        </div>
        <div className="flex-grow sm:grid grid-cols-9 overflow-hidden">
          <div className="flex flex-col col-span-3 flex-grow py-4 px-4 space-y-4 overflow-hidden">
            {contacts?.length > 0 ? (
              <Table
                items={contacts}
                columns={contactColumns}
                onRowRender={(contact) => handleLoadContact(contact.id)}
                defaultPerPage={10}
              />
            ) : (
              <div className="flex-grow flex flex-col overflow-auto space-y-4">
                <div className="flex-shrink-0 space-y-2">
                  <h3 className="text-headings text-medium font-medium">
                    {t("campaigns.send_to")}
                  </h3>
                  <LinesSelect
                    label={"inclusion"}
                    name={"inclusion"}
                    value={
                      includedDestinations.length > 0 ||
                      showIncludedDestinations
                        ? ["pick"]
                        : ["all"]
                    }
                    onChange={(selectedValues) =>
                      selectedValues.includes("all")
                        ? resetDestinations()
                        : setShowIncludedDestinations(true)
                    }
                    className="pl-0"
                    options={[
                      {
                        title: t("campaigns.destinations.all_contacts.title"),
                        description: allContactsCount ? (
                          t("campaigns.destinations.contacts_count", {
                            count: allContactsCount,
                          })
                        ) : (
                          <SkeletonLoader />
                        ),
                        icon: UserGroupIcon,
                        value: "all",
                      },
                      {
                        title: t("campaigns.destinations.pick_contacts.title"),
                        icon: MagnifyingGlassIcon,
                        value: "pick",
                      },
                    ]}
                  />
                </div>
                {showIncludedDestinations && (
                  <div className="pl-12">
                    <GroupedLinesSelect
                      onChange={updateIncludedDestinations}
                      groups={destinations}
                      value={includedDestinations}
                      name="includedDestinations"
                    />
                  </div>
                )}
                <div className="px-3 py-3 border rounded-lg mt-8 space-y-4">
                  <div
                    className="flex space-x-1 cursor-pointer"
                    onClick={() =>
                      setShowExcludedDestinations(!showExcludedDestinations)
                    }
                  >
                    {showExcludedDestinations ? (
                      <ChevronDownIcon className="w-5" />
                    ) : (
                      <ChevronRightIcon className="w-5" />
                    )}
                    <h3 className="text-headings text-md font-medium">
                      {t("campaigns.dont_send_to")}
                    </h3>
                  </div>
                  {showExcludedDestinations && (
                    <GroupedLinesSelect
                      onChange={updateExcludedDestinations}
                      groups={destinations}
                      value={excludedDestinations}
                      name="excludedDestinations"
                    />
                  )}
                </div>
              </div>
            )}
            <div className="bg-white flex-shrink-0 space-y-4 py-4">
              <InputCheckbox
                label={t("campaigns.exclude_contacts_time")}
                value={campaign?.options?.exclude_time ?? false}
                onClick={() =>
                  setCampaign((campaign) => ({
                    ...campaign,
                    options: {
                      ...campaign.options,
                      exclude_time: !campaign?.options?.exclude_time,
                    },
                  }))
                }
              />

              {/* Contacts count */}

              <div className="p-3 rounded-lg bg-light-gray space-y-3">
                <div className="flex items-center space-x-2">
                  <UsersIcon className="w-4 flex-shrink-0" />
                  <div className="text-md">
                    {t("campaigns.contacts_count_estimation")}{" "}
                    <span className="font-semibold">
                      {t("campaigns.contacts_count", {
                        count: contactsCount || 0,
                      })}
                    </span>
                  </div>
                </div>
                <Button
                  onClick={previewContacts}
                  label={t("contacts.drawer.view_contacts")}
                  size="extra-small"
                  className="ml-7"
                />
              </div>

              {limitReached && (
                <div className="bg-warning flex items-start space-x-2 p-4 rounded-lg">
                  <ExclamationCircleIcon className="w-5 flex-shrink-0" />
                  <div className="space-y-2">
                    <div className="text-md">
                      {t("campaigns.limit_reached_warning")}
                    </div>
                    <Button
                      size="small"
                      icon={FireIcon}
                      label={t("subscription.upgrade_to_pro")}
                      href="/stripe/checkout_session"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-span-6 border-l">
            <FlowWrapper>
              <Flow actions={actions} setActions={setActions}>
                <Panel
                  position="top-left"
                  className="hidden sm:block p-4 rounded-lg bg-white border space-y-3"
                >
                  {renderOptions()}
                </Panel>
                <Panel
                  position={"bottom-right"}
                  className="hidden sm:flex space-x-2"
                >
                  {dirty && (
                    <Button
                      label={t("shared.cancel_changes")}
                      onClick={resetActions}
                    />
                  )}
                </Panel>
              </Flow>
            </FlowWrapper>
          </div>
        </div>
        <div className="border-t p-4 flex-shrink-0 flex flex-col sm:flex-row items-center justify-between">
          <div className="flex space-x-3">
            <Button label={t("shared.cancel")} onClick={handleClose} />
            {isNew ? null : (
              <Button
                label={t("shared.delete")}
                style="danger"
                onClick={() => deleteCampaign(campaign.id)}
              />
            )}
          </div>
          <div className="flex space-x-3">
            <Button
              label={t("campaigns.save_as_draft")}
              loading={saving}
              disabled={!isSaveValid}
              onClick={handleSave}
            />
            <ButtonWithDropdown
              icon={PaperAirplaneIcon}
              label={t("campaigns.send_campaign")}
              style="primary"
              onClick={handleSend}
              disabled={!isSendValid || saving}
              loading={sending}
              actions={secondaryActions}
              direction="top"
            />
            {/* <Button
              icon={PaperAirplaneIcon}
              label={t("campaigns.send_campaign")}
              style="primary"
              onClick={handleSend}
              disabled={!isSendValid}
              loading={loading}
            />
            <Button
              icon={CalendarDaysIcon}
              label={t("campaigns.schedule_campaign")}
              onClick={() => setScheduleDialog(true)}
              disabled={!isSendValid}
              loading={loading}
            /> */}
          </div>
        </div>
      </div>
      {testDialog && (
        <ContactPickerDialog
          confirmPrompt={t("campaigns.send_test_confirm")}
          onClose={() => setTestDialog(false)}
          onChange={handleTest}
        />
      )}
      {scheduleDialog && (
        <CalendarModal
          title={t("campaigns.schedule_campaign")}
          confirmLabel={t("campaigns.schedule_campaign")}
          minDate={DateTime.now().toISO()}
          initialDate={
            campaign?.scheduled_at ||
            DateTime.now()
              .plus({ days: 1 })
              .set({ hour: 8, minute: 30, second: 0 })
              .toISO()
          }
          includeTime
          onConfirm={handleSchedule}
          onClose={() => setScheduleDialog(false)}
        />
      )}
      {contactsDrawer && (
        <ContactsDrawer
          {...contactsDrawer}
          onClose={() => setContactsDrawer(null)}
        />
      )}
    </>
  );
}
