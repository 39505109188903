export const KEYBOARD_OPEN_SPEED = 250;

// /!\ DON'T FORGET TO ALSO CHANGE config/initializers/constants.rb /!\

export const MAX_MESSAGE_BYTE_SIZE = 1000;

export const MIN_PAYMENT_AMOUNT = 1;
export const DEFAULT_FEE = 0.02;
export const MIN_FEE = 0.5;
export const FEE_ROUNDING = 0.1;

export const REACTION_EMOJIS = ["😂", "😮", "😍", "🥲", "👏", "🔥", "🎉", "💯"];
