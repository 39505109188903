import React, { useState } from "react";
import classNames from "~/utils/classNames";

export default function CardsRadio(props) {
  const { label, value, onChange = () => {}, options = [] } = props;

  const [selected, setSelected] = useState(value);

  const handleClick = (option) => {
    setSelected(option.value);
    onChange(option.value);
  };

  const gridColsClass = {
    1: "sm:grid-cols-3",
    2: "sm:grid-cols-3",
    3: "sm:grid-cols-3",
    4: "sm:grid-cols-4",
    5: "sm:grid-cols-5",
    6: "sm:grid-cols-3",
  }[options.length];

  return (
    <div className="space-y-1">
      {label ? (
        <div className="text-base font-medium leading-5 text-headings">
          {label}
        </div>
      ) : null}
      <div className={classNames("grid grid-cols-1 gap-4", gridColsClass)}>
        {options.map((option) => (
          <div key={option.value}>
            <div
              className={classNames(
                option.value === selected &&
                  "border-primary ring-1 ring-primary",
                "relative text-left cursor-pointer border rounded-lg bg-white p-4 shadow-sm focus:outline-none flex items-center space-x-2",
              )}
              onClick={() => handleClick(option)}
            >
              <input
                type="radio"
                checked={option.value === selected}
                readOnly
                className="text-primary"
              />
              <div className="block text-sm font-medium text-gray-900">
                {option.label}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
