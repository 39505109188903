import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import ActionNode from "./ActionNode";
import Input from "~/components/elements/Input";
import FolderPickerField from "~/components/fields/FolderPickerField";
import ContactPropertyPickerField from "../../../fields/ContactPropertyPickerField";
import InputSelect from "../../../elements/InputSelect";

const conditionTypes = [
  "is_follower",
  "is_in_folder",
  "contact_property",
  "message_contains",
];
const conditionOptions = ["emojis", "keywords"];
const conditionOperators = ["is_equal", "contains", "is_present"];

export default function ScenarioConditionNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, options } = data;

  const {
    condition_type,
    condition_option,
    condition_operator,
    condition_value,
    folder_id,
    contact_property,
  } = options;

  const { editAction } = useContext(FlowContext);

  // This function generates a function
  const handleChangeAction = (option) => (value) => {
    editAction(id, { options: { ...options, [option]: value } });
  };

  return (
    <ActionNode {...props}>
      <div
        className={`py-3 px-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all space-y-2 ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="text-md font-medium">
          {t("automation.scenarios.actions.scenario_condition.title")}
        </div>
        <div className="text-2sm text-darker-gray">
          {t("automation.scenarios.actions.scenario_condition.description")}
        </div>
        <div className="flex flex-col space-y-3">
          <InputSelect
            value={condition_type}
            onChange={handleChangeAction("condition_type")}
            options={conditionTypes.map((type) => ({
              label: t(
                "automation.scenarios.actions.scenario_condition." + type,
              ),
              value: type,
            }))}
          />
          {condition_type === "is_in_folder" && (
            <div className="flex items-center justify-start space-x-2">
              <div className="text-2sm">
                {t("automation.scenarios.actions.scenario_condition.folder")}
              </div>
              <FolderPickerField
                value={folder_id}
                onChange={handleChangeAction("folder_id")}
              />
            </div>
          )}
          {condition_type === "message_contains" && (
            <>
              <InputSelect
                options={conditionOptions.map((option) => ({
                  label: t(
                    "automation.scenarios.actions.scenario_condition.condition_options." +
                      option,
                  ),
                  value: option,
                }))}
                value={condition_option}
                onChange={handleChangeAction("condition_option")}
              />
              {condition_option == "keywords" && (
                <Input
                  placeholder={t(
                    "automation.scenarios.actions.scenario_condition.message_contains_placeholder",
                  )}
                  value={condition_value}
                  onChange={handleChangeAction("condition_value")}
                  className="flex-grow"
                />
              )}
            </>
          )}
          {condition_type === "contact_property" && (
            <>
              <ContactPropertyPickerField
                value={contact_property}
                onChange={handleChangeAction("contact_property")}
              />
              <InputSelect
                placeholder={t(
                  "contacts.filters.options.operators.placeholder",
                )}
                options={conditionOperators.map((op) => ({
                  label: t("contacts.filters.options.operators." + op),
                  value: op,
                }))}
                value={condition_operator}
                onChange={handleChangeAction("condition_operator")}
              />
              {["is_equal", "contains"].includes(condition_operator) && (
                <Input
                  placeholder={t(
                    "automation.scenarios.actions.scenario_condition.contact_property_value",
                  )}
                  value={condition_value}
                  onChange={handleChangeAction("condition_value")}
                />
              )}
            </>
          )}
        </div>
      </div>
    </ActionNode>
  );
}
