import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutomationContext } from "~/contexts/automation-context";
import ScenarioExecution from "./ScenarioExecution";
import Loader from "~/components/utils/Loader";

export default function ConversationScenarioExecutions(props) {
  const { conversationId } = props;

  const [loading, setLoading] = useState(true);

  const { loadScenarioExecutions } = useContext(AutomationContext);

  const [scenarioExecutions, setScenarioExecutions] = useState([]);

  useEffect(async () => {
    if (!conversationId) return;
    const scenarioExecutions = await loadScenarioExecutions({
      conversation_id: conversationId,
      limit: 20,
    });
    setScenarioExecutions(scenarioExecutions);
    setLoading(false);
  }, [conversationId]);

  return (
    <div className="p-3 flex-grow space-y-3 overflow-auto">
      {loading && (
        <div className="p-4 flex items-center justify-center">
          <Loader width={28} strokeWidth={8} />
        </div>
      )}
      {scenarioExecutions.map((execution, index) => (
        <ScenarioExecution
          key={index}
          execution={execution}
          setExecution={(value) =>
            setScenarioExecutions(
              scenarioExecutions.map((e) =>
                e.id == execution.id ? { ...e, ...value } : e,
              ),
            )
          }
        />
      ))}
    </div>
  );
}
