import { PlusCircleIcon } from "@heroicons/react/20/solid";
import React from "react";

export default function SidebarNavTitle(props) {
  const { title, action, className = "" } = props;

  return (
    <div className={`flex items-center pl-6 pr-1 pt-1.5 pb-1 ${className}`}>
      <div className="uppercase text-2xs text-light-gray font-bold tracking-wide flex-grow pointer-events-none">
        {title}
      </div>
      {action ? (
        <button
          className="p-0.5 opacity-60 transition-opacity hover:opacity-90"
          onClick={action}
        >
          <PlusCircleIcon className="w-4 text-light-gray" />
        </button>
      ) : null}
    </div>
  );
}
