import { ArrowUpTrayIcon, LinkIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UIContext } from "~/contexts/ui-context";
import { UserContext } from "~/contexts/user-context";
import headers from "~/utils/headers";
import ProfileSectionTitle from "../../profile/ProfileSectionTitle";
import PageHeader from "~/components/shared/PageHeader";
import FullScreen from "~/components/utils/FullScreen";
import PaymentLinkPreview from "../PaymentLinkPreview";
import { data } from "currency-codes";
import CountrySelect from "../../forms/CountrySelect";
import LabeledSelect from "../../forms/LabeledSelect";
import ProfileToggle from "../../profile/ProfileToggle";
import InputField from "~/components/shared/InputField";

export default function PaymentGeneralSettings(props) {
  const { back } = props;

  const { organization, setOrganization } = useContext(UserContext);
  const { alert, showAlert } = useContext(UIContext);

  const { t } = useTranslation();

  const screenRef = useRef(null);

  // Country
  const [country, setCountry] = useState(organization.country);

  // Currency
  const [currency, setCurrency] = useState(organization.currency);
  const [supportedCurrencies, setSupportedCurrencies] = useState([]);
  const currencyOptions = data
    .filter((c) => supportedCurrencies.indexOf(c.code) > -1)
    .map((c) => ({
      label: c.code + " - " + c.currency,
      value: c.code,
    }));

  const currencyDisabled = !country || !currencyOptions.length;

  useEffect(() => {
    if (!country) return;
    axios
      .get(`/api/stripe/currencies?country=${country}`, headers())
      .then((res) => {
        if (res.data.error || res.data.currencies.length == 0) {
          showAlert(t("payments.settings.general.country_not_supported"));
        } else {
          setSupportedCurrencies(res.data.currencies);
        }
      });
  }, [country]);

  // Terms
  const [termsActive, setTermsActive] = useState(organization?.terms_active);
  const [termsUrl, setTermsUrl] = useState(organization?.terms_url || "");

  const handleSave = () => {
    if (!country || !currency) {
      showAlert(t("payments.settings.general.validation_error"));
      return;
    }

    setOrganization({
      ...organization,
      country,
      currency,
      terms_url: termsUrl,
      terms_active: termsActive,
      payment_background: selectedBackground,
    });

    const formData = new FormData(imageFormRef.current);

    formData.append("organization[country]", country);
    formData.append("organization[currency]", currency);
    formData.append("organization[terms_url]", termsUrl);
    formData.append("organization[terms_active]", termsActive);
    formData.append("organization[payment_background]", selectedBackground);

    axios
      .patch("/api/organization", formData, headers())
      .then((res) => setOrganization(res.data));
  };

  const handleBack = () => {
    if (
      selectedBackground != organization.payment_background ||
      country != organization.country ||
      currency != organization.currency ||
      termsUrl != organization.terms_url ||
      termsActive != organization.terms_active
    ) {
      showAlert({
        title: t("shared.unsaved_changes"),
        confirm: t("shared.discard_changes"),
        confirmClassName: "text-red-500",
        cancelClassName: "text-black",
        onSubmit: back,
      });
    } else {
      back();
    }
  };

  // Backgrounds

  const backgrounds = ["default", "purple", "yellow", "dark", "custom"];
  const [selectedBackground, setSelectedBackground] = useState(
    organization.payment_background,
  );
  const [customBackground, setCustomBackground] = useState(
    organization.custom_payment_background_url,
  );
  const scrollerRef = useRef(null);
  const handleHorizontalScroll = (evt) => {
    if (!scrollerRef.current) return;

    const scrollOffset = scrollerRef.current.scrollLeft;

    const index = Math.round(scrollOffset / 304);

    setSelectedBackground(backgrounds[index]);
  };
  useEffect(() => {
    if (!scrollerRef.current) return;

    const index = backgrounds.indexOf(organization.payment_background);

    scrollerRef.current.scrollTo({
      left: index * 304,
    });
  }, [organization.payment_background]);

  const imageFormRef = useRef(null);
  const imageInputRef = useRef(null);
  const handleImageChange = () => {
    const input = imageInputRef.current;

    if (input.files.length == 0) {
      input.value = null;
      return;
    }

    const size = Math.round(input.files.item(0).size / 1024);

    if (size > 8192) {
      showAlert({
        title: t("inbox.conversation.max_file_size.image"),
      });
      input.value = null;
      return;
    }

    const fileType = input.files.item(0).type.split("/")[0];

    if (fileType != "image") {
      showAlert({
        title: t("inbox.conversation.invalid_file_type"),
      });
      input.value = null;
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(input.files.item(0));
    reader.onloadend = () => setCustomBackground(reader.result);
  };

  const renderPaymentCard = (background) => {
    return background == "custom" ? (
      <form
        className="flex-shrink-0 snap-center snap-always px-2"
        ref={imageFormRef}
        key={background}
      >
        <label
          className={`w-72 h-72 rounded-2xl overflow-hidden shadow-lg transition-transform duration-300 bg-primary flex items-center justify-center p-4 bg-cover bg-center ${
            background == selectedBackground ? "scale-100" : "scale-95"
          }`}
          style={{
            backgroundImage: customBackground
              ? `url(${customBackground})`
              : null,
          }}
        >
          <div className="w-full max-w-sm flex flex-col items-center justify-center p-4 space-y-4 bg-white rounded-lg">
            <ArrowUpTrayIcon className="w-6" />
            <div className="font-medium text-center text-md">
              {t("payments.settings.general.upload")}
            </div>
          </div>
          <input
            type="file"
            accept="image/*"
            className="hidden"
            name="organization[custom_payment_background]"
            onChange={handleImageChange}
            ref={imageInputRef}
          />
        </label>
      </form>
    ) : (
      <div
        className="flex-shrink-0 snap-center snap-always px-2"
        key={background}
      >
        <div
          className={`rounded-2xl overflow-hidden shadow-lg transition-transform duration-300 ${
            background == selectedBackground ? "scale-100" : "scale-95"
          }`}
        >
          <PaymentLinkPreview
            organization={organization}
            total={50}
            background={background}
            currency={currency}
          />
        </div>
      </div>
    );
  };

  return (
    <FullScreen className="sm:max-w-lg sm:border-r" ref={screenRef}>
      <PageHeader
        title={t("payments.settings.general.title")}
        back={handleBack}
        className="mb-4"
        action={{
          label: t("shared.save"),
          onClick: handleSave,
        }}
      />
      <ProfileSectionTitle
        label={t("payments.settings.title")}
        subtitle={t("payments.settings.general.country_description")}
        className="pb-0"
      />
      {/* Locked info */}
      {organization.stripe_account_id && (
        <div className="px-4 py-2">
          <div className="rounded-lg bg-light-primary text-2sm p-3">
            {t("payments.settings.general.locked")}
          </div>
        </div>
      )}
      <CountrySelect
        label={t("payments.settings.details.country")}
        value={country}
        onChange={setCountry}
        disabled={organization.stripe_account_id}
      />
      <LabeledSelect
        label={t("payments.settings.general.currency")}
        placeholder={t("payments.settings.general.currency_placeholder")}
        disabled={currencyDisabled || organization.stripe_account_id}
        className={currencyDisabled ? "opacity-50" : ""}
        value={currency}
        onChange={setCurrency}
        options={currencyOptions}
        groupOptionsBy={(option) => option.label[0]}
      />
      <ProfileSectionTitle
        label={t("payments.settings.general.payment_card_background")}
        subtitle={t("payments.settings.general.payment_card_background_info")}
      />
      <div
        className="flex items-center overflow-scroll scrollbar-hide snap-x snap-mandatory pb-4"
        style={{
          paddingLeft: screenRef.current
            ? (screenRef.current.clientWidth - 304) / 2
            : 0,
          paddingRight: screenRef.current
            ? (screenRef.current.clientWidth - 304) / 2
            : 0,
        }}
        ref={scrollerRef}
        onScroll={handleHorizontalScroll}
      >
        {backgrounds.map(renderPaymentCard)}
      </div>
      <ProfileToggle
        label={t("calendar.settings.terms.title")}
        description={t("calendar.settings.terms.description")}
        value={termsActive}
        onChange={setTermsActive}
      />
      <div className="px-4 pb-4">
        <InputField
          icon={<LinkIcon className="w-5 h-5 text-neutral-400" />}
          placeholder={t("calendar.settings.terms.input")}
          type="url"
          value={termsUrl}
          onChange={setTermsUrl}
          autoComplete="url"
        />
      </div>
      <div className="h-12" />
    </FullScreen>
  );
}
