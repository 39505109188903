import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "~/components/shared/PageHeader";
import FullScreen from "~/components/utils/FullScreen";
import { UserContext } from "~/contexts/user-context";
import PermissionCheck from "./PermissionCheck";
import ProfileSectionTitle from "../ProfileSectionTitle";
import CheckCircle from "~/components/shared/CheckCircle";

export default function InboxNotificationsPage(props) {
  const { back } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { user, updateNotificationSettings } = useContext(UserContext);

  const [setting, setSetting] = useState(user.notification_settings.messages);

  const handleChange = (newSetting) => {
    setSetting(newSetting);
    updateNotificationSettings("messages", newSetting);
  };

  return (
    <FullScreen>
      <PageHeader
        title={t("notifications.inbox_title")}
        back={back}
        className="mb-4"
      />
      <PermissionCheck />
      <ProfileSectionTitle
        label={t("notifications.inbox_title")}
        subtitle={t("notifications.inbox_description")}
      />
      {["disabled", "first", "less", "all"].map((option) => (
        <div
          className="border-b p-4 flex justify-between"
          key={option}
          onClick={() => handleChange(option)}
        >
          <div className="flex-grow">
            <div className="text-md mb-2">
              {t(`notifications.messages.${option}.label`)}
            </div>
            <div className="text-2sm text-dark-gray">
              {t(`notifications.messages.${option}.description`)}
            </div>
          </div>
          <CheckCircle checked={option == setting} />
        </div>
      ))}
    </FullScreen>
  );
}
