import React from "react";
import SideDrawer from "~/components/shared/SideDrawer";
import ContactPage from "./ContactPage";

export default function ContactDrawer(props) {
  const { contactId, onClose } = props;

  return (
    <SideDrawer onClose={onClose} className="w-full max-w-5xl sm:ml-20">
      <ContactPage contactId={contactId} back={onClose} />
    </SideDrawer>
  );
}
