import { Bars4Icon, BellIcon } from "@heroicons/react/20/solid";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";

import { UserContext } from "~/contexts/user-context";
import { appInset } from "~/utils/environment";
import headers from "~/utils/headers";
import Button from "~/components/elements/Button";
import PageHeader from "~/components/shared/PageHeader";

export default function ManageFolders(props) {
  const { back } = props;

  const { t } = useTranslation();

  const { organization, setOrganization, setEditingFolderId, deleteFolder } =
    useContext(UserContext);

  const [folders, setFolders] = useState(organization.folders);

  const handleSort = async () => {
    setOrganization((prev) => ({
      ...prev,
      folders,
    }));
    const ids = folders.map((folder) => folder.id);
    const res = await axios.patch(
      "/api/inbox/folders/sort",
      { ids },
      headers(),
    );
    setOrganization(res.data);
  };

  return (
    <div
      className={`h-full flex flex-col overflow-hidden`}
      style={{
        paddingTop: appInset.top,
      }}
    >
      <PageHeader title={t("inbox.settings.manage_folders")} back={back} />
      <div className="flex-grow overflow-auto">
        <div className="mt-8 border-b">
          <ReactSortable
            list={folders}
            setList={setFolders}
            direction="vertical"
            onSort={handleSort}
            handle=".handle"
          >
            {folders.map((folder) => (
              <div
                key={folder.id}
                className="flex items-center justify-between p-4 bg-white border-t"
              >
                <div className="handle flex-grow flex items-center space-x-3">
                  <Bars4Icon className="w-5 h-5 text-dark-gray" />
                  <div
                    className={`w-4 h-4 rounded-full bg-${folder.color}-500`}
                  />
                  <div className="text-md">{folder.name}</div>
                  <div
                    className={`ml-1.5 mt-0.5 px-1 py-0.5 text-dark-gray bg-light-gray rounded text-4xs`}
                  >
                    {folder.count}
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <button onClick={() => setEditingFolderId(folder.id)}>
                    <PencilIcon className="w-5 h-5 text-dark-gray" />
                  </button>
                  <button onClick={() => deleteFolder(folder.id)}>
                    <TrashIcon className="w-5 h-5 text-dark-gray" />
                  </button>
                </div>
              </div>
            ))}
          </ReactSortable>
        </div>
        <div className="p-4 mt-8">
          <div className="text-center text-dark-gray text-2sm leading-normal max-w-[280px] mx-auto mb-4">
            {t("inbox.folders.notifications_description")}
          </div>
          <Button
            label={t("notifications.folders.name")}
            icon={BellIcon}
            className="w-full"
            to="/profile/notifications"
          />
        </div>
      </div>
    </div>
  );
}
