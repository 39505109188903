import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";

import { UserContext } from "~/contexts/user-context";

import FullScreen from "~/components/utils/FullScreen";
import Calendar from "./Calendar";

import helpImageEn from "../../../assets/images/calendar_help_en.png";
import helpImageFr from "../../../assets/images/calendar_help_fr.png";

import { Link } from "react-router-dom";
import PageTitle from "~/components/shared/PageTitle";
import CalendarSettings from "./CalendarSettings";
import ScreenSlide from "~/components/utils/ScreenSlide";
import EmptyInbox from "../inbox/EmptyInbox";
import Button from "~/components/elements/Button";
import InboxModalError from "../errors/InboxModalError";

export default function CalendarPage(props) {
  const { t } = useTranslation();

  const { showSettings } = props;

  // Manage calendars
  const connectCalendarRef = useRef();

  return (
    <FullScreen className="flex flex-col overflow-hidden sm:!h-screen">
      <div className="p-4 flex items-center space-x-4 flex-shrink-0">
        <PageTitle text={t("calendar.title")} className="flex-grow" />
        <div ref={connectCalendarRef} className="relative">
          <Link
            to="/calendar/settings"
            className="rounded-lg py-1 font-medium text-2sm"
          >
            {t("shared.settings")}
          </Link>
        </div>
      </div>
      <Calendar eventsActive className="sm:pl-4" />
      {/* Settings */}
      <ScreenSlide visible={showSettings} origin="/calendar">
        <CalendarSettings />
      </ScreenSlide>
    </FullScreen>
  );
}
