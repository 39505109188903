import React, { useEffect, useState } from "react";
import { loadMentions } from "~/api/inbox/mentions";
import Loader from "../utils/Loader";
import MentionItem from "../mentions/MentionItem";

export default function ContactMentions(props) {
  const { contactId } = props;

  const [mentions, setMentions] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    if (!contactId) return;
    setLoading(true);
    const res = await loadMentions({ contact_id: contactId });
    setMentions(res);
    setLoading(false);
  }, [contactId]);

  return (
    <div className="flex-grow overflow-auto">
      {loading && (
        <div className="p-4 flex items-center justify-center">
          <Loader width={28} strokeWidth={8} />
        </div>
      )}
      <div className="divide-y">
        {mentions.map((mention) => (
          <MentionItem mention={mention} key={mention.id} />
        ))}
      </div>
    </div>
  );
}
