import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UIContext } from "~/contexts/ui-context";
import { UserContext } from "~/contexts/user-context";
import i18n from "../../locales/i18n";
import Button from "~/components/elements/Button";

export default function MessagePermission() {
  const { t } = useTranslation();

  const locale = i18n.language.split("-")[0];

  const { showVideo } = useContext(UIContext);
  const { checkPermissions } = useContext(UserContext);

  const handleShowVideo = () => {
    showVideo({
      url:
        locale == "fr"
          ? "/videos/help_message_permission_fr.mp4"
          : "/videos/help_message_permission_en.mp4",
      title: t("inbox.permissions.allow_access"),
    });
  };

  return (
    <div className="space-y-4">
      <div className="font-medium mb-4">
        {t("inbox.permissions.allow_access")}
      </div>
      <p className="text-sm mb-6">{t("inbox.permissions.allow_access_text")}</p>
      <Button
        label={t("inbox.permissions.show_me_how")}
        onClick={handleShowVideo}
        className="w-full"
      />
      <Button
        label={t("inbox.permissions.settings_changed")}
        onClick={checkPermissions}
        style="inverse"
        className="w-full"
      />
    </div>
  );
}
