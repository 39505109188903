import React, { useState } from "react";

export default function LongPress(props) {
  const { onClick = () => {}, onPress = () => {}, onPressUp = () => {}, disabled, className } = props;

  const [pressed, setPressed] = useState(false);

  // actual press action
  const handlePress = () => {
    setPressed(true);
    if (disabled) return;
    onPress();
  };

  // user touches screen
  const handleDown = (evt) => {
    setPressed(false);
    window.clickInterval = setTimeout(handlePress, 500);
  };

  const handleUp = (evt) => {
    evt.preventDefault();
    onPressUp();
    if (!pressed) {
      clearInterval(window.clickInterval);
      onClick();
    }
  };

  const handleLeave = () => {
    clearInterval(window.clickInterval);
  };

  return (
    <div
      className={className}
      onMouseDown={handleDown}
      onTouchStart={handleDown}
      onMouseUp={handleUp}
      onTouchEnd={handleUp}
      onMouseLeave={handleLeave}
    >
      {props.children}
    </div>
  );
}
