import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ContentPickerField from "./ContentPickerField";
import StoryPickerDialog from "./StoryPickerDialog";

/**
 * StoryPickerField component.
 *
 * This component renders a field for picking a story. It uses the ContentPickerField for input and button elements,
 * and opens a StoryPickerDialog when the button is clicked.
 *
 * @component StoryPickerField
 * @param {Object} props - Properties passed to component
 * @param {string} props.value - The current value of the story picker field
 * @param {Function} props.onChange - Function to call when the value changes
 * @returns {ReactNode} - StoryPickerField component
 */
export default function StoryPickerField(props) {
  const { t } = useTranslation();

  const { value = "", onChange } = props;

  const [storyPickerVisible, showStoryPicker] = useState(false);

  return (
    <>
      <ContentPickerField
        value={value}
        onChange={onChange}
        inputPlaceholder={t("content.stories.story_id")}
        buttonPlaceholder={t("content.stories.pick_story")}
        onClick={() => showStoryPicker(true)}
      />
      {storyPickerVisible ? (
        <StoryPickerDialog
          onClose={() => showStoryPicker(false)}
          onChange={onChange}
        />
      ) : null}
    </>
  );
}
