import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import ActionNode from "./ActionNode";
import { MediaFilesContext } from "~/contexts/media-files-context";
import AudioMessageModal from "../../../inbox/AudioMessageModal";
import AudioMessage from "../../../messages/AudioMessage";
import { MicrophoneIcon } from "@heroicons/react/20/solid";
import Loader from "~/components/utils/Loader";
import Button from "~/components/elements/Button";

export default function AudioMessageNode(props) {
  const { t } = useTranslation();

  const { data, selected } = props;
  const { id, editable = false, options } = data;
  const { media_file_id } = options;

  const { editAction } = useContext(FlowContext);
  const { getMedia } = useContext(MediaFilesContext);

  const [mediaFile, setMedia] = useState(null);
  const [audioModalVisible, setAudioModalVisible] = useState(editable);

  useEffect(() => {
    if (media_file_id) getMedia(media_file_id).then(setMedia);
  }, [media_file_id]);

  return (
    <ActionNode {...props}>
      <div className={`relative ${selected || editable ? "z-50" : null}`}>
        {media_file_id ? (
          mediaFile ? (
            <div
              className={`relative rounded-3xl ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all ${selected ? "ring-opacity-100" : ""}`}
            >
              <AudioMessage url={mediaFile.media_url} className="w-40 h-40" />
            </div>
          ) : (
            <div className="py-3 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl cursor-pointer nodrag opacity-75 hover:opacity-100 transition-opacity flex space-x-2 items-center">
              <Loader width={24} strokeWidth={8} />
              <div className="text-md font-medium text-center">
                {t("automation.scenarios.actions.audio_message.loading")}
              </div>
            </div>
          )
        ) : (
          <div
            className={`bg-white rounded-3xl border-2 border-medium-gray border-dashed flex flex-col items-center justify-center p-4 space-y-4 transition-colors hover:border-indigo-500 ${selected ? "z-50 border-indigo-500" : ""}`}
          >
            <Button
              icon={MicrophoneIcon}
              label={t(
                "automation.scenarios.actions.audio_message.placeholder",
              )}
              onClick={() => setAudioModalVisible(true)}
            />
          </div>
        )}
      </div>
      {audioModalVisible && (
        <AudioMessageModal
          onClose={() => setAudioModalVisible(false)}
          onCreate={(mediaFile) => {
            setMedia(mediaFile);
            editAction(id, {
              editable: false,
              options: { media_file_id: mediaFile.id },
            });
          }}
        />
      )}
    </ActionNode>
  );
}
