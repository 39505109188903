import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { UserContext } from "~/contexts/user-context";
import CreateFolder from "./CreateFolder";
import Folder from "./Folder";
import { UIContext } from "~/contexts/ui-context";
import { useNavigate } from "react-router-dom";

export default function Folders(props) {
  const { folders, activeFolder } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const scrollRef = useRef(null);

  const [foldersLength, setFoldersLength] = useState(null);

  const { setCreateFolderDialog, showFolderMenu } = useContext(UserContext);
  const { showMenu } = useContext(UIContext);

  const showFoldersMenu = () => {
    showMenu({
      title: t("inbox.folders.title", { count: folders.length }),
      actions: [
        ...folders.map((folder) => ({
          label: (
            <div className={activeFolder == folder.id ? "font-semibold" : ""}>
              {folder.name}
            </div>
          ),
          action: () => navigate("/inbox/folders/" + folder.id),
        })),
        {
          label: t("inbox.folders.create"),
          action: () => setCreateFolderDialog(true),
        },
      ],
    });
  };

  // Select last folder when new folder is created
  useEffect(() => {
    if (foldersLength && folders?.length > foldersLength) {
      scrollRef.current?.scrollTo(scrollRef.current.scrollWidth, 0);
    }
    setFoldersLength(folders.length);
  }, [folders]);

  // scroll to start when no active folder
  useEffect(() => {
    if (activeFolder == null) {
      scrollRef.current?.scrollTo({ left: 0, behavior: "smooth" });
    }
  }, [activeFolder]);

  return (
    <div className="flex items-stretch sm:hidden">
      <div
        className={`flex flex-grow w-full overflow-x-auto touch-pan-x justify-items-stretch hide-scrollbar`}
        ref={scrollRef}
      >
        <Folder
          label={t("inbox.folders.all")}
          active={activeFolder == null}
          onClick={() => navigate("/inbox/conversations")}
          className="px-5"
          hideCount
        />
        {folders.map((folder) => (
          <Folder
            key={folder.id}
            label={folder.name}
            color={folder.color}
            count={folder.count}
            active={activeFolder == folder.id}
            onClick={() => navigate("/inbox/folders/" + folder.id)}
            onLongPress={() => showFolderMenu(folder)}
          />
        ))}
        <CreateFolder onClick={() => setCreateFolderDialog(true)} />
      </div>
    </div>
  );
}
