import React from "react";

import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import Badge from "~/components/elements/Badge";
import { TrashIcon } from "@heroicons/react/20/solid";
import classNames from "~/utils/classNames";

export default function ConversionLinkMessage(props) {
  const { t } = useTranslation();

  const {
    message,
    preview = false,
    className = "",
    onImageRemove,
    editable = false,
    children,
  } = props;

  const { conversion_link, clicked_link_at, template } = message;

  if (!conversion_link && !template)
    return (
      <div
        className={`rounded-3xl px-8 py-5 max-w-sm text-center text-sm text-neutral-400 overflow-ellipsis overflow-hidden select-none bg-white border`}
      >
        {t("conversion_links.preview.missing")}
      </div>
    );

  const title = template?.title || conversion_link.title;
  const subtitle = template?.subtitle || conversion_link.subtitle;
  const button_title = template?.button_title || conversion_link.button_title;

  return (
    <div
      className={`w-72 h-auto rounded-3xl bg-white border select-none overflow-hidden flex flex-col ${className}`}
    >
      {(conversion_link?.image || conversion_link?.image_url) && (
        <div className="w-72 h-72 bg-light relative">
          <img
            src={conversion_link?.image || conversion_link?.image_url}
            className="w-full h-full object-cover object-center"
          />
          {editable && (
            <Button
              icon={TrashIcon}
              onClick={onImageRemove}
              size="small"
              className="absolute bottom-3 right-3"
            />
          )}
        </div>
      )}
      <div className="p-4 space-y-2">
        <div className="flex items-center justify-between space-x-2">
          <div className="text-md">
            {title || t("conversion_links.fields.title")}
          </div>
          {preview ? null : clicked_link_at ? (
            <Badge label={t("shared.clicked")} color="green" />
          ) : (
            <Badge label={t("shared.not_clicked")} color="gray" />
          )}
        </div>
        {subtitle && <div className="text-sm text-neutral-500">{subtitle}</div>}
        <Button
          label={button_title || t("conversion_links.fields.button_title")}
          className={classNames(
            "block w-full",
            preview ? "pointer-events-none" : "",
          )}
          style="soft"
          href={conversion_link?.url}
        />
        {children}
      </div>
    </div>
  );
}
