import axios from "axios";

const getScenarioResults = async (scenarioId) => {
  const response = await axios.get(
    `/api/automation/scenarios/${scenarioId}/results`,
  );
  return response.data;
};

export { getScenarioResults };
