import React, { useRef } from "react";
import EmojiPicker from "emoji-picker-react";
import useClickOutside from "../../hooks/useClickOutside";
import { createPortal } from "react-dom";
import Modal from "./Modal";

export default function CustomEmojiPicker(props) {
  const { onChange, onClose } = props;

  const handleEmojiClick = (res) => {
    onChange(res.emoji);
    onClose();
  };

  return createPortal(
    <Modal onClose={onClose}>
      <EmojiPicker
        searchDisabled
        skinTonesDisabled
        onEmojiClick={handleEmojiClick}
      />
    </Modal>,
    document.body,
  );
}
