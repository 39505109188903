import React from "react";

export default function TableCellCheckbox(props) {
  const { checked, className, ...passthroughProps } = props;

  return (
    <label
      className={`flex-shrink-0 inset-0 transition-colors w-full h-full flex items-center justify-center ${className} h-full mr-8`}
    >
      <input
        type="checkbox"
        className="h-4 w-4 rounded outline-none border-gray-300 text-primary focus:ring-primary"
        checked={checked}
        {...passthroughProps}
      />
    </label>
  );
}
