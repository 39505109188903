import React from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import TemplateMessage from "../messages/TemplateMessage";
import SurveyQuestionsPreview from "./questions/QuestionsSurveyPreview";

export default function SurveyType(props) {
  const { t } = useTranslation();

  const { survey, setSurvey } = props;

  const handleSelectType = (type) => {
    setSurvey((survey) => ({ ...survey, survey_type: type }));
  };

  const surveyTypePreviews = {
    single_choice: () => (
      <TemplateMessage
        template={{
          title: t("surveys.survey_types.single_choice.preview.title"),
          subtitle: t("surveys.survey_types.single_choice.preview.subtitle"),
          buttons: [
            {
              title: t("surveys.survey_types.single_choice.preview.options.0"),
            },
            {
              title: t("surveys.survey_types.single_choice.preview.options.1"),
            },
            {
              title: t("surveys.survey_types.single_choice.preview.options.2"),
            },
          ],
        }}
      />
    ),
    questions: () => (
      <div className="rounded-2xl overflow-hidden">
        <SurveyQuestionsPreview
          survey={{
            title: t("surveys.survey_types.questions.preview.title"),
            subtitle: t("surveys.survey_types.questions.preview.subtitle"),
          }}
          questions={[
            {
              title: t("surveys.survey_types.questions.preview.questions.0"),
              required: true,
            },
            {
              title: t("surveys.survey_types.questions.preview.questions.1"),
            },
          ]}
        />
      </div>
    ),
  };

  return (
    <div className="w-full min-h-[500px] flex flex-col items-center justify-center space-y-8 p-8">
      <div className="font-medium">{t("surveys.survey_types.title")}</div>
      <div className="flex items-end justify-center space-x-8">
        {["single_choice", "questions"].map((type) => (
          <div className="bg-white rounded-2xl border max-w-80 overflow-hidden">
            <div className="border-b bg-lighter-gray p-4 pointer-events-none max-h-72 overflow-hidden">
              {surveyTypePreviews[type] && surveyTypePreviews[type]()}
            </div>
            <div className="p-4 space-y-2">
              <div className="text-md font-medium">
                {t(`surveys.survey_types.${type}.title`)}
              </div>
              <div className="text-2sm text-darker-gray">
                {t(`surveys.survey_types.${type}.description`)}
              </div>
              <Button
                label={t("shared.select")}
                size="small"
                className="w-full"
                onClick={() => handleSelectType(type)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
