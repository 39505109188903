import pluralize from "pluralize";
import React from "react";
import Button from "../Button";

export default function TableSelection(props) {
  const { selectedItems, actions } = props;

  return (
    <div className="sticky inset-x-0 bottom-10 flex justify-center">
      <div className="bg-neutral-50 border shadow pl-4 pr-2 py-2 rounded-xl flex items-center space-x-6">
        <div className="font-medium text-2sm">
          {pluralize("item", selectedItems.length, true)} selected
        </div>

        <div className="flex items-center space-x-4">
          {actions.map((action, index) => (
            <Button
              key={index}
              size="small"
              label={action.label}
              count={action.count}
              onClick={action.action}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
