import {
  AtSymbolIcon,
  BellIcon,
  BoltIcon,
  CurrencyDollarIcon,
  IdentificationIcon,
  InformationCircleIcon,
  LanguageIcon,
  PhoneIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";

const engagementHistory = [
  {
    filter_type: "any_engagement",
    date_range: "last_30_days",
    icon: BellIcon,
  },
  {
    filter_type: "message",
    date_range: "last_30_days",
    icon: BellIcon,
  },
  {
    filter_type: "comment",
    date_range: "last_30_days",
    icon: BellIcon,
  },
  {
    filter_type: "story_reply",
    date_range: "last_30_days",
    icon: BellIcon,
  },
  {
    filter_type: "story_mention",
    date_range: "last_30_days",
    icon: BellIcon,
  },
  {
    filter_type: "referral_ad",
    date_range: "last_30_days",
    icon: BellIcon,
  },
  {
    filter_type: "media_mention",
    date_range: "last_30_days",
    icon: BellIcon,
  },
];

const automationHistory = [
  {
    filter_type: "folder",
    icon: BoltIcon,
  },
  {
    filter_type: "clicked_conversion_link",
    date_range: "last_30_days",
    icon: BoltIcon,
  },
  {
    filter_type: "campaign",
    date_range: "last_30_days",
    icon: BoltIcon,
  },
  {
    filter_type: "scenario",
    date_range: "last_30_days",
    icon: BoltIcon,
  },
];

const transactionHistory = [
  {
    filter_type: "appointment",
    date_range: "last_30_days",
    icon: CurrencyDollarIcon,
  },
  {
    filter_type: "payment",
    date_range: "last_30_days",
    icon: CurrencyDollarIcon,
  },
];

const contactInfo = [
  {
    filter_type: "email",
    operator: "is_present",
    icon: AtSymbolIcon,
  },
  {
    filter_type: "username",
    operator: "is_present",
    icon: UserIcon,
  },
  {
    filter_type: "status",
    operator: "is_equal",
    value: "active",
    icon: InformationCircleIcon,
  },
  {
    filter_type: "name",
    operator: "is_present",
    icon: UserIcon,
  },
  {
    filter_type: "phone",
    operator: "is_present",
    icon: PhoneIcon,
  },
  {
    filter_type: "language",
    operator: "is_equal",
    value: "en",
    icon: LanguageIcon,
  },
  {
    filter_type: "contact_property",
    operator: "is_present",
    icon: IdentificationIcon,
  },
  {
    filter_type: "is_follower",
    operator: "is_true",
    icon: UserIcon,
  },
  {
    filter_type: "follower_count",
    operator: "is_above",
    icon: UsersIcon,
  },
];

const filterTypes = {
  engagementHistory,
  automationHistory,
  transactionHistory,
  contactInfo,
};

export default filterTypes;
