import React, { useContext } from "react";
import ActionNode from "./ActionNode";
import { useTranslation } from "react-i18next";
import InputSelect from "../../../elements/InputSelect";
import Input from "../../../elements/Input";
import { FlowContext } from "../../../../contexts/flow-context";

export default function BranchExpirationNode(props) {
  const { t } = useTranslation();

  const { data } = props;
  const { id, options, label } = data;
  const { expire_duration = 3, expire_unit = "days" } = options;

  const { editAction } = useContext(FlowContext);

  const handleDurationChange = (value) => {
    editAction(id, {
      options: { ...options, expire_duration: parseInt(value) },
    });
  };

  const handleUnitChange = (value) => {
    editAction(id, {
      options: { ...options, expire_unit: value },
    });
  };

  return (
    <ActionNode noEdit noWarnings {...props}>
      <div className="space-y-2 flex flex-col items-center pb-1">
        <div className="text-3xs uppercase font-bold text-neutral-400 text-center">
          {t(label)}
        </div>
        <div className="flex space-x-2 items-center">
          <Input
            value={expire_duration}
            onChange={handleDurationChange}
            type="number"
            className="max-w-[50px]"
            changeOnBlur
          />

          <InputSelect
            value={expire_unit}
            options={["seconds", "minutes", "hours", "days"].map((unit) => ({
              value: unit,
              label: t("time." + unit),
            }))}
            onChange={handleUnitChange}
          />
        </div>
      </div>
    </ActionNode>
  );
}
