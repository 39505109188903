import React, { useContext, useEffect, useMemo, useState } from "react";
import { ContentContext } from "../../../contexts/content-context";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Input from "~/components/elements/Input";
import { useTranslation } from "react-i18next";
import Loader from "../../utils/Loader";
import StoryReplyItem from "./StoryReplyItem";
import { sortBy } from "lodash";

const sortOptions = ["newest", "oldest"];

export default function StoryReplies(props) {
  const { t } = useTranslation();

  const { story } = props;

  const { loadStoryReplies } = useContext(ContentContext);

  const [loading, setLoading] = useState(true);
  const [replies, setReplies] = useState([]);

  useEffect(async () => {
    const res = await loadStoryReplies(story.id);
    setReplies(res);
    setLoading(false);
  }, [story.id]);

  const [sortOption, setSortOption] = useState("newest");

  const sortFunction = useMemo(
    () => ({
      newest: (c) => sortBy(c, "received_at").reverse(),
      oldest: (c) => sortBy(c, "received_at"),
    }),
    [],
  );

  const [searchQuery, setSearchQuery] = useState("");
  const searchedReplies = useMemo(
    () =>
      replies.filter(
        (reply) =>
          reply.content?.includes(searchQuery) ||
          reply.contact?.name?.includes(searchQuery) ||
          reply.contact?.username?.includes(searchQuery),
      ),
    [searchQuery, replies],
  );

  return (
    <div className="flex flex-col">
      <div className="pt-3 px-3 pb-2 flex justify-between flex-shrink-0">
        <Input
          placeholder={t("shared.search")}
          icon={MagnifyingGlassIcon}
          size="small"
          className="max-w-40 w-full"
          value={searchQuery}
          onChange={setSearchQuery}
        />
        <select
          className="input-select"
          value={sortOption}
          onChange={(evt) => setSortOption(evt.target.value)}
        >
          {sortOptions.map((option) => (
            <option key={option} value={option}>
              {t("content.stories.replies.sort_options." + option)}
            </option>
          ))}
        </select>
      </div>
      <div className="flex-grow overflow-auto">
        {loading && (
          <div className="p-4 flex justify-center">
            <Loader />
          </div>
        )}
        {sortFunction[sortOption](searchedReplies).map((reply) => (
          <StoryReplyItem key={reply.id} reply={reply} />
        ))}
        {!loading && searchedReplies.length == 0 && (
          <div className="p-4 text-center text-sm text-muted">
            {t("content.stories.replies.no_replies")}
          </div>
        )}
      </div>
    </div>
  );
}
