import React from "react";
import { useTranslation } from "react-i18next";
import PublicBackground from "./shared/PublicBackground";

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <PublicBackground className="justify-between !py-12">
      <div />
      <div className="bg-white p-6 rounded-2xl flex flex-col space-y-4 w-full max-w-md">
        <div className="text-lg font-medium text-darker-gray">
          {t("shared.not_found_title")}
        </div>
        <div className="text-darker-gray">{t("shared.not_found_text")}</div>
      </div>
    </PublicBackground>
  );
}
