import React from "react";

/**
 * ButtonCount component.
 *
 * @module ButtonCount
 * @param {Object} props - Properties passed to component
 * @param {number} props.count - Count to display, default is 0
 * @returns {ReactNode} - ButtonCount component
 */
export default function ButtonCount(props) {
  const { count } = props;

  return (
    <span className="text-xs bg-black/10 rounded px-1 py-0.5 -mr-0.5 -my-0.5">
      {count}
    </span>
  );
}
