import React, { useContext, useState } from "react";
import {
  ExclamationCircleIcon,
  FireIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/20/solid";
import Button from "~/components/elements/Button";
import { Trans, useTranslation } from "react-i18next";
import { CampaignsContext } from "~/contexts/campaigns-context";
import { SubscriptionContext } from "~/contexts/subscription-context";

export default function CampaignLimitReached(props) {
  const { t } = useTranslation();

  const { campaign, setCampaign } = props;

  const { limited_executions } = campaign;

  const { subscription, limitReached } = useContext(SubscriptionContext);
  const { resendLimited } = useContext(CampaignsContext);

  const [resending, setResending] = useState(false);

  const handleResend = async () => {
    setResending(true);
    const res = await resendLimited(campaign.id);
    setResending(false);
    setCampaign({ ...campaign, ...res });
  };

  if (!limited_executions) return null;

  return (
    <div className="bg-warning p-4 flex flex-col lg:flex-row lg:items-center justify-between gap-4">
      <div className="flex space-x-2">
        <ExclamationCircleIcon className="w-5" />
        <div className="text-md">
          <Trans
            i18nKey="campaigns.limited_executions"
            count={limited_executions}
          >
            <strong>1 contact</strong> did not receive the campaign because you
            reached your monthly limit.
          </Trans>
        </div>
      </div>
      <div className="space-x-2">
        {!limitReached && (
          <Button
            label={t("campaigns.resend_limited_executions")}
            icon={PaperAirplaneIcon}
            onClick={handleResend}
            loading={resending}
          />
        )}
        {subscription.subscription_status != "active" && (
          <Button
            label={t("subscription.upgrade_to_pro")}
            icon={FireIcon}
            style="inverse"
            href="/stripe/checkout_session"
          />
        )}
      </div>
    </div>
  );
}
