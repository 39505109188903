import React, { useContext } from "react";
import { useCopyToClipboard } from "react-use";
import { UIContext } from "~/contexts/ui-context";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

export default function ContactDetailsField(props) {
  const { label, value, placeholder, canCopy = false, className } = props;

  const [state, copyToClipboard] = useCopyToClipboard();

  const { showTooltip } = useContext(UIContext);

  const { t } = useTranslation();

  const handleCopy = () => {
    copyToClipboard(value);
    showTooltip(t("shared.copied"));
  };

  return (
    <div className={`flex items-start justify-between p-4 space-x-4 border-b`}>
      <div className="min-w-[50px] flex-shrink-0 text-2sm leading-5">
        {label}
      </div>
      <div className="flex-grow text-md leading-5">
        {value || <span className="text-dark-gray">{placeholder}</span>}
      </div>
      {value && canCopy && (
        <DocumentDuplicateIcon
          className="w-5 text-dark-gray flex-shrink-0 cursor-pointer"
          onClick={handleCopy}
        />
      )}
    </div>
  );
}
