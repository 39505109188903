import React from "react";
import Media from "./Media";

export default function StoryMessage(props) {
  const { storyUrl, mediaUrl, received, caption, reaction } = props;

  return (
    <div className={`flex ${!received && "flex-row-reverse"}`}>
      <div
        className={`bg-neutral-200 w-1 rounded-full self-stretch ${
          received ? "mr-4" : "ml-4"
        }`}
      />
      <div
        className={`flex flex-col relative ${
          received ? "items-start" : "items-end"
        }`}
      >
        <div className="text-xs text-neutral-400 max-w-4xs mb-3">{caption}</div>
        <div className={`rounded-3xl max-w-3xs overflow-hidden`}>
          <Media src={mediaUrl} href={storyUrl} format="story" />
        </div>
        {reaction && (
          <div
            className={`absolute bottom-2 ${
              received ? "-right-6" : "-left-6"
            } text-7xl`}
          >
            {reaction}
          </div>
        )}
      </div>
    </div>
  );
}
