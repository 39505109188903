import React, { useContext, useEffect, useMemo, useState } from "react";
import Button from "~/components/elements/Button";
import InputCheckbox from "~/components/elements/InputCheckbox";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { Trans, useTranslation } from "react-i18next";
import { AgentContext } from "~/contexts/agent-context";
import { DateTime } from "luxon";
import { delay } from "lodash";
import AgentChat from "./AgentChat";
import FolderPickerField from "~/components/fields/FolderPickerField";
import Input from "~/components/elements/Input";
import { UserContext } from "~/contexts/user-context";

export default function AgentSettings() {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);
  const { agent: initialAgent, updateAgent } = useContext(AgentContext);

  const [loading, setLoading] = useState(false);
  const [agent, setAgent] = useState(initialAgent || {});

  const hasChanged = useMemo(
    () => agent !== initialAgent,
    [agent, initialAgent],
  );

  const handleChange = (key, value) => setAgent({ ...agent, [key]: value });

  const handleSave = async () => {
    setLoading(true);
    delay(async () => {
      const res = await updateAgent(agent);
      setAgent(res);
      setLoading(false);
    }, 2000);
  };

  useEffect(() => setAgent(initialAgent), [initialAgent]);

  return (
    <div className="flex flex-col-reverse md:flex-row gap-6 h-full">
      <div className="flex-grow flex flex-col overflow-auto space-y-6">
        <div className="bg-yellow-100 text-yellow-800 flex items-center space-x-2 rounded-lg p-3">
          <QuestionMarkCircleIcon className="h-5" />
          <div className="text-sm">{t("agent.settings.description")}</div>
        </div>
        {/* Settings */}
        <div className="grid grid-cols-8 gap-4">
          <div className="col-span-2 space-y-4">
            <div className="border rounded-lg p-4 space-y-4">
              <div className="block text-sm font-semibold leading-6 text-gray-900">
                {t("agent.settings.reply_settings")}
              </div>
              <div className="flex flex-col items-start space-y-2">
                <div className="text-sm">{t("agent.settings.reply_after")}</div>
                <div className="relative rounded-md shadow-sm">
                  <input
                    type="text"
                    name="price"
                    id="price"
                    className="block w-40 rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={agent?.reply_time_value}
                    onChange={(evt) =>
                      handleChange("reply_time_value", evt.target.value)
                    }
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center">
                    <select
                      className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                      value={agent?.reply_time_unit}
                      onChange={(evt) =>
                        handleChange("reply_time_unit", evt.target.value)
                      }
                    >
                      {["seconds", "minutes"].map((unit) => (
                        <option key={unit} value={unit}>
                          {t("time." + unit)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="border rounded-lg p-4 space-y-4">
              <div className="block text-sm font-semibold leading-6 text-gray-900">
                {t("agent.settings.tone_of_voice")}
              </div>
              <div className="space-y-2">
                {["upbeat", "neutral", "formal"].map((tone_of_voice) => (
                  <InputCheckbox
                    value={agent?.tone_of_voice === tone_of_voice}
                    onClick={() => setAgent({ ...agent, tone_of_voice })}
                    label={t("agent.settings." + tone_of_voice)}
                    key={tone_of_voice}
                  />
                ))}
              </div>
              <div className="block text-sm font-semibold leading-6 text-gray-900">
                {t("agent.settings.additional_options")}
              </div>
              <div className="space-y-2">
                {["use_emojis", "auto_like"].map((setting) => (
                  <InputCheckbox
                    className="p-1"
                    value={!!agent?.[setting]}
                    onClick={() =>
                      setAgent({
                        ...agent,
                        [setting]: !agent[setting],
                      })
                    }
                    label={t("agent.settings." + setting)}
                    key={setting}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="border rounded-lg p-4 space-y-4 col-span-3">
            <div className="text-sm font-medium">
              {t("agent.settings.no_reply_title")}
            </div>

            <div className="flex items-center space-x-2">
              <div className="text-sm">{t("agent.settings.add_to_folder")}</div>
              <FolderPickerField
                value={agent?.no_reply_folder_id}
                onChange={(folderId) =>
                  handleChange("no_reply_folder_id", folderId)
                }
              />
            </div>

            <div className="flex items-center space-x-2">
              <InputCheckbox
                value={agent?.no_reply_send_email}
                onClick={(value) => handleChange("no_reply_send_email", value)}
                label={t("agent.settings.send_email")}
              />
              <Input
                value={agent?.no_reply_email_address}
                onChange={(email) =>
                  handleChange("no_reply_email_address", email)
                }
              />
            </div>

            <div className="space-y-2">
              <div className="text-sm">
                {t("agent.settings.send_default_message")}
              </div>
              <Input
                type="textarea"
                className="w-64"
                rows={4}
                value={agent?.no_reply_message}
                onChange={(value) => handleChange("no_reply_message", value)}
                placeholder={t("agent.settings.default_message_placeholder")}
              />
            </div>
          </div>
          <div className="border rounded-lg p-4 space-y-4 col-span-3">
            <div className="text-sm font-medium">
              {t("agent.settings.request_human_title")}
            </div>

            <div className="flex items-center space-x-2">
              <div className="text-sm">{t("agent.settings.add_to_folder")}</div>
              <FolderPickerField
                value={agent?.request_human_folder_id}
                onChange={(folderId) =>
                  handleChange("request_human_folder_id", folderId)
                }
              />
            </div>

            <div className="flex items-center space-x-2">
              <InputCheckbox
                value={agent?.request_human_send_email}
                onClick={(value) =>
                  handleChange("request_human_send_email", value)
                }
                label={t("agent.settings.send_email")}
              />
              <Input
                value={agent?.request_human_email_address}
                onChange={(email) =>
                  handleChange("request_human_email_address", email)
                }
              />
            </div>

            <div className="space-y-2">
              <div className="text-sm">
                {t("agent.settings.request_human_message")}
              </div>
              <Input
                type="textarea"
                className="w-64"
                rows={4}
                value={agent?.request_human_message}
                onChange={(value) =>
                  handleChange("request_human_message", value)
                }
                placeholder={t(
                  "agent.settings.request_human_message_placeholder",
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-72 flex-shrink-0 flex-grow-0 flex flex-col space-y-4">
        <Button
          style="primary"
          label={t("agent.settings.update_agent")}
          loading={loading}
          disabled={!hasChanged}
          onClick={handleSave}
        />
        {hasChanged && (
          <Button
            label={t("shared.cancel_changes")}
            onClick={() => setAgent(initialAgent)}
          />
        )}
        <div className="text-center py-2 text-sm text-dark-gray">
          {t("shared.last_updated", {
            date: DateTime.fromISO(agent?.updated_at).toLocaleString(
              DateTime.DATETIME_MED,
            ),
          })}
        </div>
        <AgentChat training={loading} />
      </div>
    </div>
  );
}
