import { PlusCircleIcon } from "@heroicons/react/20/solid";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import TriggerTypeMenu from "./TriggerTypeMenu";

export default function AddTrigger() {
  const { t } = useTranslation();

  const { addTrigger } = useContext(FlowContext);

  const [triggerTypeMenu, showTriggerTypeMenu] = useState(false);

  return (
    <>
      <button
        className="py-2 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl cursor-pointer nodrag opacity-75 hover:opacity-100 transition-opacity flex justify-center space-x-2 items-center"
        onClick={() => showTriggerTypeMenu(true)}
      >
        <PlusCircleIcon className="w-5" />
        <div className="font-medium text-center">
          {t(`automation.scenarios.triggers.add_trigger`)}
        </div>
      </button>
      {triggerTypeMenu && (
        <TriggerTypeMenu
          onSubmit={addTrigger}
          onClose={() => showTriggerTypeMenu(false)}
        />
      )}
    </>
  );
}
