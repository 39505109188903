import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import FreePlan from "./free_trial/FreePlan";
import ProPlan from "./free_trial/ProPlan";
import EnterprisePlan from "./free_trial/EnterprisePlan";
import Modal from "../../shared/Modal";
import Button from "../../elements/Button";
import RedeemCodeModal from "../../subscription/RedeemCodeModal";

export default function FreeTrial() {
  const { t } = useTranslation();

  const [redeemCodeModal, setRedeemCodeModal] = useState(false);

  return (
    <div className="max-w-[912px] mx-auto space-y-8">
      <div className="space-y-4">
        <div className="font-black text-3xl sm:text-5xl text-headings whitespace-pre-line">
          {t("onboarding.free_trial.title")}
        </div>
        <div className="leading-6 text-muted">
          <Trans i18nKey="onboarding.free_trial.description" />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <FreePlan />
        <ProPlan />
        <EnterprisePlan />
      </div>
      <div className="flex justify-center">
        <button
          className="text-sm text-muted hover:underline"
          onClick={() => setRedeemCodeModal(true)}
        >
          {t("subscription.redeem_code.have_code")}
        </button>
      </div>
      {redeemCodeModal && (
        <RedeemCodeModal onClose={() => setRedeemCodeModal(false)} />
      )}
    </div>
  );
}
