import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../shared/Modal";
import Button from "../elements/Button";
import Input from "../elements/Input";
import { SubscriptionContext } from "../../contexts/subscription-context";

export default function RedeemCodeModal(props) {
  const { t } = useTranslation();

  const { onClose } = props;

  const { redeemCode } = useContext(SubscriptionContext);

  const handleRedeemCode = async () => {
    if (!code) {
      return;
    }
    setLoading(true);
    const res = await redeemCode(code);
    if (res) onClose();
    setLoading(false);
  };

  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      title={t("subscription.redeem_code.title")}
      onClose={onClose}
      footerLeft={<Button onClick={onClose}>{t("shared.cancel")}</Button>}
      footerRight={
        <Button
          onClick={handleRedeemCode}
          style="primary"
          disabled={!code}
          loading={loading}
        >
          {t("subscription.redeem_code.button")}
        </Button>
      }
      className="w-full max-w-sm"
    >
      <div className="p-4">
        <Input
          value={code}
          onChange={setCode}
          placeholder={t("subscription.redeem_code.placeholder")}
          autoFocus
        />
      </div>
    </Modal>
  );
}
