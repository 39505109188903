import { ClockIcon } from "@heroicons/react/24/solid";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import ActionNode from "./ActionNode";
import Input from "~/components/elements/Input";
import InputSelect from "../../../elements/InputSelect";

export default function DelayNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, options } = data;

  const { delay_unit, delay_duration, autoFocus } = options;

  const { editAction } = useContext(FlowContext);

  const handleDurationChange = (value) => {
    editAction(id, {
      options: { ...options, delay_duration: parseInt(value) },
    });
  };

  const handleUnitChange = (value) => {
    editAction(id, {
      options: { ...options, delay_unit: value },
    });
  };

  return (
    <ActionNode {...props}>
      <div
        className={`p-3 bg-logic rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all space-y-3 ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="flex gap-2 items-center">
          <ClockIcon className="w-5" />
          <div className="font-medium">
            {t("automation.scenarios.actions.delay.wait")}
          </div>
        </div>

        <div className="flex items-center gap-2">
          <div className="text-sm">
            {t("automation.scenarios.actions.delay.wait")}
          </div>

          <Input
            value={delay_duration}
            onChange={handleDurationChange}
            type="number"
            autoFocus={autoFocus}
            className="max-w-[50px]"
          />

          <InputSelect
            value={delay_unit}
            onChange={handleUnitChange}
            options={["seconds", "minutes", "hours", "days"].map((unit) => ({
              value: unit,
              label: t("time." + unit),
            }))}
          />
        </div>
      </div>
    </ActionNode>
  );
}
