import React, { useEffect, useRef, useState } from "react";

import shadeTop from "../../../assets/images/shade_top_black.png";
import shadeBottom from "../../../assets/images/shade_bottom_black.png";

export default function ShadedOverflow(props) {
  const { height = 140, className = "", children } = props;

  const scrollRef = useRef(null);
  const topRef = useRef(null);
  const bottomRef = useRef(null);

  const handleScroll = (evt) => {
    const scroll = scrollRef.current.scrollTop;

    topRef.current.style.opacity = scroll <= 0 ? 0 : 1;

    bottomRef.current.style.opacity =
      scroll >= scrollRef.current.scrollHeight - height ? 0 : 1;
  };

  useEffect(handleScroll, [height]);

  return (
    <div
      className={`relative ${className}`}
      style={{ maxHeight: `${height}px` }}
    >
      <div
        ref={topRef}
        className={`absolute top-0 inset-x-0 w-full h-5 bg-contain bg-repeat-x z-10 duration-75 transition-opacity pointer-events-none`}
        style={{
          backgroundImage: `url(${shadeTop})`,
          opacity: 0,
        }}
      />
      <div
        ref={bottomRef}
        className={`absolute bottom-0 inset-x-0 w-full h-5 bg-contain bg-repeat-x z-10 duration-75 transition-opacity pointer-events-none`}
        style={{
          backgroundImage: `url(${shadeBottom})`,
          opacity: 1,
        }}
      />
      <div
        ref={scrollRef}
        onScroll={handleScroll}
        className={`overflow-auto scrollbar-hide`}
        style={{ maxHeight: `${height}px` }}
      >
        {children}
      </div>
    </div>
  );
}
