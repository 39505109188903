import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { PlusCircleIcon } from "@heroicons/react/20/solid";

import { UserContext } from "~/contexts/user-context";

import FolderTag from "./FolderTag";
import SkeletonLoader from "~/components/utils/SkeletonLoader";
import FoldersDrawer from "./FoldersDrawer";
import { ContactsContext } from "../../../contexts/contacts-context";

export default function EditContactFolders(props) {
  const { loading, contact: initialContact, className } = props;

  const { t } = useTranslation();

  const { organization } = useContext(UserContext);
  const { contact, setContact } = useContext(ContactsContext);

  const [folderIds, setFolderIds] = useState(contact?.folder_ids);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    setFolderIds(contact?.folder_ids);
  }, [contact?.folder_ids]);

  const folders = organization.folders.filter((f) => folderIds?.includes(f.id));

  // FoldersDrawer onSubmit function passes an array of contacts
  const handleSubmit = (contacts) => {
    setFolderIds(contacts[0].folder_ids);
    setContact((prev) => ({ ...prev, folder_ids: contacts[0].folder_ids }));
  };

  useEffect(() => {
    if (!contact) {
      setContact(initialContact);
    }
  }, [initialContact]);

  return (
    <>
      <button
        className={`flex items-center gap-2 flex-wrap ${className}`}
        onClick={() => setShowDrawer(true)}
      >
        {loading ? (
          <SkeletonLoader width={160} height={22} />
        ) : (
          <>
            {folders.map((folder) => (
              <FolderTag key={folder.id} folder={folder} />
            ))}
            {(organization.folders.length || 1) > folders.length ? (
              <div className="flex items-center text-2xs text-dark-gray bg-light-gray rounded-full px-2 py-1 whitespace-nowrap flex-shrink-0">
                <PlusCircleIcon className="w-3.5 mr-1" />
                <div>{t("inbox.folders.add_to_folder")}</div>
              </div>
            ) : null}
          </>
        )}
      </button>
      {showDrawer && (
        <FoldersDrawer
          contacts={[{ ...contact, folder_ids: folderIds }]}
          onSubmit={handleSubmit}
          onClose={() => setShowDrawer(false)}
        />
      )}
    </>
  );
}
