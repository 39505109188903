export function toQueryParams(obj, parentKey = "") {
  const queryParams = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const fullKey = parentKey ? `${parentKey}[${key}]` : key;
      let value = obj[key];

      if (typeof value === "object" && value !== null) {
        // Recursively process nested objects
        queryParams.push(...toQueryParams(value, fullKey));
      } else {
        // Encode key and value for URL
        value = value === null ? "" : value;
        queryParams.push(
          `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`,
        );
      }
    }
  }

  return queryParams;
}

export function toQueryString(obj) {
  return toQueryParams(obj).join("&");
}
