import React, { useContext } from "react";
import { FlowContext } from "~/contexts/flow-context";
import ActionNode from "./ActionNode";
import { useTranslation } from "react-i18next";
import ScenarioPickerField from "../../../fields/ScenarioPickerField";

export default function TriggerScenarioNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, options, runnable_type, runnable_id } = data;

  const { scenario_id } = options;

  const { editAction } = useContext(FlowContext);

  const currentScenarioId = runnable_type == "Scenario" ? runnable_id : null;

  return (
    <>
      <ActionNode {...props}>
        <div
          className={`w-[280px] py-3 px-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all space-y-3 ${
            selected ? "ring-opacity-100 z-50" : null
          }`}
        >
          <div className="space-y-1">
            <div className="text-md font-medium">
              {t("automation.scenarios.actions.trigger_scenario.title")}
            </div>
            <div className="text-2sm text-darker-gray">
              {t("automation.scenarios.actions.trigger_scenario.description")}
            </div>
          </div>
          <ScenarioPickerField
            value={scenario_id}
            onChange={(value) =>
              editAction(id, { options: { ...options, scenario_id: value } })
            }
            excludedIds={[currentScenarioId]}
          />
        </div>
      </ActionNode>
    </>
  );
}
