import React from "react";

export default function Loader({
  className = "",
  width = 32,
  strokeWidth = 8,
  loading = true,
  stroke = "#999",
  ...props
}) {
  const viewWidth = 36 + strokeWidth;

  return (
    <svg
      width={width}
      height={width}
      stroke={stroke}
      viewBox={`0 0 ${viewWidth} ${viewWidth}`}
      className={`svg-loaders-svg ${loading && "animate-spin"} ${
        className ? ` ${className}` : ""
      }`}
      {...props}
    >
      <g
        transform={`translate(${strokeWidth / 2}, ${strokeWidth / 2})`}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
      >
        <circle strokeOpacity={0.5} cx={18} cy={18} r={18} />
        <path d="M36 18c0-9.94-8.06-18-18-18" />
      </g>
    </svg>
  );
}
