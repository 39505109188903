import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { appInset, tabBarHeight } from "~/utils/environment";

export default function BottomTooltip(props) {
  const { text, onClose } = props;

  const [visible, setVisible] = useState(false);
  const [removed, setRemoved] = useState(false);

  useEffect(() => {
    setTimeout(() => setVisible(true), 50);
    setTimeout(() => setRemoved(true), 1800);
    setTimeout(onClose, 2100);
  }, []);

  return createPortal(
    <div
      className={`fixed inset-x-4 sm:inset-x-0 sm:max-w-80 sm:mx-auto rounded-lg p-3 bg-black text-white text-center font-semibold text-md transition-all transform-gpu scale-90 ${
        visible ? "opacity-100 !scale-100 ease-out duration-300" : "opacity-0"
      } ${removed ? "!scale-110 !opacity-0 !ease-in !duration-150" : ""}`}
      style={{
        bottom: appInset.bottom + tabBarHeight + 16,
        zIndex: 1100,
      }}
    >
      {text}
    </div>,
    document.body,
  );
}
