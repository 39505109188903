import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "~/utils/classNames";
import ResultsNode from "./ResultsNode";
import formatMessageText from "~/utils/formatMessageText";

export default function AskQuestionResultsNode(props) {
  const { t } = useTranslation();

  const { data } = props;

  const { content, options } = data;

  const { ask_question_options = [] } = options;

  return (
    <ResultsNode {...props}>
      <div
        className={`w-full py-3 px-3 bg-white border-2 rounded-2xl nodrag space-y-2`}
      >
        <div className="flex flex-col items-end">
          <div
            className={classNames(
              "nodrag nopan block text-message border-none whitespace-pre-wrap ring-offset-1",
            )}
          >
            {content}
          </div>
        </div>
        <div className="flex flex-wrap items-start justify-end gap-2">
          {ask_question_options?.map((option, index) => (
            <div
              key={index}
              className="border rounded-2xl bg-white pl-2.5 pr-2 py-1 text-sm"
            >
              {option.title}
            </div>
          ))}
        </div>
      </div>
    </ResultsNode>
  );
}
