import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Tabs from "~/components/elements/Tabs";
import PageTitle from "~/components/shared/PageTitle";
import ContentMedias from "./ContentMedias";
import Button from "~/components/elements/Button";
import { ContentContext } from "~/contexts/content-context";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { UserContext } from "~/contexts/user-context";
import ContentStories from "./ContentStories";

export default function ContentPage() {
  const { t } = useTranslation();

  const { tab } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname == "/content") navigate("/content/medias");

  const { organization } = useContext(UserContext);
  const { loading, refreshMedias, refreshStories } = useContext(ContentContext);

  const activeTab = tab || "medias";

  const tabs = ["medias", "stories"].map((tab) => ({
    title: t(`content.${tab}.title`),
    active: activeTab === tab,
    onClick: () => navigate(`/content/${tab}`),
  }));

  const handleRefresh = () => {
    refreshMedias();
    refreshStories();
  };

  return (
    <div className="flex-grow h-screen px-4 sm:px-6 lg:px-8 pt-3 sm:pt-6 flex flex-col overflow-hidden space-y-4">
      <div className="flex justify-between space-x-4">
        <PageTitle text={t("content.title")} />

        <Button
          label={t("content.medias.refresh")}
          icon={ArrowPathIcon}
          onClick={handleRefresh}
          loading={loading}
        />
      </div>
      {organization.dev && <Tabs tabs={tabs} className="w-full" />}
      <div className="overflow-auto flex-grow">
        {activeTab === "medias" && <ContentMedias />}
        {activeTab === "stories" && <ContentStories />}
      </div>
    </div>
  );
}
