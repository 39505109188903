import React, { useContext } from "react";
import calendlyIcon from "../../../../assets/images/calendly_icon.png";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import ExternalAccount from "./ExternalAccount";
import Button from "~/components/elements/Button";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

export default function CalendlyAccount() {
  const { organization } = useContext(UserContext);

  const { calendly_account } = organization;

  const { connected, name, email, plan } = calendly_account;

  const { t } = useTranslation();

  return (
    <ExternalAccount
      icon={calendlyIcon}
      provider="calendly"
      connected={connected}
      name={name}
      subname={email}
      refresh
      connect={
        <Button
          label={t("integrations.calendly.connect")}
          className="w-full block"
          href="/users/auth/calendly"
        />
      }
    >
      {plan == "basic" && (
        <div className="p-2 rounded-lg bg-yellow-200 flex items-start space-x-1 text-yellow-800">
          <ExclamationTriangleIcon className="w-8" />
          <div className="text-2sm">{t("integrations.calendly.free_plan")}</div>
        </div>
      )}
    </ExternalAccount>
  );
}
