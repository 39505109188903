import React from "react";
import { useTranslation } from "react-i18next";
import ResultsNode from "./ResultsNode";

export default function BranchExpirationResultsNode(props) {
  const { t } = useTranslation();

  const { data } = props;
  const { id, options, label } = data;
  const { expire_duration = 3, expire_unit = "days" } = options;

  return (
    <ResultsNode noEdit {...props} noMetrics>
      <div className="space-y-2 flex flex-col items-center">
        <div className="text-3xs uppercase font-bold text-neutral-400">
          {t(label)}
        </div>
        <div className="font-medium text-sm text-neutral-600 bg-white rounded-lg border-2 px-2 py-1">
          {expire_duration} {t("time." + expire_unit)}
        </div>
      </div>
    </ResultsNode>
  );
}
