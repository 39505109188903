import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

export default function Dialog(props) {
  const {
    label,
    placeholder,
    defaultValue,
    forceUppercase = false,
    confirm,
    onSubmit,
    onClose,
    keyboardHeight,
  } = props;

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  // animate appear
  useEffect(() => {
    setTimeout(() => setVisible(true), 50);
  }, []);

  const handleClose = (evt) => {
    evt.preventDefault();
    setVisible(false);
    setTimeout(onClose, 50);
  };

  const inputRef = useRef(null);
  const [text, setText] = useState(defaultValue || "");

  const handleChange = (evt) => {
    if (forceUppercase) {
      setText(evt.target.value.toUpperCase());
    } else {
      setText(evt.target.value);
    }
  };

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter") {
      evt.preventDefault();
      if (text.length > 0) {
        handleSubmit(evt);
      } else {
        inputRef.current.blur();
      }
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onSubmit(text);
    onClose();
  };

  return createPortal(
    <div
      className={`fixed inset-0 flex flex-col items-center justify-center p-4 select-none transition-all duration-250 ease-in-out ${
        visible ? "pointer-events-auto" : "pointer-events-none"
      }`}
      style={{
        zIndex: 1100,
        paddingBottom: keyboardHeight / 2 + 16,
      }}
    >
      <div
        className={`absolute inset-0 bg-black/30 transition-opacity ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleClose}
      />
      <form
        className={`w-full max-w-md bg-white rounded-xl shadow-lg relative transform-gpu transition-all ${
          visible ? "scale-100 opacity-100" : "scale-95 opacity-0"
        }`}
        onSubmit={handleSubmit}
      >
        <div className="p-4">
          <label htmlFor="text" className="block mb-2 text-neutral-600">
            {label}
          </label>
          <input
            ref={inputRef}
            autoFocus
            placeholder={placeholder}
            type="text"
            value={text}
            onChange={handleChange}
            enterKeyHint="done"
            onKeyDown={handleKeyDown}
            className="w-full border rounded p-2"
          />
        </div>
        <div className="flex flex-row-reverse border-t">
          <button
            type="submit"
            className="w-1/2 text-center p-3 disabled:text-neutral-400"
            disabled={text.length == 0}
          >
            {confirm || t("shared.confirm")}
          </button>
          <button
            className="w-1/2 text-center p-3 text-red border-r"
            onClick={handleClose}
          >
            {t("shared.cancel")}
          </button>
        </div>
      </form>
    </div>,
    document.body,
  );
}
