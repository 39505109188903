export default function paymentClassName(status) {
  switch (status) {
    case "paid":
      return "bg-green-100 text-green-900";
    case "manual":
      return "bg-blue-100 text-blue-900";
    case "cancelled":
      return "bg-gray-100 text-gray-900";
    case "attempted":
      return "bg-yellow-500 text-yellow-900";
    case "failed":
      return "bg-red-100 text-red-900";
    case "refunded":
      return "bg-gray-100 text-gray-900";
    default:
      return "bg-yellow-500 text-yellow-900";
  }
}
