import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import classNames from "~/utils/classNames";
import { compact } from "lodash";

export default function Dropdown(props) {
  const {
    children,
    actions,
    wrapperClassName = "",
    buttonClassName = "",
    direction = "bottom",
  } = props;

  if (actions.length == 0) return children;

  return (
    <Menu as="div" className={classNames("relative", wrapperClassName)}>
      <Menu.Button className={classNames("cursor-pointer", buttonClassName)}>
        <span className="sr-only">Open options</span>
        {children}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            "absolute overflow-visible right-0 z-50 min-w-[180px] w-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
            direction == "top"
              ? "origin-bottom-right mb-2 bottom-full"
              : "origin-top-right mt-2",
          )}
        >
          <div className="py-1">
            {compact(actions).map((action) => (
              <Menu.Item key={action.label}>
                {({ active }) => {
                  const Icon = action.icon;
                  return (
                    <button
                      onClick={action.onClick}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-3 py-2 text-sm w-full disabled:opacity-60 disabled:pointer-events-none",
                        action.className,
                      )}
                      disabled={action.disabled}
                    >
                      {Icon && (
                        <Icon className={`w-4 mr-2`} aria-hidden="true" />
                      )}
                      {action.label}
                    </button>
                  );
                }}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
