import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "~/utils/classNames";
import ResultsNode from "./ResultsNode";
import formatMessageText from "~/utils/formatMessageText";

export default function MessageResultsNode(props) {
  const { t } = useTranslation();

  const { data } = props;

  const text = formatMessageText(data?.content || "");

  return (
    <ResultsNode {...props}>
      <div className={`w-[220px] flex flex-col items-center`}>
        <div
          className={classNames("text-message whitespace-pre-wrap")}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </ResultsNode>
  );
}
