import { CheckIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ics } from "calendar-link";

import Button from "~/components/elements/Button";

import ContactHeader from "../contacts/ContactHeader";
import PublicBackground from "~/components/shared/PublicBackground";
import axios from "axios";
import headers from "~/utils/headers";
import PublicPaymentPage from "../payments/PublicPaymentPage";
import Loader from "~/components/utils/Loader";
import PaymentLinkCard from "../payments/PaymentLinkCard";
import StatusBadge from "~/components/shared/StatusBadge";
import ContactInformationForm from "../contacts/ContactInformationForm";
import AppointmentEvent from "../messages/AppointmentEvent";
import { sortBy } from "lodash";
import { DateTime } from "luxon";
import formatEventTime from "~/utils/formatEventTime";

export default function PublicAppointmentPage(props) {
  const { organization, contact, id, initialEventId } = props;

  const { t } = useTranslation();

  const [eventId, setEventId] = useState(initialEventId);
  const [appointment, setAppointment] = useState();
  const [showPayment, setShowPayment] = useState(false);
  const [showContactInformation, setShowContactInformation] = useState(false);

  const loadAppointment = () => {
    axios.get(`/public/appointments/${id}.json`, headers()).then((res) => {
      setAppointment(res.data);
      if (res.data.payment_status == "attempted") {
        setTimeout(loadAppointment, 3000);
      }
    });
  };

  useEffect(loadAppointment, [id]);

  const checkAvailable = () => {
    const selectedEvent = appointment?.calendar_events?.find(
      (evt) => evt.id == eventId,
    );
    if (
      selectedEvent?.status == "deleted" ||
      selectedEvent?.status == "cancelled"
    )
      setEventId("unavailable");
  };

  useEffect(checkAvailable, [appointment, eventId]);

  const fullPaymentAmount = appointment?.payment
    ? parseFloat(appointment?.payment?.amount) +
      parseFloat(appointment?.payment?.fee)
    : null;

  const handleConfirmDate = () => {
    if (fullPaymentAmount > 0) {
      setShowPayment(true);
    } else {
      setShowContactInformation(true);
    }
  };

  const handleSubmit = () => {
    axios
      .patch(
        `/public/appointments/${appointment.id}`,
        { event_id: eventId },
        headers(),
      )
      .then((res) => setAppointment(res.data));
  };

  const addToCalendar = (event) => {
    const link = ics({
      title: t("calendar.message.calendar_invite_title", {
        name: organization?.username,
      }),
      // start: event?.start_time,
      // duration: [event?.duration, "minutes"],
      duration: [60, "minutes"],
    });
    window.open(`webcal://${link}`, "_blank");
  };

  // Payment pending
  if (!organization || !appointment)
    return (
      <PublicBackground className="justify-end">
        <div className="bg-white p-4 rounded-lg w-full max-w-md">
          <ContactHeader contact={organization} large />
          <Loader className="w-8 mt-4 mx-auto" />
        </div>
      </PublicBackground>
    );

  // Appointment cancelled
  if (appointment.appointment_status == "appointment_cancelled")
    return (
      <PublicBackground className="justify-end">
        <div className="bg-white p-4 rounded-lg w-full max-w-md">
          <ContactHeader contact={organization} large />
          <div className="text-base font-medium mt-4">
            {t("calendar.message.cancelled_message")}
          </div>
        </div>
      </PublicBackground>
    );

  // Appointment confirmed
  if (appointment.status == "confirmed") {
    const event = appointment?.calendar_events?.find(
      (event) => event.status == "confirmed",
    );
    const date = formatEventTime(event);
    return (
      <PublicBackground className="justify-end">
        <div className="bg-white p-4 rounded-lg flex flex-col space-y-4 w-full max-w-sm">
          <div className="w-8 h-8 p-1 rounded-full bg-primary self-center">
            <CheckIcon className="w-6 h-6 text-white" />
          </div>
          <div className="text-md font-medium text-center">
            {t("calendar.message.confirmed_message")}
          </div>
        </div>
        {fullPaymentAmount > 0 ? (
          <PaymentLinkCard
            organization={organization}
            amount={appointment?.payment?.amount}
            fee={appointment?.payment?.fee}
            total={fullPaymentAmount}
            date={date}
            badge={
              appointment?.payment?.status == "paid" && (
                <StatusBadge
                  label={t("payments.labels.paid")}
                  status="success"
                  small
                />
              )
            }
          />
        ) : (
          <div className="bg-white p-4 rounded-lg flex flex-col space-y-4 w-full max-w-md">
            <ContactHeader contact={organization} large />
            <hr className="border-light-gray" />
            <div className="text-md font-medium text-center">
              {DateTime.fromISO(event.start_time).toFormat(
                "EEEE, MMMM d - HH:mm",
              )}
            </div>
          </div>
        )}
        {/* <Button
          label={t("calendar.message.add_to_calendar")}
          className="text-base font-medium w-full max-w-md"
          onClick={() => addToCalendar(event)}
        /> */}
      </PublicBackground>
    );
  }

  // Payment Page
  if (showPayment) {
    const event = appointment?.calendar_events?.find(
      (event) => event.id == eventId,
    );
    const date = DateTime.fromISO(event?.start_time)
      .setZone(event?.time_zone)
      .toFormat("EEEE, MMMM d - HH:mm");
    return (
      <PublicPaymentPage
        eventDate={date}
        onSubmit={handleSubmit}
        id={appointment.payment.id}
      />
    );
  }

  // Contact Information
  if (showContactInformation) {
    const event = appointment?.calendar_events?.find(
      (event) => event.id == eventId,
    );
    const date = DateTime.fromISO(event?.start_time)
      .setZone(event?.time_zone)
      .toFormat("EEEE, MMMM d - HH:mm");
    return (
      <ContactInformationForm
        eventDate={date}
        organization={organization}
        contact={contact}
        onSubmit={handleSubmit}
      />
    );
  }

  // if all events are past
  const isExpired =
    appointment?.appointment_status == "appointment_expired" ||
    appointment?.calendar_events.every(
      (event) => DateTime.fromISO(event.start_time) < DateTime.now(),
    );

  if (isExpired)
    return (
      <PublicBackground className="justify-end">
        <div className="bg-white p-4 rounded-lg w-full max-w-md">
          <ContactHeader contact={organization} large />
          <div className="text-base font-medium mt-4">
            {t("calendar.message.expired_message")}
          </div>
        </div>
      </PublicBackground>
    );

  return (
    <PublicBackground className="justify-end">
      <div className="bg-white p-4 rounded-xl flex flex-col space-y-4 w-full max-w-md">
        <ContactHeader contact={organization} large />

        <div className="text-md text-darker-gray">
          {t("calendar.message.label", { name: organization?.username })}
        </div>

        {eventId == "unavailable" ? (
          <div className="text-2sm p-2 rounded text-dark-orange bg-light-orange">
            {t("calendar.message.unavailable")}
          </div>
        ) : null}

        <div className="flex flex-col space-y-4">
          {sortBy(appointment?.calendar_events, "start_time").map((event) => (
            <AppointmentEvent
              key={event.id}
              event={{
                ...event,
                status: event.id == eventId ? "selected" : event.status,
              }}
              onClick={() => setEventId(event.id)}
            />
          ))}
        </div>

        {fullPaymentAmount > 0 ? (
          <div className="text-2sm text-darker-gray">
            {t("calendar.message.payment_needed", {
              amount: fullPaymentAmount,
            })}
          </div>
        ) : null}

        {fullPaymentAmount > 0 && appointment.payment_status == "attempted" && (
          <div className="text-2xs p-2 rounded-lg bg-yellow-500 text-dark-yellow-500 mb-4">
            {t("calendar.message.payment_attempted_public")}
          </div>
        )}
      </div>
      <Button
        label={
          fullPaymentAmount > 0
            ? t("calendar.message.go_to_payment")
            : t("calendar.message.confirm_date")
        }
        className="w-full max-w-md"
        onClick={handleConfirmDate}
        disabled={!eventId}
      />
    </PublicBackground>
  );
}
