import { ClockIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useTranslation } from "react-i18next";

import ResultsNode from "./ResultsNode";

export default function DelayResultsNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, options } = data;

  const { delay_unit, delay_duration } = options;

  return (
    <ResultsNode {...props}>
      <div
        className={`p-3 bg-logic rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all space-y-3 ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="text-sm">
          {t("automation.scenarios.actions.delay.wait")}{" "}
          <span className="font-semibold">
            {delay_duration} {t("time." + delay_unit)}
          </span>
        </div>
      </div>
    </ResultsNode>
  );
}
