import React from "react";
import Input from "../../elements/Input";
import Button from "../../elements/Button";
import { useTranslation } from "react-i18next";
import classNames from "../../../utils/classNames";
import InputSelect from "../../elements/InputSelect";
import SurveyQuestionInput from "./SurveyQuestionInput";

export default function QuestionsSurveyPreview(props) {
  const { t } = useTranslation();

  const { survey, questions, className = "" } = props;

  return (
    <div
      className={classNames(
        "bg-black p-4 flex flex-col items-center justify-center",
        className,
      )}
    >
      <div className="flex flex-col space-y-4 max-w-xs w-full">
        <div className="bg-white rounded-lg p-4 space-y-2">
          <div className="font-medium">{survey?.title}</div>
          <div className="text-sm">{survey?.subtitle}</div>
        </div>
        <div className="bg-white rounded-lg p-4 space-y-4">
          {questions.map((question, index) => (
            <SurveyQuestionInput key={index} question={question} />
          ))}
        </div>
        <Button
          className="w-full max-w-sm"
          style="primary"
          label={t("surveys.questions.submit")}
        />
      </div>
    </div>
  );
}
