export default function appointmentClassName(status) {
  switch (status) {
    case "confirmed":
      return "bg-light-green text-dark-green-500";
    case "cancelled":
      return "bg-light-gray text-dark-gray";
    default:
      return "bg-yellow-500 text-dark-yellow-500";
  }
}
