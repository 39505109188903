import React, { useContext, useState } from "react";
import BottomDrawer from "~/components/shared/BottomDrawer";
import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import Calendar from "./Calendar";
import { CalendarContext } from "~/contexts/calendar-context";

export default function EditEventModal(props) {
  const { event, onClose } = props;

  const { t } = useTranslation();

  const { updateEvent } = useContext(CalendarContext);

  // Event slot
  const [slots, setSlots] = useState([event]);

  const handleSelectSlot = (time) => {
    setSlots([{ ...event, start_time: time }]);
  };

  const handleSubmit = () => {
    updateEvent(event.id, {
      start_time: slots[0].start_time,
      duration: slots[0].duration,
    });
    onClose();
  };

  return (
    <BottomDrawer
      title={t("calendar.details.edit_appointment")}
      onClose={onClose}
    >
      <Calendar
        slots={slots}
        addSlot={handleSelectSlot}
        disablePast
        eventsActive={false}
        hideEvent={event}
        defaultDay={event.start_time}
      />
      <div className="p-4 border-t">
        <Button
          label={t("shared.confirm")}
          onClick={handleSubmit}
          className="w-full"
        />
      </div>
    </BottomDrawer>
  );
}
