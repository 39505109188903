import React from "react";
import Toggle from "~/components/shared/Toggle";

export default function ProfileToggle(props) {
  const { label, description = null, value, onChange } = props;

  return (
    <div className="px-4 py-6 border-t">
      <div className="flex justify-between items-center">
        <div className="text-md">{label}</div>
        <Toggle value={value} onChange={onChange} />
      </div>
      {description && (
        <div className="text-2xs text-dark-gray mt-2">{description}</div>
      )}
    </div>
  );
}
