import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import Textarea from "react-expanding-textarea";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import headers from "~/utils/headers";
import { appInset, isApp } from "~/utils/environment";

import Button from "~/components/elements/Button";

import { UIContext } from "~/contexts/ui-context";
import { UserContext } from "~/contexts/user-context";
import { MAX_MESSAGE_BYTE_SIZE } from "../../constants";

export default function BatchMessage(props) {
  const { conversationIds, onClose, onSuccess = () => {} } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { showAlert, keyboardHeight } = useContext(UIContext);

  const [visible, setVisible] = useState(false);

  // animate appear
  useEffect(() => {
    setTimeout(() => setVisible(true), 50);
  }, []);

  const handleClose = () => {
    if (!visible) return;
    setVisible(false);
    setTimeout(onClose, 150);
  };

  // Close on swipe down
  const touchPosition = useRef();
  const handleTouchMove = (evt) => {
    if (evt.changedTouches[0].clientY > touchPosition.current) handleClose();
  };

  // Message
  const [message, setMessage] = useState("");
  const textByteSize = new Blob([message]).size;

  const handleTextChange = (evt) => {
    const newByteSize = new Blob([evt.target.value]).size;
    if (newByteSize > MAX_MESSAGE_BYTE_SIZE) return;
    setMessage(evt.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await axios.post(
      "/api/inbox/messages",
      {
        conversation_ids: conversationIds,
        content: message,
      },
      headers(),
    );
    setLoading(false);
    onSuccess();
    onClose();
  };

  return createPortal(
    <div
      className="fixed inset-0 flex flex-col justify-end sm:justify-center items-center"
      style={{
        zIndex: 1000,
        paddingTop: appInset.top + 20,
      }}
      onTouchStart={(evt) =>
        (touchPosition.current = evt.changedTouches[0].clientY)
      }
      onTouchEnd={() => (touchPosition.current = null)}
      onTouchMove={handleTouchMove}
    >
      <div
        className={`absolute inset-0 bg-black/30 transition-opacity ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleClose}
      />
      <div
        className={`max-w-md w-full bg-white overflow-y-auto rounded-t-2xl sm:rounded-2xl flex flex-col space-y-6 px-4 pt-6 transform-gpu transition-all duration-250 ${
          visible
            ? "translate-y-0 opacity-100"
            : "translate-y-full sm:translate-y-0 opacity-0"
        }`}
        style={{
          paddingBottom:
            keyboardHeight > 0 ? keyboardHeight + 24 : appInset.bottom + 24,
        }}
      >
        <div className="text-center text-2sm">
          {t("inbox.actions.sending_title", {
            count: conversationIds.length,
          })}
        </div>
        <div className="flex items-start bg-neutral-100 rounded-3xl px-4 py-2.5">
          <Textarea
            className="text-sm outline-none flex-grow resize-none bg-transparent"
            placeholder={t("inbox.conversation.compose")}
            value={message}
            onChange={handleTextChange}
            name="message"
            autoFocus
          />
          {textByteSize > 0 && (
            <div className="text-dark-gray pt-0.5 pl-2 text-2sm">
              {textByteSize}/{MAX_MESSAGE_BYTE_SIZE}
            </div>
          )}
        </div>
        <Button
          loading={loading}
          label={t("inbox.conversation.send")}
          disabled={message.length <= 0}
          style="primary"
          onClick={() =>
            showAlert({
              title: t("inbox.actions.send_confirm", {
                count: conversationIds.length,
              }),
              confirm: t("inbox.conversation.send"),
              onSubmit: handleSubmit,
            })
          }
        />
        <button className="text-red text-center text-md" onClick={handleClose}>
          {t("shared.cancel")}
        </button>
      </div>
    </div>,
    document.body,
  );
}
