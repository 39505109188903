import React, { useEffect, useState } from "react";
import classNames from "~/utils/classNames";
import i18n from "../../locales/i18n";

import photoAnais from "../../../assets/images/photos/anais_garnier.jpg";
import photoYoline from "../../../assets/images/photos/yoline_badet.jpg";
import photoMathilde from "../../../assets/images/photos/mathilde_devaux.jpg";

import logoWeink from "../../../assets/images/logos/weink.png";
import logoYoyo from "../../../assets/images/logos/yoyo-match.png";
import logoNona from "../../../assets/images/logos/nona-source.png";

const quotes = [
  {
    quote: {
      en: "I used to do this manually. Inrō saves me at least 2 hours everyday.",
      fr: "Avant, je faisais ça à la main. J'ai gagné au moins 2 heures par jour.",
    },
    author: "Anaïs Garnier",
    title: "CEO & Co-founder - WeInk",
    photo: photoAnais,
    logo: logoWeink,
    logoWidth: 40,
  },
  {
    quote: {
      en: "Inrō DM campaigns feel so much more personal and authentic. My users love it!",
      fr: "Les campagnes DMs sont tellement plus personnelles et authentiques. J'adore !",
    },
    author: "Yoline Badet",
    title: "CEO & Founder - YOYOMATCH",
    photo: photoYoline,
    logo: logoYoyo,
    logoWidth: 100,
  },
  {
    quote: {
      en: "I am amazed by the number of leads we got with the exact same ad budget.",
      fr: "Je n'imaginais avoir autant de leads avec le même budget publicité.",
    },
    author: "Mathilde Devaux",
    title: "Head of Marketing - Nona Source",
    photo: photoMathilde,
    logo: logoNona,
    logoWidth: 40,
  },
];

export default function QuoteCarousel() {
  const [current, setCurrent] = useState(0);

  const locale = i18n.resolvedLanguage;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((current) => (current + 1) % quotes.length);
    }, 10000);

    return () => clearInterval(interval);
  });

  return (
    <div className="w-[512px] h-[512px] relative p-10 bg-white rounded-2xl flex flex-col items-center justify-between">
      <div className="flex">
        {quotes.map((quote, index) => (
          <div
            key={index}
            className="px-1 py-2 cursor-pointer group"
            onClick={() => setCurrent(index)}
          >
            <div
              className={classNames(
                index == current
                  ? "bg-interactive w-6"
                  : "bg-highlight group-hover:bg-highlight-hover w-2",
                "h-2 rounded-lg transition-all",
              )}
            />
          </div>
        ))}
      </div>
      <div className="text-[48px] font-light leading-tight">
        "{quotes[current]?.quote[locale]}"
      </div>
      <div className="flex items-center justify-between self-stretch">
        <div className="flex space-x-2">
          <img
            src={quotes[current]?.photo}
            className="w-10 h-10 object-cover rounded-full"
          />
          <div>
            <div className="text-headings leading-tight font-medium">
              {quotes[current]?.author}
            </div>
            <div className="text-muted leading-tight">
              {quotes[current]?.title}
            </div>
          </div>
        </div>
        {/* Logo */}
        {quotes[current]?.logo && (
          <img
            src={quotes[current]?.logo}
            className="h-auto object-contain"
            style={{ width: quotes[current]?.logoWidth || 50 }}
          />
        )}
      </div>
    </div>
  );
}
