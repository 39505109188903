import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import OnboardingSidebar from "./OnboardingSidebar";
import ConnectInstagram from "./steps/ConnectInstagram";
import AboutYou from "./steps/AboutYou";
import Goal from "./steps/Goal";
import FreeTrial from "./steps/FreeTrial";

export default function OnboardingScreen() {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);

  const currentStep = organization.onboarding_step;

  const StepScreen = {
    connect_instagram: ConnectInstagram,
    about_you: AboutYou,
    goal: Goal,
    free_trial: FreeTrial,
  }[currentStep];

  return (
    <div className="sm:flex sm:flex-row sm:w-screen sm:items-stretch h-screen overflow-hidden">
      <OnboardingSidebar />
      <div className="flex-grow p-10 h-full overflow-auto">
        {StepScreen && <StepScreen />}
      </div>
    </div>
  );
}
