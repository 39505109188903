import React from "react";
import { CalendarIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import RelativeDate from "~/components/utils/RelativeDate";
import appointmentClassName from "~/utils/appointmentClassName";

export default function AppointmentStatusIcon(props) {
  const { status, date } = props;

  const { t } = useTranslation();

  const className = appointmentClassName(status);

  const label = () => {
    if (date) {
      return <RelativeDate date={date} />;
    } else if (status == "awaiting") {
      return t("shared.appt");
    } else {
      return t(`calendar.message.${status}`);
    }
  };

  return (
    <div
      className={`rounded px-1.5 py-1 text-xs flex-shrink-0 flex items-center space-x-1 ${className}`}
    >
      <CalendarIcon className="w-4" />
      <div>{label()}</div>
    </div>
  );
}
