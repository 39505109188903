import React, { useEffect, useState } from "react";
import ContactHeader from "./ContactHeader";
import { getContact } from "~/api/contacts/contacts";
import ContactFolders from "../inbox/folders/ContactFolders";

export default function ContactsListItem(props) {
  const {
    contact: initialContact,
    onClick = () => {},
    showFolders = false,
  } = props;

  const [contact, setContact] = useState(initialContact);

  useEffect(async () => {
    if (!initialContact?.id) return;
    const res = await getContact(initialContact.id);
    setContact((c) => ({ ...c, ...res }));
  }, [initialContact?.id]);

  return (
    <div
      key={contact.id}
      className="p-2 rounded-lg hover:bg-neutral-50 transition-colors cursor-pointer"
      onClick={onClick}
    >
      <ContactHeader contact={contact} />
      {showFolders && (
        <div className="pt-2 pl-10">
          <ContactFolders folderIds={contact.folder_ids} size="sm" max={8} />
        </div>
      )}
    </div>
  );
}
