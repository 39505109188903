import React from "react";

export default function DoubleClick(props) {
  const handleClick = (evt) => {
    if (evt.detail >= 2) {
      props.action();
    }
  };

  return (
    <div onClick={props.action ? handleClick : null}>{props.children}</div>
  );
}
