import React from "react";
import InputSelect from "../../elements/InputSelect";
import Input from "../../elements/Input";
import { useTranslation } from "react-i18next";
import InputCheckboxes from "../../elements/InputCheckboxes";

export default function SurveyQuestionInput(props) {
  const { t } = useTranslation();

  const { question, value, onChange } = props;

  const questionOptions = (question.options || []).map((o) => ({
    label: o.title,
    value: o.title,
  }));

  if (question.field_type == "multiple_choice") {
    if (question.accept_multiple) {
      return (
        <InputCheckboxes
          label={question.title}
          options={questionOptions}
          value={value || []}
          onChange={onChange}
        />
      );
    } else {
      return (
        <InputSelect
          label={question.title}
          options={questionOptions}
          value={value}
          onChange={onChange}
          placeholder={t("shared.select_option")}
          required={question.required}
        />
      );
    }
  }

  return (
    <Input
      label={question.title}
      value={value}
      onChange={onChange}
      optional={!question.required}
      required={question.required}
      type={question.field_type}
      autoComplete={question.field_type}
    />
  );
}
