import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { appInset } from "~/utils/environment";

export default function FullscreenAttachments(props) {
  const { media, index = 0, onClose } = props;

  const [visible, setVisible] = useState(false);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollTo(index * scrollRef.current.offsetWidth, 0);
    setTimeout(() => setVisible(true), 50);
  }, []);

  const handleClose = (evt) => {
    evt.preventDefault();
    setVisible(false);
    setTimeout(onClose, 150);
  };

  return createPortal(
    <div
      className={`fixed inset-0 bg-black transition-opacity flex flex-col ${
        visible ? "opacity-100" : "opacity-0"
      }`}
      style={{
        zIndex: 800,
        paddingTop: appInset.top,
        paddingBottom: appInset.bottom,
      }}
      onClick={handleClose}
    >
      <div className="flex justify-end items-center space-x-2 sm:p-4 flex-shrink-0">
        {/* <div className="w-8 h-8 rounded flex justify-center items-center">
          <ArrowDownTrayIcon className="w-6 h-6 text-white" />
        </div> */}
        <div
          className="w-8 h-8 rounded flex justify-center items-center"
          onClick={handleClose}
        >
          <XMarkIcon className="w-6 h-6 text-white" />
        </div>
      </div>
      <div
        ref={scrollRef}
        className={`overflow-y-hidden overflow-x-auto scrollbar-hide snap-x snap-mandatory flex flex-grow items-center`}
      >
        {media.map(({ url, type }, i) => (
          <div
            key={i}
            className={`h-full w-full flex-shrink-0 snap-center snap-always p-2 flex flex-col items-center justify-center transition-transform ${
              visible ? "scale-100" : "scale-90"
            }`}
          >
            {type == "image" && (
              <img
                src={url}
                className="rounded-xl max-w-full max-h-full"
                onClick={(evt) => evt.stopPropagation()}
              />
            )}
            {type == "video" && (
              <video
                className="rounded-xl max-w-full max-h-full"
                autoPlay
                loop
                playsInline
                controls
              >
                <source src={url} type="video/mp4" />
              </video>
            )}
          </div>
        ))}
      </div>
    </div>,
    document.body,
  );
}
