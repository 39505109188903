import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LinesSelect from "./LinesSelect";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

export default function GroupedLinesSelect(props) {
  const { value = [], onChange = () => {}, groups = [], name = "" } = props;

  const [openedGroup, setOpenedGroup] = useState(null);
  const { t } = useTranslation();

  const selectedCount = (options, value) => {
    return options.filter((option) => value.includes(option.value)).length;
  };

  const handleToggleOpen = (group) => {
    if (openedGroup === group) {
      setOpenedGroup(null);
    } else {
      setOpenedGroup(group);
    }
  };

  return (
    <div className="">
      <div className={"relative rounded-md bg-white space-y-2"}>
        {groups.map((group, index) => {
          return (
            <div className="space-y-2" key={`${group.label}_${name}`}>
              <div
                className={
                  "p-4 rounded-lg bg-surface space-y-2 border-default border"
                }
              >
                <div
                  className="flex items-center justify-between cursor-pointer gap-2"
                  onClick={() => handleToggleOpen(group.value)}
                >
                  {openedGroup == group.value ? (
                    <ChevronDownIcon className="w-5" />
                  ) : (
                    <ChevronRightIcon className="w-5" />
                  )}
                  {group.label && (
                    <div className="text-headings font-medium leading-6 text-sm flex-grow">
                      {group.label}
                    </div>
                  )}
                  {group.description && (
                    <div className="text-muted text-xs">
                      {group.description}
                    </div>
                  )}
                  {selectedCount(group.options, value) ? (
                    <div className="bg-interactive text-xs text-white px-1 rounded">
                      {t("shared.selected", {
                        count: selectedCount(group.options, value),
                      })}
                    </div>
                  ) : null}
                </div>
                <LinesSelect
                  key={group.value}
                  label={group.title}
                  value={value}
                  closed={openedGroup !== group.value}
                  onChange={onChange}
                  icon={group.icon}
                  multiple={group.multiple}
                  foldable={true}
                  name={`${group.name}_${name}`}
                  description={group.description}
                  options={group.options}
                  searchable={group.searchable}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
