import React from "react";
import { useTranslation } from "react-i18next";
import TriggerBadge from "../flow/triggers/TriggerBadge";
import classNames from "../../../utils/classNames";

export default function ScenarioTriggerBadges(props) {
  const { t } = useTranslation();

  const { triggers, noWrap = false } = props;

  return triggers?.length ? (
    <div
      className={classNames("flex gap-2", noWrap ? "flex-nowrap" : "flex-wrap")}
    >
      {triggers.slice(0, 2).map((trigger) => (
        <TriggerBadge
          key={trigger.id}
          label={t(
            `automation.scenarios.trigger_type.${trigger.trigger_type}.title`,
          )}
        />
      ))}
      {triggers.length > 2 && (
        <TriggerBadge label={`+ ${triggers.length - 2}`} />
      )}
    </div>
  ) : (
    <div className="text-neutral-400 italic text-sm">
      {t("automation.scenarios.triggers.no_triggers")}
    </div>
  );
}
