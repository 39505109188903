import React, { useContext, useMemo, useState } from "react";
import Modal from "../../shared/Modal";
import { ContactsContext } from "../../../contexts/contacts-context";
import { useTranslation } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/20/solid";
import ContactPropertyForm, { propertyTypes } from "./ContactPropertyForm";
import Button from "../../elements/Button";

export default function EditContactProperty(props) {
  const { t } = useTranslation();

  const { onClose, onUpdate, initialValues } = props;

  const { updateContactProperty } = useContext(ContactsContext);

  const [contactProperty, setContactProperty] = useState(initialValues || {});

  const handleUpdate = async () => {
    const res = await updateContactProperty(contactProperty);
    onUpdate && onUpdate(res);
    onClose();
  };

  const isValid = useMemo(() => {
    return (
      contactProperty?.title &&
      propertyTypes.includes(contactProperty?.property_type)
    );
  }, [contactProperty]);

  return (
    <Modal onClose={onClose} zIndex={1000}>
      <div className="w-[400px] max-w-full">
        <div className="p-3 space-y-3">
          <div className="flex justify-between items-center">
            <h3 className="font-medium text-lg">
              {t("contacts.properties.edit_property")}
            </h3>
            <XMarkIcon
              className="h-5 w-5 text-gray-600 cursor-pointer"
              onClick={onClose}
            />
          </div>
          <ContactPropertyForm
            property={contactProperty}
            setProperty={setContactProperty}
            disableType
          />
        </div>
        <div className="border-t p-3 flex justify-between">
          <Button label={t("shared.cancel")} onClick={onClose} />
          <Button
            style="primary"
            label={t("shared.save")}
            onClick={handleUpdate}
            disabled={!isValid}
            loadingOnClick
          />
        </div>
      </div>
    </Modal>
  );
}
