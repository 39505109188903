import React from "react";

import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import Unsupported from "./Unsupported";
import { truncate } from "lodash";
import classNames from "../../utils/classNames";

export default function TemplateMessage(props) {
  const { t } = useTranslation();

  const { template, received, badge = null, children, className = "" } = props;

  if (!template) return <Unsupported received={received} />;

  const { image_url, title, subtitle, buttons } = template;

  return (
    <div
      className={`w-[280px] h-auto max-h-80 rounded-3xl bg-white border select-none overflow-hidden flex flex-col ${className}`}
    >
      {image_url && (
        <div className="w-72 h-72 bg-light relative">
          <img
            src={image_url}
            className="w-full h-full object-cover object-center"
          />
        </div>
      )}
      <div className="p-4 flex flex-col space-y-2 overflow-hidden">
        <div className="flex items-center justify-between space-x-2">
          <div className="text-md font-medium">
            {truncate(title, { length: 80 })}
          </div>
          {badge}
        </div>
        {subtitle && (
          <div className="text-sm text-neutral-500">
            {truncate(subtitle, { length: 80 })}
          </div>
        )}
        <div className="overflow-y-auto space-y-2">
          {(buttons || []).map((button, index) => (
            <Button
              key={index}
              label={truncate(button.title, { length: 20 })}
              className={classNames("block w-full")}
              style={button.selected ? "success" : "soft"}
              href={button.url}
              disabled={button.disabled}
            />
          ))}
        </div>
        {children}
      </div>
    </div>
  );
}
