import React, { useEffect, useMemo, useState } from "react";
import SideDrawer from "../shared/SideDrawer";
import { getContacts } from "../../api/contacts/contacts";
import { useTranslation } from "react-i18next";
import ContactHeader from "./ContactHeader";
import Button from "~/components/elements/Button";
import {
  DocumentArrowDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import ContactPage from "./ContactPage";
import Input from "../elements/Input";
import SkeletonLoader from "../utils/SkeletonLoader";
import { times } from "lodash";
import ContactsListItem from "./ContactsListItem";
import { toQueryString } from "../../utils/urlSearchParams";

export default function ContactsDrawer(props) {
  const { t } = useTranslation();

  const { params, onClose, zIndex = 1000 } = props;

  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(async () => {
    if (params) {
      setLoading(true);
      const payload = { ...params, page };
      if (searchQuery) {
        payload.search = searchQuery;
      }
      const res = await getContacts(payload);
      setContacts(res.contacts);
      setTotalCount(res.total_count);
      setTotalPages(res.total_pages);
      setLoading(false);
    }
  }, [params, searchQuery, page]);

  const filterSearchParams = useMemo(() => {
    return toQueryString(params);
  }, [params]);

  const [contactId, setContactId] = useState(null);

  return (
    <>
      <SideDrawer onClose={onClose} zIndex={zIndex} className="max-w-sm w-full">
        <div className="flex flex-col overflow-hidden h-full">
          <div className="p-4 space-y-2 flex-shrink-0">
            <div className="font-medium">
              {t("contacts.drawer.view_contacts")}
            </div>
            <div className="flex items-center justify-between">
              {totalCount == null ? (
                <SkeletonLoader width={80} height={20} />
              ) : (
                <div className="text-sm font-semibold">
                  {t("contacts.drawer.contacts_count", {
                    count: totalCount,
                  })}
                </div>
              )}
              {totalCount == null ? (
                <SkeletonLoader width={144} height={36} />
              ) : (
                <Button
                  size="small"
                  icon={DocumentArrowDownIcon}
                  href={"/export/contacts?" + filterSearchParams}
                  target="_blank"
                  label={t("contacts.drawer.export_contacts")}
                  disabled={loading}
                />
              )}
            </div>
            <Input
              icon={MagnifyingGlassIcon}
              placeholder={t("contacts.drawer.search_contacts")}
              size="small"
              value={searchQuery}
              onChange={setSearchQuery}
              debounce
            />
          </div>
          <div className="flex-grow overflow-auto space-y-2 px-2 pb-2">
            {loading ? (
              times(5).map((i) => (
                <div key={i} className="px-2 py-1">
                  <ContactHeader loading />
                </div>
              ))
            ) : contacts.length > 0 ? (
              contacts?.map((contact) => (
                <ContactsListItem
                  key={contact.id}
                  contact={contact}
                  onClick={() => setContactId(contact.id)}
                  showFolders
                />
              ))
            ) : (
              <div className="text-center text-sm text-neutral-500 py-4">
                {t("contacts.drawer.no_contacts")}
              </div>
            )}
          </div>
          {totalPages && totalPages > 1 && (
            <div className="p-4 flex items-center space-x-2">
              <Button
                label={t("shared.previous")}
                disabled={page <= 1}
                onClick={() => setPage(Math.max(page - 1, 1))}
              />
              <div className="flex-grow text-center text-sm font-medium text-neutral-700">
                {t("contacts.drawer.page", {
                  page: page,
                  total: totalPages,
                })}
              </div>
              <Button
                label={t("shared.next")}
                disabled={page >= totalPages}
                onClick={() => setPage(Math.min(page + 1, totalPages))}
              />
            </div>
          )}
        </div>
      </SideDrawer>
      {contactId && (
        <SideDrawer
          zIndex={zIndex + 1}
          onClose={() => setContactId(null)}
          className="w-full max-w-5xl sm:ml-20"
        >
          <ContactPage contactId={contactId} />
        </SideDrawer>
      )}
    </>
  );
}
