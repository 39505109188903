import React from "react";

export default function RepliedIcon(props) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props?.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.16565 0.634376C5.31563 0.784398 5.39988 0.987844 5.39988 1.19998C5.39988 1.41211 5.31563 1.61555 5.16565 1.76558L3.33125 3.59998H7.80005C9.28526 3.59998 10.7096 4.18997 11.7598 5.24018C12.81 6.29038 13.4 7.71476 13.4 9.19998V10.8C13.4 11.0121 13.3158 11.2156 13.1657 11.3657C13.0157 11.5157 12.8122 11.6 12.6 11.6C12.3879 11.6 12.1844 11.5157 12.0344 11.3657C11.8843 11.2156 11.8 11.0121 11.8 10.8V9.19998C11.8 8.13911 11.3786 7.12169 10.6285 6.37155C9.87833 5.6214 8.86091 5.19998 7.80005 5.19998H3.33125L5.16565 7.03438C5.24206 7.10817 5.303 7.19645 5.34493 7.29405C5.38686 7.39166 5.40893 7.49663 5.40985 7.60286C5.41077 7.70908 5.39053 7.81442 5.35031 7.91274C5.31008 8.01106 5.25068 8.10038 5.17556 8.17549C5.10045 8.25061 5.01113 8.31001 4.91281 8.35023C4.81449 8.39046 4.70915 8.4107 4.60293 8.40978C4.4967 8.40885 4.39173 8.38678 4.29413 8.34486C4.19652 8.30293 4.10825 8.24198 4.03445 8.16558L0.834449 4.96558C0.684472 4.81555 0.60022 4.61211 0.60022 4.39998C0.60022 4.18784 0.684472 3.9844 0.834449 3.83438L4.03445 0.634376C4.18447 0.484399 4.38792 0.400146 4.60005 0.400146C4.81218 0.400146 5.01563 0.484399 5.16565 0.634376V0.634376Z"
        fill="#CDCBCB"
      />
    </svg>
  );
}
