import React, { useContext, useState } from "react";
import { ContactsContext } from "../../../contexts/contacts-context";
import { useTranslation } from "react-i18next";
import {
  PencilIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import Button from "../../elements/Button";
import NewContactProperty from "./NewContactProperty";
import { UIContext } from "../../../contexts/ui-context";
import EditContactProperty from "./EditContactProperty";

export default function ContactProperties(props) {
  const { t } = useTranslation();

  const { onClose } = props;

  const { showPrompt } = useContext(UIContext);
  const { contactProperties, destroyContactProperty } =
    useContext(ContactsContext);

  const [newProperty, setNewProperty] = useState(false);
  const [editProperty, setEditProperty] = useState(null);

  const handleDelete = async (id) => {
    showPrompt(t("contacts.properties.delete_confirm"), async () => {
      await destroyContactProperty(id);
    });
  };

  return (
    <div className="p-3 space-y-3 w-[400px] max-w-full">
      <div className="flex justify-between items-center">
        <h3 className="font-medium text-lg">
          {t("contacts.properties.title")}
        </h3>
        <XMarkIcon
          className="h-5 w-5 text-gray-600 cursor-pointer"
          onClick={onClose}
        />
      </div>
      <div className="space-y-2 overflow-auto">
        {contactProperties.map((property) => (
          <div
            key={property.id}
            className="border rounded-lg p-3 flex justify-between items-start"
          >
            <div className="space-y-1">
              <div className="font-medium text-headings">{property.title}</div>
              <div className="text-sm text-gray-500">
                {t(`contacts.properties.type_${property.property_type}`)}
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <PencilIcon
                className="w-4 text-gray-400 cursor-pointer hover:text-gray-500"
                onClick={() => setEditProperty(property)}
              />
              <TrashIcon
                className="w-4 text-gray-400 cursor-pointer hover:text-gray-500"
                onClick={() => handleDelete(property.id)}
              />
            </div>
          </div>
        ))}
      </div>
      <Button
        label={t("contacts.properties.new_property")}
        onClick={() => setNewProperty(true)}
        icon={PlusIcon}
      />
      {newProperty && (
        <NewContactProperty onClose={() => setNewProperty(false)} />
      )}
      {editProperty && (
        <EditContactProperty
          onClose={() => setEditProperty(null)}
          initialValues={editProperty}
        />
      )}
    </div>
  );
}
