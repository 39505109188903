import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { UIContext } from "~/contexts/ui-context";

export default function LabeledSelect({
  name,
  value,
  onChange,
  label,
  placeholder,
  required = false,
  disabled = false,
  options = [],
  groupOptionsBy = null,
  className = "",
}) {
  const { t } = useTranslation();

  const { showMenu } = useContext(UIContext);

  const handleSelect = useCallback(() => {
    if (disabled) return;
    showMenu({
      title: label,
      actions: options.map((option) => ({
        ...option,
        bold: option.value === value,
        selected: option.value === value,
        action: () => onChange(option.value),
      })),
      groupActionsBy: groupOptionsBy,
    });
  }, [value, options, disabled]);

  const selectedOption = options.find((option) => option.value === value);

  return (
    <div className={"border-b pt-4 " + className}>
      <label htmlFor={name} className="block px-4 text-2sm">
        {label}
      </label>
      <div className="flex items-center px-4 pt-3 pb-4" onClick={handleSelect}>
        <div className="flex-grow text-md">
          {selectedOption?.label || (
            <span className="text-neutral-400">{placeholder}</span>
          )}
        </div>
        {!disabled && <ChevronRightIcon className="w-6 h-6 text-dark-gray" />}
      </div>
    </div>
  );
}
