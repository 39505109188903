import React from "react";
import { useTranslation } from "react-i18next";
import LoveReaction from "~/components/messages/LoveReaction";
import ResultsNode from "./ResultsNode";

export default function LikeResultsNode(props) {
  const { t } = useTranslation();

  return (
    <ResultsNode {...props}>
      <div className="relative">
        <div
          className={`relative py-2.5 px-3 bg-white border rounded-3xl nodragflex space-x-2 items-center`}
        >
          <LoveReaction className="absolute left-2 -bottom-3.5" />
          <div className="text-md">
            {t("automation.scenarios.actions.like.title")}
          </div>
        </div>
      </div>
    </ResultsNode>
  );
}
