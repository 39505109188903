import React, { useContext, useState } from "react";
import PageTitle from "~/components/shared/PageTitle";
import { useTranslation } from "react-i18next";
import { AutomationContext } from "~/contexts/automation-context";
import Button from "~/components/elements/Button";
import { useNavigate, useParams } from "react-router-dom";
import SurveyForm from "./SurveyForm";
import SurveyItem from "./SurveyItem";
import Modal from "~/components/shared/Modal";
import SurveyModal from "./SurveyModal";

export default function SurveysPage() {
  const { t } = useTranslation();

  const { surveys } = useContext(AutomationContext);

  const [newSurvey, showNewSurvey] = useState(false);

  const { surveyId } = useParams();
  const navigate = useNavigate();

  const activeSurvey = surveys.find((survey) => survey.id == surveyId);

  return (
    <div className="min-h-screen sm:min-h-0 flex-grow py-3 sm:p-10 overflow-y-auto relative">
      <div className="max-w-3xl space-y-4 sm:space-y-6">
        <div className="px-3 sm:px-0 space-y-4 sm:space-y-6">
          <PageTitle text={t("surveys.title")} />
          <div className="flex items-center justify-between">
            <div className="text-md">
              {t("surveys.count", { count: surveys.length })}
            </div>
            <Button
              label={t("surveys.create")}
              onClick={() => showNewSurvey(true)}
            />
          </div>
        </div>
        <div className="border-t sm:border-t-0 sm:space-y-3">
          {surveys.map((survey) => (
            <SurveyItem key={survey.id} survey={survey} />
          ))}
        </div>
      </div>
      {newSurvey || activeSurvey ? (
        <SurveyModal
          survey={activeSurvey}
          onClose={() => {
            showNewSurvey(null);
            navigate("/automation/surveys");
          }}
        />
      ) : null}
    </div>
  );
}
