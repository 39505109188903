import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import Button from "~/components/elements/Button";
import {
  CodeBracketSquareIcon,
  DocumentDuplicateIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import Input from "../../elements/Input";
import { useCopyToClipboard } from "react-use";
import { UIContext } from "../../../contexts/ui-context";

export default function PrivateApi() {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);
  const { showTooltip } = useContext(UIContext);

  const [state, copyToClipboard] = useCopyToClipboard();

  const handleCopyToClipboard = () => {
    showTooltip(t("shared.copied"));
    copyToClipboard(organization.private_api_token);
  };

  return (
    <div className="border rounded-2xl rounded-tl-none p-4 space-y-4">
      <div className="flex items-center justify-between">
        <div className="text-md flex">
          <CodeBracketSquareIcon className="w-5 mr-2" />
          {t(`integrations.private_api.title`)}
        </div>
      </div>
      <div className="text-2sm">{t(`integrations.private_api.subtitle`)}</div>
      <div className="flex gap-2">
        <Input
          disabled
          size="small"
          value={organization.private_api_token}
          className="flex-grow"
        />
        <Button
          size="small"
          label={t("shared.copy")}
          icon={DocumentDuplicateIcon}
          onClick={handleCopyToClipboard}
        />
      </div>
      <Button
        icon={DocumentMagnifyingGlassIcon}
        size="small"
        label={t(`integrations.private_api.view_documentation`)}
        className="w-full"
        href="/api_doc.html"
        target="_blank"
      />
    </div>
  );
}
