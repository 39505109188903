import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

export default function RelativeDate(props) {
  const { t, i18n } = useTranslation();

  const date = DateTime.fromISO(props.date);

  const now = DateTime.now();

  // moment.locale(i18n.resolvedLanguage);

  const { includeTime = false } = props;
  const includedTime = includeTime ? " " + date.toLocaleString(DateTime.TIME_24_SIMPLE) : "";

  if (!date.isValid) {
    return "";
  } else if (date > now.endOf("year")) {
    // Next year
    return date.toLocaleString(DateTime.DATE_SHORT) + includedTime;
  } else if (date > now.plus({ days: 1 }).endOf("day")) {
    // After tomorrow
    return date.toFormat("d MMM") + includedTime;
  } else if (date > now.endOf("day")) {
    // Tomorrow
    return t("time.tomorrow") + includedTime;
  } else if (date.hasSame(now, "day")) {
    // Today
    const hoursDiff = date.diff(now, "hours").hours;
    if (hoursDiff >= 1) {
      return includedTime;
    } else if (hoursDiff < 1 && hoursDiff > -1) {
      const minutesDiff = date.diff(now, "minutes").minutes;
      // between 1 min ago and 5 min from now
      if (minutesDiff < 5 && minutesDiff > -1) {
        return t("time.now");
      } else {
        return Math.abs(Math.round(minutesDiff)) + t("time.min");
      }
      // in the past
    } else {
      return Math.abs(Math.round(hoursDiff)) + "h";
    }
  } else if (date > now.minus({ days: 1 }).startOf("day")) {
    // yesterday
    return t("time.yesterday") + includedTime;
  } else if (date > now.minus({ weeks: 1 }).startOf("day")) {
    // last week
    return date.toFormat("EEEE") + includedTime;
  } else if (date > now.startOf("year")) {
    // this year
    return date.toFormat("d MMM") + includedTime;
  } else {
    // before
    return date.toLocaleString(DateTime.DATE_SHORT) + includedTime;
  }
}
