import React from "react";
import Input from "../../elements/Input";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../elements/ButtonGroup";

export const propertyTypes = ["text", "number", "date"];

export default function ContactPropertyForm(props) {
  const { t } = useTranslation();

  const { property, setProperty, disableType = false } = props;

  return (
    <div className="space-y-3">
      <Input
        label={t("contacts.properties.title_label")}
        value={property.title}
        placeholder={t("contacts.properties.title_placeholder")}
        onChange={(title) => setProperty({ ...property, title })}
        autoFocus
      />
      <div className="font-medium leading-5 text-headings">
        {t("contacts.properties.type_label")}
      </div>
      <ButtonGroup
        className="w-full"
        buttons={propertyTypes.map((type) => ({
          label: t(`contacts.properties.type_${type}`),
          style: property.property_type == type ? "primary" : "default",
          onClick: () => setProperty({ ...property, property_type: type }),
          disabled: disableType,
        }))}
      />
    </div>
  );
}
