import { truncate } from "lodash";
import React, { useContext } from "react";
import { UserContext } from "~/contexts/user-context";

import { ChatBubbleOvalLeftIcon } from "@heroicons/react/20/solid";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import ReelIcon from "~/components/shared/icons/ReelIcon";

export default function MediaItem(props) {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);

  const { media } = props;

  return (
    <div className="flex-shrink-0 space-y-2">
      {/* <div className="flex items-center space-x-2">
        <img
          className={`w-6 h-6 rounded-full flex-shrink-0`}
          src={organization?.profile_picture}
        />
        <div className="text-xs font-medium">{organization?.username}</div>
      </div> */}
      <div className="relative">
        <img
          className="block w-full aspect-square object-cover object-center"
          src={media.url}
        />
        {media.media_type === "VIDEO" && (
          <div className="absolute top-2 right-2 text-white">
            <ReelIcon />
          </div>
        )}
      </div>
      {media.caption ? (
        <div className="text-xs">
          <span className="font-medium">{organization?.username}</span>{" "}
          {truncate(media.caption, { length: 68 })}
        </div>
      ) : null}
      <div className="flex items-center space-x-1">
        <ChatBubbleOvalLeftIcon className="w-4 h-4 text-gray-500" />
        <div className="text-xs text-gray-500">
          {t("content.medias.comments", { count: media.comments_count || 0 })}
        </div>
      </div>
      <div className="text-2xs text-gray-400">
        {DateTime.fromISO(media.posted_at).toLocaleString(DateTime.DATE_MED)}
      </div>
    </div>
  );
}
