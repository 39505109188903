import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import { ContentContext } from "~/contexts/content-context";
import { UserContext } from "~/contexts/user-context";
import { unionBy } from "lodash";

export default function ContentProvider(props) {
  const [loading, setLoading] = useState(true);

  const { organization } = useContext(UserContext);

  // Medias

  const [medias, setMedias] = useState([]);

  const loadMedias = async () => {
    setLoading(true);
    const res = await axios.get("/api/content/medias");
    setMedias(res.data);
    setLoading(false);
    return res.data;
  };

  useEffect(loadMedias, [organization.id]);

  const loadMedia = async (id) => {
    const res = await axios.get(`/api/content/medias/${id}`);
    setMedias((medias) => unionBy([res.data], medias, "id"));
    return res.data;
  };

  const refreshMedias = () => {
    setLoading(true);
    axios.get("/api/content/medias/refresh").then((res) => {
      setMedias(res.data);
      setLoading(false);
    });
  };

  // Stories

  const [stories, setStories] = useState([]);

  const loadStories = async () => {
    setLoading(true);
    const res = await axios.get("/api/content/stories");
    setStories(res.data);
    setLoading(false);
    return res.data;
  };

  useEffect(loadStories, [organization.id]);

  const refreshStories = async () => {
    setLoading(true);
    const res = await axios.get("/api/content/stories/refresh");
    setStories(res.data);
    setLoading(false);
    return res.data;
  };

  const loadStory = async (storyId) => {
    const res = await axios.get(`/api/content/stories/${storyId}`);
    return res.data;
  };

  const loadStoryReplies = async (storyId) => {
    const res = await axios.get(`/api/content/stories/${storyId}/replies`);
    return res.data;
  };

  const mediaValues = {
    loading,
    medias,
    loadMedias,
    loadMedia,
    refreshMedias,
    stories,
    loadStories,
    refreshStories,
    loadStory,
    loadStoryReplies,
  };

  return (
    <ContentContext.Provider value={mediaValues}>
      {props.children}
    </ContentContext.Provider>
  );
}
