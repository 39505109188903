import React, { useState } from "react";
import Modal from "../shared/Modal";
import Button from "../elements/Button";
import { LockOpenIcon, SparklesIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { delay } from "lodash";
import axios from "axios";
import headers from "../../utils/headers";

export default function FeatureRequestAccess(props) {
  const { t } = useTranslation();

  const { feature, onClose } = props;

  const [accessRequested, setAccessRequested] = useState(false);

  const requestAccess = () => {
    axios.post(
      "/api/organization/request_feature_access",
      {
        feature,
      },
      headers(),
    );
    delay(() => setAccessRequested(true), 2000);
  };

  return (
    <Modal className="max-w-sm" onClose={onClose}>
      <div className="flex flex-col space-y-4 p-3 pb-6 sm:p-4">
        <div className="font-medium text-lg">
          <SparklesIcon className="w-5 inline-block mr-2" />
          {t(`request_access.title`)}
        </div>
        <div className="text-sm">{t(`request_access.${feature}`)}</div>
        {accessRequested ? (
          <div className="text-center text-sm font-medium text-green-500 py-2">
            {t(`request_access.requested`)}
          </div>
        ) : (
          <Button
            label={t("request_access.button")}
            style="primary"
            className="w-full"
            icon={LockOpenIcon}
            loadingOnClick
            onClick={requestAccess}
          />
        )}
      </div>
    </Modal>
  );
}
