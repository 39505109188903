import React, { useState } from "react";
import Loader from "~/components/utils/Loader";
import { isApp } from "~/utils/environment";

export default function Media(props) {
  const { src, href, format } = props;

  const [fileType, setFileType] = useState("image");
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const handleSuccess = () => {
    setLoaded(true);
  };

  const handleImageError = () => {
    setFileType("video");
  };

  const handleVideoError = () => {
    setError(true);
  };

  const dimensions =
    format == "story"
      ? {
          width: "192px",
          height: "340px",
        }
      : {
          width: "222px",
          height: "222px",
        };

  return (
    <>
      <a href={href} className={loaded ? null : "hidden"} target="_blank">
        {fileType == "image" && (
          <img
            src={src}
            className={`w-full h-auto`}
            onLoad={handleSuccess}
            onError={handleImageError}
          />
        )}
        {fileType == "video" && (
          <video
            src={src}
            className={`w-full h-auto`}
            onCanPlay={handleSuccess}
            onError={handleVideoError}
            preload={isApp ? "none" : "auto"}
            muted
            playsInline
            loop
          ></video>
        )}
      </a>
      {!loaded && !error && (
        <div
          style={dimensions}
          className="bg-neutral-300 flex items-center justify-center p-10"
        >
          <Loader stroke="#fff" />
        </div>
      )}
      {!loaded && error && (
        <div
          style={dimensions}
          className="bg-neutral-400 flex items-center justify-center"
        >
          <div className="text-sm text-white">Story expired</div>
        </div>
      )}
    </>
  );
}
