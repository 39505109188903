import { DEFAULT_FEE, FEE_ROUNDING, MIN_FEE } from "../constants";

export default function calculateFee(
  amount,
  feeRatio = DEFAULT_FEE
) {
  const floatAmount = parseFloat(amount);
  const floatRatio = parseFloat(feeRatio);

  let fee = floatAmount * floatRatio;
  fee = Math.ceil(fee / FEE_ROUNDING) * FEE_ROUNDING;
  if (fee < MIN_FEE && feeRatio > 0) fee = MIN_FEE;

  const stripeFee = Math.round((floatAmount * 0.015 + 0.25) * 100) / 100;

  return [floatAmount - fee - stripeFee, fee, stripeFee];
}
