import { DateTime } from "luxon";
import moment from "moment/moment";
import React, { useContext } from "react";
import { UserContext } from "~/contexts/user-context";

export default function AppointmentEvent(props) {
  const { event, appointmentCancelled, onClick } = props;

  const datetime = DateTime.fromISO(event.start_time).setZone(event.time_zone);

  const expired = event.status == "sent" && datetime < DateTime.now();

  const statusClassName = () => {
    switch (event.status) {
      case "selected":
        return "bg-light-primary text-black ring-2 ring-primary";
      case "confirmed":
        return "bg-light-green text-dark-green-500";
      case "deleted":
        return "bg-light-gray text-dark-gray line-through";
      case "cancelled":
        return "bg-light-red text-dark-red opacity-50";
      default:
        return expired
          ? "bg-light-gray text-dark-gray line-through"
          : "bg-light-gray text-black";
    }
  };

  const disabled =
    expired || event.status == "deleted" || event.status == "cancelled";

  const handleClick = () => {
    if (!disabled) onClick();
  };

  return (
    <div
      className={`text-center p-2 text-2sm font-medium rounded-lg ${
        appointmentCancelled ? "line-through text-dark-gray" : ""
      } ${statusClassName()} ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      onClick={handleClick}
    >
      {datetime.toFormat("EEEE, MMMM d - HH:mm")}
    </div>
  );
}
