import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { unionBy } from "lodash";
import classNames from "~/utils/classNames";

export default function PendingExecutions(props) {
  const {
    executions,
    setExecutions,
    executionComponent: ExecutionComponent,
  } = props;

  const { t } = useTranslation();

  const [current, setCurrent] = useState(0);

  return (
    <div className="space-y-2">
      {ExecutionComponent && (
        <ExecutionComponent
          key={executions[current].id}
          execution={executions[current]}
          setExecution={(value) =>
            setExecutions(unionBy([value], executions, "id"))
          }
          opened={true}
        />
      )}
      {executions.length > 1 && (
        <div className="flex items-center justify-center space-x-2">
          {executions.map((exec, index) => (
            <div
              className={classNames(
                "w-2 h-2 rounded-full cursor-pointer",
                index == current
                  ? "bg-primary"
                  : "bg-grey-400 hover:bg-grey-500",
              )}
              onClick={() => setCurrent(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
