import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { every, some } from "lodash";
import { AutomationContext } from "~/contexts/automation-context";
import Button from "~/components/elements/Button";
import Input from "~/components/elements/Input";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import CardsSelect from "~/components/elements/CardsSelect";
import { BoltIcon, PlayCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import Modal from "../../shared/Modal";

export default function NewScenarioDialog(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { onClose } = props;

  const { createScenario, scenarioTemplates, loadScenarioTemplates } =
    useContext(AutomationContext);

  const [scenario, setScenario] = useState({});
  const [loading, setLoading] = useState(false);

  const isValid = some([scenario.title, scenario.template_id]);

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 250);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);

    if (!isValid) return;

    createScenario(scenario).then((newScenario) => {
      if (newScenario) {
        setLoading(false);
        navigate(`/automation/scenarios/${newScenario.id}`);
        handleClose();
      }
    });
  };

  useEffect(() => setTimeout(() => setIsOpen(true), 50), []);
  useEffect(loadScenarioTemplates, []);
  const cardOptions = useMemo(() => {
    return [
      ...scenarioTemplates.map((template) => {
        return {
          value: template.id,
          label: template.title,
          description: template.template_description,
          icon: template.template_icon || "bolt",
        };
      }),
      {
        value: "new",
        label: t("automation.scenarios.empty_scenario"),
        description: t("automation.scenarios.empty_scenario_description"),
        icon: "document_plus",
      },
    ];
  }, [scenarioTemplates]);

  return (
    <Modal
      onClose={handleClose}
      className="w-full max-w-2xl"
      title={t("automation.scenarios.new_scenario")}
      titleIcon={BoltIcon}
      footerLeft={<Button label={t("shared.cancel")} onClick={handleClose} />}
      footerRight={
        <Button
          label={t("automation.scenarios.create_scenario")}
          style="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={!isValid}
          loading={loading}
        />
      }
    >
      <div className="flex-grow py-4 px-4 space-y-4">
        <div className="space-y-1">
          <div className="text-base font-semibold leading-6 text-gray-900">
            {t("automation.scenarios.title_label")}
          </div>
          <Input
            placeholder={t("automation.scenarios.title_placeholder")}
            name="title"
            value={scenario?.title}
            onChange={(value) => setScenario({ ...scenario, title: value })}
            className="w-1/2"
            autoFocus
          />
        </div>
        <CardsSelect
          label={t("automation.scenarios.choose_template")}
          options={cardOptions}
          cols={3}
          onChange={(value) => setScenario({ ...scenario, template_id: value })}
        />
      </div>
    </Modal>
  );
}
