import React, { useContext } from "react";
import { FlowContext } from "~/contexts/flow-context";
import triggerTypeOptions from "./triggerTypeOptions";
import { useTranslation } from "react-i18next";
import TriggerKeywordsOption from "./TriggerKeywordsOption";

export default function OutgoingMessageOptions(props) {
  const { t } = useTranslation();

  const { trigger } = props;

  const { editTriggerOptions } = useContext(FlowContext);

  const options = triggerTypeOptions.outgoing_message.options;

  const handleOptionChange = (evt) => {
    editTriggerOptions(trigger.id, {
      outgoing_message_option: evt.target.value,
    });
  };

  return (
    <div className="flex flex-col space-y-3">
      <select
        className="input-select"
        value={trigger.options.outgoing_message_option}
        onChange={handleOptionChange}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {t(
              "automation.scenarios.trigger_type.outgoing_message.options." +
                option,
            )}
          </option>
        ))}
      </select>
      {trigger.options.outgoing_message_option === "keywords" && (
        <TriggerKeywordsOption trigger={trigger} />
      )}
    </div>
  );
}
