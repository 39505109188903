import React, { useState } from "react";

export default function InputAuthenticityToken() {
  const [token, setToken] = useState(null);

  if (!token) {
    setToken(document.querySelector("[name=csrf-token]").content);
    return null;
  }

  return <input type="hidden" name="authenticity_token" value={token} />;
}
