import React from "react";

export default function SkeletonLoader(props) {
  const { width = 100, height = 20, round = false, className = "" } = props;

  return (
    <div
      className={`bg-light-gray animate-pulse ${round ? "rounded-full" : "rounded-md"} ${className}`}
      style={{
        width: width + "px",
        height: height + "px",
      }}
    />
  );
}
