import React, { useContext } from "react";
import { UserContext } from "../../../contexts/user-context";
import { useTranslation } from "react-i18next";
import {
  ExclamationTriangleIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/20/solid";
import Button from "../../elements/Button";

export default function SidebarMissingPermissions() {
  const { t } = useTranslation();

  const { missingPermissions } = useContext(UserContext);

  if (!missingPermissions?.length) return null;

  return (
    <div className="p-3 rounded-lg bg-yellow-300 space-y-2">
      <div className="flex space-x-2 items-center">
        <ExclamationTriangleIcon className="w-5 h-5 text-yellow-800" />
        <div className="font-medium text-sm">
          {t("profile.instagram_account.missing_permissions.title")}
        </div>
      </div>
      <div className="text-sm">
        {t("profile.instagram_account.missing_permissions.short_description")}
      </div>
      <Button
        label={t("profile.instagram_account.missing_permissions.fix")}
        to="/profile/organization_account"
        icon={WrenchScrewdriverIcon}
        className="w-full block"
        size="small"
      />
    </div>
  );
}
