import { LinkIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import ConversionLinkMessage from "~/components/messages/ConversionLinkMessage";
import Loader from "~/components/utils/Loader";
import ResultsNode from "./ResultsNode";

export default function ConversionLinkResultsNode(props) {
  const { t } = useTranslation();

  const { data } = props;

  const { conversion_link } = data;

  return (
    <ResultsNode {...props} icon={LinkIcon}>
      <div>
        {conversion_link ? (
          <ConversionLinkMessage message={{ conversion_link }} />
        ) : (
          <div className="py-3 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl cursor-pointer nodrag opacity-75 hover:opacity-100 transition-opacity flex space-x-2 items-center">
            <Loader width={24} strokeWidth={8} />
            <div className="text-md font-medium text-center">
              {t("automation.scenarios.actions.conversion_link.loading")}
            </div>
          </div>
        )}
      </div>
    </ResultsNode>
  );
}
