import React from "react";
import { useTranslation } from "react-i18next";
import ActionNode from "./ActionNode";

export default function WaitForReplyNode(props) {
  const { t } = useTranslation();

  const { selected } = props;

  return (
    <ActionNode {...props}>
      <div
        className={`p-3 bg-white border-2 rounded-2xl max-w-xs nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all space-y-2 ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="text-md font-medium">
          {t("automation.scenarios.actions.wait_for_reply.title")}
        </div>
        <div className="text-2sm text-darker-gray">
          {t("automation.scenarios.actions.wait_for_reply.description")}
        </div>
      </div>
    </ActionNode>
  );
}
