import React, { useEffect, useState } from "react";
import { getScenarioResults } from "../../../api/automation/scenarios";
import Loader from "../../utils/Loader";
import FlowWrapper from "../flow/FlowWrapper";
import ResultsFlow from "../flow/results/ResultsFlow";

export default function ScenarioInsights(props) {
  const { scenarioId } = props;

  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [triggers, setTriggers] = useState([]);

  useEffect(async () => {
    setLoading(true);
    const { actions, triggers } = await getScenarioResults(scenarioId);
    setActions(actions);
    setTriggers(triggers);
    setLoading(false);
  }, [scenarioId]);

  if (loading) {
    return (
      <div className="flex-grow flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex-grow">
      <FlowWrapper>
        <ResultsFlow hasTriggers triggers={triggers} actions={actions} />
      </FlowWrapper>
    </div>
  );
}
