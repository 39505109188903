import React from "react";

import loginInstagram from "../../../assets/images/login_instagram.png";
import { useTranslation } from "react-i18next";
import FacebookIcon from "~/components/shared/icons/FacebookIcon";
import Button from "~/components/elements/Button";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";

export default function NoInstagram(props) {
  const { showTutorial } = props;

  const { t } = useTranslation();

  return (
    <div className="space-y-6">
      <h2 className="text-lg font-semibold mb-4">
        {t("inbox.permissions.no_instagram.title")}
      </h2>
      <div className="max-w-sm space-y-6">
        <p className="text-sm">{t("inbox.permissions.no_instagram.text")}</p>
        <div className="px-4">
          <img src={loginInstagram} className="shadow-md rounded" />
        </div>

        <Button
          href="/users/auth/instagram"
          label={t("inbox.permissions.retry")}
          style="inverse"
          icon={FacebookIcon}
          className="w-full"
        />

        {showTutorial && (
          <Button
            className="w-full"
            icon={QuestionMarkCircleIcon}
            label={t("onboarding.connect_instagram.show_me_how")}
            onClick={showTutorial}
          />
        )}
      </div>
    </div>
  );
}
