import axios from "axios";

const getCampaignResults = async (campaignId) => {
  const response = await axios.get(
    `/api/campaigns/campaigns/${campaignId}/results`,
  );
  return response.data;
};

export { getCampaignResults };
