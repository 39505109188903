import React, { useState } from "react";
import SkeletonLoader from "./SkeletonLoader";
import classNames from "../../utils/classNames";

export default function LoadingImage(props) {
  const { src, alt, className = "", width, height, hideOnError } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  if (error && hideOnError) return null;

  return (
    <>
      {loading && (
        <SkeletonLoader
          width={width}
          height={height}
          className={className + " !rounded-none"}
        />
      )}
      <img
        src={src}
        alt={alt}
        className={classNames(loading && "hidden", className)}
        onLoad={() => setLoading(false)}
        onError={() => setError(true)}
      />
    </>
  );
}
