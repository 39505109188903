import React from "react";

export default function LoveReaction(props) {
  const { className } = props;

  return (
    <div
      className={`bg-neutral-200 rounded-2xl pl-1.5 pr-2 pt-0.5 text-xs border-2 border-white text-center select-none ${className}`}
    >
      ❤️
    </div>
  );
}
