import React, { useContext, useMemo } from "react";
import { FlowContext } from "~/contexts/flow-context";
import { useTranslation } from "react-i18next";
import Input from "~/components/elements/Input";
import { Square2StackIcon } from "@heroicons/react/24/outline";
import { UserContext } from "~/contexts/user-context";
import { useCopyToClipboard } from "react-use";
import triggerTypeOptions from "./triggerTypeOptions";
import { UIContext } from "~/contexts/ui-context";
import {
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";

export default function ReferralOptions(props) {
  const { t } = useTranslation();
  const [state, copyToClipboard] = useCopyToClipboard();

  const { trigger } = props;

  const { showTooltip } = useContext(UIContext);
  const { editTriggerOptions } = useContext(FlowContext);
  const { organization } = useContext(UserContext);

  const options = triggerTypeOptions.referral.options;

  const handleOptionChange = (evt) => {
    editTriggerOptions(trigger.id, { referral_option: evt.target.value });
  };

  const urlBase = useMemo(
    () => `ig.me/m/${organization.username}`,
    [organization.username],
  );

  const handleCopyToClipboard = () => {
    showTooltip(t("shared.copied"));
    copyToClipboard(`${urlBase}?ref=${trigger.options.referral_code}`);
  };

  return (
    <div className="flex flex-col space-y-3">
      <select
        className="input-select"
        value={trigger.options.referral_option}
        onChange={handleOptionChange}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {t("automation.scenarios.trigger_type.referral.options." + option)}
          </option>
        ))}
      </select>
      {trigger.options.referral_option === "any" && (
        <div className="flex overflow-hidden px-3 py-2 rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
          <div className="flex-shrink-0 flex-grow flex items-center text-gray-500 sm:text-sm whitespace-nowrap">
            {urlBase}
          </div>
          <Square2StackIcon
            className="w-5 text-darker-gray opacity-75 hover:opacity-100 cursor-pointer"
            onClick={handleCopyToClipboard}
          />
        </div>
      )}
      {trigger.options.referral_option === "ref_code" && (
        <Input
          leadingAddon={urlBase + "?ref="}
          value={trigger.options.referral_code}
          onChange={(value) =>
            editTriggerOptions(trigger.id, { referral_code: value })
          }
          autoFocus={!trigger.options.referral_code}
          className="max-w-80"
          trailingAddon={
            <Square2StackIcon
              className="w-5 text-darker-gray opacity-75 hover:opacity-100 cursor-pointer"
              onClick={handleCopyToClipboard}
            />
          }
        />
      )}
      <Input
        label={t("automation.scenarios.trigger_type.referral.icebreaker")}
        value={trigger.options.icebreaker}
        onChange={(value) =>
          editTriggerOptions(trigger.id, { icebreaker: value })
        }
        changeOnBlur
        trailingAddon={
          <a
            href="https://developers.facebook.com/docs/messenger-platform/instagram/features/ice-breakers"
            target="_blank"
            className="block text-dark-gray opacity-75 hover:opacity-100"
          >
            <QuestionMarkCircleIcon className="w-5" />
          </a>
        }
      />
      <div className="bg-lighter-gray px-3 py-2 rounded-lg text-2sm">
        <ExclamationTriangleIcon className="w-4 inline-block mr-1" />
        {t("automation.scenarios.trigger_type.referral.icebreaker_notice")}
      </div>
    </div>
  );
}
